import Panel_Boards from "./panel/boards"
import Panel_Board from "./panel/board"
import Panel_Thread from "./panel/thread"

import { state } from "../../state";
import { views } from "../../view";
import {Page_Type} from "./page"

class BarRight {
    elem :HTMLElement;
    constructor () {
        this.elem = document.createElement("div");
        this.elem.id = "bar_right";
        this.elem.className = "bar_side";
        this.update();
    }

    panel_assert_init (page_id :any) {
        switch(page_id.type) {
            case Page_Type.BOARDS_OWNED:
            case Page_Type.BOARDS_JOINED:
            case Page_Type.BOARDS_OFFICIAL:
            case Page_Type.BOARDS_PUBLIC:
			{
				if(!views.panel.boards) views.panel.boards = new Panel_Boards(); break;
			} break;

            case Page_Type.BOARD: if(!(page_id.id in views.panel.board)) views.panel.board[page_id.id] = new Panel_Board(page_id.id); break;
            case Page_Type.THREAD: if(!(page_id.id in views.panel.thread)) views.panel.thread[page_id.id] = new Panel_Thread(page_id.id); break;
        }
    }

    update () {
        this.elem.innerHTML = "";

        let window = state.windows[state.window_selected];
        let tab = state.tabs[window.tabs[window.tab_selected]];
        let page_id = tab.history[tab.pos];

        this.panel_assert_init(page_id);
        let elem_panel = null;
        switch(page_id.type) {
            case Page_Type.BOARDS_OWNED:
            case Page_Type.BOARDS_JOINED:
            case Page_Type.BOARDS_OFFICIAL:
            case Page_Type.BOARDS_PUBLIC:
			{
				elem_panel = views.panel.boards.elem; break;
			} break;
            case Page_Type.BOARD: elem_panel = views.panel.board[page_id.id].elem; break;
            case Page_Type.THREAD: elem_panel = views.panel.thread[page_id.id].elem; break;
        }
        if(elem_panel) this.elem.appendChild(elem_panel);
    }
}

export default BarRight;