import {Page, Page_Type} from "../page";

class Page_Home extends Page {
    constructor () {
        super({type: Page_Type.HOME, id: ""});
    }

    new () {
        let elem = document.createElement("div");
        elem.className = "home";
        elem.innerHTML = "HOME";
        return({e: elem, es: elem, h: 0, s: 0});
    }
}

export default Page_Home;
