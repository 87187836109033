import { auth, auth_is_guest, auth_is_user, auth_callback_set } from "../../auth";
import { socket_send, ACTION } from "../../socket";
import {state} from "../../state";

export const auth_assert = (callback: any = () => {}) => {
	if(!auth_is_user()) {
		auth_callback_set(callback);
		Auth_Quick();
	}
	else {
		callback();
	}
}

export const Auth_Quick = () => {
    if(state.modal != "") {
        let elem_modal_found = document.querySelector("#modal");
        if(elem_modal_found) elem_modal_found!.outerHTML = "";
    }
    state.modal = "auth_quick";

    let elem_app = document.querySelector("#app");

    let elem_modal = document.createElement("div");
    elem_modal.id = "modal";
    elem_app?.appendChild(elem_modal);

    let elem_auth = document.createElement("div");
    elem_auth.className = "auth_quick";
    elem_auth.setAttribute("lc", "stop");
    elem_modal.appendChild(elem_auth);

    let elem_background = document.createElement("div");
    elem_background.className = "background";
    elem_modal.appendChild(elem_background);

    let elem_container = document.createElement("div");
    elem_container.className = "container";
    elem_auth.appendChild(elem_container);

    let elem_anonymous = document.createElement("div");
    elem_anonymous.className = "anonymous";
    elem_container.appendChild(elem_anonymous);

    let elem_or = document.createElement("div");
    elem_or.className = "or";
    elem_container.appendChild(elem_or)

    let elem_or_text = document.createElement("span");
    elem_or_text.className = "text";
    elem_or_text.textContent = "or";
    elem_or.appendChild(elem_or_text);

    let elem_username = document.createElement("div");
    elem_username.className = "username";
    elem_container.appendChild(elem_username);

    /* Anonymous */
    let elem_anonymous_header = document.createElement("div");
    elem_anonymous_header.className = "header";
    elem_anonymous_header.textContent = "Anon";
    elem_anonymous.appendChild(elem_anonymous_header);

    let elem_anonymous_button = document.createElement("button");
    elem_anonymous_button.textContent = "Continue Anonymously";
    elem_anonymous_button.onclick = (e) => {
        e.preventDefault();
        if(auth_is_user()) {
            socket_send(ACTION.PROFILE_ADD, {profile: {is_anon: true}});
        }
        else {
            let invite = localStorage.getItem("invite");
            socket_send(ACTION.AUTH_REGISTER_QUICK, {profile: {is_anon: true}, invite, agent: navigator.userAgent});
        }
    }
    elem_anonymous.appendChild(elem_anonymous_button);

    /* Username */
    let elem_username_header = document.createElement("div");
    elem_username_header.className = "header";
    elem_username_header.textContent = "User";
    elem_username.appendChild(elem_username_header);

    let elem_username_input = document.createElement("input");
    elem_username_input.type = "text";
    elem_username_input.name = "username";
    elem_username_input.placeholder = "Username";
    elem_username_input.autocomplete = "off";
    elem_username.appendChild(elem_username_input);

    let elem_username_button = document.createElement("button");
    elem_username_button.textContent = "Continue";
    elem_username_button.onclick = (e) => {
        e.preventDefault();
        let username = elem_username_input.value;
        let username_test = !username || /^\s*$/.test(username);
        if(username_test) {
            /* Throw Error */
            return;
        }

        if(auth_is_user()) {
            socket_send(ACTION.PROFILE_ADD, {profile: {is_anon: false, name: username}});
        }
        else {
            let invite = localStorage.getItem("invite");
            socket_send(ACTION.AUTH_REGISTER_QUICK, {profile: {is_anon: false, name: username}, invite, agent: navigator.userAgent});
        }
    }
    elem_username.appendChild(elem_username_button);
}