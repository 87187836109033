import {Page, Page_Type} from "../../page";

import {socket_send, ACTION} from "../../../../socket";
import {state} from "../../../../state";
import {Author_Name, Author_Avatar} from "../../../modular/author";

class Page_Admin_Channels extends Page {
    constructor () {
        super({type: Page_Type.ADMIN_CHANNELS, id: ""});
        if(!state.admin.channels) socket_send(ACTION.ADMIN_CHANNELS_GET);
    }

    new () {
        let elem = document.createElement("div");
        elem.className = "admin_channels";
        if(state.admin.channels) this.update_elem(elem);
        return({e: elem, es: elem, h: 0, s: 0});
    }

    update_elem (elem :HTMLElement) {
        elem.innerHTML = "";
        let channels = state.admin.channels;

        for(let channel_id of channels) {
            let channel = state.channels[channel_id];

            let user_left :any = channel.participants[0];
            let user_right :any = channel.participants[1];

            let elem_channel = document.createElement("div");
            elem_channel.className = "channel";
            elem_channel.setAttribute("lc", `nav:${Page_Type.CHANNEL}/${channel.id}`)

            elem_channel.appendChild(Author_Avatar(user_left, {size: "32"}));

            let elem_mid = document.createElement("div");
            elem_mid.className = "mid";
            let elem_mid_top = document.createElement("div");
            elem_mid_top.className = "top";
            elem_mid_top.appendChild(Author_Name(user_left, {badges: true})!);
            elem_mid.appendChild(elem_mid_top);
            let elem_mid_bot = document.createElement("div");
            elem_mid_bot.className = "bot";
            elem_mid_bot.appendChild(Author_Name(user_right, {badges: true})!);
            elem_mid.appendChild(elem_mid_bot);
            elem_channel.appendChild(elem_mid);

            elem_channel.appendChild(Author_Avatar(user_right, {size: "32"}));

            elem.appendChild(elem_channel);
        }
    }

    update () {
        if(!state.admin.channels) return;
        for(let i of Object.keys(this.instances)) this.update_elem(this.instances[i].e);
    }
}

export default Page_Admin_Channels;
