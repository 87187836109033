import {Content} from "../modular/content"
import {state} from "../../state"
import { Author_Name, Author_Avatar } from "../modular/author";
import {Icon} from "../modular/icon";
import { ACTION, socket_send } from "../../socket";
import { auth, auth_profile } from "../../auth";
import { ID_Type } from "../../id";
import { content_get, upload_get } from "../../helper";

export class Gallery {
    elem :HTMLElement;
    elem_media :HTMLElement;
    elem_thumbnails :HTMLElement;

	mouse_is_down = false;

	contents :any;
	content_kind: string;
    content_index :number;
    message :any;

	drag :any;
	video :any;
	overlay_is_open :boolean;

    constructor () {
		this.drag = {
			direction: "",
			start: { x: 0, y: 0, },
			limit: { x: window.innerWidth / 10, y: window.innerHeight / 4, },
		};
		this.video = {
			elem: null,
			volume: 1.0,
			is_muted: false,
			is_playing: true,
		};
		this.overlay_is_open = false;

        this.message = {};
        this.contents = [];
		this.content_kind = "";
        this.content_index = -1;

        this.elem = document.createElement("div");
        this.elem.className = "modal gallery";
		this.update();
    }

	update () {
		this.elem.innerHTML = "";

		/* Overlay */
		let elem_overlay_message = document.createElement("div");
		elem_overlay_message.className = "overlay top left";
		/* TODO: User picture and message goes here */
		//this.elem.appendChild(elem_overlay_message);

		let elem_overlay_close = document.createElement("div");
		elem_overlay_close.className = "overlay top right close";
		elem_overlay_close.innerHTML = Icon.Close;
		elem_overlay_close.onclick = () => this.close();
		this.elem.appendChild(elem_overlay_close);

		let elem_overlay_arrow_left = document.createElement("div");
		elem_overlay_arrow_left.className = "overlay left arrow";
		elem_overlay_arrow_left.innerHTML = Icon.Arrow.Left;
		elem_overlay_arrow_left.onclick = () => this.content_index_set(this.content_index-1);
		this.elem.appendChild(elem_overlay_arrow_left);

		let elem_overlay_arrow_right = document.createElement("div");
		elem_overlay_arrow_right.className = "overlay right arrow";
		elem_overlay_arrow_right.innerHTML = Icon.Arrow.Right;
		elem_overlay_arrow_right.onclick = () => this.content_index_set(this.content_index+1);
		this.elem.appendChild(elem_overlay_arrow_right);

		let elem_overlay_controls = document.createElement("div");
		elem_overlay_controls.className = "overlay bot controls";
		this.elem.appendChild(elem_overlay_controls);

		let elem_buttons = document.createElement("div");
		elem_buttons.className = "buttons";
		elem_overlay_controls.appendChild(elem_buttons);

		let elem_buttons_left = document.createElement("div");
		elem_buttons_left.className = "left";
		elem_buttons.appendChild(elem_buttons_left);

		let elem_play = document.createElement("div");
		elem_play.className = "play";
		elem_play.onclick = () => this.play_toggle();
		elem_buttons_left.appendChild(elem_play);

		let elem_volume = document.createElement("div");
		elem_volume.className = "volume";
		elem_volume.onclick = () => this.mute_toggle();
		elem_buttons_left.appendChild(elem_volume);

		let elem_buttons_right = document.createElement("div");
		elem_buttons_right.className = "right";
		elem_buttons.appendChild(elem_buttons_right);

		let elem_save = document.createElement("div");
		elem_save.className = "save";
		elem_save.innerHTML = Icon.Save;
		elem_save.onclick = () => this.content_save();
		elem_buttons_right.appendChild(elem_save);

		let elem_playback = document.createElement("div");
		elem_playback.className = "playback";
		elem_overlay_controls.appendChild(elem_playback);

		let elem_time_current = document.createElement("div");
		elem_time_current.className = "time current";
		elem_time_current.textContent = "00:00";
		elem_playback.appendChild(elem_time_current);

		let elem_slider = document.createElement("div");
		elem_slider.className = "slider";
		elem_playback.appendChild(elem_slider);

		let elem_slider_input = document.createElement("div");
		elem_slider_input.className = "input";
		elem_slider.appendChild(elem_slider_input);

		elem_slider.onmousedown = (e :any) => {
			this.mouse_is_down = true;
			if(this.video.is_playing) this.video.elem.pause();

			var rect = e.target.getBoundingClientRect();
			var value = (e.clientX - rect.left) / (rect.right-rect.left);
			this.video_t_set(value);
		}
		elem_slider.onmouseup = (e :any) => {
			this.mouse_is_down = false;
			if(this.video.is_playing) this.video.elem.play();
		}
		elem_slider.onmousemove = (e :any) => {
			if(!this.mouse_is_down) return;

			var rect = e.target.getBoundingClientRect();
			var value = (e.clientX - rect.left) / (rect.right-rect.left);
			this.video_t_set(value);
		}
		elem_slider.ontouchstart = (e: any) => {
			this.mouse_is_down = true;
			if(this.video.is_playing) this.video.elem.pause();

			var rect = e.target.getBoundingClientRect();
			var value = (e.touches[0].clientX - rect.left) / (rect.right-rect.left);
			this.video_t_set(value);

		}
		elem_slider.ontouchend = (e: any) => {
			this.mouse_is_down = false;
			if(this.video.is_playing) this.video.elem.play();
		}
		elem_slider.ontouchmove = (e: any) => {
			if(!this.mouse_is_down) return;

			var rect = e.target.getBoundingClientRect();
			var value = (e.touches[0].clientX - rect.left) / (rect.right-rect.left);
			this.video_t_set(value);
		}

		let elem_time_length = document.createElement("div");
		elem_time_length.className = "time length";
		elem_time_length.textContent = "00:00";
		elem_playback.appendChild(elem_time_length);

		/* Contents */
		let elem_contents = document.createElement("div");
		elem_contents.className = "contents";

		/*
		if(window.innerWidth < 540) elem_contents.onclick = () => this.overlay_toggle();
		elem_contents.onmousedown = (e: any) => this.on_start(e.clientX, e.clientY);
		elem_contents.onmousemove = (e: any) =>  {
			var flags = e.buttons !== undefined ? e.buttons : e.which;
			let mouse_is_down = (flags & 1) === 1;
			if(mouse_is_down) this.on_update(e.clientX, e.clientY);
		}
		elem_contents.onmouseup = (e: any) => this.on_end(e.clientX, e.clientY);
		*/

		elem_contents.onclick = () => this.overlay_toggle();
		elem_contents.ontouchstart = (e: any) => this.on_start(e.touches[0].clientX, e.touches[0].clientY);
		elem_contents.ontouchmove = (e: any) => this.on_update(e.touches[0].clientX, e.touches[0].clientY);
		elem_contents.ontouchend = (e: any) => this.on_end(e.changedTouches[0].clientX, e.changedTouches[0].clientY);

		for(let content_id of this.contents) {
			let content = this.content_get(content_id);

			let elem_content = document.createElement("div"); elem_contents.appendChild(elem_content);
			elem_content.className = "content";
			elem_content.style.width = `${window.innerWidth}px`;
			elem_content.style.height = `${window.innerHeight}px`;
			elem_content.setAttribute("rc", `menu:upload/${content_id}/right`);

			let elem_media = Content(content, {size: "full", video: {preview: false, autoplay: false, muted: false}})!;
			elem_media.style.display = "none";
			elem_content.appendChild(elem_media);
		}
		this.elem.appendChild(elem_contents);
	}

	reset () {
		this.drag.direction = "";
		this.drag.start = { x: 0, y: 0};
		this.video.elem = null;

        this.message = {};
        this.contents = [];
		this.content_kind = "";
        this.content_index = -1;
	}

	overlay_update () {
		this.play_update();
		this.volume_update();

		this.close_update();
		this.arrows_update();
		this.controls_update();
	}

	overlay_toggle () {
		this.overlay_is_open = !this.overlay_is_open;
		this.overlay_update();
	}

	volume_update () {
		let elem_play = this.elem.querySelector(".overlay.controls .buttons .volume")! as HTMLElement;
		if(this.video.is_muted) {
			elem_play.innerHTML = Icon.Audio.Mute;
		}
		else {
			elem_play.innerHTML = Icon.Audio.High;
		}
		elem_play.style.display = this.overlay_is_open ? "flex" : "none";

		let content = this.content_curr_get();
		elem_play.style.display = this.overlay_is_open && content.type.split("/")[0] == "video" ? "flex" : "none";

		if(this.video.elem) {
			this.video.is_playing ? this.video.elem.play() : this.video.elem.pause();
		}
	}

	mute_toggle () {
		this.video.is_muted = !this.video.is_muted;
		this.video.elem.muted = this.video.is_muted;
		this.volume_update();
	}

	play_update() {
		let elem_play = this.elem.querySelector(".overlay.controls .buttons .play")! as HTMLElement;
		elem_play.innerHTML = this.video.is_playing ? Icon.Video.Pause : Icon.Video.Play;
		elem_play.className = `play ${this.video.is_playing ? "playing" : "paused"}`;
		elem_play.style.display = this.overlay_is_open ? "flex" : "none";

		let content = this.content_curr_get();
		elem_play.style.display = this.overlay_is_open && content.type.split("/")[0] == "video" ? "flex" : "none";

		if(this.video.elem) {
			this.video.is_playing ? this.video.elem.play() : this.video.elem.pause();
		}
	}

	play_toggle () {
		this.video.is_playing = !this.video.is_playing;
		this.play_update();
	}

	arrows_update() {
		let elem_left = this.elem.querySelector(".overlay.left.arrow") as HTMLElement;
		let elem_right = this.elem.querySelector(".overlay.right.arrow") as HTMLElement;
		if(this.overlay_is_open && window.innerWidth >= 540) {
			elem_left.style.display = this.content_index == 0 ? "none" : "flex";
			elem_right.style.display = this.content_index == this.contents.length-1 ? "none" : "flex";
		}
		else {
			elem_left.style.display = "none";
			elem_right.style.display = "none";
		}
	}

	controls_update () {
		let elem_controls = this.elem.querySelector(".overlay.controls") as HTMLElement;
		elem_controls.style.display = this.overlay_is_open ? "flex": "none";

		let content = this.content_curr_get();
		let elem_playback = elem_controls.querySelector(".playback") as HTMLElement;
		let elem_buttons_left = elem_controls.querySelector(".buttons .left") as HTMLElement;

		if(content.type.split("/")[0] == "video") {
			elem_playback.style.display =  "flex";
			elem_buttons_left.style.display =  "flex";
		}
		else {
			elem_playback.style.display =  "none";
			elem_buttons_left.style.display =  "none";
		}
	}

	close_update () {
		let elem_close = this.elem.querySelector(".overlay.close") as HTMLElement;
		elem_close.style.display = this.overlay_is_open && window.innerWidth >= 540 ? "flex" : "none";
	}

    open () {
        let elem_app = document.querySelector("#app");
        elem_app?.appendChild(this.elem);
        state.modal = "gallery";
    }

    close () {
        if(state.modal != "") {
            let parent = this.elem.parentNode;
            if(parent) parent.removeChild(this.elem);
            state.modal = "";
        }
    }

	video_time_update (time :number, percentage :number) {
		let elem_bot = this.elem.querySelector(".overlay.bot");
		let elem_slider_input = elem_bot?.querySelector(".slider .input") as HTMLInputElement;
		elem_slider_input.style.background = `linear-gradient(to right, rgb(255, 255, 255) ${percentage}%, rgb(60, 60, 60) ${percentage}%)`;

		let time_current = elem_bot?.querySelector(".time.current") as HTMLElement;
		time_current.textContent = `${Math.floor(time/60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${Math.floor(time%60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`

		let time_length = elem_bot?.querySelector(".time.length") as HTMLElement;
		let content = this.content_curr_get();
		time_length.textContent = `${Math.floor(content.duration/60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${Math.floor(content.duration%60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`

	}

	video_time_set (time :number) {
		let content = this.content_curr_get();
		let percentage = (time/content.duration) * 100.0;
		this.video_time_update(time, percentage);
	}

	video_t_set (t :number) {
		let content = this.content_curr_get();
		let percentage = t * 100.0;
		let time = t * content.duration;
		this.video.elem.currentTime = time;
		this.video_time_update(time, percentage);
	}

	content_get (content_id :string) {
		if(this.content_kind == "upload") {
			let upload = upload_get(content_id);
			content_id = upload.content;
		}
		return(content_get(content_id));
	}

	content_curr_get () {
		let content_id = this.contents[this.content_index];
		return(this.content_get(content_id));
	}

	content_elems_get () {
		/* TODO: This should be the elements in the gallery */
		let contents = this.elem.querySelectorAll(".contents .content");
		let result = {
			"-2": this.content_index >= 2                     ? contents[this.content_index-2] as HTMLElement : null,
			"-1": this.content_index >= 1                     ? contents[this.content_index-1] as HTMLElement : null,
			 "0": this.contents.length != 0                   ? contents[this.content_index]   as HTMLElement : null,
			"+1": this.content_index < this.contents.length-1 ? contents[this.content_index+1] as HTMLElement : null,
			"+2": this.content_index < this.contents.length-2 ? contents[this.content_index+2] as HTMLElement : null,
		}
		return(result);
	}

	contents_set (contents :any, content_kind :string, content_index :number, message :any = null) {
		this.reset();
		this.message = message;
		this.contents = contents;
		this.content_kind = content_kind;

		this.update();
		this.content_index_set(content_index);
	}

	content_save () {
		let content_id = this.contents[this.content_index];
		socket_send(ACTION.PROFILE_ITEM_SAVE, {profile_id: auth_profile().id, id: {type: ID_Type.UPLOAD, id: content_id}});
	}

	content_index_set (content_index :number) {
		if(content_index < 0 || content_index > this.contents.length-1) return;
		this.content_index = content_index;

		/* Last Video */
		if(this.video.elem) {
			if(this.video.elem.tagName == "VIDEO") this.video.elem.pause();
			else console.error("VIDEO PREVIEW");
			this.video.elem = null;
		}

		/* If element is video */
		let contents_list = this.elem.querySelectorAll(".contents .content .media");
		let elem_content = contents_list[content_index]
		let content = this.content_curr_get();
		if(content.type.split("/")[0] == "video") {
			if(elem_content.tagName == "VIDEO") {
				this.video.elem = elem_content;
				this.video.elem.addEventListener("timeupdate", (e :any) => { this.video_time_set(e.target.currentTime); })

				/* Playing */
				if(this.video.is_playing && this.video.elem.paused) {
					this.video.elem.play().catch(() => {
						this.video.elem.autoplay = true;
					});
				}
				if(!this.video.is_playing && this.video.elem.playing) this.video.elem.pause();

				/* Muted */
				if(this.video.is_muted != this.video.elem.muted) this.video.elem.muted = !this.video.elem.muted;
			}
			else {
				console.error("VIDEO PREVIEW");
			}
		}

		for(let content_i = 0; content_i < contents_list.length; ++content_i) {
			let elem_content = contents_list[content_i] as HTMLElement;

			if(content_i < content_index-2 || content_i > content_index+2) {
				elem_content.style.display = "none";
				continue;
			}
			else elem_content.style.display = "block";
		}

		let elem_contents = this.elem.querySelector(".contents")! as HTMLElement;
		elem_contents.style.left = `${content_index*-window.innerWidth}px`;

		/* Overlay */
		this.overlay_update();
	}

	on_start (x :number, y :number) {
		this.drag.start.x = x;
		this.drag.start.y = y;
	}

	on_update (x :number, y :number) {
		let dx = x - this.drag.start.x;
		let dy = y - this.drag.start.y;
		if(this.drag.direction == "") {
			if(Math.abs(dx) > Math.abs(dy)) this.drag.direction = "x";
			else if(Math.abs(dx) < Math.abs(dy)) this.drag.direction = "y";
			else this.drag.direction = "";
		}

		let contents = this.elem.querySelector(".contents")! as HTMLElement;
		switch(this.drag.direction) {
		case "x": {
			if((dx >= 0 && this.content_index <= 0) || (dx <= 0 && this.content_index >= this.contents.length-1)) return;
			contents.style.left = `${(this.content_index*-window.innerWidth) + dx}px`;
		} break;
		case "y": {
			contents.style.top = `${dy}px`;
		} break;
		default: break;
		}
	}

	on_end (x :number, y :number) {
		let contents = this.elem.querySelector(".contents")! as HTMLElement;
		switch(this.drag.direction) {
		case "x": {
			let dx = this.drag.start.x-x;
			if((dx <= 0 && this.content_index <= 0) || (dx >= 0 && this.content_index >= this.contents.length-1)) {
				this.drag.direction = "";
				this.drag.start = {x: 0, y: 0};
				return;
			}

			if(Math.abs(dx) > this.drag.limit.x) this.content_index_set(this.content_index + (dx > 0 ? +1 : -1));
			else contents.style.left = `${this.content_index*-window.innerWidth}px`;
		} break;
		case "y": {
			let dy = this.drag.start.y-y;
			if(Math.abs(dy) > this.drag.limit.y) {
				this.close();
			}
			else {
				contents.style.top = `0px`;
			}
		} break;
		case "": {
			//if(window.innerWidth >= 540) this.overlay_toggle();
		} break;
		default: break;
		}
		this.drag.direction = "";
		this.drag.start = {x: 0, y: 0};
	}
}