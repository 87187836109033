import { auth, auth_is_user } from "../../auth";
import { author_is_me, author_self_other } from "../../author";
import { Perms_Manage, auth_perms_get } from "../../permission";
import { socket_send, ACTION } from "../../socket";
import { state } from "../../state";
import {menu_close} from "../modal/menu";

const THREAD_FLAG = {
    PINNED:   0x01,
    EDITED:   0x02,
    MERGE:    0x04,

    DELETED:  0x10,
    ARCHIVED: 0x20,
}


export const Menu_Thread = (thread :any) => {
    let elem = document.createElement("div");
    elem.className = "thread";

    /* Interact */
	if(auth_is_user()) {
		let elem_block_interact = document.createElement("div");
		elem_block_interact.className = "block interact";

		const is_watching = state.threads_watching && thread.board in state.threads_watching && state.threads_watching[thread.board].includes(thread.id);
		let elem_watch = document.createElement("div");
		elem_watch.className = "tab watch";
		elem_watch.textContent = is_watching ? "Unwatch" : "Watch";
		elem_watch.onclick = () => {
			is_watching ? socket_send(ACTION.THREAD_WATCHING_DEL, {thread_id: thread.id}) : socket_send(ACTION.THREAD_WATCHING_ADD, {thread_id: thread.id});
			menu_close();
		}
		elem_block_interact.appendChild(elem_watch);
		elem.appendChild(elem_block_interact);

		/* Power */
		let board = state.boards[thread.board];
		//let options :any = {thread: thread.id, board: board.id}; /* TODO: Fetching thread roles here? */
		let options :any = {board: board.id};
		let perms = auth_perms_get(options);

		let elem_block_power = null;

		/* Pin */
		if(!(thread.flags & THREAD_FLAG.PINNED) && perms[author_self_other(board.author)].obj.thread & Perms_Manage.PIN_ADD) {
            if(!elem_block_power) {
                elem_block_power = document.createElement("div");
                elem_block_power.className = "block power";
                elem.appendChild(elem_block_power);
            }

			let elem_pin_add = document.createElement("div");
			elem_pin_add.className = "tab pin_add";
			elem_pin_add.textContent = "Pin";
			elem_pin_add.onclick = () => {
				/* TODO: Category */
				let pin_category = "";
				socket_send(ACTION.BOARD_PIN_ADD, {board_id: board.id, thread_id: thread.id, pin_category: pin_category});
				menu_close();
			}
			elem_block_power.appendChild(elem_pin_add);
		}
		else if(thread.flags & THREAD_FLAG.PINNED && perms[author_self_other(board.author)].obj.thread & Perms_Manage.PIN_DEL) {
            if(!elem_block_power) {
                elem_block_power = document.createElement("div");
                elem_block_power.className = "block power";
                elem.appendChild(elem_block_power);
            }

			let elem_pin_del = document.createElement("div");
			elem_pin_del.className = "tab pin_del";
			elem_pin_del.textContent = "Unpin";
			elem_pin_del.onclick = () => {
				/* TODO: Category */
				let pin_category = "";
				socket_send(ACTION.BOARD_PIN_DEL, {board_id: thread.board, thread_id: thread.id, pin_category: pin_category});
				menu_close();
			}
			elem_block_power.appendChild(elem_pin_del);
		}

		if(perms[author_self_other(board.author)].obj.thread & Perms_Manage.DELETE) {
            if(!elem_block_power) {
                elem_block_power = document.createElement("div");
                elem_block_power.className = "block power";
                elem.appendChild(elem_block_power);
            }

			let elem_del = document.createElement("div");
			elem_del.className = "tab caution delete"
			elem_del.textContent = "Delete";
			elem_del.onclick = () => {
				socket_send(ACTION.THREAD_DEL, {thread_id: thread.id});
				menu_close();
			}
			elem_block_power.appendChild(elem_del);
		}
	}

    /* Block Bot */
    let elem_block_bot = document.createElement("div");
    elem_block_bot.className = "block bot";

    let elem_copy_id = document.createElement("div");
    elem_copy_id.className = "tab copy_id";
    elem_copy_id.textContent = "Copy ID";
    elem_copy_id.onclick = () => {
        navigator.clipboard.writeText(`<#${thread.id}>`);
        menu_close();
    }
    elem_block_bot.appendChild(elem_copy_id);

    elem.appendChild(elem_block_bot);

    return(elem);
}
