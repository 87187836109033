import {Page, Page_Type} from "../page";
import {socket_send, ACTION} from "../../../socket";
import Messages from "../../modular/messages";
import Message_Input from "../../modular/message_input";
import Board_Join from "../../modular/board_join";
import {dropzone_new} from "../../modular/dropzone";
import { state } from "../../../state";
import { auth_assert } from "../../modal/auth_quick";
import {auth_is_subscriber} from "../../../auth"
import { ID_Type } from "../../../id";

const BOARD_FLAG = {
    DELETED:  0x1,
    PRIVATE:  0x2,
    OFFICIAL: 0x4,
    PINNED:   0x8,
}

class Page_Thread extends Page {
    constructor (id :string) {
        super({type: Page_Type.THREAD, id: id});
        if(!state.threads || !(id in state.threads) || state.threads[id].level == 0) socket_send(ACTION.THREAD_GET, {thread_id:id});

		let target = {type: ID_Type.THREAD, id: id}
        let thread = state.threads[this.id.id];
		if(!auth_is_subscriber() || !("read" in thread) || thread.read.curr.date == thread.date_bumped) socket_send(ACTION.MESSAGES_GET, {target: target, key: {last: ""}});
		else socket_send(ACTION.MESSAGES_GET, {target: target, key: {around: thread.read.curr.date}});

        //socket_send(ACTION.THREAD_PARTICIPANTS_GET, {target: target});
		socket_send(ACTION.ROLES_GET, {target: target});
    }

    new () {
        let elem = document.createElement("div");
        elem.className = "thread";

        let msgs = new Messages(this.id);
        elem.appendChild(msgs.elem);

        let thread = state.threads[this.id.id];
        let board = state.boards[thread.board];

        let msg_input = null;
        //let is_joined = ((board.flags & BOARD_FLAG.PRIVATE) == 0 || board.is_joined);
        //if(is_joined) {
            msg_input = new Message_Input(this.id);
            elem.appendChild(msg_input.elem);
            dropzone_new(elem, msg_input);
        //}
        //else {
            //let board_join = new Board_Join(board.id);
            //elem.appendChild(board_join.elem);
        //}

		this.update(elem);
        return({e: elem, es: msgs.elem, h: 0, s: 0, msgs, msg_input});
    }

	update (elem :HTMLElement) {
		let thread = state.threads[this.id.id];
		let board_id = thread.board;
		if(!state.boards_me || !state.boards_me.includes(board_id)) this.add_join(elem);
	}

	add_join (elem :HTMLElement) {
		let thread = state.threads[this.id.id];
		let board_id = thread.board;

		let elem_join = document.createElement("div");
		elem_join.className = "join";
		elem_join.onclick = () => { auth_assert(() => socket_send(ACTION.BOARD_LIST_ADD, {board_id: board_id})) }
		elem.appendChild(elem_join);

		let elem_join_text = document.createElement("div");
		elem_join_text.textContent = "Join Board";
		elem_join.append(elem_join_text);
	}

	del_join (elem :HTMLElement) {
		let elem_join = elem.querySelector(".join");
		elem_join?.remove();
	}

	add_join_all () {
        for(let i of Object.keys(this.instances)) this.add_join(this.instances[i].e);
	}

	del_join_all () {
		for(let i of Object.keys(this.instances)) this.del_join(this.instances[i].e);
	}
}

export default Page_Thread;
