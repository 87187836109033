import {Page, Page_Type} from "../page"
import {state} from "../../../state";
import { ACTION, socket_send } from "../../../socket";

const BOARD_FLAG = {
    DELETED:  0x1,
    PRIVATE:  0x1,
	OFFICIAL: 0x4,
    PINNED:   0x8,
}

class Page_Boards extends Page {
    elem_header :HTMLElement | null = null;
    elem_container :HTMLElement | null = null;

    constructor (type :Page_Type) {
        super({type: type, id: ""});
    }

    new () {
        let elem = document.createElement("div");
        elem.className = "boards";

        let is_mobile = state.view.width < 540;

        this.elem_container = document.createElement("div");
        this.elem_container.className = "container";
        if(is_mobile) this.elem_container.classList.add("mobile");
        elem.appendChild(this.elem_container);

		switch(this.id.type) {
			case Page_Type.BOARDS_OWNED: socket_send(ACTION.BOARDS_OWNED_GET); break;
			case Page_Type.BOARDS_JOINED: socket_send(ACTION.BOARDS_JOINED_GET); break;
			case Page_Type.BOARDS_OFFICIAL: socket_send(ACTION.BOARDS_OFFICIAL_GET); break;
			case Page_Type.BOARDS_PUBLIC: socket_send(ACTION.BOARDS_PUBLIC_GET); break;
		}

        return({e: elem, es: elem, h: 0, s: 0});
    }

    update (elem :any) {
		this.elem_container!.innerHTML = "";

        let list :string[] = [];
        switch(this.id.type)
        {
            case Page_Type.BOARDS_OWNED:    list = state.boards_owned;    break;
            case Page_Type.BOARDS_JOINED:   list = state.boards_joined;   break;
            case Page_Type.BOARDS_OFFICIAL: list = state.boards_official; break;
            case Page_Type.BOARDS_PUBLIC:   list = state.boards_public;   break;
        }

		if(!list || list.length == 0) {
			let elem_empty = document.createElement("div");
			switch(this.id.type) {
				case Page_Type.BOARDS_OWNED:    elem_empty.textContent = "You don't own any boards";      break;
				case Page_Type.BOARDS_JOINED:   elem_empty.textContent = "You haven't joined any boards"; break;
				case Page_Type.BOARDS_OFFICIAL: elem_empty.textContent = "There are no official boards";  break;
				case Page_Type.BOARDS_PUBLIC:   elem_empty.textContent = "There are no public boards";    break;
			}
			this.elem_container!.appendChild(elem_empty);
			return;
		}

		/* Header */
		this.elem_header = document.createElement("div");
		this.elem_header.className = "header";
		switch(this.id.type) {
			case Page_Type.BOARDS_OWNED:    this.elem_header.textContent = "Owned Boards";    break;
			case Page_Type.BOARDS_JOINED:   this.elem_header.textContent = "Joined Boards";   break;
			case Page_Type.BOARDS_OFFICIAL: this.elem_header.textContent = "Official Boards"; break;
			case Page_Type.BOARDS_PUBLIC:   this.elem_header.textContent = "Public Boards";   break;
		}
		this.elem_container!.appendChild(this.elem_header);

		/* Entries */
        let elem_entries = document.createElement("div");
        elem_entries.className = "entries";
        this.elem_container!.appendChild(elem_entries);

        let elem_table = document.createElement("div");
        elem_table.className = "table";
        elem_entries.appendChild(elem_table);

        let elem_list = document.createElement("div");
        elem_list.className = "list";
        elem_entries.appendChild(elem_list);

        let is_mobile = state.view.width < 540;

        let elem_to_add_to = null;
        if(is_mobile) {
            elem_to_add_to = document.createElement("div");
            elem_to_add_to.className = "top";
            elem_table.appendChild(elem_to_add_to);
        }
        else elem_to_add_to = elem_table;

		if(this.id.type == Page_Type.BOARDS_OFFICIAL) {
			let elem_table_key = document.createElement("div");
			elem_table_key.className = "key";
			elem_table_key.textContent = "Key";
			elem_to_add_to.appendChild(elem_table_key);
		}

        let elem_table_name = document.createElement("div");
        elem_table_name.className = "name";
        elem_table_name.textContent = "Name";
        elem_to_add_to.appendChild(elem_table_name);

        let elem_table_description = document.createElement("div");
        elem_table_description.className = "description";
        elem_table_description.textContent = "Description";
        elem_table.appendChild(elem_table_description);

        let elem_table_thread_count = document.createElement("div");
        elem_table_thread_count.className = "thread_count";
        elem_table_thread_count.textContent = "Threads";
        elem_to_add_to.appendChild(elem_table_thread_count);

		let html = "";
        for(let board_id of list) {
            let board = state.boards[board_id];

			let elem_board = document.createElement("div");
			elem_board.className = `entry ${board.safety}`;
			if(board.flags & BOARD_FLAG.DELETED) elem_board.classList.add("deleted");
			elem_board.setAttribute("lc", `nav:${Page_Type.BOARD}/${board.id}`);
			elem_board.setAttribute("rc", `menu:board/${board.id}/right`);
			elem_list.appendChild(elem_board);

			let elem_safety = document.createElement("div");
			elem_safety.className = "safety";
			elem_board.appendChild(elem_safety);

			let elem_rest = null;
			if(is_mobile) {
				let elem_top = document.createElement("div");
				elem_top.className = "top";
				elem_rest = elem_top;
			}
			else {
				elem_rest = elem_board;
			}

			if(this.id.type == Page_Type.BOARDS_OFFICIAL) {
				let elem_key = document.createElement("div");
				elem_key.className = "key";
				elem_key.textContent = `/${board.key}/`;
				elem_rest.appendChild(elem_key);
			}

			let elem_name = document.createElement("div");
			elem_name.className = "name";
			elem_name.textContent = board.name;
			elem_rest.appendChild(elem_name);

			let elem_description = document.createElement("div");
			elem_description.className = "description";
			elem_description.textContent = board.description;

			let elem_thread_count = document.createElement("div");
			elem_thread_count.className = "thread_count";
			elem_thread_count.textContent = board.thread_count;

			if(is_mobile) {
				elem_board.appendChild(elem_rest);
				elem_rest.appendChild(elem_thread_count);
				elem_board.appendChild(elem_description);
			}
			else {
				elem_rest.appendChild(elem_description);
				elem_rest.appendChild(elem_thread_count);
			}
        }
    }

    update_all() {
        for(let i of Object.keys(this.instances)) this.update(this.instances[i].e);
    }
}

export default Page_Boards;
