import { Page, Page_Type } from "../../page";
import { auth, auth_exists, auth_is_user, auth_profile } from "../../../../auth";
import { Author_Avatar, Author_Color, Author_Name } from "../../../modular/author";
import { Profile_Badges, profile_name_str, user_about_me_str, Profile_Avatar } from "../../../modular/profile";
import { color_int_to_hex, color_hex_to_int, color_rgb_parse, color_hex_to_rgb } from "../../../modular/color";
import { Content } from "../../../modular/content";
import { Anon_Avatar } from "../../../modular/anon";
import { ACTION, socket_send, uploads_store } from "../../../../socket";
import { date_str } from "../../../modular/time";
import { views } from "../../../../view";
import { profile_roles_groups_get } from "../../../../permission";
import { state } from "../../../../state";
import { ID_Type } from "../../../../id";
import { UPLOAD_TARGET } from "../../../../upload";
import { file_upload } from "../../../modular/file";
import { content_get, upload_get } from "../../../../helper";

class Page_SettingsProfile extends Page {
	profile :any = {
		name: "",
		about: "",
		avatar: "",
		banner: "",
		color_name: "",
		color_primary: "",
		color_secondary: "",
	};
	packet :any = {};

	extra :any = {
		theme: "dark",
		content: {
		},
	}

	elem_buttons_bot :null | HTMLElement = null;

	constructor () {
		super({type: Page_Type.SETTINGS_PROFILE, id: ""});
		this.reset();
	}

	reset = () => {
		/* profile */
		let profile = auth_profile();
		this.profile.name = profile_name_str(profile.id, profile.name);
		this.profile.about = profile.about,
		this.profile.avatar = profile.avatar;
		this.profile.banner = profile.banner;
		this.profile.color_primary = color_int_to_hex(profile.color_primary || Author_Color(profile));
		this.profile.color_secondary = color_int_to_hex(profile.color_secondary) || this.profile.color_primary;

		let c = color_rgb_parse(color_hex_to_rgb(this.profile.color_primary!));
		this.extra.theme = (c.r + c.g + c.b) / 3.0 > 0x80 ? "light" : "dark";
		this.profile.color_name = color_int_to_hex(profile.color_name) || (this.extra.theme == "dark" ? "#ffffff" : "#000000");

		/* packet */
		this.packet  = {};
	}

    new = () => {
        let elem = document.createElement("div");
        elem.className = "settings profile";
        this.update_elem(elem);
        return({e: elem, es: elem, h: 0, s: 0});
    }

	changed = () => {
		if(this.elem_buttons_bot?.style.display == "none") {
			if(Object.entries(this.packet).length > 0) {
				this.elem_buttons_bot?.style.display = "flex";
			}
		}
	}

    file_add = (file :File, target :any, path :string, elem_status: HTMLElement) => {
		let body = {target: target};

		let xhr = new XMLHttpRequest();
		xhr.upload.onprogress = (e :any) => {
			const percentage = (e.loaded / e.total)*100.0;
			elem_status.textContent = `${Math.round(percentage)}%`;
		}
		xhr.onload = (e :any) => {
			if(xhr.status == 200) {
				const res = JSON.parse(xhr.response);
				uploads_store([res]);

				elem_status.textContent = ``;

				if(path == "banner") elem_status.className = "banner-img";
				elem_status.innerHTML = `<img src="${URL.createObjectURL(file)}" />`;
				this.profile[path] = res.id;
				this.packet[path] = res.id;

				this.changed();
			}
		}

        file_upload(xhr, file, body);
    }

    update_elem = (elem :any) => {
        elem.innerHTML = "";

		let elem_container = document.createElement("div"); elem.appendChild(elem_container);
		elem_container.className = "container";

		/* PREVIEW */
		let elem_preview = document.createElement("div"); elem_container.appendChild(elem_preview);
		elem_preview.className = "preview";
		elem_preview.style.position = "relative";
		elem_preview.style.zIndex = "100";
		if(window.innerWidth < 540) elem_preview.style.display = "none";

		let profile_width = 340;
		let elem_card = document.createElement("div"); elem_preview.appendChild(elem_card);
		elem_card.className = "profile_card";
		elem_card.style.position = "relative";
		elem_card.classList.add(this.extra.theme);

		elem_card.classList.add("colored");
		elem_card.style.backgroundImage = `linear-gradient(${this.profile.color_primary}, ${this.profile.color_primary} ${!!this.profile.banner ? "120px" : "60px"}, ${this.profile.color_secondary})`;

		let elem_outer = document.createElement("div"); elem_card.appendChild(elem_outer);
		elem_outer.className = "outer";
		elem_outer.style.background = `linear-gradient(${this.profile.color_primary}, ${this.profile.color_secondary})`

		let elem_header = document.createElement("div"); elem_card.appendChild(elem_header);
		elem_header.className = "header";

		let elem_banner = document.createElement("div"); elem_header.appendChild(elem_banner);
		if(!!this.profile.banner) {
			elem_banner.className = "banner-img";
			let content = content_get(upload_get(this.profile.banner).content);
			elem_banner.appendChild(Content(content, {thumb: false, size: "512"})!);
		}
		else {
			elem_banner.className = "banner";
			elem_banner.style.backgroundColor = this.profile.color_primary;
		}

		let elem_body = document.createElement("div"); elem_card.appendChild(elem_body)
		elem_body.className = "body";

		let elem_body_header = document.createElement("div"); elem_body.appendChild(elem_body_header);
		elem_body_header.className = "header";

		let elem_body_header_logo = document.createElement("div"); elem_body_header.appendChild(elem_body_header_logo);
		elem_body_header_logo.className = "logo";

		let elem_body_header_logo_avatar = Profile_Avatar(auth_profile(), {size: "128"});
		elem_body_header_logo.appendChild(elem_body_header_logo_avatar);

		let elem_body_header_logo_border = document.createElement("div"); elem_body_header_logo.appendChild(elem_body_header_logo_border);
		elem_body_header_logo_border.className = "logo_border";
		elem_body_header_logo_border.style.backgroundImage = `linear-gradient(${this.profile.color_primary}, ${this.profile.color_primary}`;

		//let elem_body_header_logo_border = document.createElement("div"); elem_body_header_logo.appendChild(elem_body_header_logo_border);
		//elem_body_header_logo_border.className = "logo_border";

		/*
		let elem_body_header_badges = document.createElement("div"); elem_body_header.appendChild(elem_body_header_badges);
		elem_body_header_badges.className = "badges";

		let elem_body_header_badge = document.createElement("div"); elem_body_header_badges.appendChild(elem_body_header_badge);
		elem_body_header_badge.className = "badge";

		let elem_body_header_badge_img = document.createElement("img"); elem_body_header_badge.appendChild(elem_body_header_badge_img);
		elem_body_header_badge_img.src = "./assets/img/badges/bravery.webp";
		*/

		let elem_body_body = document.createElement("div"); elem_body.appendChild(elem_body_body);
		elem_body_body.className = "body";

		let elem_body_body_username = document.createElement("div"); elem_body_body.appendChild(elem_body_body_username);
		elem_body_body_username.className = "username";
		elem_body_body_username.textContent = this.profile.name;
		elem_body_body_username.style.color = this.profile.color_name;

		/*
		let elem_name = Author_Name(auth_profile(), {color: false, badge: false, hover: false});
		elem_name.removeAttribute("lc");
		elem_name.removeAttribute("rc");
		elem_body_body_username.appendChild(elem_name);
		*/

		elem_body_body.appendChild(document.createElement("hr"));

		/* About Me */
		let elem_body_body_about = document.createElement("div"); elem_body_body.appendChild(elem_body_body_about);
		elem_body_body_about.className = "info";

		let elem_body_body_about_title = document.createElement("div"); elem_body_body_about.appendChild(elem_body_body_about_title);
		elem_body_body_about_title.className = "title";
		elem_body_body_about_title.textContent = "About Me";

		let elem_body_body_about_content = document.createElement("p"); elem_body_body_about.appendChild(elem_body_body_about_content);
		elem_body_body_about_content.textContent = this.profile.about;

		if(!this.profile.about || this.profile.about == "") {
			elem_body_body_about.style.display = "none";
		}

		/* Member Since */
		let elem_body_body_member_since = document.createElement("div"); elem_body_body.appendChild(elem_body_body_member_since);
		elem_body_body_member_since.className = "info";

		let elem_body_body_member_since_title = document.createElement("div"); elem_body_body_member_since.appendChild(elem_body_body_member_since_title);
		elem_body_body_member_since_title.className = "title";
		elem_body_body_member_since_title.textContent = "Member Since";

		let elem_body_body_member_since_content = document.createElement("p"); elem_body_body_member_since.appendChild(elem_body_body_member_since_content);
		elem_body_body_member_since_content.textContent = date_str(new Date(auth_profile().date_register));

		/* Roles */
		let elem_body_body_roles = document.createElement("div"); elem_body_body.appendChild(elem_body_body_roles);
		elem_body_body_roles.className = "roles";

		let elem_body_body_roles_title = document.createElement("div"); elem_body_body_roles.appendChild(elem_body_body_roles_title);
		elem_body_body_roles_title.className = "title";

		let has_roles = auth_is_user() && auth_profile().roles && Object.keys(auth_profile().roles).length > 0;
		elem_body_body_roles_title.textContent = has_roles ? "Roles" : "No Roles";

		if(has_roles) {
			let roles = profile_roles_groups_get(auth_profile());
			for(let role_group in roles) {
				let elem_body_body_roles_group = document.createElement("div"); elem_body_body_roles.appendChild(elem_body_body_roles_group);
				elem_body_body_roles_group.className = "group";

				let elem_body_body_roles_group_title = document.createElement("p"); elem_body_body_roles_group.appendChild(elem_body_body_roles_group_title);
				elem_body_body_roles_group_title.className = "title";

				let elem_body_body_roles_group_title_line1 = document.createElement("p"); elem_body_body_roles_group_title.appendChild(elem_body_body_roles_group_title_line1);
				elem_body_body_roles_group_title_line1.className = "line";

				let elem_body_body_roles_group_title_text = document.createElement("p"); elem_body_body_roles_group_title.appendChild(elem_body_body_roles_group_title_text);
				elem_body_body_roles_group_title_text.className = "text";
				switch(Number(role_group)) {
				case ID_Type.WEBSITE: elem_body_body_roles_group_title_text.textContent = "Website"; break;
				case ID_Type.BOARD: elem_body_body_roles_group_title_text.textContent = "Board"; break;
				case ID_Type.THREAD: elem_body_body_roles_group_title_text.textContent = "Thread"; break;
				default: { console.error("Invalid"); }
				}

				let elem_body_body_roles_group_title_line2 = document.createElement("p"); elem_body_body_roles_group_title.appendChild(elem_body_body_roles_group_title_line2);
				elem_body_body_roles_group_title_line2.className = "line";

				let elem_body_body_roles_list = document.createElement("div"); elem_body_body_roles_group.appendChild(elem_body_body_roles_list);
				elem_body_body_roles_list.className = "list";
				for(let role_id of roles[role_group]) {
					let role = state.roles[role_id];
					let elem_body_body_roles_list_role = document.createElement("div"); elem_body_body_roles_list.appendChild(elem_body_body_roles_list_role);
					elem_body_body_roles_list_role.className = "role";

					if(role.icon) {
						let elem_body_body_roles_list_role_icon = document.createElement("div"); elem_body_body_roles_list_role.appendChild(elem_body_body_roles_list_role_icon);
						elem_body_body_roles_list_role_icon.className = "icon";
						elem_body_body_roles_list_role_icon.appendChild(Content(role.icon));
					}
					else {
						let elem_body_body_roles_list_role_color = document.createElement("div"); elem_body_body_roles_list_role.appendChild(elem_body_body_roles_list_role_color);
						elem_body_body_roles_list_role_color.className = "color";
						elem_body_body_roles_list_role_color.style.background = color_int_to_hex(role.color)!;
					}

					let elem_body_body_roles_list_role_content = document.createElement("p"); elem_body_body_roles_list_role.appendChild(elem_body_body_roles_list_role_content);
					elem_body_body_roles_list_role_content.textContent = role.name;
				}

				/* NOTE: This should only ever appear if you have permissions */
				/*
				let elem_body_body_roles_list_add = document.createElement("div"); elem_body_body_roles_list.appendChild(elem_body_body_roles_list_add);
				elem_body_body_roles_list_add.className = "role add";

				let elem_body_body_roles_list_add_text = document.createElement("p"); elem_body_body_roles_list_add.appendChild(elem_body_body_roles_list_add_text);
				elem_body_body_roles_list_add_text.className = "text";
				elem_body_body_roles_list_add_text.textContent = "+";
				*/
			}
		}

		/* Message */
		let elem_body_body_message = document.createElement("div"); elem_body_body.appendChild(elem_body_body_message);
		elem_body_body_message.className = "message";

		let elem_body_body_message_input = document.createElement("input"); elem_body_body_message.appendChild(elem_body_body_message_input);
		elem_body_body_message_input.type = "text";
		elem_body_body_message_input.placeholder = `Message @${this.profile.name}`;
		elem_body_body_message_input.style.border = `1px solid ${this.profile.color_secondary}`;

		/* OPTIONS */
		let elem_options = document.createElement("div"); elem_container.appendChild(elem_options);
		elem_options.className = "options";

		{
			let elem_option_name = document.createElement("div"); elem_options.appendChild(elem_option_name);
			elem_option_name.className = "section name";

			let elem_option_name_title = document.createElement("div"); elem_option_name.appendChild(elem_option_name_title);
			elem_option_name_title.className = "title";
			elem_option_name_title.textContent = "Name";

			let elem_option_name_content = document.createElement("input"); elem_option_name.appendChild(elem_option_name_content);
			elem_option_name_content.className = "content txt";
			elem_option_name_content.placeholder = "Username";
			if(auth_profile().name && auth_profile().name != "") elem_option_name_content.value = this.profile.name;
			elem_option_name_content.oninput = (e: any) => {
				this.profile.name = profile_name_str(auth_profile().id, e.target.value);
				this.packet.name = e.target.value;

				elem_body_body_username.textContent = this.profile.name;
				elem_body_body_message_input.placeholder = `Message @${this.profile.name}`;
				this.changed();
			}
		}

		{
			let elem_option_about = document.createElement("div"); elem_options.appendChild(elem_option_about);
			elem_option_about.className = "section about";

			let elem_option_about_title = document.createElement("div"); elem_option_about.appendChild(elem_option_about_title);
			elem_option_about_title.className = "title";
			elem_option_about_title.textContent = "About Me";

			let elem_option_about_content = document.createElement("textarea"); elem_option_about.appendChild(elem_option_about_content);
			elem_option_about_content.className = "content txt";
			elem_option_about_content.placeholder = "About me"
			if(auth_profile().about && auth_profile().about != "") elem_option_about_content.value = this.profile.about;
			elem_option_about_content.oninput = (e :any) => {
				let last_about = this.profile.about;
				this.profile.about = e.target.value;
				this.packet.about = e.target.value;

				if(!last_about || last_about == "") elem_body_body_about.style.display = "block";
				else if(!this.profile.about || this.profile.about == "")      elem_body_body_about.style.display = "none";
				elem_body_body_about_content.textContent = this.profile.about;
				this.changed();
			}
		}

		{
			let elem_option_avatar = document.createElement("div"); elem_options.appendChild(elem_option_avatar);
			elem_option_avatar.className = "section avatar";

			let elem_option_avatar_title = document.createElement("div"); elem_option_avatar.appendChild(elem_option_avatar_title);
			elem_option_avatar_title.className = "title";
			elem_option_avatar_title.textContent = "Avatar";

			let elem_option_avatar_content = document.createElement("div"); elem_option_avatar.appendChild(elem_option_avatar_content);
			elem_option_avatar_content.className = "content img";

			let elem_option_avatar_change = document.createElement("div"); elem_option_avatar_content.appendChild(elem_option_avatar_change);
			let elem_option_avatar_remove = document.createElement("div"); elem_option_avatar_content.appendChild(elem_option_avatar_remove);

			/* change */
			elem_option_avatar_change.className = "change";

			let elem_option_avatar_change_label = document.createElement("label"); elem_option_avatar_change.appendChild(elem_option_avatar_change_label);
			elem_option_avatar_change_label.className = "label";
			elem_option_avatar_change_label.htmlFor = "avatar_input";

			if(!this.profile.avatar || this.profile.avatar == "") elem_option_avatar_change_label.textContent = "Upload avatar";
			else elem_option_avatar_change_label.textContent = "Change avatar";

			let elem_option_avatar_change_input = document.createElement("input"); elem_option_avatar_change.appendChild(elem_option_avatar_change_input);
			elem_option_avatar_change_input.id = "avatar_input";
			elem_option_avatar_change_input.className = "input";
			elem_option_avatar_change_input.type = "file";
			elem_option_avatar_change_input.multiple = false;
			elem_option_avatar_change_input.style.display = "none";
			elem_option_avatar_change_input.onchange = (e :any) => {
				if (!e.target.files || !e.target.files.length) return;
				elem_option_avatar_change_label.textContent = "Change avatar";
				elem_option_avatar_remove.style.display = "block";
				this.file_add(e.target.files[0], {type: UPLOAD_TARGET.PROFILE_AVATAR, id: auth_profile().id}, "avatar", elem_body_header_logo_avatar);
			}

			/* remove */
			elem_option_avatar_remove.className = "remove";
			elem_option_avatar_remove.textContent = "Remove avatar";
			if(!this.profile.avatar || this.profile.avatar == "") elem_option_avatar_remove.style.display = "none";
			elem_option_avatar_remove.onclick = (e: any) => {
				elem_option_avatar_change_label.textContent = "Upload avatar";
				elem_option_avatar_remove.style.display = "none";
				this.profile.avatar = "";
				this.packet.avatar = "";

				elem_body_header_logo_avatar.innerHTML = "";
				elem_body_header_logo_avatar.appendChild(Anon_Avatar(auth_profile()));
				this.changed();
			}
		}

		{
			let elem_option_banner = document.createElement("div"); elem_options.appendChild(elem_option_banner);
			elem_option_banner.className = "section banner";

			let elem_option_banner_title = document.createElement("div"); elem_option_banner.appendChild(elem_option_banner_title);
			elem_option_banner_title.className = "title";
			elem_option_banner_title.textContent = "Banner";

			let elem_option_banner_content = document.createElement("div"); elem_option_banner.appendChild(elem_option_banner_content);
			elem_option_banner_content.className = "content img";

			let elem_option_banner_change = document.createElement("div"); elem_option_banner_content.appendChild(elem_option_banner_change);
			let elem_option_banner_remove = document.createElement("div"); elem_option_banner_content.appendChild(elem_option_banner_remove);

			/* change */
			elem_option_banner_change.className = "change";

			let elem_option_banner_change_label = document.createElement("label"); elem_option_banner_change.appendChild(elem_option_banner_change_label);
			elem_option_banner_change_label.className = "label";
			elem_option_banner_change_label.htmlFor = "banner_input";

			if(!this.profile.banner || this.profile.banner == "") elem_option_banner_change_label.textContent = "Upload banner";
			else elem_option_banner_change_label.textContent = "Change banner";

			let elem_option_banner_change_input = document.createElement("input"); elem_option_banner_change.appendChild(elem_option_banner_change_input);
			elem_option_banner_change_input.id = "banner_input";
			elem_option_banner_change_input.className = "input";
			elem_option_banner_change_input.type = "file";
			elem_option_banner_change_input.multiple = false;
			elem_option_banner_change_input.style.display = "none";
			elem_option_banner_change_input.onchange = (e :any) => {
				if (!e.target.files || !e.target.files.length) return;
				elem_option_banner_change_label.textContent = "Change banner";
				elem_option_banner_remove.style.display = "block";
				this.file_add(e.target.files[0], {type: UPLOAD_TARGET.PROFILE_BANNER, id: auth_profile().id}, "banner", elem_banner);
			}

			/* remove */
			elem_option_banner_remove.className = "remove";
			elem_option_banner_remove.textContent = "Remove banner";
			if(!this.profile.banner || this.profile.banner == "") elem_option_banner_remove.style.display = "none";
			elem_option_banner_remove.onclick = (e :any) => {
				elem_option_banner_change_label.textContent = "Upload banner";
				elem_option_banner_remove.style.display = "none";
				this.profile.banner = "";
				this.packet.banner = "";

				elem_banner.innerHTML = "";
				elem_banner.className = "banner"
				elem_banner.style.backgroundColor = this.profile.color_primary;
				this.changed();
			}
		}

		{
			let elem_option_colors = document.createElement("div"); elem_options.appendChild(elem_option_colors);
			elem_option_colors.className = "section colors";

			let elem_option_colors_title = document.createElement("div"); elem_option_colors.appendChild(elem_option_colors_title);
			elem_option_colors_title.className = "title";
			elem_option_colors_title.textContent = "Colors";

			let elem_option_colors_content = document.createElement("div"); elem_option_colors.appendChild(elem_option_colors_content);
			elem_option_colors_content.className = "content clr";

			let elem_option_colors_content_name = document.createElement("div"); elem_option_colors_content.appendChild(elem_option_colors_content_name);

			elem_option_colors_content_name.className = "color name";
			let elem_option_colors_content_name_label = document.createElement("label");
			elem_option_colors_content_name_label.className = "label";
			elem_option_colors_content_name_label.htmlFor = "color_name_input";
			let elem_option_colors_content_name_square = document.createElement("div");
			elem_option_colors_content_name_square.className = "square";
			elem_option_colors_content_name_square.style.backgroundColor = this.profile.color_name;
			elem_option_colors_content_name_label.appendChild(elem_option_colors_content_name_square);
			let elem_option_colors_content_name_name = document.createElement("div");
			elem_option_colors_content_name_name.className = "name";
			elem_option_colors_content_name_name.textContent = "Name";
			elem_option_colors_content_name_label.appendChild(elem_option_colors_content_name_name);
			elem_option_colors_content_name.appendChild(elem_option_colors_content_name_label);
			let elem_option_colors_content_name_input = document.createElement("input");
			elem_option_colors_content_name_input.style.display = "none";
			elem_option_colors_content_name_input.type = "color";
			elem_option_colors_content_name_input.id = "color_name_input"
			elem_option_colors_content_name_input.oninput = (e :any) => {
				this.profile.color_name = elem_option_colors_content_name_input.value;
				this.packet.color_name = color_hex_to_int(elem_option_colors_content_name_input.value);

				elem_option_colors_content_name_square.style.backgroundColor = this.profile.color_name;
				elem_body_body_username.style.color = this.profile.color_name;
				this.changed();
			}
			elem_option_colors_content_name.appendChild(elem_option_colors_content_name_input);

			let elem_option_colors_content_primary = document.createElement("div"); elem_option_colors_content.appendChild(elem_option_colors_content_primary);
			elem_option_colors_content_primary.className = "color bg";
			let elem_option_colors_content_primary_label = document.createElement("label");
			elem_option_colors_content_primary_label.className = "label";
			elem_option_colors_content_primary_label.htmlFor = "color_primary_input";
			let elem_option_colors_content_primary_square = document.createElement("div");
			elem_option_colors_content_primary_square.className = "square";
			elem_option_colors_content_primary_square.style.backgroundColor = this.profile.color_primary;
			elem_option_colors_content_primary_label.appendChild(elem_option_colors_content_primary_square);
			let elem_option_colors_content_primary_name = document.createElement("div");
			elem_option_colors_content_primary_name.className = "name";
			elem_option_colors_content_primary_name.textContent = "Primary";
			elem_option_colors_content_primary_label.appendChild(elem_option_colors_content_primary_name);
			elem_option_colors_content_primary.appendChild(elem_option_colors_content_primary_label);
			let elem_option_colors_content_primary_input = document.createElement("input");
			elem_option_colors_content_primary_input.style.display = "none";
			elem_option_colors_content_primary_input.type = "color";
			elem_option_colors_content_primary_input.id = "color_primary_input"
			elem_option_colors_content_primary_input.oninput = (e :any) => {
				/* Input */
				this.profile.color_primary = elem_option_colors_content_primary_input.value;
				this.packet.color_primary = color_hex_to_int(elem_option_colors_content_primary_input.value);
				elem_option_colors_content_primary_square.style.backgroundColor = this.profile.color_primary;

				/* Theme */
				elem_card.classList.remove(this.extra.theme);
				let c = color_rgb_parse(color_hex_to_rgb(this.profile.color_primary!));
				this.extra.theme = (c.r + c.g + c.b) / 3.0 > 0x80 ? "light" : "dark";
				elem_card.classList.add(this.extra.theme);

				/* Primary */
				elem_card.style.backgroundImage = `linear-gradient(${this.profile.color_primary}, ${this.profile.color_primary} ${!!this.profile.banner ? "120px" : "60px"}, ${this.profile.color_secondary})`;
				elem_outer.style.background = `linear-gradient(${this.profile.color_primary}, ${this.profile.color_secondary})`
				if(!!!this.profile.banner) {
					elem_banner.style.backgroundColor = this.profile.color_primary;
				}
				elem_body_header_logo_border.style.backgroundImage = `linear-gradient(${this.profile.color_primary}, ${this.profile.color_primary}`;
				this.changed();
			}
			elem_option_colors_content_primary.appendChild(elem_option_colors_content_primary_input);

			let elem_option_colors_content_secondary = document.createElement("div"); elem_option_colors_content.appendChild(elem_option_colors_content_secondary);

			elem_option_colors_content_secondary.className = "color bg";
			let elem_option_colors_content_secondary_label = document.createElement("label");
			elem_option_colors_content_secondary_label.className = "label";
			elem_option_colors_content_secondary_label.htmlFor = "color_secondary_input";
			let elem_option_colors_content_secondary_square = document.createElement("div");
			elem_option_colors_content_secondary_square.className = "square";
			elem_option_colors_content_secondary_square.style.backgroundColor = this.profile.color_secondary;
			elem_option_colors_content_secondary_label.appendChild(elem_option_colors_content_secondary_square);
			let elem_option_colors_content_secondary_name = document.createElement("div");
			elem_option_colors_content_secondary_name.className = "name";
			elem_option_colors_content_secondary_name.textContent = "Secondary";
			elem_option_colors_content_secondary_label.appendChild(elem_option_colors_content_secondary_name);
			elem_option_colors_content_secondary.appendChild(elem_option_colors_content_secondary_label);
			let elem_option_colors_content_secondary_input = document.createElement("input");
			elem_option_colors_content_secondary_input.style.display = "none";
			elem_option_colors_content_secondary_input.type = "color";
			elem_option_colors_content_secondary_input.id = "color_secondary_input"
			elem_option_colors_content_secondary_input.oninput = (e :any) => {
				/* Input */
				this.profile.color_secondary = elem_option_colors_content_secondary_input.value;
				this.packet.color_secondary = color_hex_to_int(elem_option_colors_content_secondary_input.value);
				elem_option_colors_content_secondary_square.style.backgroundColor = this.profile.color_secondary;

				/* Secondary */
				elem_card.style.backgroundImage = `linear-gradient(${this.profile.color_primary}, ${this.profile.color_primary} ${!!this.profile.banner ? "120px" : "60px"}, ${this.profile.color_secondary})`;
				elem_outer.style.background = `linear-gradient(${this.profile.color_primary}, ${this.profile.color_secondary})`
				elem_body_header_logo_border.style.backgroundColor = !!this.profile.banner ? this.profile.color_primary : "";
				elem_body_body_message_input.style.border = `1px solid ${this.profile.color_secondary}`;
				this.changed();
			}
			elem_option_colors_content_secondary.appendChild(elem_option_colors_content_secondary_input);
		}

		/* Buttons */
		let elem_buttons = document.createElement("div"); elem.appendChild(elem_buttons);
		elem_buttons.className = "buttons";

		let elem_buttons_top = document.createElement("div"); elem_buttons.appendChild(elem_buttons_top);
		elem_buttons_top.className = "top";

		if(window.innerWidth < 540) {
			let elem_buttons_top_preview = document.createElement("div"); elem_buttons_top.appendChild(elem_buttons_top_preview);
			elem_buttons_top_preview.className = "button preview";
			elem_buttons_top_preview.textContent = "Preview";
			elem_buttons_top_preview.onclick = () => {
				if(elem_buttons_top_preview.textContent == "Preview") {
					elem_buttons_top_preview.textContent = "Options";
					elem_options.style.display = "none";
					elem_preview.style.display = "block";
				}
				else {
					elem_buttons_top_preview.textContent = "Preview";
					elem_options.style.display = "flex";
					elem_preview.style.display = "none";
				}
			}
		}

		this.elem_buttons_bot = document.createElement("div"); elem_buttons.appendChild(this.elem_buttons_bot);
		this.elem_buttons_bot.className = "bot";
		this.elem_buttons_bot.style.display = "none";

		let elem_buttons_bot_reset = document.createElement("div"); this.elem_buttons_bot.appendChild(elem_buttons_bot_reset);
		elem_buttons_bot_reset.className = "button reset";
		elem_buttons_bot_reset.textContent = "Reset";
		elem_buttons_bot_reset.onclick = () => {
			this.reset();
			this.update_elem(elem);
		}

		let elem_buttons_bot_save = document.createElement("div"); this.elem_buttons_bot.appendChild(elem_buttons_bot_save);
		elem_buttons_bot_save.className = "button save";
		elem_buttons_bot_save.textContent = "Save changes";
		elem_buttons_bot_save.onclick = () => {
			socket_send(ACTION.SETTINGS_PROFILE, this.packet);
			this.elem_buttons_bot?.style.display = "none";
		}
    }
}

export default Page_SettingsProfile;
