import { auth, auth_is_guest } from "../../auth";

export const file_upload = (xhr :XMLHttpRequest, file :File, body: any = {}) => {
	if(auth_is_guest()) return;

	try {
		const form_data = new FormData();
		form_data.append('content', file);
		for(let [key, value] of Object.entries(body)) {
			form_data.append(key, JSON.stringify(value));
		}

		xhr.open('POST', '/api/content'); 
		xhr.setRequestHeader("Authorization", 'Bearer ' + auth.token);
		xhr.setRequestHeader("profile_index",  localStorage.getItem("profile_index") || "");
		xhr.send(form_data);
	}
	catch(err) {
		console.error(err);
	}
}