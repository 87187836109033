export function clamp(v: number, min: number, max: number): number {
    return v < min ? min : ((v > max) ? max : v);
}

export function accumulate(arr: number[], initialValue: number) {
    return arr.reduce((acc, value) => acc + value, initialValue);
}


export type Size = {w :number, h: number};
export type GroupMediaLayout = {
    geo: {
        x :number,
        y: number,
        w: number,
        h: number,
    },
    s :number, /* rectangle sides */
}

export enum RectSide {
    N = 0,   /* NULL */
    T = 0x1, /* TOP */
    R = 0x2, /* RIGHT */
    B = 0x4, /* BOTTOM */
    L = 0x8, /* LEFT */
}

export class Layouter {
    private count :number;
    private ratios: number[];
    private proportions :string;
    private w_min :number;
    private w_max :number;
    private h_max :number;
    private spacing :number;
    private ratio_avg :number;
    private ratio_size :number;

    constructor(sizes :Size[], w_min :number, w_max :number, h_max :number, spacing :number) {
        this.count = sizes.length;
        this.ratios = Layouter.ratios_from_sizes(sizes);
        this.proportions = Layouter.proportions_from_ratios(this.ratios);

        this.w_min = w_min;
        this.w_max = w_max;
        this.h_max = h_max;
        this.spacing = spacing;
        this.ratio_avg = accumulate(this.ratios, 1) / this.count;
        this.ratio_size = w_max / this.h_max;
    }

    /* Layout */
    public layout() :GroupMediaLayout[] {
        if(!this.count) return[];

        if(this.count >= 5 || this.ratios.find((r) => r > 2)) {
            let layouter = new Layouter_Complex(this.ratios, this.ratio_avg, this.w_min, this.w_max, this.h_max, this.spacing);
            return(layouter.layout());
        }

        if(this.count == 2) return(this.layout_2());
        else if(this.count == 3) return(this.layout_3());
        else return(this.layout_4());
    }

    /* Layout 2 */
    private layout_2 = () :GroupMediaLayout[] => {
        if(this.proportions === "hh" && (this.ratio_avg > 1.4*this.ratio_size) && (this.ratios[1] - this.ratios[0] < 0.2)) return(this.layout_2_stack());
        else if(this.proportions == "hh" || this.proportions == "ss") return(this.layout_2_side_eql());
        else return(this.layout_2_side_any());
    }

    private layout_2_stack () :GroupMediaLayout[] {
        const w = this.w_max;
        const h = Math.round(Math.min(w/this.ratios[0], Math.min(w/this.ratios[1], (this.h_max-this.spacing)/2)));
        return [
            {
                geo: {x: 0, y: 0, w, h},
                s: RectSide.L | RectSide.T | RectSide.R,
            },
            {
                geo: {x: 0, y: h+this.spacing, w, h},
                s: RectSide.L | RectSide.B | RectSide.R,
            },
        ]
    }

    private layout_2_side_eql () :GroupMediaLayout[] {
        const w = (this.w_max - this.spacing) / 2;
        const h = Math.round(Math.min(w / this.ratios[0], Math.min(w / this.ratios[1], this.h_max*1)));
        return [
            {
                geo: {x: 0, y: 0, w, h},
                s: RectSide.T | RectSide.L | RectSide.B,
            },
            {
                geo: {x: w + this.spacing, y: 0, w, h},
                s: RectSide.T | RectSide.R | RectSide.B
            },
        ];
    }

    private layout_2_side_any () :GroupMediaLayout[] {
        const w_min = Math.round(this.w_min*1.5);
        const w2 = Math.min( Math.round(Math.max( 0.4 * (this.w_max - this.spacing), (this.w_max - this.spacing) / this.ratios[0] / (1 / this.ratios[0] + 1 / this.ratios[1]))), this.w_max - this.spacing - w_min);
		const w1 = this.w_max - w2 - this.spacing;
        const h = Math.min( this.h_max, Math.round(Math.min( w1 / this.ratios[0], w2 / this.ratios[1])));

        return [
            {
                geo: {x: 0, y: 0, w: w1, h},
                s: RectSide.T | RectSide.L | RectSide.B,
            },
            {
                geo: {x: w1+this.spacing, y: 0, w: w2, h},
                s: RectSide.T | RectSide.R | RectSide.B,
            },
        ];
    }

    /* Layout 3 */
    private layout_3 () :GroupMediaLayout[] {
        if(this.proportions[0] == "v") return(this.layout_3_left_1_right_2());
        else return(this.layout_3_top_1_bottom_2());
    }

    private layout_3_left_1_right_2 () :GroupMediaLayout[] {
        const h1 = this.h_max;
		const h3 = Math.round(Math.min( (this.h_max - this.spacing) / 2., (this.ratios[1] * (this.w_max - this.spacing) / (this.ratios[2] + this.ratios[1]))));
        const h2 = h1 - h3 - this.spacing;
        const wr = Math.max( this.w_min, Math.round(Math.min( (this.w_max - this.spacing) / 2., Math.min( h3 * this.ratios[2], h2 * this.ratios[1]))));
		const wl = Math.min( Math.round(h1 * this.ratios[0]), this.w_max - this.spacing - wr);
		return [
			{
				geo: {x: 0, y: 0, w: wl, h: h1},
				s: RectSide.T | RectSide.L | RectSide.B,
			},
			{
				geo: {x: wl + this.spacing, y: 0, w: wr, h: h2},
				s: RectSide.T | RectSide.R,
			},
			{
				geo: {x: wl + this.spacing, y: h2 + this.spacing, w: wr, h: h3},
				s: RectSide.B | RectSide.R,
			},
		];
    }

    private layout_3_top_1_bottom_2 () :GroupMediaLayout[] {
        const w1 = this.w_max;
		const h1 = Math.round(Math.min( w1 / this.ratios[0], (this.h_max - this.spacing) * 0.66));
		const w2 = (this.w_max - this.spacing) / 2;
		const h2 = Math.min( this.h_max - h1 - this.spacing, Math.round(Math.min( w2 / this.ratios[1], w2 / this.ratios[2])));
		const w3 = w1 - w2 - this.spacing;
		return [
			{
				geo: {x: 0, y: 0, w: w1, h: h1},
				s: RectSide.L | RectSide.T | RectSide.R,
			},
			{
				geo: {x: 0, y: h1 + this.spacing, w: w2, h: h2},
				s: RectSide.B | RectSide.L,
			},
			{
				geo: {x: w2 + this.spacing, y: h1 + this.spacing, w: w3, h: h2},
				s: RectSide.B | RectSide.R,
			},
		]

    }

    /* Layout 4 */
    private layout_4 = () :GroupMediaLayout[] => {
        if(this.proportions[0] == "h") return(this.layout_4_top_1_bottom_3())
        else return(this.layout_4_left_1_right_3())
    }

    private layout_4_top_1_bottom_3 = () :GroupMediaLayout[] => {
        const w = this.w_max;
		const h0 = Math.round(Math.min( w / this.ratios[0], (this.h_max - this.spacing) * 0.66));
		const h = Math.round( (this.w_max - 2 * this.spacing) / (this.ratios[1] + this.ratios[2] + this.ratios[3]));
		const w0 = Math.max( this.w_min, Math.round(Math.min( (this.w_max - 2 * this.spacing) * 0.4, h * this.ratios[1])));
		const w2 = Math.round(Math.max( Math.max( this.w_min * 1., (this.w_max - 2 * this.spacing) * 0.33), h * this.ratios[3]));
		const w1 = w - w0 - w2 - 2 * this.spacing;
		const h1 = Math.min( this.h_max - h0 - this.spacing, h);
	
		return [
			{
				geo: {x: 0, y: 0, w: w, h: h0},
				s: RectSide.L | RectSide.T | RectSide.R,
			},
			{
				geo: {x: 0, y: h0 + this.spacing, w: w0, h: h1},
				s: RectSide.B | RectSide.L,
			},
			{
				geo: {x: w0 + this.spacing, y: h0 + this.spacing, w: w1, h: h1},
				s: RectSide.B,
			},
			{
				geo: {x: w0 + this.spacing + w1 + this.spacing, y: h0 + this.spacing, w: w2, h: h1},
				s: RectSide.R | RectSide.B,
			},
		];
    }

    private layout_4_left_1_right_3 = () :GroupMediaLayout[] => {
        const h = this.h_max;
		const w0 = Math.round(Math.min( h * this.ratios[0], (this.w_max - this.spacing) * 0.6));
		const w = Math.round( (this.h_max - 2 * this.spacing) / (1. / this.ratios[1] + 1. / this.ratios[2] + 1. / this.ratios[3]));
		const h0 = Math.round(w / this.ratios[1]);
		const h1 = Math.round(w / this.ratios[2]);
		const h2 = h - h0 - h1 - 2 * this.spacing;
		const w1 = Math.max( this.w_min, Math.min(this.w_max - w0 - this.spacing, w));
	
		return [
			{
				geo: {x: 0, y: 0, w: w0, h: h},
				s: RectSide.T | RectSide.L | RectSide.B,
			},
			{
				geo: {x: w0 + this.spacing, y: 0, w: w1, h: h0},
				s: RectSide.T | RectSide.R,
			},
			{
				geo: {x: w0 + this.spacing, y: h0 + this.spacing, w: w1, h: h1},
				s: RectSide.R,
			},
			{
				geo: {x: w0 + this.spacing, y: h0 + h1 + 2 * this.spacing, w: w1, h: h2},
				s: RectSide.B | RectSide.R,
			},
		];
    }

    /* Utils */
    private static ratio_from_size (size :Size) {
        return(size.w/size.h);
    }

    private static ratios_from_sizes (sizes :Size[]) {
        return(sizes.map((size) => this.ratio_from_size(size)));
    }

    private static proportion_from_ratio (ratio :number) {
        if(ratio > 1.2) return("h");     /* w - Horizontal */
        else if(ratio < 0.8) return "v"; /* n - Vertical */
        else return("s");                /* q - Square */
    }

    private static proportions_from_ratios (ratios :number[]) {
        return(ratios.map((ratio) => this.proportion_from_ratio(ratio)).join(""));
    }
}
type Attempt = {
	lineCounts: number[],
	heights: number[]
};
class Layouter_Complex {
    ratios :number[];
    count :number;
    ratio_avg :number;
    w_min :number;
    w_max :number;
    h_max :number;
    spacing: number;

    constructor (ratios :number[], ratio_avg :number, w_min :number, w_max :number, h_max :number, spacing :number) {
        this.ratios = Layouter_Complex.ratios_clamp(ratios, ratio_avg);
        this.count = ratios.length;
        this.ratio_avg = ratio_avg;
        this.w_min = w_min;
        this.w_max = w_max;
        this.h_max = h_max;
        this.spacing = spacing;
    }

    private static ratios_clamp (ratios :number[], ratio_avg :number) {
        const R_MIN = 0.6667;
        const R_MAX = 2.75;
        return(ratios.map((ratio) => {return ratio_avg > 1.1 ? clamp(ratio, 1., R_MAX) : clamp(ratio, R_MIN, 1.)}))
    }

    public layout () :GroupMediaLayout[] {
        let result = Array<GroupMediaLayout>(this.count);

        let attempts: Attempt[] = [];
		const multiHeight = (offset: number, count: number) => {
			const ratios = this.ratios.slice(offset, offset + count); // warn
			const sum = accumulate(ratios, 0);
			return (this.w_max - (count - 1) * this.spacing) / sum;
		};
		const pushAttempt = (lineCounts: number[]) => {
			let heights: number[] = [];
			let offset = 0;
			for(let count of lineCounts) {
				heights.push(multiHeight(offset, count));
				offset += count;
			}
			attempts.push({lineCounts, heights}); // warn
		};

		for(let first = 1; first !== this.count; ++first) {
			const second = this.count - first;
			if(first > 3 || second > 3) {
				continue;
			}
			pushAttempt([first, second]);
		}
		for(let first = 1; first !== this.count - 1; ++first) {
			for(let second = 1; second !== this.count - first; ++second) {
				const third = this.count - first - second;
				if((first > 3)
					|| (second > ((this.ratio_avg < 0.85) ? 4 : 3))
					|| (third > 3)) {
					continue;
				}
				pushAttempt([first, second, third]);
			}
		}
		for(let first = 1; first !== this.count - 1; ++first) {
			for(let second = 1; second !== this.count - first; ++second) {
				for(let third = 1; third !== this.count - first - second; ++third) {
					const fourth = this.count - first - second - third;
					if(first > 3 || second > 3 || third > 3 || fourth > 3) {
						continue;
					}
					pushAttempt([first, second, third, fourth]);
				}
			}
		}

		let optimalAttempt: Attempt = null;
		let optimalDiff = 0;
		for(const attempt of attempts) {
			const {heights, lineCounts: counts} = attempt;
			const lineCount = counts.length;
			const totalHeight = accumulate(heights, 0) 
				+ this.spacing * (lineCount - 1);
			const minLineHeight = Math.min(...heights);
			const maxLineHeight = Math.max(...heights);
			const bad1 = (minLineHeight < this.w_min) ? 1.5 : 1;
			const bad2 = (() => {
				for(let line = 1; line !== lineCount; ++line) {
					if(counts[line - 1] > counts[line]) {
						return 1.5;
					}
				}
				return 1.;
			})();
			const diff = Math.abs(totalHeight - this.h_max) * bad1 * bad2;
			if(!optimalAttempt || diff < optimalDiff) {
				optimalAttempt = attempt;
				optimalDiff = diff;
			}
		}

		const optimalCounts = optimalAttempt.lineCounts;
		const optimalHeights = optimalAttempt.heights;
		const rowCount = optimalCounts.length;
		
		let index = 0;
		let y = 0;
		for(let row = 0; row !== rowCount; ++row) {
			const colCount = optimalCounts[row];
			const lineHeight = optimalHeights[row];
			const height = Math.round(lineHeight);

			let x = 0;
			for(let col = 0; col !== colCount; ++col) {
				const sides = RectSide.N
					| (row === 0 ? RectSide.T : RectSide.N)
					| (row === rowCount - 1 ? RectSide.B : RectSide.N)
					| (col === 0 ? RectSide.L : RectSide.N)
					| (col === colCount - 1 ? RectSide.R : RectSide.N);

				const ratio = this.ratios[index];
				const width = (col === colCount - 1)
					? (this.w_max - x)
					: Math.round(ratio * lineHeight);
				result[index] = {
					geo: {x, y, w: width, h: height},
					s: sides
				};

				x += width + this.spacing;
				++index;
			}
			y += height + this.spacing;
		}

		return result;

    }
}