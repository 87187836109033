import {state, Status} from "../../../state"
import {socket_send, ACTION} from "../../../socket"
import {auth} from "../../../auth";

import {Page, Page_Type} from "../page"
import Message_Input from "../../modular/message_input"
import Messages from "../../modular/messages";
import { dropzone_new } from "../../modular/dropzone";
import { ID_Type } from "../../../id";

class Page_Channel extends Page {
    constructor (id: string, is_tmp :boolean, look_for = true) {
        super({id: id, type: is_tmp ? Page_Type.CHANNEL_TMP : Page_Type.CHANNEL});
		if(!state.channels_loaded) socket_send(ACTION.CHANNELS_ME_GET);

		let channel = this.id.type == Page_Type.CHANNEL ? state.channels[this.id.id] : state.channels_tmp[this.id.id];
        if(!is_tmp) {
			if(state.channels) {
				if(!(this.id.id in state.channels)) socket_send(ACTION.CHANNEL_GET, {channel_id: channel.id});
				else this.update();
			}
        }
		else if(look_for) {
			socket_send(ACTION.CHANNEL_ME_GET, {must_exist: false, me: {is_anon: channel.me.is_anon, from: channel.me.from}, you: {id: channel.you.id, is_anon: channel.you.is_anon}})
		}
    }

    update () {
		let target = {type: ID_Type.CHANNEL, id: this.id.id};

        let channel = state.channels[this.id.id];
		if(!("read" in channel) || channel.read.curr.date == channel.date_bumped) socket_send(ACTION.MESSAGES_GET, {target: target, key: {last: ""}});
		else socket_send(ACTION.MESSAGES_GET, {target: target, key: {around: channel.read.curr.date}});
    }
    
    new () {
        let elem = document.createElement("div");
        elem.className = "channel";

        let msgs = new Messages(this.id);
        elem.appendChild(msgs.elem);

        /* In case we're not in this channel */
		/* me != auth */
        //if(!this.me) return {e: elem, h: 0, s: 0, msgs};

        let msg_input = new Message_Input(this.id);
        elem.appendChild(msg_input.elem);

        dropzone_new(elem, msg_input);

        return({e: elem, es: msgs.elem, h: 0, s: 0, msgs, msg_input});
    }
}

export default Page_Channel;
