import { ACTION, socket_send } from "../../socket";
import {state} from "../../state";
import { Author_Name } from "../modular/author";
import {Perms_Access} from "../../permission"

const rule_new = (rule :any, number: string, index :number) => {
    let elem = document.createElement("li");
    elem.className = "rule";

    let elem_main = document.createElement("div");
    elem_main.className = "main"
    elem.appendChild(elem_main);

    let elem_number = document.createElement("h3");
    elem_number.className = "number";
    elem_number.textContent = `${number}${index+1}.`;
    elem_main.appendChild(elem_number);

    let elem_description = document.createElement("div");
    elem_description.className = "description";
    elem_description.textContent = rule.description;
    elem_main.appendChild(elem_description);

    if("subrules" in rule && rule.subrules) {
        let elem_subrules = document.createElement("ul");
        elem_subrules.className = "subrules";

        for(let subrule_i = 0; subrule_i < rule.subrules.length; ++subrule_i) {
            let subrule = rule.subrules[subrule_i];
            let elem_subrule = rule_new(subrule, elem_number.textContent, subrule_i);
            elem_subrules.appendChild(elem_subrule);
        }

        elem.appendChild(elem_subrules);
    }

    elem.onclick = (e) => {
        e.stopPropagation();
        if(elem.querySelector(".subrules")) return;
        if(elem.classList.contains("selected")) {
            elem.classList.remove("selected");
        }
        else elem.classList.add("selected");
    }

    return(elem);
}

const rules_from_elem_list = (elem_list :Element, rules_array = state.rules) => {
    let rules :any[] = [];
    for(let rule_i = 0; rule_i < elem_list.children.length; ++rule_i) {
        let rule = rules_array[rule_i];
        let elem_rule = elem_list.children[rule_i];
        if(elem_rule.classList.contains("selected")) rules.push(rule.id);
        if(elem_rule.children.length > 1) {
            let subrules = rules_from_elem_list(elem_rule.children[1], rule.subrules);
            rules = [...rules, ...subrules];
        }
    }
    return(rules);
}

const close_modal = () => {
    if(state.modal != "") {
        let elem_modal = document.querySelector("#modal");
        if(elem_modal) elem_modal.outerHTML = "";
    }
}

export const Ban_Report = (user :any, perms :any) => {
    close_modal();
    state.modal = "ban_report";

    let elem_app = document.querySelector("#app");

    let elem_modal = document.createElement("div");
    elem_modal.id = "modal";

    let elem_background = document.createElement("div");
    elem_background.className = "background";
    elem_modal.appendChild(elem_background);

    let elem = document.createElement("div");
    elem.setAttribute("lc", "stop");
    elem.className = "ban_report";
    elem_modal.appendChild(elem);

    let elem_top = document.createElement("div");
    elem_top.className = "top";
    elem.appendChild(elem_top);

    let title = perms.other.user.access & Perms_Access.BAN ? "Ban" : "Report";

    let elem_title = document.createElement("div");
    elem_title.className = "title";
    elem_title.textContent = `${title}`;
    elem_top.appendChild(elem_title);
    elem_top.appendChild(Author_Name(user, {mention: true, badges: true})!);

    let elem_mid = document.createElement("div");
    elem_mid.className = "mid";
    elem.appendChild(elem_mid);

	let elem_violations = document.createElement("div");
	elem_violations.className = "section violations";
	elem_mid.appendChild(elem_violations);
	let elem_violations_title = document.createElement("div");
	elem_violations_title.className = "title";
	elem_violations_title.textContent = "violations";
	elem_violations.appendChild(elem_violations_title);
	let elem_violations_content = document.createElement("div");
	elem_violations_content.className = "content";
	if(state.rules && state.rules.length) {
		let elem_rules_global = document.createElement("div");
		elem_rules_global.className = "section global";

		let elem_rules_global_title = document.createElement("div");
		elem_rules_global_title.className = "title";
		elem_rules_global_title.textContent = "global";
		elem_rules_global.appendChild(elem_rules_global_title);
		let elem_rules_global_content = document.createElement("ul");
		elem_rules_global_content.className = "content";
		for(let rule_i = 0; rule_i < state.rules.length; ++rule_i) {
			let rule = state.rules[rule_i];
			let elem_rule = rule_new(rule, "", rule_i);
			elem_rules_global_content.appendChild(elem_rule);
		}
		elem_rules_global.appendChild(elem_rules_global_content);

		elem_violations_content.appendChild(elem_rules_global);
	}
	elem_violations.appendChild(elem_violations_content);

    /* Extra */
    let elem_extra = document.createElement("div");
    elem_extra.className = "section extra";
    elem_mid.appendChild(elem_extra);
    let elem_extra_title = document.createElement("div");
    elem_extra_title.className = "title";
    elem_extra_title.textContent = "Extra";
    elem_extra.appendChild(elem_extra_title);
    let elem_extra_content = document.createElement("textarea");
    elem_extra_content.className = "content";
    elem_extra_content.placeholder = "Add more information associated with the ban..."
    elem_extra.appendChild(elem_extra_content);

    /* Messages */
    if(title == "Report") {
        /* TODO: Get messages from user */
        // let elem_messages = document.createElement("div");
        // elem_messages.className = "section messages";
        // elem_mid.appendChild(elem_messages);
        // let elem_messages_title = document.createElement("div");
        // elem_messages_title.className = "title";
        // elem_messages_title.textContent = "delete messages";
        // elem_messages.appendChild(elem_messages_title);
        // let elem_messages_content = document.createElement("div");
        // elem_messages_content.className = "content";
        // let elem_delete_text = document.createElement("div");
        // elem_delete_text.className = "text";
        // elem_delete_text.textContent = "past";
        // elem_messages_content.appendChild(elem_delete_text);
        // let elem_delete_input = document.createElement("input");
        // elem_delete_input.className = "input";
        // elem_delete_input.placeholder = "ex.: 2s, 15m, 2h, 5D, 1W, 3M, 1Y";
        // elem_messages_content.appendChild(elem_delete_input);
        // elem_messages.appendChild(elem_messages_content);
    }
    else {
        let elem_messages = document.createElement("div");
        elem_messages.className = "section messages";
        elem_mid.appendChild(elem_messages);
        let elem_messages_title = document.createElement("div");
        elem_messages_title.className = "title";
        elem_messages_title.textContent = "delete messages";
        elem_messages.appendChild(elem_messages_title);
        let elem_messages_content = document.createElement("div");
        elem_messages_content.className = "content";
        let elem_delete_text = document.createElement("div");
        elem_delete_text.className = "text";
        elem_delete_text.textContent = "past";
        elem_messages_content.appendChild(elem_delete_text);
        let elem_delete_input = document.createElement("input");
        elem_delete_input.className = "input";
        elem_delete_input.placeholder = "ex.: 2s, 15m, 2h, 5D, 1W, 3M, 1Y";
        elem_messages_content.appendChild(elem_delete_input);
        elem_messages.appendChild(elem_messages_content);
    }

    /* Buttons */
    let elem_bot = document.createElement("div");
    elem_bot.className = "bot";
    elem.appendChild(elem_bot);

    let elem_button_cancel = document.createElement("div");
    elem_button_cancel.className = "button cancel";
    elem_button_cancel.textContent = "CANCEL";
    elem_button_cancel.onclick = () => {close_modal()};
    elem_bot.appendChild(elem_button_cancel);

    let elem_button_ban = document.createElement("div");
    elem_button_ban.className = "button ban_report";
    elem_button_ban.textContent = `${title.toUpperCase()}`;
    elem_button_ban.onclick = () => {
        let user_id = {is_anon: user.is_anon, id: user.id};

        let rules :any[] = rules_from_elem_list(elem_rules_global_content);
        if(rules.length == 0) return;

        let extra = elem_extra_content.value;

        if(title == "Report") {
            socket_send(ACTION.USER_REPORT, {user: user_id, rules, extra});
        }
        else if(title == "Ban") {
            let messages = new Date();
            let msg_nums = elem_delete_input.value.match(/\d+/g);
            let msg_fmts =  elem_delete_input.value.match(/[a-zA-Z]+/g);
            if(msg_nums || msg_fmts) {
                if(msg_nums!.length != 1 || msg_fmts!.length != 1) { console.error("Wrong date format"); return; }

                let msg_num = parseInt(msg_nums![0]);
                let msg_fmt =  msg_fmts![0];

                let fmt :any = {}; fmt.s = 1000; fmt.m = fmt.s*60; fmt.h = fmt.m*60; fmt.D = fmt.h*24; fmt.W = fmt.D*7; fmt.M = fmt.D*30; fmt.Y = fmt.D*365;
                if(!(msg_fmt in fmt)) { console.error("Wrong date format"); return; }
                messages.setTime(messages.getTime() - msg_num! * fmt[msg_fmt]); 
            }
            socket_send(ACTION.USER_BAN, {user: user_id, rules, extra, messages});
        }
        else {
            /* TODO: Ban/Report */
        }

        close_modal();
    }
    elem_bot.appendChild(elem_button_ban);

    elem_app?.appendChild(elem_modal);
}