import {Author_Color, author_name_str_from_id} from "./author";
import {color_int_to_hex} from "./color";
import {Icon} from "./icon";
import {Content} from "./content";
import { content_get, profile_get, upload_get } from "../../helper";

export const profile_name_str = (id: string, name: string) => {
	if(!name || name == "") return(`User ${author_name_str_from_id(id)}`);
	else return(name);
}

export const profile_about_me_str = (about_me: string) => {
	if(!about_me || about_me == "") return(`There is nothing about me.`);
	else return(about_me);
}

export const Profile_Badges = (profile :any) => {
    let elem = document.createElement("div");
    elem.className = "profile_badges";

    if(profile.is_subscriber) {
        let elem_member = document.createElement("div");
        elem_member.className = "member";
        let elem_symbol = document.createElement("div");
        elem_symbol.className = "symbol";
        elem_symbol.textContent = "M";
        elem_member.appendChild(elem_symbol);
        elem.appendChild(elem_member);
    }

    return(elem);
}

export const Profile_Name = (profile :any, options :any = {}) => {
    if(!profile) return null

	/* Defaults */
	if(!("color" in options)) options.color = true;
	if(!("badge" in options)) options.badge = true;
	if(!("profile" in options)) options.profile = null;

    let elem = document.createElement("div");
    elem.className = "profile_name";

    let elem_name = document.createElement("div");
    elem_name.className = "name";
    elem_name.textContent = profile_name_str(profile.id, profile.name);
    elem.appendChild(elem_name);

	if(options.color) {
		if("color_name" in profile && profile.color_name) {
			elem_name.style.color = color_int_to_hex(profile.color_name);
		}
	}

    if(options.profile) {
        elem.setAttribute("lc", `modal:profile/${profile.id}/${options.profile}`);
        elem.setAttribute("rc", `menu:profile/${profile.id}/${options.profile}`);
    }

	//if(options.badge) elem.appendChild(Profile_Badges(profile));

    return(elem);
}

export const Profile_Avatar = (profile :any, options :any = {}) => {
    if(!profile) return null;

	if(!("size" in options)) options.size = "32";

    let elem = document.createElement("div");
    elem.className = "profile_avatar";

    if(profile.avatar) {
		let avatar = upload_get(profile.avatar);
		let content = content_get(avatar.content);
		if(content) elem.appendChild(Content(content, {size: options.size})!);
	}
    else {
        elem.innerHTML = Icon.Author.Default;

        /* Color */
        let color = Author_Color(profile);
        let elem_svg :HTMLElement = (elem.children[0] as HTMLElement);
        elem_svg.style.backgroundColor = color_int_to_hex(color);
    }

    if(options.profile) {
        elem.setAttribute("lc", `modal:profile/${profile.id}/${options.profile}`);
        elem.setAttribute("rc", `menu:profile/${profile.id}/${options.profile}`);
    }

    return(elem);
}

export const Profile_Entry = (profile :any, options :any = {}) => {
    if(!profile) return null;

	if(!("size" in options)) options.size = "32";
	if(!("profile" in options)) options.profile = null;

    let elem = document.createElement("div");
    elem.className = "profile_entry";
	elem.appendChild(Profile_Avatar(profile, {...options, profile: false})!);

	let elem_right = document.createElement("div");
	elem_right.className = "right";
	elem_right.appendChild(Profile_Name(profile, {...options, profile: false})!);

	let elem_status = document.createElement("div");
	elem_status.className = "status";
	elem_right.appendChild(elem_status);
	elem.appendChild(elem_right);

    if(options.profile) {
        elem.setAttribute("lc", `modal:profile/${profile.id}/${options.profile}`);
        elem.setAttribute("rc", `menu:profile/${profile.id}/${options.profile}`);
    }

    return(elem);
}