import { Ban_Report } from "../modal/ban";
import {menu_close} from "../modal/menu";
import { state } from "../../state";
import {views} from "../../view";
import {ACTION, socket_send} from "../../socket"
import {Perms_Access, Perms_Manage, auth_perms_get} from "../../permission";
import { author_is_me } from "../../author";
import { auth, auth_is_user, auth_profile } from "../../auth";
import { channel_page_find_or_yield } from "../../model/channel";
import { nav_to } from "../../router";
import { ID_Type } from "../../id";

const author_mention_get = (author :any) => {
    return `<${author.is_anon ? "!" : "@"}${author.id}>`;
}

export const Menu_Author = (author :any, target :any) => {
    let elem = document.createElement("div");
    elem.className = "author";

    if(auth_is_user()) {
		let options :any = {};
		if(target.type == ID_Type.THREAD) {
			options.thread = target.id;
			options.board = state.threads[target.id].board;
		}
		if(target.type == ID_Type.BOARD) options.board = target.id;
		let perms = auth_perms_get(options);

		let elem_interact = document.createElement("div");
		elem_interact.className = "block interact";
		elem.appendChild(elem_interact);

		/* Profile */
		// let elem_profile = document.createElement("div");
		// elem_profile.className = "tab profile";
		// elem_profile.textContent = "Profile";
		// elem_profile.onclick = () => {
		// 	menu_close();
		// }
		// elem_interact.appendChild(elem_profile);

		/* Mention */
        if(target.type == ID_Type.THREAD || target.type == ID_Type.CHANNEL) {

            let elem_mention = document.createElement("div");
            elem_mention.className = "tab mention";
            elem_mention.textContent = "Mention";
            elem_mention.onclick = () => {
                let view = null;
                if(target.type == ID_Type.THREAD) view = views.page.thread[target.id];
                else if(target.type == ID_Type.CHANNEL) view = views.page.channel[target.id];

                let msg_input = view.instances[state.window_selected].msg_input;
                msg_input.text_insert(`${author_mention_get(author)} `);

                menu_close();
            }
            elem_interact.appendChild(elem_mention);
        }

		/* Message */
		// if(!author_is_me(author)) {
		// 	let elem_message = document.createElement("div");
		// 	elem_message.className = "tab message";
		// 	elem_message.textContent = "Message";
		// 	elem_message.onclick = () => {
		// 		let me  = {...auth_profile(), target: target};
		// 		let you = {...author,         target: target};
		// 		let page_id = channel_page_find_or_yield(me, you);

		// 		nav_to(page_id);
		// 		menu_close();
		// 	}
		// 	elem_interact.appendChild(elem_message);
		// }

        if(!author_is_me(author)) {
            let elem_block_access = document.createElement("div");
            elem_block_access.className = "block access";
            let elem_ban_report = document.createElement("div");
            elem_ban_report.className = "tab caution ban_report";
			elem_ban_report.textContent = perms.other.user.access & Perms_Access.BAN ? "Ban" : "Report";
            elem_ban_report.onclick = () => {
                Ban_Report(author, perms);
                menu_close();
            }
            elem_block_access.appendChild(elem_ban_report);
            elem.appendChild(elem_block_access);
        }

		if(perms.other.obj.website & Perms_Manage.EDIT) {
            let elem_block_admin = document.createElement("div");
            elem_block_admin.className = "block admin";
            let elem_subscription = document.createElement("div");
            elem_subscription.className = "tab subscription";
            elem_subscription.textContent = "Give subscription";
            elem_subscription.onclick = () => {
                socket_send(ACTION.ADMIN_SUBSCRIPTION, {author: {id: author.id, is_anon: author.is_anon}});
                menu_close();
            }
            elem_block_admin.appendChild(elem_subscription);
            elem.appendChild(elem_block_admin);
        }

        /*
        if((author_is_me() && perms.user.self.roles & (Perms_Roles.ADD|Perms_Roles.DEL)) ||
          (!author_is_me() && perms.user.other.roles & (Perms_Roles.ADD|Perms_Roles.DEL))) {
            let elem_block_roles = document.createElement("div");
            elem_block_roles.className = "block roles";

            let elem_roles_tab = document.createElement("div");
            elem_roles_tab.className = "tab caution roles_tab";
            elem_roles_tab.textContent = "Roles";
            elem_block_roles.appendChild(elem_roles_tab);

            let elem_roles = document.createElement("div");
            elem_roles.className = "roles";
            elem_roles.style.position = "absolute";
            elem_roles.style.left = "calc(100% + 0px)";
            elem_roles.style.top = "0px";
            if(state.roles) {
                let elem_roles_website = document.createElement("div");
                elem_roles_website.className = "block website";
                let elem_roles_website_title = document.createElement("div");
                elem_roles_website_title.className = "title";
                elem_roles_website_title.textContent = "WEBSITE";
                elem_roles_website.appendChild(elem_roles_website_title);
                for(let role_id in state.roles) {
                    let role = state.roles[role_id];
                    let elem_role = document.createElement("div");
                    elem_role.className = "tab caution role";
                    let elem_role_name = document.createElement("div");
                    elem_role_name.className = "name";
                    elem_role_name.textContent = role.name;
                    elem_role.appendChild(elem_role_name);
                    let elem_role_checkbox = document.createElement("input");
                    elem_role_checkbox.type = "checkbox";
                    elem_role_checkbox.className = "checkbox";
                    elem_role_checkbox.checked = false;
                    elem_role_checkbox.onclick = (e) => {
                        e.stopPropagation();
                        if(elem_role_checkbox.checked) {
                            socket_send(ACTION.ROLE_ADD, {user: {is_anon: false, id: user.id}, role_id: role.id});
                        }
                        else {
                            socket_send(ACTION.ROLE_DEL, {user: {is_anon: false, id: user.id}, role_id: role.id});
                        }
                    }
                    elem_role.appendChild(elem_role_checkbox);

                    elem_role.onclick = (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        elem_role_checkbox.checked = !elem_role_checkbox.checked;
                        if(elem_role_checkbox.checked) {
                            socket_send(ACTION.ROLE_ADD, {user: {is_anon: false, id: user.id}, role_id: role.id});
                        }
                        else {
                            socket_send(ACTION.ROLE_DEL, {user: {is_anon: false, id: user.id}, role_id: role.id});
                        }
                    }
                    elem_roles_website.appendChild(elem_role);
                }
                elem_roles.appendChild(elem_roles_website);
            }
            elem_roles_tab.appendChild(elem_roles);
            elem.appendChild(elem_block_roles);
        }
        */

    }

    /* Block Bot */
    let elem_block_bot = document.createElement("div");
    elem_block_bot.className = "block bot";

    let elem_copy_id = document.createElement("div");
    elem_copy_id.className = "tab copy_id";
    elem_copy_id.textContent = "Copy ID";
    elem_copy_id.onclick = () => {
        navigator.clipboard.writeText(author_mention_get(author));
        menu_close();
    }
    elem_block_bot.appendChild(elem_copy_id);

    elem.appendChild(elem_block_bot);

    return(elem);
}
