export enum ID_Type {
    WEBSITE = 0,

    MESSAGE = 1,
    CONTENT = 2,

    CHANNEL = 3,
    GROUP = 4,
    THREAD = 5,
    BOARD = 6,
    PROFILE = 7,

    IP = 8,
    ANON = 9,
    USER = 10,
    ROLE = 11,
    /* tag? */

    /* New */
    INVITE = 12,
    PAYMENT = 13,
    REPORT = 14,
	FOLDER = 15,

	UPLOAD = 16,
	DOWNLOAD = 17,
};

const id_mention = (type: ID_Type, id: string) => {
	switch(type) {
	case ID_Type.MESSAGE: {
	} break;
	case ID_Type.WEBSITE:
	{
		console.error("Can't mention this");
	} break;
	}
}