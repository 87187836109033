import {Page_Type} from "./page"
import Page_Admin from "./page/admin/admin";
import Page_Admin_Channels from "./page/admin/channels";
import Page_Admin_Members from "./page/admin/members";
import Page_Mod_Reports from "./page/mod/reports";

import Page_Home from "./page/home";
import Page_Tips from "./page/tips";
import Page_Rules from "./page/rules";
import Page_Invite from "./page/invite";
import Page_Invites from "./page/invites";
import Page_subscription from "./page/subscription";
import Page_subscriptionUpdate from "./page/subscription_update";
import Page_Boards from "./page/boards";
import Page_Messages from "./page/messages";
import Page_EmojiSet from "./page/emoji_set";
import Page_SettingsAccount from "./page/settings/account";
import Page_SettingsProfile from "./page/settings/profile";
import Page_SettingsSafety from "./page/settings/safety";
import Page_SettingsNotifs from "./page/settings/notifs";

import Page_Board from "./page/board";
import Page_Thread from "./page/thread";
import Page_Channel from "./page/channel";

import {state} from "../../state"
import {views} from "../../view";
import Tab from "./tab";
import Page_ProfileContentContent from "./page/profile_content";

class Window  {
    index :number;
    elem :HTMLElement;
    elem_bar_top: HTMLElement;
    elem_page :HTMLElement;

    constructor (index :number) {
        this.index = index;
        this.elem = document.createElement("div");
        this.elem.className = "window";
        this.elem.setAttribute("i", `${this.index}`)
        this.elem_bar_top = document.createElement("div");
        this.elem_bar_top.className = "bar_top";
		this.elem_bar_top.onwheel = (e :any) => {
			e.preventDefault();
			this.elem_bar_top.scrollBy({left: e.deltaY < 0 ? -30 : 30});
		}
        this.elem_page = document.createElement("div");
        this.elem_page.className = "page";
        this.update();
    }

    select () {
        if(state.window_selected == this.index) return;
        state.window_selected_last = state.window_selected;
        state.window_selected = this.index;
        views.window[state.window_selected_last].elem.classList.remove("selected");
        this.elem.classList.add("selected");
        views.bar_right.update();
    }

    page_assert_init(page_id :any) {
        switch(page_id.type) {
        case Page_Type.ADMIN: if(!views.page.admin.admin) views.page.admin.admin = new Page_Admin(); break;
        case Page_Type.ADMIN_MEMBERS: if(!views.page.admin.members) views.page.admin.members = new Page_Admin_Members(); break;
        case Page_Type.ADMIN_CHANNELS: if(!views.page.admin.channels) views.page.admin.channels = new Page_Admin_Channels(); break;
        case Page_Type.MOD_REPORTS: if(!views.page.mod.reports) views.page.mod.reports = new Page_Mod_Reports(); break;

        case Page_Type.HOME: if(!views.page.home) views.page.home = new Page_Home(); break;
        case Page_Type.TIPS: if(!views.page.tips) views.page.tips = new Page_Tips(); break;
        case Page_Type.RULES: if(!views.page.rules) views.page.rules = new Page_Rules(); break;
        case Page_Type.INVITE: if(!views.page.invite) views.page.invite = new Page_Invite(page_id.id); break;
        case Page_Type.INVITES: if(!views.page.invites) views.page.invites = new Page_Invites(); break;
        case Page_Type.SUBSCRIPTION: if(!views.page.subscription) views.page.subscription = new Page_subscription(); break;
        case Page_Type.SUBSCRIPTION_UPDATE: if(!views.page.subscription_update) views.page.subscription_update = new Page_subscriptionUpdate(); break;

        case Page_Type.SETTINGS_ACCOUNT: if(!views.page.settings.account) views.page.settings.account = new Page_SettingsAccount(); break;
        case Page_Type.SETTINGS_PROFILE: if(!views.page.settings.profile) views.page.settings.profile = new Page_SettingsProfile(); break;
        case Page_Type.SETTINGS_SAFETY: if(!views.page.settings.safety) views.page.settings.safety = new Page_SettingsSafety(); break;
        case Page_Type.SETTINGS_NOTIFS: if(!views.page.settings.notifs) views.page.settings.notifs = new Page_SettingsNotifs(); break;

        case Page_Type.MESSAGES: if(!views.page.messages) views.page.messages = new Page_Messages(); break;

        case Page_Type.EMOJI_SET: if(!(page_id.id in views.page.emoji_set)) views.page.emoji_set[page_id.id] = new Page_EmojiSet(page_id.id); break;

        case Page_Type.BOARDS_OWNED: if(!views.page.boards_owned) views.page.boards_owned = new Page_Boards(page_id.type); break;
        case Page_Type.BOARDS_JOINED: if(!views.page.boards_joined) views.page.boards_joined = new Page_Boards(page_id.type); break;
        case Page_Type.BOARDS_OFFICIAL: if(!views.page.boards_official) views.page.boards_official = new Page_Boards(page_id.type); break;
        case Page_Type.BOARDS_PUBLIC: if(!views.page.boards_public) views.page.boards_public = new Page_Boards(page_id.type); break;

        case Page_Type.PROFILE_CONTENT: if(!(page_id.id in views.page.profile_content)) views.page.profile_content[page_id.id] = new Page_ProfileContentContent(page_id.id); break;
        case Page_Type.BOARD: if(!(page_id.id in views.page.board)) views.page.board[page_id.id] = new Page_Board(page_id.id); break;
        case Page_Type.THREAD: if(!(page_id.id in views.page.thread)) views.page.thread[page_id.id] = new Page_Thread(page_id.id); break;
        case Page_Type.CHANNEL: if(!(page_id.id in views.page.channel)) views.page.channel[page_id.id] = new Page_Channel(page_id.id, false); break;
        case Page_Type.CHANNEL_TMP: if(!(page_id.id in views.page.channel_tmp)) views.page.channel_tmp[page_id.id] = new Page_Channel(page_id.id, true); break;
        default: throw Error("Wrong page type");
        }
    }

    page_find_or_create(page_id :any) {
        this.page_assert_init(page_id);

        let result = null;
        switch(page_id.type) {
        case Page_Type.ADMIN: result = views.page.admin.admin; break;
        case Page_Type.ADMIN_MEMBERS: result = views.page.admin.members; break;
        case Page_Type.ADMIN_CHANNELS: result = views.page.admin.channels; break;
        case Page_Type.MOD_REPORTS: result = views.page.mod.reports; break;

        case Page_Type.HOME: result = views.page.home; break;
        case Page_Type.TIPS: result = views.page.tips; break;
        case Page_Type.RULES: result = views.page.rules; break;
        case Page_Type.INVITE: result = views.page.invite; break;
        case Page_Type.INVITES: result = views.page.invites; break;
        case Page_Type.SUBSCRIPTION: result = views.page.subscription; break;
        case Page_Type.SUBSCRIPTION_UPDATE: result = views.page.subscription_update; break;

        case Page_Type.SETTINGS_ACCOUNT: result = views.page.settings.account; break;
        case Page_Type.SETTINGS_PROFILE: result = views.page.settings.profile; break;
        case Page_Type.SETTINGS_SAFETY: result = views.page.settings.safety; break;
        case Page_Type.SETTINGS_NOTIFS: result = views.page.settings.notifs; break;

        case Page_Type.MESSAGES: result = views.page.messages; break;

        case Page_Type.EMOJI_SET: result = views.page.emoji_set[page_id.id]; break;

        case Page_Type.BOARDS_OWNED: result = views.page.boards_owned; break;
        case Page_Type.BOARDS_JOINED: result = views.page.boards_joined; break;
        case Page_Type.BOARDS_OFFICIAL: result = views.page.boards_official; break;
        case Page_Type.BOARDS_PUBLIC: result = views.page.boards_public; break;

        case Page_Type.BOARD: result = views.page.board[page_id.id]; break;
        case Page_Type.THREAD: result = views.page.thread[page_id.id]; break;
        case Page_Type.CHANNEL: result = views.page.channel[page_id.id]; break;
        case Page_Type.CHANNEL_TMP: result = views.page.channel_tmp[page_id.id]; break;
        case Page_Type.PROFILE_CONTENT: result = views.page.profile_content[page_id.id]; break;
        default: throw Error("Wrong page type");
        }
        result.elem_assert(this.index);
        return(result);
    }

    update_tab() {
        let window_state = state.windows[this.index];
        let tab_i = window_state.tabs[window_state.tab_selected];
        views.tab[tab_i].update();
    }

    update_bar () {
        this.elem_bar_top.innerHTML = "";
        let window_state = state.windows[this.index];
        for(let window_tab_i = 0; window_tab_i < window_state.tabs.length; ++window_tab_i) {
            let tab_i = window_state.tabs[window_tab_i];
            let tab_is_selected = window_state.tab_selected == window_tab_i;

            if(!(tab_i in views.tab)) views.tab[tab_i] = new Tab(tab_i, this.index, window_tab_i);
            views.tab[tab_i].update();

            let elem_tab = views.tab[tab_i].elem;
            this.elem_bar_top.appendChild(elem_tab);
        }
    }

    update_page () {
        this.elem_page.innerHTML = "";

        let window_state :any = state.windows[this.index];
        let tab = state.tabs[window_state.tabs[window_state.tab_selected]];
        let page_id = tab.history[tab.pos];

        let page = this.page_find_or_create(page_id);
        let instance = page.instances[this.index];

        this.elem_page.appendChild(instance.e);
        if(instance.es) instance.es.scroll(0, instance.s);
		if("msgs" in instance) instance.msgs.update_read();
    }

    update_tab_and_page () {
        this.update_tab();
        this.update_page();
    }

    update () {
        if(!("windows" in state)) return;
        if(!("tabs" in state)) return;
        this.elem.innerHTML = "";
        if(state.window_selected == this.index) this.elem.classList.add("selected");

        this.update_bar();
        this.elem.appendChild(this.elem_bar_top);

        this.update_page();
        this.elem.appendChild(this.elem_page);
    }
}

export default Window;
