import { ACTION, socket_send } from "../../../socket";
import { state } from "../../../state";
import {Page, Page_Type} from "../page";
import {ID_Type} from "../../../id";
import { Perms_Manage, auth_perms_get } from "../../../permission";
import { auth } from "../../../auth";

class Page_Rules extends Page {
    mode :string;

    constructor () {
        super({type: Page_Type.RULES, id: ""});
        this.mode = "view";
    }

    new () {
        let elem = document.createElement("div");
        elem.className = "rules";
        this.update_elem(elem);
        return({e: elem, es: elem, h: 0, s: 0});
    }

    rules_from_elem_list (elem_list :Element) {
        let rules :any[] = [];
        for(let rule_i = 0; rule_i < elem_list.children.length; ++rule_i) {
            let rule :any = {};
            let elem_rule = elem_list.children[rule_i];

            let elem_rule_description = elem_rule.children[0].querySelector(".description");
            rule.description = elem_rule_description?.textContent;

            if(elem_rule.children.length > 1) rule.subrules = this.rules_from_elem_list(elem_rule.children[1]);
            rules.push(rule);
        }
        return(rules);
    }

    mode_toggle_elem (elem :HTMLElement) {
        if(this.mode == "view") {
            this.mode = "edit";

            let elem_buttons = elem.querySelector(".buttons");
            elem_buttons!.innerHTML = "";

            let elem_button_cancel = document.createElement("div");
            elem_button_cancel.className = "cancel";
            elem_button_cancel.textContent = "CANCEL";
            elem_button_cancel.onclick = () => this.update_elem(elem);
            elem_buttons?.appendChild(elem_button_cancel);

            let elem_button_save = document.createElement("div");
            elem_button_save.className = "save";
            elem_button_save.textContent = "SAVE";
            elem_button_save.onclick = () => {
                state.rules = [];
                let elem_list = elem.querySelector(".container ul");
                if(elem_list) state.rules = this.rules_from_elem_list(elem_list);
                else this.update_elem(elem);
                socket_send(ACTION.RULESET_UPDATE, {target: {id: null, type: ID_Type.WEBSITE}, rules: state.rules});
            }
            elem_buttons?.appendChild(elem_button_save);


            if(state.rules.length == 0) {
                let elem_container = elem.querySelector(".container");
                elem_container!.innerHTML = `<ul><li class="rule"><div class="main"><h3 class="number">${"1."}</h3><div class="description" contenteditable="true">${"New rule."}</div></div></li></ul>`;
            }
            else {
                let elem_rules = elem.querySelectorAll(".rule");
                for(let rule_i = 0; rule_i < elem_rules.length; ++rule_i) {
                    let elem_rule = elem_rules[rule_i];
                    
                    let elem_description = elem_rule.querySelector(".description");
                    elem_description!.setAttribute("contenteditable", "true");
                    elem_description?.classList.add("selectable");
                }
            }
        }
        else if(this.mode == "edit") {
            this.mode = "view";
        }
    }

    rule_new (rule :any, number: string, index :number) {
        let elem = document.createElement("li");
        elem.className = "rule";

        let elem_main = document.createElement("div");
        elem_main.className = "main"
        elem.appendChild(elem_main);

        let elem_number = document.createElement("h3");
        elem_number.className = "number";
        elem_number.textContent = `${number}${index+1}.`;
        elem_main.appendChild(elem_number);

        let elem_description = document.createElement("div");
        elem_description.className = "description";
        elem_description.textContent = rule.description;
        elem_main.appendChild(elem_description);

        if("subrules" in rule && rule.subrules) {
            let elem_subrules = document.createElement("ul");
            elem_subrules.className = "subrules";

            for(let subrule_i = 0; subrule_i < rule.subrules.length; ++subrule_i) {
                let subrule = rule.subrules[subrule_i];
                let elem_subrule = this.rule_new(subrule, elem_number.textContent, subrule_i);
                elem_subrules.appendChild(elem_subrule);
            }

            elem.appendChild(elem_subrules);
        }

        return(elem);
    }

    update_elem (elem :HTMLElement) {
        elem.innerHTML = "";

        let elem_container = document.createElement("div");
        elem_container.className = "container";
        elem.appendChild(elem_container);

        if(!state.rules) {
            let elem_nothing = document.createElement("h2");
            elem_nothing.className = "nothing";
            elem_nothing.textContent = "There are no rules!";
            elem_container.appendChild(elem_nothing);
        }
        else {
            let elem_list = document.createElement("ul");
            elem_container.appendChild(elem_list);

            for(let rule_i = 0; rule_i < state.rules.length; ++rule_i) {
                let rule = state.rules[rule_i];
                let elem_rule = this.rule_new(rule, "", rule_i);
                elem_list.appendChild(elem_rule);
            }
        }

		let perms = auth_perms_get();
		if(perms.other.obj.website & Perms_Manage.EDIT) {
            let elem_buttons = document.createElement("div");
            elem_buttons.className = "buttons";
            elem.appendChild(elem_buttons);

            let elem_button_edit = document.createElement("div");
            elem_button_edit.className = "edit";
            elem_button_edit.textContent = "EDIT";
            elem_button_edit.onclick = () => {this.mode_toggle_elem(elem)};
            elem_buttons.appendChild(elem_button_edit);
        }
    }

    update () {
        for(let i of Object.keys(this.instances)) this.update_elem(this.instances[i].e);
    }
}

export default Page_Rules;
