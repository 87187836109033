import {Page, Page_Type} from "../../page";

class Page_Admin extends Page {
    constructor() {
        super({type: Page_Type.ADMIN, id: ""});
    }

    new () {
        let elem = document.createElement("div");
        elem.className = "admin";
        return({e: elem, es: elem, h: 0, s: 0});
    }
}

export default Page_Admin;
