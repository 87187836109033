export const color_int_to_hex = (color :number) => {
	if(!color) return(null);

    let color_str = "";
    if(color <= 0xffff) color_str += "00";
    if(color <= 0xff)   color_str += "00";
    color_str += color.toString(16)
	if(color_str.length == 5) color_str = "0" + color_str;
	color_str = "#" + color_str;

    return(color_str);
}

export const color_hex_to_int = (color :string) => {
	let color_str = "0x" + color.substring(1);
	return(Number(color_str));
}

export const color_rgb_to_hex = (color :any = {}) => {
    const result =  "#" + ((1 << 24) + (color.r << 16) + (color.g << 8) + color.b).toString(16).slice(1);
    return(result);
}

export const color_hex_to_rgb = (color :string) => {
    let result :any = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    result = result ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})` : '';
    return(result);
}

export const color_rgb_parse = (color :string) => {
    let result :any = color.substring(4).slice(0, -1).split(",");
    result = {
        r: Number(result[0]),
        g: Number(result[1]),
        b: Number(result[2]),
    };
    return (result);
}

const color_rgb_unparse = (color :any) => {
    let result :string = `rgb(${color.r}, ${color.g}, ${color.b})`;
    return (result);
}

export const color_rgb_brightness = (color_str :string, percent :number) => {
    const color = color_rgb_parse(color_str);
    color.r = color.r * (100 + percent) / 100;
    color.g = color.g * (100 + percent) / 100;
    color.b = color.b * (100 + percent) / 100;

    color.r = (color.r<255)?color.r:255;  
    color.g = (color.g<255)?color.g:255;  
    color.b = (color.b<255)?color.b:255;  
    const result = color_rgb_unparse(color);
    return(result);
}