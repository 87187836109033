import {socket_send, ACTION} from "../../socket";
import { state } from "../../state";
import { auth, auth_is_user } from "../../auth";
import {menu_close} from "../modal/menu";
import { author_is_me } from "../../author";

export const Menu_Board = (board :any) => {
    let elem = document.createElement("div");
    elem.className = "board";

    /* Block Iteract */
    if(auth_is_user()) {
        let elem_block_interact = document.createElement("div");
        elem_block_interact.className = "block interact";
        elem.appendChild(elem_block_interact);

        let elem_tab_listing = document.createElement("div");
        elem_tab_listing.className = "tab listing";
        elem_block_interact.appendChild(elem_tab_listing);

        let board_ownership = 0; /* 0 Not Joined, 1 Joined, 2 Owner */
		if(author_is_me(board.author)) board_ownership = 2;
        else if(state.boards_me.includes(board.id)) board_ownership = 1;
		else board_ownership = 0;

        switch(board_ownership) {
            case 0:
			case 1: {
                let is_joined = state.boards_me.includes(board.id);
                if(!is_joined) elem_tab_listing.textContent = "Join";
                else elem_tab_listing.textContent = "Leave";

                elem_tab_listing.onclick = () => {
                    if(!is_joined) socket_send(ACTION.BOARD_LIST_ADD, {board_id: board.id});
                    else socket_send(ACTION.BOARD_LIST_DEL, {board_id: board.id})
                    menu_close();
                }
            } break;
            case 2: {
				elem_tab_listing.textContent = "Delete";
                elem_tab_listing.onclick = () => {
					menu_close();
					return;
                    socket_send(ACTION.BOARD_DEL, {board_id: board.id});
                    menu_close();
                }
            } break;
        }
    }

    /* Block Bot */
    let elem_block_bot = document.createElement("div");
    elem_block_bot.className = "block bot";

    let elem_copy_id = document.createElement("div");
    elem_copy_id.className = "tab copy_id";
    elem_copy_id.textContent = "Copy ID";
    elem_copy_id.onclick = () => {
        navigator.clipboard.writeText(`<$${board.id}>`);
        menu_close();
    }
    elem_block_bot.appendChild(elem_copy_id);

    elem.appendChild(elem_block_bot);

    return(elem);
}
