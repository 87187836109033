import { state } from "../../../state";
import {Page, Page_Type} from "../page";
import {socket_send, ACTION} from "../../../socket"

class Page_Messages extends Page {
    constructor() {
        super({type: Page_Type.MESSAGES, id: ""});
        if(!state.channels_loaded) socket_send(ACTION.CHANNELS_ME_GET);
    }

    new () {
        let elem = document.createElement("div");
        elem.className = "messages";
        elem.textContent = "Select message channel to view"
        return({e: elem, es: elem, h: 0, s: 0});
    }
}

export default Page_Messages
