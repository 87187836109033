import {Icon} from "./icon";
import {state} from "../../state";
import {Page_Type} from "../app/page";
import {ACTION, socket_send} from "../../socket"
import { Author_Name } from "./author";
import {Content} from "./content";
import { Auth_Quick, auth_assert } from "../modal/auth_quick";
import { auth, auth_is_guest, auth_is_user } from "../../auth";
import { views } from "../../view";
import { Repost } from "../modal/repost";
import { ID_Type } from "../../id";
import { file_upload } from "./file";
import { content_get } from "../../helper";

enum Msg_SendStatus {
    NULL,
    SENDING,
    SENT,
    ACKED,
    ERROR,
}

const msg_empty = () => {
    let msg = {text: "", contents: [], state: {progress: 0}, status: Msg_SendStatus.NULL};
    return(msg);
}

class Message_Input {
    target :any;
	page_id :any;

    mode :string; //send or edit
    messages :any[];

    elem :HTMLElement;
    elem_edit :HTMLElement;
    elem_send :HTMLElement;
    elem_text :HTMLTextAreaElement;
    elem_uploads :HTMLElement;

    constructor (page_id :any) {
		this.page_id = page_id;
		switch(page_id.type) {
		case Page_Type.THREAD: this.target = {type: ID_Type.THREAD, id: page_id.id}; break;
		case Page_Type.CHANNEL: this.target = {type: ID_Type.CHANNEL, id: page_id.id}; break;
		case Page_Type.CHANNEL_TMP: this.target = null; break;
		}

        this.mode = "send";
        this.messages = [msg_empty()];

        this.elem = document.createElement("div");
        this.elem.className = "message_input";
        this.elem_edit = document.createElement("div");
        this.elem_send = document.createElement("div");
        this.elem_text = document.createElement("textarea");
        this.elem_uploads = document.createElement("div");
        this.update();
    }

    update () {
        this.elem.innerHTML = "";
        /* Edit */
        this.elem.appendChild(this.elem_edit);
        this.elem_edit.className = "edit";
        this.elem_edit.style.display = "none";

        let elem_edit_close = document.createElement("div");
        elem_edit_close.className = "close";
        elem_edit_close.textContent = "X";
        elem_edit_close.onclick = () => { this.message_edit_stop(); }
        this.elem_edit.appendChild(elem_edit_close);

        let elem_edit_title = document.createElement("div");
        elem_edit_title.textContent = "EDIT: ";
        elem_edit_title.className = "title";
        this.elem_edit.appendChild(elem_edit_title);

        let elem_edit_author = document.createElement("div");
        elem_edit_author.className = "author";
        this.elem_edit.appendChild(elem_edit_author);

        let elem_edit_text = document.createElement("div");
        elem_edit_text.className = "text";
        this.elem_edit.appendChild(elem_edit_text);

		/* Sending */
		this.elem.appendChild(this.elem_send);
		this.elem_send.className = "sending";
		this.elem_send.style.display = "none";

        /* Contents */
        this.elem.appendChild(this.elem_uploads);
        this.elem_uploads.className = "uploads";
        this.elem_uploads.style.display = "none";

        /* Text */
        let elem_container = document.createElement("div");
        this.elem.appendChild(elem_container);
        elem_container.className = "text_container"

        /*
        let elem_repost = document.createElement("div");
        elem_container.appendChild(elem_repost);
        elem_repost.className = "icon repost";
        elem_repost.innerHTML = Icon.Repost;
        */

        let elem_add = document.createElement("div");
        elem_add.className = "icon add";
        elem_container.appendChild(elem_add);

        let elem_add_label = document.createElement("label");
        elem_add_label.className = "label";
        elem_add_label.htmlFor = "input_file_id";
        elem_add_label.innerHTML = Icon.Add;
        elem_add.appendChild(elem_add_label);

		auth_assert(() => {
            let elem_add_input = document.createElement("input");
            elem_add_input.className = "input";
            elem_add_input.type = "file";
            elem_add_input.multiple = true;
            elem_add_input.id = "input_file_id";
            elem_add_input.style.display = "none";
            elem_add_input.onchange = (e :any) => {
                if(!e.target.files || !e.target.files.length) return;
                for(let file of e.target.files) this.file_add(file);
            }
            elem_add.appendChild(elem_add_input);
		});

        /*
        let elem_repost = document.createElement("div");
        elem_repost.className = "icon repost";
        elem_repost.innerHTML = Icon.Repost;
		elem_repost.onclick = () => auth_assert(() => {
			if(!views.modal.repost) views.modal.repost = new Repost();
			views.modal.repost.open(this);
		})
        elem_container.appendChild(elem_repost);
        */

        /*
        let elem_emoji = document.createElement("div");
        elem_emoji.className = "icon emoji";
        elem_emoji.innerHTML = Icon.Emoji;
		elem_emoji.onclick = () => auth_assert(() => {
			//if(!views.modal.emoji) views.modal.emoji = new Emoji();
			//views.modal.emoji.open(this);
		})
        elem_container.appendChild(elem_emoji);
        */


        elem_container.appendChild(this.elem_text);

        let elem_send = document.createElement("div");
        elem_container.appendChild(elem_send);
        elem_send.className = "icon send";
        elem_send.innerHTML = Icon.Send;

        /* Textarea */
        this.elem_text.placeholder = "Message...";
        //this.textarea_resize(this.elem_text);
        this.elem_text.oninput = (e :Event) => {
            /* Input Event */
            this.messages[this.messages.length-1].text = this.elem_text.value;
            this.textarea_resize(this.elem_text);
        }
        this.elem_text.onkeydown = (e :KeyboardEvent) => {
            if(e.key == "Enter" && !e.shiftKey) {
                e.preventDefault();
				auth_assert(() => this.message_send(this));
            }
        }
        elem_send.onclick = (e) => {
            e.preventDefault();
			auth_assert(() => this.message_send(this));
        }

    }

    textarea_resize (elem :HTMLElement) {
        elem.style.height = "0px";
        //elem.style.height = `calc(${elem.scrollHeight}px - 1em)`;
        elem.style.height = `${elem.scrollHeight-22}px`;
    }

    text_insert = (text: string) => {
        let message_index = this.messages.length-1;
        let message = this.messages[message_index];

        message.text += text;
        this.elem_text.value += text;

        this.textarea_resize(this.elem_text);
        this.elem_text.focus();
    }


	message_upload_insert = (upload :any) => {
		if(!upload) return;

        let message_index = this.messages.length-1;
        let message = this.messages[message_index];

        if(!this.elem.classList.contains("open")) this.elem.classList.add("open");
        this.elem_uploads.style.display = "block";

		let elem = document.createElement("div");
		elem.className = "upload";

		let elem_status = document.createElement("div");
		elem_status.className = "status";
		elem_status.innerHTML = Icon.Close;
		elem_status.onclick = () => {
			if(message.status == Msg_SendStatus.NULL) {
				message.contents = message.contents.filter((up :any) => up.index != upload.index);
				if(message.contents.length == 0 && this.elem.classList.contains("open")) this.elem.classList.remove("open");
				elem.outerHTML = "";
			}
		}
		elem.appendChild(elem_status);

		let elem_preview = document.createElement("div"); elem.appendChild(elem_preview);
		elem_preview.className = "preview";
		if(upload.state.finished) {
			let preview = content_get(upload.content);
			elem_preview.appendChild(Content(preview, {thumb: true, size: "32"})!);
		}
		else {
			if(upload.type.split("/")[0] == "image") {
				let elem_preview_media = document.createElement("img");
				elem_preview_media.src = upload.preview;
				elem_preview.appendChild(elem_preview_media);
			}
			else if(upload.type.split("/")[0] == "video") {
				let elem_preview_media = document.createElement("video");
				let elem_preview_media_source = document.createElement("source");
				elem_preview_media_source.src = upload.preview;
				elem_preview_media.appendChild(elem_preview_media_source);
				elem_preview.appendChild(elem_preview_media);
			}
		}

		let elem_name = document.createElement("div");
		elem_name.className = "name";
		elem_name.textContent = upload.name;
		elem.appendChild(elem_name);

		this.elem_uploads.appendChild(elem); /* This needs to be here because of the elem_status.onclick */
		message.contents.push(upload);

		return(elem);
	}

    uploads_insert = (uploads :any[]) => {
        let message_index = this.messages.length-1;
        let message = this.messages[message_index];

		for(let upload of uploads) {
			upload.index = message.contents.length;
			upload.preview =  upload.path;
			upload.state = {
				progress: 100,
				finished: true,
			}
			this.message_upload_insert(upload);

        }

    }

    file_add (file :File) {
		/*
        let is_it_okay_to_upload_new_files = true;
        for(let msg of this.messages) if(msg.status == Msg_SendStatus.SENDING) is_it_okay_to_upload_new_files = false;
        if(!is_it_okay_to_upload_new_files) return;
		*/

        let message_index = this.messages.length-1;
        let message = this.messages[message_index];

        let upload = {
            id: "",
            name: file.name,
			type: file.type,

			index: message.contents.length,
            preview: URL.createObjectURL(file),
			state: {
				progress: 0,
				finished: false,
			},
        }

		let elem = this.message_upload_insert(upload);
		let elem_status = elem?.querySelector(".status")! as HTMLElement;
        this.file_upload(file, message_index, upload.index, elem_status);
    }

    file_upload = async (file :any, message_index :number, upload_index :number, elem_status :HTMLElement) => {
        if(!auth_is_user()) return;

        try {
			let body = {target: this.target};

            let message = this.messages[message_index];
            let upload = message.contents[upload_index];

            let xhr = new XMLHttpRequest();
            xhr.upload.onprogress = (e :any) => {
                const percentage = e.loaded / e.total;
                upload.state.progress = percentage;

				if(message.status == Msg_SendStatus.SENDING) {
					this.message_state_update(message_index);
					elem_status.textContent = `${Math.round(percentage*100.0)}%`;
				}
            };
            xhr.onload = (e :any) => {
                if(xhr.status == 200) {
                    const res = JSON.parse(xhr.response);
                    upload.id = res.id;
                    upload.state.finished = true;

					if(message.status == Msg_SendStatus.SENDING) {
						elem_status.textContent = `V`;
						this.message_state_update(message_index);
					}
                    this.message_send_if_ready(message_index);
                }
            };
			file_upload(xhr, file, body);
        }
        catch (err) {
            console.error(err);
        }
    }

	message_state_update (message_index :number) {
		let message = this.messages[message_index];
		message.state.finished_count = message.contents.filter((upload :any) => upload.state.finished).length;
		message.state.progress = 0; for(let up of message.contents) message.state.progress += up.state.progress / message.contents.length;

		let elem_send_msg = this.elem_send.querySelector(`.msg[index="${message_index}"]`)!;
		let elem_send_msg_title = elem_send_msg.querySelector(`.title`)!;
		elem_send_msg_title.textContent = `Uploading message: ${message.state.finished_count}/${message.contents.length} (${Math.round(message.state.progress*100.0)}%)`

		if(message.state.finished_count == message.contents.length) {
			this.elem_send.removeChild(elem_send_msg);
			if(this.elem_send.childElementCount == 0) this.elem_send.style.display = "none";
		}
	}

    message_edit (message :any) {
        this.mode = "edit";
        this.messages.push(msg_empty());

        let message_index = this.messages.length-1;
        let message_ = this.messages[message_index];
        message_.id = message.id;

        this.text_insert(message.text);
        this.uploads_insert(message.contents);

        this.elem_edit.querySelector(".author")!.appendChild(Author_Name(message.author));
        this.elem_edit.querySelector(".text")!.textContent = message.text;
        this.elem_edit.style.display = "inline";
    }

    message_edit_stop = () => {
        this.mode = "send";
        this.elem_edit.querySelector(".text")!.innerHTML = "";
        this.elem_edit.querySelector(".author")!.innerHTML = "";
        //this.elem_edit.querySelector(".uploads")!.innerHTML = "";
        this.elem_edit.style.display = "none";

        this.elem_text.value = "";
        this.textarea_resize(this.elem_text);
    }

    message_send (msg_input :any) {
        if(auth_is_guest()) {
            /* TODO: */
            Auth_Quick();
            return;
        }
        
        let message_index = msg_input.messages.length-1;
        let message = msg_input.messages[message_index];
        message.text = message.text.trim();
        
        let msg_test_text = !message.text || /^\s*$/.test(message.text);
        let msg_test_contents = !message.contents || message.contents.length == 0 || message.contents.length > 10;
        if(msg_test_text && msg_test_contents) return;

        /* Check if contents finished uploading */
        message.status = Msg_SendStatus.SENDING;
        msg_input.message_send_if_ready(message_index);

		this.elem_send.style.display = "flex";
		let elem_send_msg = document.createElement("div"); this.elem_send.appendChild(elem_send_msg);
		elem_send_msg.className = "msg open";
		elem_send_msg.setAttribute("index", `${message_index}`);

		let elem_send_msg_title = document.createElement("div"); elem_send_msg.appendChild(elem_send_msg_title);
		elem_send_msg_title.className = "title";
		this.message_state_update(message_index);

		let elem_send_msg_uploads = document.createElement("div"); elem_send_msg.appendChild(elem_send_msg_uploads);
		elem_send_msg_uploads.className = "uploads";
		elem_send_msg_uploads.append(...msg_input.elem_uploads.childNodes);

        /* Clear input */
        msg_input.elem_text.value = "";
        msg_input.textarea_resize(msg_input.elem_text);
        msg_input.elem_text!.focus();

        msg_input.elem_uploads.innerHTML = "";
        msg_input.elem_uploads.style.display = "none";

        if(msg_input.elem.classList.contains("open")) msg_input.elem.classList.remove("open");

        /* Add new message */
        msg_input.messages.push(msg_empty());
    }

    message_send_if_ready (message_index :number) {
        let message = this.messages[message_index];
        if(message.status != Msg_SendStatus.SENDING) return;

        let finished = true;
        for(let upload of message.contents) if(!upload.state.finished) finished = false;

        if(finished) {
            let payload = {
                text: message.text,
                contents: message.contents.filter((c :any) => !c.removed).map((content :any) => content.id),
            };
            message.status = Msg_SendStatus.SENT;

            switch(this.mode) {
                case "send": {
                    switch(this.page_id.type) {
                        case Page_Type.THREAD: {
                            socket_send(ACTION.MESSAGE_ADD, {target: this.target, message: payload}); 
                            
                            let thread = state.threads[this.target.id];
                            if(!state.threads_watching || !(thread.board in state.threads_watching) || !state.threads_watching[thread.board].includes(this.target.id)) socket_send(ACTION.THREAD_WATCHING_ADD, {thread_id: this.target.id});
                        } break;
                        case Page_Type.CHANNEL: {
                            socket_send(ACTION.MESSAGE_ADD, {target: this.target, message: payload})
                        } break;
                        case Page_Type.CHANNEL_TMP: {
							let channel = state.channels_tmp[this.target.id];
                            socket_send(ACTION.CHANNEL_ME_MESSAGE_ADD, {target: this.target, you: channel.you, message: payload});
                        } break;
                        default: console.error("Message_Input: Wrong TO TYPE"); break;
                    }
                } break;
                case "edit": {
					socket_send(ACTION.MESSAGE_EDIT, {message_id: message.id, message: payload});
                    this.message_edit_stop();
                } break;
            }
        }
    }
}

export default Message_Input;