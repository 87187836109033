import {Page_Type} from "./view/app/page";

export enum Status {
    NULL,
    LOADING,
    LOADED_PARTLY,
    LOADED_FULLY,
}

/*
type Tab = {
    page_selected :Page_ID,
    pages :Page[],
};

type Window = {
    tab_selected :number,
    tabs :Tab[],
};

type Bar = {
    open :boolean,
    width: number,
}
*/

export let state :any = {
	version: null,

    invites: null,

    rules: null,

    roles: null,
	roles_target: null,

	sub_update: null,

    users_online: [],
    profiles: null,

    boards: null,
    boards_me: null,
	boards_owned: null,
	boards_joined: null,
	boards_official: null,
	boards_public: null,

    board_tmp: null,
    board_threads: null,

    threads: null,
    threads_read: null,
    threads_pinned: null,
    threads_watching: null,

    channels: null,
	channels_sorted: null,
	channels_loaded: false,

    channels_tmp: null,
    channel_tmp_count: 0,

	uploads: null,
    contents: null,
    messages: null,

    emoji_sets: null,

    menu: "",
    modal: "",

    admin: {
        members: null,
        channels: null,
    },
    mod: {
        reports: null,
    },

    view: {
        width: window.innerWidth,
        height: window.innerHeight,

        main:      {width: 0},
        bar_left:  {width: 0, is_open: true},
        bar_right: {width: 0, is_open: true},
    },
    
    last_nav: {
        links: Page_Type.BOARDS_OFFICIAL,
        messages: null,
        boards: {},
    },

    // tabs: [
    //     {
    //         pos: 0,
    //         history: [{id: "", type: Page_Type.BOARDS_OFFICIAL}],
    //     },
    //     {
    //         pos: 0,
    //         history: [{id: "", type: Page_Type.HOME}],
    //     },
    //     {
    //         pos: 0,
    //         history: [{id: "", type: Page_Type.TIPS}],
    //     },
    //     {
    //         pos: 0,
    //         history: [{id: "", type: Page_Type.BOARDS_OFFICIAL}],
    //     },
    //     {
    //         pos: 0,
    //         history: [{id: "", type: Page_Type.RULES}],
    //     }
    // ],


    // window_selected: 0,
    // window_selected_last: -1,

    // windows: [ {
    //     tab_selected: 0,
    //     tab_selected_last: -1,
    //     tabs: [0, 1],
    // }, {
    //     tab_selected: 0,
    //     tab_selected_last: -1,
    //     tabs: [2, 3],
    // }, {
    //     tab_selected: 0,
    //     tab_selected_last: -1,
    //     tabs: [4]
    // }],

    // layout: {
    //     s: "v",
    //     d: .5,
    //     w: [
    //         0, 
    //         {
    //             w: [1, 2],
    //             s: "h", 
    //             d: .5,
    //         },
    //     ],
    // }
//    layout: 0,
}
