import {views} from "../../view"
import {auth} from "../../auth";
import {state} from "../../state"
import {Page_Type} from "./page";
import { Author_Name } from "../modular/author";

class Tab {
    elem :HTMLElement;
    index :number;
    window_index :number;
    window_tab_index :number;

    /* For mouse input */
    target :HTMLElement | null;
    action :string;


    constructor (index :number, window_index :number, window_tab_index :number) {
        this.index = index;
        this.window_index = window_index;
        this.window_tab_index = window_tab_index;

        this.target = null;
        this.action = "";

        this.elem = document.createElement("div");
        this.elem.className = "tab";

        this.elem.onmousedown = (e) => {
            this.target = e.target as HTMLElement;
            if(!this.target.classList.contains("tab")) this.target = this.target.parentElement;

            let button = (e as MouseEvent).button;
            if(e.button == 1 || (e.button == 0 && e.ctrlKey)) this.action = "close";
            else if(e.button == 0) this.action = "select";
        }

        this.elem.onmouseup = (e) => {
            let target = e.target as HTMLElement;

            let action = null;
            let button = (e as MouseEvent).button;
            if(e.button == 1 || (e.button == 0 && e.ctrlKey)) action = "close";
            else if(e.button == 0) action = "select";

            if(this.target != target || action != this.action) {
                this.target = null;
                this.action = "";
                return;
            }

            let window_state = state.windows[this.window_index];
            switch(action) {
                case "select": {
                    if(this.window_tab_index == window_state.tab_selected) return;
                    window_state.tab_selected_last = window_state.tab_selected;
                    window_state.tab_selected = this.window_tab_index;
                    views.tab[window_state.tabs[window_state.tab_selected_last]].elem.classList.remove("selected");
                    views.tab[window_state.tabs[window_state.tab_selected]].elem.classList.add("selected");
                    views.window[this.window_index].update_page();
                } break;
                case "close": {
                    window_state.tabs.splice(this.window_tab_index, 1);

                    /* Update last selected tab */
                    if(window_state.tab_selected_last == this.window_tab_index) window_state.tab_selected_last = -1;
                    else if(window_state.tab_selected_last > this.window_tab_index) window_state.tab_selected_last -= 1;

                    /* Update selected tab */
                    if(window_state.tab_selected > this.window_tab_index) window_state.tab_selected -= 1;
                    if(window_state.tab_selected == this.window_tab_index) {
                        if(window_state.tabs.length > 1) {
                            if(window_state.tab_selected_last != -1) {
                                /* Move to last one */
                                window_state.tab_selected = window_state.tab_selected_last;
                            }
                            else {
                                /* Move to either right or left */
                                if(window_state.tab_selected == window_state.tabs.length) window_state.tab_selected -= 1;
                            }
                        }
                        else; /* TODO: Close window */
                    }

                    /* Remove Tab */
                    this.elem.outerHTML = "";
                    state.tabs.splice(this.index, 1);
                    delete views.tab[this.index];
                } break;
            }
        }
    }

    update () {
        this.elem.innerHTML = "";

        let window_state = state.windows[this.window_index];
        if(window_state.tab_selected == this.window_tab_index) this.elem.classList.add("selected");

        let tab = state.tabs[this.index];
        let page_id = tab.history[tab.pos];

        switch(page_id.type) {
            case Page_Type.ADMIN: this.elem.appendChild(document.createTextNode("ADMIN")); break;
            case Page_Type.ADMIN_MEMBERS: this.elem.appendChild(document.createTextNode("MEMBERS")); break;
            case Page_Type.ADMIN_CHANNELS: this.elem.appendChild(document.createTextNode("CHANNELS")); break;
            case Page_Type.MOD_REPORTS: this.elem.appendChild(document.createTextNode("REPORTS")); break;

            case Page_Type.HOME: this.elem.appendChild(document.createTextNode("HOME")); break;
            case Page_Type.TIPS: this.elem.appendChild(document.createTextNode("TIPS")); break;
            case Page_Type.RULES: this.elem.appendChild(document.createTextNode("RULES")); break;
            case Page_Type.INVITE: this.elem.appendChild(document.createTextNode("INVITE")); break;
            case Page_Type.INVITES: this.elem.appendChild(document.createTextNode("INVITES")); break;
            case Page_Type.SUBSCRIPTION: this.elem.appendChild(document.createTextNode("SUBSCRIPTION")); break;
            case Page_Type.SUBSCRIPTION_UPDATE: this.elem.appendChild(document.createTextNode("SUBSCRIPTION UPDATE")); break;

            case Page_Type.SETTINGS_ACCOUNT: this.elem.appendChild(document.createTextNode("SETTINGS ACCOUNT")); break;
            case Page_Type.SETTINGS_PROFILE: this.elem.appendChild(document.createTextNode("SETTINGS PROFILE")); break;
            case Page_Type.SETTINGS_SAFETY: this.elem.appendChild(document.createTextNode("SETTINGS SAFETY")); break;
            case Page_Type.SETTINGS_NOTIFS: this.elem.appendChild(document.createTextNode("SETTINGS NOTIFS")); break;

            case Page_Type.MESSAGES: this.elem.textContent = "MESSAGES"; break;
            case Page_Type.EMOJI_SET: {
                let emoji_set = state.emoji_sets[page_id.id];
                this.elem.textContent = `: ${emoji_set.name}`; break;
            } break;

            case Page_Type.BOARDS_OWNED: this.elem.appendChild(document.createTextNode("BOARDS OWNED")); break;
            case Page_Type.BOARDS_JOINED: this.elem.appendChild(document.createTextNode("BOARDS JOINED")); break;
            case Page_Type.BOARDS_OFFICIAL: this.elem.appendChild(document.createTextNode("BOARDS OFFICIAL")); break;
            case Page_Type.BOARDS_PUBLIC: this.elem.appendChild(document.createTextNode("BOARDS PUBLIC")); break;

			case Page_Type.PROFILE_CONTENT: {
				let user = state.profiles[page_id.id];
				this.elem.appendChild(Author_Name(user));
			} break;
            case Page_Type.BOARD: {
                //this.elem.appendChild(document.createTextNode("BOARD"));
                let board = state.boards[page_id.id];
                if(board) this.elem.textContent = board.key ? `/${board.key}/ - ${board.name}` : board.name;
                else this.elem.textContent = "";
            } break;
            case Page_Type.THREAD: {
                //this.elem.appendChild(document.createTextNode("THREAD"));
                let thread_subject = state.threads && page_id.id in state.threads ? state.threads[page_id.id].subject : "";
                this.elem.textContent = `# ${thread_subject}`;
            } break;
            case Page_Type.CHANNEL: {
                let channel = state.channels[page_id.id];
                this.elem.appendChild(Author_Name(channel.you)!);
            } break;
            case Page_Type.CHANNEL_TMP: {
				let channel = state.channels_tmp[page_id.id];
                this.elem.appendChild(Author_Name(channel.you)!);
            } break;
            default: console.error("Wrong page type");
        }
    }
}

export default Tab;