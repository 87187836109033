import { ACTION, socket_send } from "../../../socket";
import { state } from "../../../state";
import { Auth_Quick } from "../../modal/auth_quick";
import {Page, Page_Type} from "../page";
import { ID_Type } from "../../../id";
import { nav_to } from "../../../router";

class Page_Invite extends Page {
    constructor (id :string) {
        super({type: Page_Type.INVITE, id: id});

        /* TODO: Do some testing to see if you're allowed on this page */
        localStorage.setItem("invite", id);
        socket_send(ACTION.INVITE_GET, {invite_id: id});
    }

    new () {
        let elem = document.createElement("div");
        elem.className = "invite";
        return({e: elem, es: elem, h: 0, s: 0});
    }

    set_invite(invite :any) {
        var elem = this.instances[0].e;

        let page_id = null;
        switch(invite.target.type)
        {
            case ID_Type.WEBSITE: page_id = {type: Page_Type.HOME,   id: ""};               break;
            case ID_Type.BOARD:   page_id = {type: Page_Type.BOARD,  id: invite.target.id}; break;
            case ID_Type.THREAD:  page_id = {type: Page_Type.THREAD, id: invite.target.id}; break;
            default:              page_id = {type: Page_Type.HOME,   id: ""};               break;
        }
        nav_to(page_id);
    }
}

export default Page_Invite;
