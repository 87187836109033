import {socket_send, ACTION} from "../../socket";
import {state, Status} from "../../state";
import {Page_Type, page_id_eql} from "../app/page";
import {message_new, message_chunk_new, message_block_new} from "./message";
import {Content} from "./content";
import { Author_Name, Author_Avatar, author_name_str } from "./author";
import {auth_is_guest, auth_is_user, auth_is_subscriber}  from "../../auth.ts";
import { auth } from "../../auth";
import { views } from "../../view";
import { auth_assert } from "../modal/auth_quick";
import { nav_to } from "../../router";
import { Perms_Manage, auth_perms_get } from "../../permission";
import { author_same, author_self_other } from "../../author";
import { ID_Type } from "../../id";

const TRIGGER = 800;
const TIME_BLOCK_DURATION = 2 * 1000 * 60;

class Messages {
    target :any;
    page_id :any;

    data :any;
    elem :HTMLElement;

	scroll :number;
    update_read :any;
	on_scroll_stop :any;

    constructor (page_id :any) {
        this.scroll = 0;
		this.on_scroll_stop = null;

        this.page_id = page_id;
		switch(page_id.type) {
		case Page_Type.THREAD: this.target = {type: ID_Type.THREAD, id: page_id.id}; break;
		case Page_Type.CHANNEL: this.target = {type: ID_Type.CHANNEL, id: page_id.id}; break;
		case Page_Type.CHANNEL_TMP: this.target = null; break;
		}

		this.update_data();
        this.elem = document.createElement("div");
        this.elem.className = "messages";

        this.update_read = () => {
            if(auth_is_guest()) return;

            /* Find mesasge at the bottom of the screen */
			let rect = this.elem.getBoundingClientRect();
            const elem_chunks = this.elem.querySelectorAll(".list .chunk")!;

            let chunk_y = Number.NEGATIVE_INFINITY;
            let elem_chunk_last = null;
            for(let ec of elem_chunks) {
                let elem_chunk = ec as HTMLElement;
				let chunk_rect = elem_chunk.getBoundingClientRect();

                if(chunk_rect.bottom < rect.top || chunk_rect.top > rect.bottom) continue;
                if(chunk_rect.top >= chunk_y) {
                    chunk_y = chunk_rect.top;
                    elem_chunk_last = elem_chunk;
                }
            }
			if(!elem_chunk_last) return;

            let block_y = Number.NEGATIVE_INFINITY;
            let elem_block_last = null;
            for(let eb of elem_chunk_last!.children) {
                let elem_block = eb as HTMLElement;
				let block_rect = elem_block.getBoundingClientRect();

                if(block_rect.bottom < rect.top || block_rect.top > rect.bottom) continue;
                if(block_rect.top >= block_y) {
                    block_y = block_rect.top;
                    elem_block_last = elem_block;
                }
            }
			if(!elem_block_last) return;

            let msg_y = Number.NEGATIVE_INFINITY;
            let elem_msg_last = null;
            let elem_messages = elem_block_last!.querySelectorAll(".message");
            for(let em of elem_messages) {
                let elem_msg = em as HTMLElement;
				let msg_rect = elem_msg.getBoundingClientRect();

                if(msg_rect.bottom < rect.top || msg_rect.top > rect.bottom) continue;
                if(msg_rect.top >= msg_y) {
                    msg_y = msg_rect.top;
                    elem_msg_last = elem_msg;
                }
            }
			if(!elem_msg_last) return;
            
            let date = new Date(Number(elem_msg_last?.getAttribute("date")!)).toISOString();
            socket_send(ACTION.MESSAGE_READ_SET, {target: this.target, read: {index: null, date: date}})
        }

        this.elem.onscroll = (e) => {
            let elem = e.target as HTMLElement;

			let scroll = this.elem_scroll_get(this.elem);
			let scroll_dir = scroll.b - this.scroll; /* == 0 still, > 0 up, < 0 down */

			if(scroll_dir == 0) return;
			if(scroll_dir > 0) {
				/* Scroll Up */
				if(scroll.t >= scroll.h-TRIGGER && this.data.messages_status.b == Status.LOADED_PARTLY) {
					this.data.messages_status.b = Status.LOADING;
					socket_send(ACTION.MESSAGES_GET, {target: this.target, key: {before: this.data.messages[0][0][0].date_created}});
				}
			}
			else if(scroll_dir < 0) {
				/* Scroll Down */
				if(scroll.b <= TRIGGER && this.data.messages_status.a == Status.LOADED_PARTLY) {
					this.data.messages_status.a = Status.LOADING;
					let chunk_last = this.data.messages[this.data.messages.length-1];
					let block_last = chunk_last[chunk_last.length-1];
					let msg_last = block_last[block_last.length-1];
					socket_send(ACTION.MESSAGES_GET, {target: this.target, key: {after: msg_last.date_created}});
				}
			}

			/* Update scroll */
			this.scroll = scroll.b;

            /* Update message read */
            if(auth_is_user()) {
                if(this.on_scroll_stop) {
                    clearTimeout(this.on_scroll_stop);
                    this.on_scroll_stop = null;
                }
                this.on_scroll_stop = setTimeout(() => this.update_read(), 1000);
            }
        }

        let elem_list = document.createElement("div"); this.elem.appendChild(elem_list);
        elem_list.className = "list";
    }

	elem_scroll_get (elem :HTMLElement) {
		let result = {h: 0, o: 0, t: 0, b: 0};
		result.h = elem.scrollHeight;
		result.o = elem.offsetHeight;
		result.b = -elem.scrollTop;
		result.t = -elem.scrollTop + elem.offsetHeight;
		return(result);
	}

	elem_scroll_to (elem :HTMLElement, scroll :number) {
		elem.scroll(0, -scroll);
	}

	update_data () {
		switch(this.page_id.type) {
			case Page_Type.THREAD:      this.data = (state.threads      && this.page_id.id in state.threads)      ? state.threads[this.page_id.id]      : null; break;
			case Page_Type.CHANNEL:     this.data = (state.channels     && this.page_id.id in state.channels)     ? state.channels[this.page_id.id]     : null; break;
			case Page_Type.CHANNEL_TMP: this.data = (state.channels_tmp && this.page_id.id in state.channels_tmp) ? state.channels_tmp[this.page_id.id] : null; break;
		}
	}

    message_add (index :number, message :any) {
        let elem_list = this.elem.querySelector(".list")!;
        if(!elem_list.childElementCount) return;

        let elem_chunk_last = elem_list.querySelector(".chunk.last");
        if(!elem_chunk_last) {
            /* TODO: Create new chunk with the message received */
            let date = new Date(message.date_created).getTime();
            let chunk = this.chunk_from_messages([message]);
            let elem_chunk = message_chunk_new(chunk, date, date);
            elem_chunk.classList.add("first");
            elem_chunk.classList.add("last");
            elem_list.appendChild(elem_chunk);
            if(index == 0) this.data.messages = [chunk];
            return;
        }

        /* Keep scroll */
		let scroll = this.elem_scroll_get(this.elem);
		let scrolled_all_the_way_down = scroll.b <= 0;

        /* Add message */
        let elem_msg = message_new(message);
        let chunk_last = this.data.messages[this.data.messages.length-1];
        if(elem_chunk_last.childElementCount > 0) {
            let elem_block_last = elem_chunk_last.children[elem_chunk_last.childElementCount-1];
            let elem_msg_last = elem_block_last.children[1].children[elem_block_last.childElementCount-1];

            let is_author_block = String(message.author.is_anon) == elem_block_last.getAttribute("author_is_anon") && message.author.id == elem_block_last.getAttribute("author_id");
            let is_msg_in_time = (Date.parse(message.date_created) - Number(elem_msg_last.getAttribute("date")!)) < TIME_BLOCK_DURATION;

            if(is_author_block && is_msg_in_time) {
                /* Add to last Block */
                let elem_block_right = elem_block_last.children[1];
                elem_block_right.appendChild(elem_msg);
                if(index == 0) {
                    let block_last = chunk_last[chunk_last.length-1];
                    block_last.push(message);
                }
            }
            else {
                /* New Block */
                let elem_block = message_block_new(message.author, message.date_created);
                let elem_block_right = elem_block.children[1];
                elem_block_right.append(elem_msg);
                elem_chunk_last?.appendChild(elem_block);
                if(index == 0) chunk_last.push([message]);
            }
        }
        else {
            /* New Block */
            /* TODO: This needs a GOTO or something */
            let elem_block = message_block_new(message.author, message.date_created);
            let elem_block_right = elem_block.children[1];
            elem_block_right.append(elem_msg);
            elem_chunk_last?.appendChild(elem_block);
            if(index == 0) chunk_last.push([message]);
        }


		/* Window focused */
		let window = state.windows[state.window_selected];
		let tab = state.tabs[window.tabs[window.tab_selected]];
		let page_id = tab.history[tab.pos];

		if(scrolled_all_the_way_down && page_id_eql(page_id, this.page_id)){
            this.update_read();
		}
		else {
            /* TODO: Play sound, only for first instance */
            views.bar_left.listing_update(message.target);
        }
    }

    message_del (message :any) {
        let elem_msg = this.elem.querySelector(`#msg\\/${message.id}`)! as HTMLElement;
        if(!elem_msg) return;

		let options :any = {};
		if(message.target.type == ID_Type.THREAD) {
			options.thread = message.target.id;
			options.board = state.threads[message.target.id].board;
		}
		if(message.target.type == ID_Type.BOARD) options.board = message.target.id;
		let perms = auth_perms_get(options);

        let elem_block_right = elem_msg.parentElement!;
        let elem_msgs = elem_block_right.querySelectorAll(".message:not(.deleted)");
        if(elem_msgs.length == 1) {
            let elem_block = elem_block_right.parentElement!;
			if(perms[author_self_other(message.author)].obj.message & Perms_Manage.VIEW_DELETED) elem_block.classList.add("deleted")
			else elem_block.outerHTML = "";
        }
        else {
			if(perms[author_self_other(message.author)].obj.message & Perms_Manage.VIEW_DELETED) elem_msg.classList.add("deleted")
			else elem_block_right.removeChild(elem_msg);
		}

		/* TODO: Delete message from state */
    }

    message_edit (index :number, message :any) {
        let elem_msg = this.elem.querySelector(`#msg\\/${message.id}`)! as HTMLElement;
        if(!elem_msg) return;

        let elem_msg_new = message_new(message);
        elem_msg.innerHTML = elem_msg_new.innerHTML;
    }

	msg_next_is_block (msg_curr :any, msg_next :any) {
		let msg_curr_date = new Date(msg_curr.date_created);
		let msg_next_date = new Date(msg_next.date_created);

		let result = 
			msg_curr_date.getDay() != msg_next_date.getDay() ||                         /* Different days */
			(msg_next_date.getTime()-msg_curr_date.getTime()) > TIME_BLOCK_DURATION ||  /* Miliseconds in between messages */
			!author_same(msg_curr.author, msg_next.author);                             /* Different authors */
		return(result);
	}

	block_merge (chunk_start :any, block_start :any, chunk_end :any, block_end :any) {
		let block_start_msg_last = block_start[block_start.length-1];
		let block_end_msg_first = block_end[0];

		const next_msg_is_new_block = this.msg_next_is_block(block_start_msg_last, block_end_msg_first);
		if(next_msg_is_new_block) return(false);

		for(let msg of block_end) block_start.push(msg);
		chunk_end.shift();

		return(true);
	}

	elem_block_merge (chunk_start :any, block_start :any, chunk_end :any, block_end :any) {
		let block_start_messages = block_start.querySelector(".right");
		let block_end_messages = block_end.querySelectorAll(".message");
		for(let elem_msg of block_end_messages) block_start_messages.appendChild(elem_msg);
		chunk_end.removeChild(block_end);
	}

	chunk_from_messages (messages :any) {
		/* Turn chunk into blocks */ 
        let chunk :any = [];
        let block :any = [];

		for(let message_i = 0; message_i < messages.length-1; ++message_i) {
            block.push(messages[message_i+0]);
            const next_msg_is_new_block = this.msg_next_is_block(messages[message_i+0], messages[message_i+1]);
            if(next_msg_is_new_block) {
                chunk.push(block);
				block = [];
            }
		}
		block.push(messages[messages.length-1]);
		chunk.push(block);
		return(chunk);
	}

    chunk_add (index :number, messages_new :any, keys: any = "", all = {b: false, a: false}, more = {b: false, a: false}) {
		this.update_data();

		let scroll_old = this.elem_scroll_get(this.elem);

		let key = Object.keys(keys)[0]!;
		let val = Object.values(keys)[0]!;

        const elem_list = this.elem.querySelector(".list")!;
        if(messages_new.length > 0) {
            /* Find chunk and start and end dates */
            let chunk_date_start :number = new Date(messages_new[0].date_created).getTime();
            let chunk_date_end   :number = new Date(messages_new[messages_new.length-1].date_created).getTime();

            /* Adjust date based on query */
            switch(key) {
            case "last":
            case "around": {
                /* Do nothing */; break;
            } break;
            case "after":  chunk_date_start = new Date(val).getTime(); break;
            case "before": chunk_date_end   = new Date(val).getTime(); break;
            }

            /* Turn chunk into visible elements and append it */
            if(!this.data.messages) {
                /* Only chunk element */
                let chunk = this.chunk_from_messages(messages_new);
                let elem_chunk :Element | null = message_chunk_new(chunk, chunk_date_start, chunk_date_end);
                if(key == "last") elem_chunk?.classList.add("last");

                elem_list.innerHTML = "";
                elem_list.appendChild(elem_chunk);
                if(index == 0) this.data.messages = [chunk];
            }
            else {
                /* Find chunks in same range */
                let chunks_merge = [];
                for(let data_chunk_i = 0; data_chunk_i < this.data.messages.length; ++data_chunk_i) {
                    let data_chunk = this.data.messages[data_chunk_i];
                    let data_chunk_date_start = new Date(data_chunk[0][0].date_created).getTime();

                    let data_chunk_block_last = data_chunk[data_chunk.length-1];
                    let data_chunk_date_end = new Date(data_chunk_block_last[data_chunk_block_last.length-1].date_created).getTime();

                    let is_in_range = !((chunk_date_end < data_chunk_date_start) || (chunk_date_start > data_chunk_date_end));
                    if(is_in_range) {
                        chunks_merge.push(data_chunk_i);
                    }
                }

                if(chunks_merge.length == 0) {
                    /* Create new chunk, and append it to messages */
                    let data_chunks_date_start = new Date(this.data.messages[0][0][0].date_created).getTime();

                    let data_chunk_last = this.data.messages[this.data.messages.length-1];
                    let data_block_last = data_chunk_last[data_chunk_last.length-1];
                    let data_chunks_date_end = new Date(data_block_last[data_block_last.length-1].date_created).getTime();

                    let chunk = this.chunk_from_messages(messages_new);
                    let elem_chunk :Element | null = message_chunk_new(chunk, chunk_date_start, chunk_date_end);
                    if(chunk_date_end < data_chunks_date_start) {
                        /* At the start */
                        elem_list.prepend(elem_chunk);
                        if(index == 0) this.data.messages.unshift(chunk);
                    }
                    else if(chunk_date_start > data_chunks_date_end) {
                        /* At the end */
                        elem_list.append(elem_chunk);
                        if(index == 0) this.data.messages.push(chunk);
                    }
                    else {
                        /* In between */
                        for(let data_chunk_i = 0; data_chunk_i < this.data.messages.length-1; ++data_chunk_i) {
                            let data_chunk_curr = this.data.messages[data_chunk_i+0];
                            let data_chunk_curr_block_last = data_chunk_curr[data_chunk_curr.length-1];
                            let data_chunk_curr_date_end = new Date(data_chunk_curr_block_last[data_chunk_curr_block_last.length-1].date_created).getTime();

                            let data_chunk_next = this.data.messages[data_chunk_i+1];
                            let data_chunk_next_date_start = new Date(data_chunk_next[0][0].date_created).getTime();

                            let is_in_between = data_chunk_curr_date_end < chunk_date_start && chunk_date_end < data_chunk_next_date_start;
                            if(is_in_between) {
                                elem_list.insertBefore(elem_chunk, elem_list.childNodes[data_chunk_i+1]);
                                if(index == 0) this.data.messages.splice(data_chunk_i, 0, chunk);
                                break;
                            }
                        }
                    }
                }
                else {
                    /* Merge new chunk into found ones */
                    const elem_chunks = this.elem.querySelectorAll(".list .chunk");

                    let chunk_first_index = chunks_merge[0];
                    let chunk_first = this.data.messages[chunk_first_index];

                    let chunk_last_index = chunks_merge[chunks_merge.length-1];
                    let chunk_last = this.data.messages[chunk_last_index];
                    let chunk_last_block_last = chunk_last[chunk_last.length-1];
                    let chunk_last_message_last = chunk_last_block_last[chunk_last_block_last.length-1]

                    /* Start */ 
                    let chunk_first_date = new Date(chunk_first[0][0].date_created).getTime()
                    if(chunk_date_start <= chunk_first_date) {
                        let messages = messages_new.filter((msg :any) => new Date(msg.date_created).getTime() < chunk_first_date);

                        let msg_chunk = this.chunk_from_messages(messages);
                        let msg_chunk_block_last = msg_chunk[msg_chunk.length-1];
                        let elem_msg_chunk = message_chunk_new(msg_chunk, 0, 0);
                        let elem_msg_chunk_block_last = elem_msg_chunk.children[elem_msg_chunk.childElementCount-1];

                        let chunk_first_block_first = chunk_first[0];
                        let elem_chunk_first = elem_chunks[chunks_merge[0]];
                        let elem_chunk_first_block_first = elem_chunk_first.children[0];

                        /* Merge the ends */
                        let merge_end = this.block_merge(msg_chunk, msg_chunk_block_last, chunk_first, chunk_first_block_first);
                        if(merge_end) this.elem_block_merge(elem_msg_chunk, elem_msg_chunk_block_last, elem_chunk_first, elem_chunk_first_block_first);

                        /* Prepend the rest */
                        chunk_first = [...msg_chunk, ...chunk_first];
                        this.data.messages[chunk_first_index] = chunk_first;
                        /* NOTE: Don't use node.childElementCount in loops because they decrement when appending to another div */
                        while(elem_msg_chunk.childElementCount != 0) {
                            let elem_block = elem_msg_chunk.children[elem_msg_chunk.childElementCount-1];
                            elem_chunk_first.insertBefore(elem_block, elem_chunk_first.children[0]);
                        }

                        /* Update chunk start */
                        elem_chunk_first.setAttribute("start", chunk_first_date.toString());
                    }

                    /* End */
                    let chunk_last_date = new Date(chunk_last_message_last.date_created).getTime();
                    if(chunk_date_end >= chunk_last_date) {
                        let messages = messages_new.filter((msg :any) => new Date(msg.date_created).getTime() > chunk_last_date);

                        let msg_chunk = this.chunk_from_messages(messages);
                        let msg_chunk_block_first = msg_chunk[0];
                        let elem_msg_chunk = message_chunk_new(msg_chunk, 0, 0);
                        let elem_msg_chunk_block_first = elem_msg_chunk.children[0];

                        let elem_chunk_last = elem_chunks[chunks_merge[chunks_merge.length-1]];
                        let elem_chunk_last_block_last = elem_chunk_last.children[elem_chunk_last.childElementCount-1];

                        /* Merge the ends */
                        let merge_end = this.block_merge(chunk_last, chunk_last_block_last, msg_chunk, msg_chunk_block_first);
                        if(merge_end) this.elem_block_merge(elem_chunk_last, elem_chunk_last_block_last, elem_msg_chunk, elem_msg_chunk_block_first);

                        /* Append the rest */
                        chunk_last = [...chunk_last, ...msg_chunk];
                        /* NOTE: Don't use node.childElementCount in loops because they decrement when appending to another div */
                        this.data.messages[chunk_last_index] = chunk_last;
                        while(elem_msg_chunk.childElementCount != 0) {
                            let elem_block = elem_msg_chunk.children[0];
                            elem_chunk_last.appendChild(elem_block);
                        }

                        /* Update chunk start */
                        elem_chunk_last.setAttribute("end", chunk_last_date.toString());
                    }

                    /* Between */
                    for(let chunk_i = 0; chunk_i < chunks_merge.length-1; ++chunk_i) {
                        throw Error("Not completed");

                        let data_chunk_curr = this.data.messages[chunks_merge[chunk_i+0]];
                        let data_chunk_curr_block_last = data_chunk_curr[data_chunk_curr.length-1];
                        let data_chunk_curr_date_end = new Date(data_chunk_curr_block_last[data_chunk_curr_block_last.length-1].date_created).getTime();

                        let data_chunk_next = this.data.messages[chunks_merge[chunk_i+1]];
                        let data_chunk_next_block_first = data_chunk_next[0];
                        let data_chunk_next_date_start = new Date(data_chunk_next_block_first[0]).getTime();

                        let messages = messages_new.filter((msg :any) => {
                            let date = new Date(msg.date_created).getTime();
                            return (data_chunk_curr_date_end < date && date < data_chunk_next_date_start);
                        });
                        let msg_chunk = this.chunk_from_messages(messages);
                        let msg_chunk_block_first = msg_chunk[0];
                        let msg_chunk_block_last = msg_chunk[msg_chunk.length-1];

                        let elem_msg_chunk = message_chunk_new(msg_chunk, 0, 0);
                        let elem_msg_chunk_block_first = msg_chunk.children[0];
                        let elem_msg_chunk_block_last = msg_chunk.children[msg_chunk.childElementCount-1];

                        let merge_end = this.block_merge(msg_chunk_block_last, data_chunk_next_block_first);
                        if(merge_end) {
                            data_chunk_next.shift();

                            let elem_chunk_next = elem_chunks[chunks_merge[chunk_i+1]];
                            let elem_chunk_next_block_first = elem_chunk_next.children[0];
                            for(let child of elem_chunk_next_block_first.children) elem_msg_chunk_block_last.appendChild(child);
                            elem_chunk_next.removeChild((elem_chunk_next_block_first));
                        }

                        let merge_start = this.block_merge(data_chunk_curr_block_last, msg_chunk_block_first);
                        if(merge_start) {
                        }

                        /* Glute together */
                        //this.block_merge()
                    }
                }
            }

            /* Scroll to right place */
            {
				let scroll = this.elem_scroll_get(this.elem);

                switch(key) {
                case "last": 
                case "before": {
					/* Do nothing */
                } break;
                case "after": {
                    this.scroll = scroll_old.b + (scroll.h-scroll_old.h);
                } break;
                case "around": {
                    const elem_msg = this.elem.querySelector(`.message[date="${new Date(val).getTime()}"]`)!;
                    if(elem_msg) {
                        const rec_msg = elem_msg.getBoundingClientRect();
                        const rec_win = this.elem.getBoundingClientRect();
                        this.scroll = (rec_win.bottom-rec_msg.bottom); /* Bottom of the last message */
                    }
                } break;
                }
				this.elem_scroll_to(this.elem, this.scroll);
            }
            this.update_read();
        }

        /* Check if no more messages */
		/* TODO: This only works for single chunk */
        if(index == 0) {
			let load_b = ["before", "around", "last"].includes(key);
			let load_a = ["after",  "around", "last"].includes(key);

			/* Before */
			if(load_b) {
				if(!more.b) {
					this.data.messages_status.b = Status.LOADED_FULLY;

					let elem_beginning = document.createElement("div");
					elem_beginning.className = "beginning";

					switch(this.page_id.type) {
						case Page_Type.THREAD: {
							elem_beginning.classList.add("thread");
							let elem_title = document.createElement("div");
							elem_title.className = "title";
							elem_title.textContent = `#${this.data.subject}`;
							elem_beginning.appendChild(elem_title);
							let elem_start = document.createElement("div");
							elem_start.className = "start";
							elem_start.textContent = `This is the start of the "${this.data.subject}" thread.`;
							elem_beginning.appendChild(elem_start);

							let elem_separator = document.createElement("div");
							elem_separator.className = "separator";
							elem_beginning.appendChild(elem_separator);
						} break;
						case Page_Type.CHANNEL:
						case Page_Type.CHANNEL_TMP: {
							elem_beginning.classList.add("channel");

							let user_you :any = this.data.you;
							let elem_user = document.createElement("div");
							elem_user.className = "user";

							elem_user.appendChild(Author_Avatar(user_you, {size: "128"}));
							elem_user.appendChild(Author_Name(user_you));
							elem_beginning.appendChild(elem_user);

							let elem_start = document.createElement("div");
							elem_start.className = "start";
							elem_start.textContent = `This is the start of your conversation with ${author_name_str(user_you)}`;
							elem_beginning.appendChild(elem_start);

							let elem_separator = document.createElement("div");
							elem_separator.className = "separator";
							elem_beginning.appendChild(elem_separator);
						} break;
					}
					elem_list.prepend(elem_beginning);

                    /* First chunk */
                    let elem_chunks = this.elem.querySelectorAll(".list .chunk");
                    if(elem_chunks.length  > 0) {
                        let elem_chunk_frist = elem_chunks[0];
                        elem_chunk_frist.classList.add("first");
                    }
				}
				else if(!all.b) {
					this.data.messages_status.b = Status.LOADED_FULLY;

					let elem_archived = document.createElement("div");
					elem_archived.className = "archived";

					let elem_text = document.createElement("div");
					elem_text.appendChild(document.createTextNode(`Old messages have been archived, subscribers can still view them.`));
					elem_archived.appendChild(elem_text);
					let elem_subscription = document.createElement("div");
					elem_subscription.className = "subscription";
					elem_subscription.textContent = "Subscribe";
					elem_subscription.onclick = () => {
						auth_assert(() => {
							nav_to({type: Page_Type.SUBSCRIPTION, id: ""});
						})
					}
					elem_archived.appendChild(elem_subscription);
					elem_list.prepend(elem_archived);
				}
				else {
					this.data.messages_status.b = Status.LOADED_PARTLY;
				}
			}

			/* Ater */
			if(load_a) {
				if(!more.a) {
					this.data.messages_status.a = Status.LOADED_FULLY;

                    /* Last chunk */
                    let elem_chunks = this.elem.querySelectorAll(".list .chunk");
                    if(elem_chunks.length > 0) {
                        let elem_chunk_last = elem_chunks[elem_chunks.length-1];
                        elem_chunk_last.classList.add("last");
                    }
				}
				else {
					this.data.messages_status.a = Status.LOADED_PARTLY;
				}
			}
        }
    }
}

export default Messages;
