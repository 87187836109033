import { state } from "../../state";
import {Menu_Author} from "../menu/author";
import {Menu_Board} from "../menu/board";
import {Menu_Thread} from "../menu/thread";
import {Menu_Upload} from "../menu/upload";
import {Menu_Message} from "../menu/message";

export const menu_close = () => {
    if(state.menu != "") {
        let elem_menu = document.querySelector("#menu");
        if(elem_menu) elem_menu.outerHTML = "";
    }
}

export const Menu = (menu :string, from :any, pos :any, v :string = "down", h :string = "right", body: any) => {
    menu_close();
    state.menu = menu;

    let elem_app = document.querySelector("#app");

    let elem_menu = document.createElement("div");
    elem_app!.appendChild(elem_menu);
    elem_menu.id = "menu";
    elem_menu.className = `${window.innerWidth < 540 ? "mobile" : "desktop"}`;
    elem_menu.setAttribute("lc", "stop");

    switch(menu) {
        case "author": {
            elem_menu.appendChild(Menu_Author(body, from)); 
        } break;

        case "board": elem_menu.appendChild(Menu_Board(body)); break;
        case "thread": elem_menu.appendChild(Menu_Thread(body)); break;
        case "message": elem_menu.appendChild(Menu_Message(body)); break;
        case "upload": elem_menu.appendChild(Menu_Upload(body)); break;
        default: menu_close();
    }

    if(window.innerWidth < 540) {

    }
    else {
        elem_menu.style.left = `${pos.x}px`;
        elem_menu.style.top = `${pos.y}px`;
    }
}
