export enum Page_Type {
    NULL,

    ADMIN,
    ADMIN_MEMBERS,
    ADMIN_CHANNELS,

    MOD_REPORTS,

    HOME,
    TIPS,
    RULES,
    INVITE,
    INVITES,
    SUBSCRIPTION,
    SUBSCRIPTION_UPDATE,

    SETTINGS_ACCOUNT,
    SETTINGS_PROFILE,
    SETTINGS_SAFETY,
    SETTINGS_NOTIFS,

    MESSAGES,

    EMOJI_SET,

	BOARDS_OWNED,
	BOARDS_JOINED,
	BOARDS_OFFICIAL,
	BOARDS_PUBLIC,

	PROFILE_CONTENT,
    BOARD,
    THREAD,
    CHANNEL,
    CHANNEL_TMP,

    PAGE_404,
};

export type Page_ID = {
    id :string,
    type :Page_Type,
}

export const page_id_eql = (a :Page_ID, b :Page_ID) => {
	if(a.type != b.type) return(false);
	if(a.id != b.id) return(false);
	return(true);
}

export class Page {
    id :Page_ID;
    instances :any;

    constructor (id: Page_ID) {
        this.id = id;
        this.instances = [];
    }

    instance (window_index :number) {
        if(!(window_index in this.instances)) return(null);
        return(this.instances[window_index]);
    }

    elem_assert (window_index :number) {
        if(!(window_index in this.instances)) {
            let instance = this.new();
            instance.e.classList.add("page");
            if(instance.es) {
                const scroll_update = () => {
                    instance.s = instance.es.scrollTop;
                    instance.h = instance.es.scrollHeight;
                }
                instance.es.addEventListener('scroll', scroll_update);
                instance.es.addEventListener('resize', scroll_update);
            }
            this.instances[window_index] = instance;
        }
    }

    new(): any {
        throw new Error("Method not implemented.");
    }

    height (window_index :number) {
        if(!(window_index in this.instances)) return null;
        return(this.instances[window_index].h);
    }

    scroll (window_index :number) {
        if(!(window_index in this.instances)) return null;
        return(this.instances[window_index].s);
    }
}
