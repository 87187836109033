import Board from "./view/app/page/board";
import Thread from "./view/app/page/thread";
import {state} from "./state";
import {Page_Type, Page_ID} from "./view/app/page";
import {views} from "./view";
import App, {resize} from "./app";
import Main from "./view/app/main";
import {ID_Type} from "./id";

import {Gallery} from "./view/modal/gallery"
import {Profile} from "./view/modal/profile"
import {Menu} from "./view/modal/menu"
import {Auth} from "./view/modal/auth_full";
import { content_get, upload_get } from "./helper";

type Route = {
    path :string,
    type :Page_Type,
    has_bar_right :boolean,
}

//const PAGE_HOME :Page_Type = Page_Type.HOME;
export const PAGE_HOME :Page_Type = Page_Type.BOARDS_OFFICIAL;

const ROUTE_404 :Route = {path: "/404", type: Page_Type.PAGE_404, has_bar_right: false};
const routes :Route[] = [
    {path: "/",                    type: PAGE_HOME,                     has_bar_right: false},
    {path: "/tips",                type: Page_Type.TIPS,                has_bar_right: false},
    {path: "/rules",               type: Page_Type.RULES,               has_bar_right: false},
    {path: "/invites",             type: Page_Type.INVITES,             has_bar_right: false},
    {path: "/invite_id/:id",       type: Page_Type.INVITE,              has_bar_right: false},
    {path: "/subscription",        type: Page_Type.SUBSCRIPTION,        has_bar_right: false},
    {path: "/subscription/update", type: Page_Type.SUBSCRIPTION_UPDATE, has_bar_right: false},
    {path: "/boards/owned",        type: Page_Type.BOARDS_OWNED,        has_bar_right: true},
    {path: "/boards/joined",       type: Page_Type.BOARDS_JOINED,       has_bar_right: true},
    {path: "/boards/official",     type: Page_Type.BOARDS_OFFICIAL,     has_bar_right: true},
    {path: "/boards/public",       type: Page_Type.BOARDS_PUBLIC,       has_bar_right: true},
    {path: "/profile/:id",         type: Page_Type.PROFILE_CONTENT,     has_bar_right: false},
    {path: "/board/:id",           type: Page_Type.BOARD,               has_bar_right: true},
    {path: "/thread/:id",          type: Page_Type.THREAD,              has_bar_right: true},
    {path: "/channel/:id",         type: Page_Type.CHANNEL,             has_bar_right: true},
    {path: "/channel_tmp/:id",     type: Page_Type.CHANNEL_TMP,         has_bar_right: true},
    {path: "/settings/account",    type: Page_Type.SETTINGS_ACCOUNT,    has_bar_right: false},
    {path: "/settings/profile",    type: Page_Type.SETTINGS_PROFILE,    has_bar_right: false},
    {path: "/settings/safety",     type: Page_Type.SETTINGS_SAFETY,     has_bar_right: false},
    {path: "/settings/notifs",     type: Page_Type.SETTINGS_NOTIFS,     has_bar_right: false},
]

const nav_back = () => {
    let window_state = state.windows[state.window_selected];
    let tab = state.tabs[window_state.tabs[window_state.tab_selected]];
    if(tab.pos > 0) tab.pos -= 1;
    views.app.update_page();
    if(state.view.width < 540) views.app.set_main();
}

const nav_forward = () => {
    let window_state = state.windows[state.window_selected];
    let tab = state.tabs[window_state.tabs[window_state.tab_selected]];
    if(tab.pos < tab.history.length-1) tab.pos += 1;
    views.app.update_page();
    if(state.view.width < 540) views.app.set_main();
}

export const nav_to = (page_id :Page_ID, options :any = {}) => {
	if(!("new_tab" in options)) options.new_tab = false;
	if(!("close" in options)) options.close = true;
	if(!("history" in options)) options.history = true;

	if(page_id.type == Page_Type.SUBSCRIPTION_UPDATE) {
		options.history = false;
	}

    /* Set new history */
    let window_state = state.windows[state.window_selected];
    let window_view = views.window[state.window_selected];

    let tab = null;
    if(options.new_tab) {
        tab = {pos: 0, history: [page_id], pages: [{id: page_id, scroll: 0}]};
        state.tabs.push(tab);
        window_state.tabs.push(state.tabs.length-1);
        window_view.update_bar()
    }
    else {
		/* NOTE: For some reason this needs to be before window update */
        if(options.close && state.view.width < 540) views.app.set_main();

        tab = state.tabs[window_state.tabs[window_state.tab_selected]];
		tab.history.length = tab.pos+1;
		tab.history.push(page_id);
		tab.pos += 1;
        views.app.update_page();
    }

	/* Remove from history */
	if(!options.history) {
		tab.history.pop();
		tab.pos -= 1;
	}
}

export const route_init = () => {
    if(!("layout" in state)) {
        /* Figure out a path */
        const path = location.pathname.split("/").filter(x=>x);
        let match = routes.map(route => {
            const match = route.path.split("/").filter(x=>x).map(x=>x[0]==":"?x.slice(1):x);
            if(path.length != match.length) return null;
            if(path.length == 0 && match.length == 0) {
                return {route: route, params: []}
            }
            else if(path[0] == match[0]) {
                let params :any = {};
                const skip = 1; path.slice(skip).map((p, i) => params[match[i+skip]] = p);
                return {route: route, params: params};
            }
            else return null;
        }).filter(x=>x)[0];
        // if(!match) match = { route: ROUTE_404, params: [] };
        if(!match) match = {route: routes[0], params: []};

        /* Bar right should be drawn? */
        // if(match.route.has_bar_right) views.app.update_bar_right();

        /* Create a window for that patch */
        let page_id = {id: Object.values(match.params)[0] || "", type: match.route.type};

        /* State */
        state.tabs = [{pos: 0, history: [page_id]}];
        state.window_selected = 0;
        state.window_selected_last = -1;
        state.windows = [{
            tab_selected: 0,
            tab_selected_last: -1,
            tabs: [0],
        }];
        state.layout = 0;
    }
}

const action_menu = (e :any, elem :any) => {
    while(elem) {
        let rc = elem.getAttribute("rc");
        if(rc) {
            let type = rc.split(":")[0];
            let value = rc.substring(rc.indexOf(':') + 1);
            let success = false;
            switch(type) {
                case "menu": {
                    let menu = value.split("/")[0];
                    let pos = {x: e.clientX, y: e.clientY};

					let from = {};
					let window = state.windows[state.window_selected];
					let tab = state.tabs[window.tabs[window.tab_selected]];
					let page_id = tab.history[tab.pos];
					switch(page_id.type) {
						case Page_Type.THREAD: from = {id: page_id.id, type: ID_Type.THREAD}; break;
						case Page_Type.CHANNEL: from = {id: page_id.id, type: ID_Type.CHANNEL}; break;
						case Page_Type.CHANNEL_TMP: /* TODO: */; break;
						case Page_Type.BOARD: {
							/*
							let tar = t as HTMLElement | null;
							while(tar = tar!.parentElement)
							{
								if(tar.classList.contains("entry")) {
									let lc_id = tar.getAttribute("lc");
									let id = lc_id!.substring(lc_id!.indexOf('/')+1);
									from = {id: id, type: ID_Type.THREAD};
									break;
								}
							}
							*/
						} break;
					}

                    switch(menu) {
                        case "anon":
                        case "profile": {
                            let profile_id = value.split("/")[1];
                            let direction = value.split("/")[2];

                            let author :any = {};
                            if(menu == "anon") {
                                author = {is_anon: true, id: profile_id};
                                if(value.split("/")[3]) author.is_you = true;
                                else author.is_you = false;
                            }
                            else if(menu == "profile") author = state.profiles[profile_id];

                            Menu("author", from, pos, "down", direction, author);
                        } return(0);
						case "upload": {
							let upload_id = value.split("/")[1];
							let upload = upload_get(upload_id);
							Menu(menu, from, pos, "down", "right", upload);
						} return(0);
                        case "message": {
                            let message_id = elem.getAttribute("id")!.split("/")[1];
                            let message = state.messages[message_id];
                            Menu(menu, from, pos, "down", "right", message);
                        } return(0);
                        case "thread": {
                            let thread_id = value.split("/")[1];
                            let thread = state.threads[thread_id];
                            Menu(menu, from, pos, "down", "right", thread);
                        } return(0);
                        case "board": {
                            let board_id = value.split("/")[1];
                            let board = state.boards[board_id];
                            Menu(menu, from, pos, "down", "right", board);
                        } return(0);
                        case "stop": return(0);
                    }
                } break;
            }
        }
        elem = elem.parentElement;
    }

    return(-1);
}

let action = "";
let target :any = null;

const down = (e :any) => {
    if(e.button == 1 || (e.button == 0 && e.ctrlKey)) action = "click_super";
    else if(e.button == 0) action = "click";
    else if(e.button == 2) action = "menu";
    else return;

    target = e.target as HTMLElement;

    let t = target;
    while(t.parentElement) {
        if(t.classList.contains("window")) {
            let index = parseInt(t.getAttribute("i"));
            views.window[index].select();
            break;
        }
        t = t.parentElement;
    }
}

const up = (e :any) => {
    let t = e.target as HTMLElement;

    if(t == target) {
        switch(action) {
            case "click": {
                while(target.parentElement) {
                    let lc = target.getAttribute("lc");
                    if(lc) {
                        let type = lc.split(":")[0];
                        let value = lc.substring(lc.indexOf(':') + 1);
                        let success = false;
                        switch(type) {
                            case "nav": {
                                let page_id :Page_ID = {id: "", type: parseInt(value.split("/")[0])};
								let pages_with_id = [
                                    Page_Type.PROFILE_CONTENT, 
                                    Page_Type.EMOJI_SET, 

                                    Page_Type.BOARD, 
                                    Page_Type.THREAD, 
                                    Page_Type.CHANNEL, 
                                    Page_Type.CHANNEL_TMP
                                ];
                                if(pages_with_id.includes(page_id.type)) page_id.id = value.split("/")[1];
                                
								let options = {close: !target.classList.contains("bubble")};
                                nav_to(page_id, options);
                                success = true; break;
                            } break;
                            case "modal": {
                                let modal = value.split("/")[0];
                                switch(modal) {
                                    case "auth": {
                                        Auth();
                                    } return;
                                    case "anon": 
                                    case "profile": {
                                        let profile_id = value.split("/")[1];
                                        let direction = value.split("/")[2];

										let author :any = {};
										if(modal == "anon") {
											author = {is_anon: true, id: profile_id};
											if(value.split("/")[3]) author.is_you = true;
											else author.is_you = false;
										}
										else if(modal == "profile") author = state.profiles[profile_id];

                                        let window = state.windows[state.window_selected];
                                        let tab = state.tabs[window.tabs[window.tab_selected]];
                                        let page_id = tab.history[tab.pos];

                                        let from = null;
                                        switch(page_id.type) {
                                            case Page_Type.THREAD: from = {id: page_id.id, type: ID_Type.THREAD}; break;
                                            case Page_Type.CHANNEL: from = {id: page_id.id, type: ID_Type.CHANNEL}; break;
                                            case Page_Type.CHANNEL_TMP: /* TODO: */; break;
                                            case Page_Type.BOARD: {
                                                let tar = t as HTMLElement | null;
                                                while(tar = tar!.parentElement)
                                                {
                                                    if(tar.classList.contains("entry")) {
                                                        let lc_id = tar.getAttribute("lc");
                                                        let id = lc_id!.substring(lc_id!.indexOf('/')+1);
                                                        from = {id: id, type: ID_Type.THREAD};
                                                        break;
                                                    }
                                                }
												if(!from) from = {id: page_id.id, type: ID_Type.BOARD};
                                            } break;
                                        }
                                        Profile(author, from, target, "down", direction);
                                    } return;
                                }
                            } break;
                            case "stop": return;
                        }
                        break;
                    }

                    target = target.parentElement;
                }
            } break;
            case "click_super": {
                while(target.parentElement) {
                    let lc = target.getAttribute("lc");
                    if(lc) {
                        let type = lc.split(":")[0];
                        let value = lc.substring(lc.indexOf(':') + 1);
                        let success = false;
                        switch(type) {
                            case "nav": 
                            {
                                let page_id :Page_ID = {id: "", type: parseInt(value.split("/")[0])};
                                if([Page_Type.BOARD, Page_Type.THREAD, Page_Type.CHANNEL, Page_Type.CHANNEL_TMP].includes(page_id.type)) page_id.id = value.split("/")[1];
                                nav_to(page_id, {new_tab: true});
                                success = true; break;
                            }
                        }
                        if(success) break;
                    }

                    target = target.parentElement;
                }
            }
            case "menu": if(action_menu(e, target) == 0) return; break;
        }

        /* Close Modal */
        if(state.modal != "") {
            let elem_modal = document.querySelector("#modal");
            if(!elem_modal) return;
            elem_modal!.outerHTML = "";
            state.modal = "";
        }

        /* Close Menu */
        if(state.menu != "") {
            let elem_menu = document.querySelector("#menu");
            if(!elem_menu) return;
            elem_menu!.outerHTML = "";
            state.menu = "";
        }
    }
}

export const router_init = () => {
    /* Create Windows */
    route_init();


    /* Clicking and Touching */
    document.body.addEventListener("mousedown", (e :any) => down(e));
    document.body.addEventListener("mouseup", (e :any) => up(e));
    if(window.innerWidth < 540) {
        //document.body.addEventListener("touchstart", (e :any) => down(e));
        //document.body.addEventListener("touchend", (e :any) => up(e));
        document.body.addEventListener("contextmenu", (e :any) => {
            e.preventDefault();
            action_menu(e, e.target)
        });
    }
    else {
        /* Disable Right Click Default Menu */
        document.body.addEventListener("contextmenu", (e :any) => { e.preventDefault(); })
    }

    /* History */
    let url = "/";
    // let url = null;
    history.pushState(-1, "", url); // back state
    history.pushState( 0, "", url), // main state
    history.pushState(+1, "", url); // forward state
    history.go(-1);                   // start in main state

    window.addEventListener("popstate", (e) => {
        if(history.state != 0) {
            if(state.modal == "") {
                if(history.state > 0) nav_forward();
                else if(history.state < 0) nav_back();
            }
            history.go(-history.state);
        }
    })

}