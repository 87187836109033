import { state } from "./state";
import { views } from "./view";
import { ID_Type } from "./id";
import { socket, socket_send, socket_queue, ACTION } from "./socket";
import { Perms_Manage, auth_perms_get } from "./permission";
import { Page_Type } from "./view/app/page";

const version_assert = (version :string) => {
	if(!state.version) state.version = version;
	else if(version != state.version) window.location.reload();
}

export let auth :any = null;
export let auth_callback :any = null;

export const auth_exists = () => {
	return(auth != null);
}

export const auth_is_guest = () => {
	return(auth_exists() && auth.is_guest);
}

export const auth_is_user = () => {
	return(auth_exists() && !auth.is_guest);
}

export const auth_is_subscriber = () => {
	return(auth_is_user() && auth.user.is_subscriber);
}

export const auth_is_subscribed = () => {
	return(auth_is_user() && auth.user.is_subscribed);
}

export const auth_profile = () => {
	if(auth_is_guest()) return(null);
	let profile_id = Object.keys(auth.user.profiles)[auth.profile_index];
	return(auth.user.profiles[profile_id]);
}

export const profile_is_auth = () => {
}

export const auth_callback_set = (callback: any) => {
	auth_callback = callback;
}

export const auth_callback_exec = ()  => {
	if(auth_callback) {
		auth_callback();
	}
	auth_callback = null;
}

export const auth_roles_update = (target :any) => {
	let perms = auth_perms_get();

	if(target == null) {
		if(perms.other.obj.website & Perms_Manage.EDIT) views.bar_left.add_user_admin();
		else views.bar_left.del_user_admin();
	}
	else {
		switch(target.kind) {
		case ID_Type.THREAD: break;
		case ID_Type.BOARD: break;
		}
	}
}

export const auth_on_login = (payload :any) => {
	localStorage.setItem("token", payload.auth.token);
	localStorage.setItem("profile_index", payload.auth.profile_index);

	version_assert(payload.version);
	auth = payload.auth;

	if(!auth_is_user()) return;

	if(state.modal != "") {
		let elem_modal = document.querySelector("#modal");
		if(elem_modal) elem_modal!.outerHTML = "";
		state.modal = "";
	}

	/* Check for bans */
	let bans :any[] = [];
	if("bans" in auth.user) bans = [...bans, ...auth.user.bans];
	if(bans.length > 0) {
		//for(let ban of bans) for(let vio of ban.violations) if(vio.target.type == 0) {auth = auth; views.app.update(); return;};
	}

	views.bar_left.update_links();
	views.bar_left.update_messages();
	views.bar_left.update_bot();

	let elem_claim_account = document.querySelector("#claim_account");
	if(!elem_claim_account && !auth.user.account_is_claimed) {
		let elem_important = document.querySelector("#important") as HTMLElement;

		elem_claim_account = document.createElement("div");
		elem_claim_account.id = "claim_account";
		elem_claim_account.className = "urgent";
		elem_claim_account.textContent = "Remember to set up an email and a password so you don't lose your account!";
		elem_claim_account.setAttribute("lc", `nav:${Page_Type.SETTINGS_ACCOUNT}`);
		elem_important!.appendChild(elem_claim_account);

		let elem_app = document.querySelector("#app") as HTMLElement;
		elem_app.style.height = `calc(100% - ${elem_important.offsetHeight}px)`;
		elem_app.style.minHeight = elem_app.style.height;
		elem_app.style.maxHeight = elem_app.style.height;
	}

	socket_send(ACTION.BOARDS_ME_GET);
	socket_send(ACTION.CHANNELS_ME_GET);
}

export const auth_on_logout = () => {
	/* Only close private boards */
	//views.bar_left.del_user_links();
}

export const auth_on_join = (payload :any) => {
	version_assert(payload.version);
	auth = payload.auth;

	/* TODO: Banned screen */
	let bans :any[] = [];
	if("bans" in auth) bans = [...bans, ...auth.bans];
	if(bans.length > 0) {
		//for(let ban of bans) for(let vio of ban.violations) if(vio.target.type == 0) {auth = payload.auth; views.app.update(); return;};
	}

	/* Only send after auth */
	socket_send(ACTION.ROLES_GET);
	socket_send(ACTION.RULESET_GET, {target: {type: ID_Type.WEBSITE}});
	for(const message of socket_queue) socket!.send(message);

	if(auth_is_user()) auth_on_login(payload);
}

export const auth_on_subscription = (payload :any) => {
	auth.user = {...auth.user, ...payload};

	/* We only ever hit this function after a refresh, so it doens't make sense to remove the threads archive notice since no threads should be open */
	views.bar_left.update_bot();
}

export const auth_on_leave = (payload :any) => {
}
