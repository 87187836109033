import {state} from "./state";
import { ID_Type } from "./id";
import { ROLE_USER_GENERIC } from "./role";
import { author_same } from "./author";
import { auth, auth_profile } from "./auth";

export enum Perms_Manage {
    CREATE        = 0x1,
    DELETE        = 0x2,
    UNDELETE      = 0x4,

    EDIT          = 0x10,
    MERGE         = 0x20,
	REACT         = 0x40,

    PIN_ADD       = 0x100,
    PIN_DEL       = 0x200,
    PIN_EDIT      = 0x400,

	VIEW          = 0x1000,
    VIEW_EDITS    = 0x2000,
    VIEW_DELETED  = 0x4000,
    VIEW_ARCHIVED = 0x8000,

	SAVE          = 0x10000,
	DOWNLOAD      = 0x20000,
};

export enum Perms_Data {
    TEXT     = 0x1,
    LINK     = 0x2,
	FILE     = 0x4,
    MEDIA    = 0x8,

    TTS      = 0x10,
    SOUND    = 0x20,
    EMOJI    = 0x40,
    REACTION = 0x80,
};

export enum Perms_User {
	/* Status */
    ALL      = 0x1,
    ONLINE   = 0x2,
    OFFLINE  = 0x4,

	/* Auth Kind */
	GUEST     = 0x10,
	AUTH      = 0x20,
	AUTH_ANON = 0x40,
    AUTH_USER = 0x80,

	/* Auth Board */
	BOARD_MEMBER  = 0x400,
	BOARD_LURKER  = 0x800,

	/* Specific */
	IP      = 0x100,
	ANON    = 0x200,
	USER    = 0x400,
}

export enum Perms_Mention {
	MSG     = 0x1,
	CONTENT = 0x2,
	THREAD  = 0x4,
	BOARD   = 0x8,
}

export enum Perms_Call {
    VOICE      = 0x1, /* Microphone */
    WEBCAM     = 0x2, /* Webcam */
    SCREEN_VID = 0x4, /* Screen Video */
    SCREEN_AUD = 0x8, /* Screen Audio */
}

export enum Perms_Roles {
    ADD = 0x1,
    DEL = 0x2,
};

export enum Perms_Profile {
    NAME     = 0x1,
    ABOUT    = 0x2,
    AVATAR   = 0x4,
    BANNER   = 0x8,

    COLOR_NAME      = 0x10,
    COLOR_PRIMARY   = 0x20,
    COLOR_SECONDARY = 0x40,
};

export enum Perms_Access {
    BAN     = 0x1,
    KICK    = 0x2,
}

export type Perms_Single = {
    obj: {
        website: number, //Perms_Manage,
        board: number, //Perms_Manage,
        thread: number, //Perms_Manage,
        channel: number, //Perms_Manage,
        message: number, //Perms_Manage,
        content: number, //Perms_Manage,
    },
    user: {
		roles:number; // Perms_Roles,
		profile:number; // Perms_Profile,
		access:number; // Perms_Access,
    },
    data: {
		send:number; // Perms_Data,
		recv:number; // Perms_Data,
		user:number; //Perms_User,
		repost:number; // Perms_Mention,
		mention:number; // Perms_Mention,
    },
    call: {
		send:number; // Perms_Call,
		recv:number; // Perms_Call,
		access:number; // Perms_Access,
    },
}

export type Perms = {
	self :Perms_Single,
	other: Perms_Single,
}

export type Perms_Changes = {
	allow :any,
	prohibit :any,
}

export const PERMS_DEFAULT :Perms = {
	self: {
		obj:  {website: 0, board: 0, thread: 0, message: 0, content: 0},
		user: {roles: 0, profile: 0, access: 0,},
		data: {send: 0, recv: 0, user: 0, repost: 0, mention: 0,},
		call: {send: 0, recv: 0, access: 0,},
	},
	other: {
		obj:  {website: 0, board: 0, thread: 0, message: 0, content: 0},
		user: {roles: 0, profile: 0, access: 0,},
		data: {send: 0, recv: 0, user: 0, repost: 0, mention: 0,},
		call: {send: 0, recv: 0, access: 0,},
	},
}

const perms_apply_changes = (perms :any, changes :any) => {
	for(let action in changes) {
		let change_perms :any = changes[action];
		for(let key_0 in change_perms) {
			let val_0 = change_perms[key_0];
			for(let key_1 in val_0) {
				let val_1 = val_0[key_1];
				for(let key_2 in val_1) {
					let val_2 = val_1[key_2];
					switch(action) {
					case "allow":    perms[key_0][key_1][key_2] |=  val_2; break;
					case "prohibit": perms[key_0][key_1][key_2] &= ~val_2; break;
					}
				}
			}
		}
	}
	return(perms);
}

const perms_apply_roles = (perms :any, roles :any[]) => {
	for(let role_id of roles) {
		let role = state.roles[role_id];
		perms = perms_apply_changes(perms, role.perms);
	}
	return(perms);
}

const roles_sorted_get = (options :any = {}) => {
	if(!("board" in options)) options.board = null;
	if(!("thread" in options)) options.thread = null;

	if(!state.roles_target) throw Error("Roles were not fetched");

	/* Step 1: Get all the roles */
	let roles = [];
	roles.push(...state.roles_target[""]);
	roles.push(...state.roles_target[ID_Type.THREAD][""]);
	if(options.thread)  {
		if(!(options.thread in state.roles_target[ID_Type.THREAD])) throw Error("Thread roles were not fetched");
		else roles.push(...state.roles_target[ID_Type.THREAD][options.thread]);
	}
	roles.push(...state.roles_target[ID_Type.BOARD][""]);
	if(options.board)  {
		if(!(options.board in state.roles_target[ID_Type.BOARD])) throw Error("Board roles were not fetched");
		else roles.push(...state.roles_target[ID_Type.BOARD][options.board]);
	}
	roles.push(...state.roles_target[ID_Type.WEBSITE]);

	/* Step 2: Group the roles */
	let roles_grouped :any = {};
	for(let role_id of roles) {
		let role = state.roles[role_id];
		if(!(role.pos_global in roles_grouped)) roles_grouped[role.pos_global] = [];
		roles_grouped[role.pos_global].push(role_id);
	}

	/* Step 3: Sort the roles inside the groups */
	let roles_sorted :any = [];
	for(let group_id = 7; group_id >= 0; --group_id) {
		if(!(group_id.toString() in roles_grouped)) continue;
		roles_grouped[group_id].sort((a :any, b :any) => state.roles[b].pos - state.roles[a].pos);
		roles_sorted.push(...roles_grouped[group_id]);
	}

	return(roles_sorted);
}

const profile_roles_filter = (profile :any, roles :any[]) => {
	let is_online = true;

	let roles_filtered :any = [];
	for(let role_id of roles) {
		let role = state.roles[role_id];

		/* Generic */
		let match_generic = false;
		if(role.user_generic & ROLE_USER_GENERIC.ENABLED) {
			match_generic = true;

			/* TODO: These all need to be true at the same time */
			let match_auth = true;
			if(role.user_generic & ROLE_USER_GENERIC.AUTH) {
				//if(role.user_generic & ROLE_USER_GENERIC.AUTH_USER) if(auth.is_guest) match_auth = false;
			}

			let match_status = true;
			if(role.user_generic & ROLE_USER_GENERIC.STATUS) {
				if(role.user_generic & ROLE_USER_GENERIC.STATUS_ONLINE) if(!is_online) match_auth = false;
				if(role.user_generic & ROLE_USER_GENERIC.STATUS_ANON) if(!profile.is_anon) match_auth = false;
			}

			let match_object = true;
			if(role.user_generic & ROLE_USER_GENERIC.OBJECT) {
				if(role.user_generic & ROLE_USER_GENERIC.OBJECT_CREATED) {
					let object = null;
					switch(role.target.type) {
					case ID_Type.THREAD: {
						if(!(role.target.id in state.threads)) throw Error("Thread not fetched");
						object = state.threads[role.target.id];
					} break;
					case ID_Type.BOARD: {
						if(!(role.target.id in state.boards)) throw Error("Board not fetched");
						object = state.boards[role.target.id];
					} break;
					default: {
						throw Error("Invalid object kind");
					} break;
					}
					if(!author_same(profile, object!.author)) match_object = false;
				}

				if(role.user_generic & ROLE_USER_GENERIC.OBJECT_WATCHING) {
					throw Error("Not implemented");
				}
				if(role.user_generic & ROLE_USER_GENERIC.OBJECT_INTERACTED) {
					throw Error("Not implemented");
				}
			}
			if(!match_auth || !match_status || !match_object) match_generic = false;
		}
		if(match_generic) {
			roles_filtered.push(role_id);
			continue;
		}

		/* Specific */
		let match_specific = false;
		if("roles" in profile) {
			switch(role.target.type) {
			case null: if("" in profile.roles && profile.roles[""].includes(role_id)) match_specific = true; break;
			case ID_Type.WEBSITE: if(ID_Type.WEBSITE in profile.roles && profile.roles[ID_Type.WEBSITE].includes(role_id)) match_specific = true; break;
			default: if(role.target.type in profile.roles && role.target.id in profile.roles[role.target.type]) if(profile.roles[role.target.type][role.target.id].includes(role_id)) match_specific = true; break;
			}
		}
		if(match_specific) {
			roles_filtered.push(role_id);
			continue;
		}
	}

	return(roles_filtered);
}

const guest_roles_filter = (roles :any[]) => {
	let is_online = true;

	let roles_filtered :any = [];
	for(let role_id of roles) {
		let role = state.roles[role_id];

		/* Generic */
		let match_generic = false;
		if(role.user_generic & ROLE_USER_GENERIC.ENABLED) {
			match_generic = true;

			/* TODO: These all need to be true at the same time */
			let match_auth = true;
			if(role.user_generic & ROLE_USER_GENERIC.AUTH) {
				if(role.user_generic & ROLE_USER_GENERIC.AUTH_USER) match_auth = false;
			}

			let match_status = true;
			if(role.user_generic & ROLE_USER_GENERIC.STATUS) {
				if(role.user_generic & ROLE_USER_GENERIC.STATUS_ONLINE) if(!is_online) match_auth = false;
				//if(role.user_generic & ROLE_USER_GENERIC.STATUS_ANON) if(!user.is_anon) match_auth = false;
			}

			let match_object = true;
			if(role.user_generic & ROLE_USER_GENERIC.OBJECT) {
				if(role.user_generic & ROLE_USER_GENERIC.OBJECT_CREATED) match_object = false;
				if(role.user_generic & ROLE_USER_GENERIC.OBJECT_WATCHING) match_object = false;
				if(role.user_generic & ROLE_USER_GENERIC.OBJECT_INTERACTED) match_object = false;
			}
			if(!match_auth || !match_status || !match_object) match_generic = false;
		}
		if(match_generic) roles_filtered.push(role_id);
	}
	return(roles_filtered);
}

export const profile_roles_get = (profile :any, options :any = {}) => {
	let roles_sorted = roles_sorted_get(options);
	let roles_filtered = profile_roles_filter(profile, roles_sorted);
	return(roles_filtered);
}

export const profile_roles_groups_get = (profile :any, options: any = {}) => {
	let roles = profile_roles_get(profile, options);
	let groups :any = {};
	roles.map((role_id :string) => {
		let role = state.roles[role_id];
		if(role.target.type == null) return;

		if(!(role.target.type in groups)) groups[role.target.type] = [];
		groups[role.target.type].push(role_id);
	})
	return(groups);
}

export const profile_perms_get = (profile :any, options :any = {}) => {
	let roles = profile_roles_get(profile, options);
	let perms = perms_apply_roles(PERMS_DEFAULT, roles);
	return(perms);
}


export const auth_roles_get = (options :any = {}) => {
	let roles_sorted = roles_sorted_get(options);
	let roles_filtered = auth.is_guest ? guest_roles_filter(roles_sorted) : profile_roles_filter(auth_profile(), roles_sorted);
	return(roles_filtered);
}

export const auth_roles_groups_get = (options: any = {}) => {
	let roles = auth_roles_get(options);
	let groups :any = {};
	roles.map((role_id :string) => {
		let role = state.roles[role_id];
		if(role.target.type == null) return;

		if(!(role.target.type in groups)) groups[role.target.type] = [];
		groups[role.target.type].push(role_id);
	})
	return(groups);
}

export const auth_perms_get = (options :any = {}) => {
	let roles = auth_roles_get(options);
	let perms = perms_apply_roles(PERMS_DEFAULT, roles);
	return(perms);
}