import { auth } from "../../auth";
import { ACTION, socket_send } from "../../socket";
import { state } from "../../state";
import { Author_Avatar, Author_Name } from "../modular/author";
import { Auth_Quick } from "./auth_quick";

const close_modal = () => {
    if(state.modal != "") {
        let elem_modal = document.querySelector("#modal");
        if(elem_modal) elem_modal.outerHTML = "";
    }
}


export class Profile_Switch {
    elem: HTMLElement | null = null;

    constructor () {
    }

    new () {
        close_modal();
        state.modal = "profile_switch";

        let elem_app = document.querySelector("#app")!!;

        let elem_modal = document.createElement("div"); elem_app.appendChild(elem_modal)
        elem_modal.id = "modal";

        let elem_background = document.createElement("div"); elem_modal.appendChild(elem_background);
        elem_background.className = "background";
        
        this.elem = document.createElement("div"); elem_modal.appendChild(this.elem);
        this.elem.className = "profile_switch";
        this.elem.setAttribute("lc", "stop");

        let elem_profiles = document.createElement("div"); this.elem.appendChild(elem_profiles);
        elem_profiles.className = "profiles";

        for(let i = 0; i < Object.keys(auth.user.profiles).length; ++i) {
            let profile = Object.values(auth.user.profiles)[i];

            let elem_profile = document.createElement("div"); elem_profiles.appendChild(elem_profile);
            elem_profile.className = "profile";
            if(i == auth.profile_index) elem_profile.classList.add("selected");

            elem_profile.appendChild(Author_Avatar(profile, {size: "32"})!);

            let elem_name_status = document.createElement("div"); elem_profile.appendChild(elem_name_status);
            elem_name_status.className = "name_status";

            elem_name_status.appendChild(Author_Name(profile, {badges: true})!);

            let elem_status = document.createElement("div"); elem_name_status.appendChild(elem_status);
            elem_status.className = "status";

            elem_profile.onclick = (e :any) => {
                let index = Array.prototype.indexOf.call(elem_profiles.children, e.target);
                if(index == -1) return;

                localStorage.setItem("profile_index", JSON.stringify(index));
                window.location.reload();
                close_modal();
            }
        }

        let elem_new_profile = document.createElement("div"); this.elem.appendChild(elem_new_profile);
        elem_new_profile.className = "new";
        elem_new_profile.textContent = "New profile";
        elem_new_profile.onclick = () => {
            Auth_Quick();
        }
    }
}