import {Anon_Name, Anon_Avatar, anon_name_str, Anon_Entry} from "./anon";
import {Profile_Name, Profile_Avatar, profile_name_str, Profile_Entry} from "./profile";
import {Icon} from "./icon";
import { state } from "../../state";
import { profile_get } from "../../helper";

export const author_name_str_from_id = (id :string) => {
    return `#${id.slice(0, 8)}`;
}

export const author_name_str = (author :any) => {
	if(author.is_anon) return(anon_name_str(author.id));
	else return(profile_name_str(author.id, author.name));
}

export const Author_Name = (author :any, options :any = {}) => {
    if(!("mention" in options)) options.mention = false;
    if(!("specific" in options)) options.specific = true;

    let elem = document.createElement("div");
    elem.className = "author_name";
    if(!author) return elem;
    
    if(options.mention) {
        let elem_mention = document.createElement("div");
        elem_mention.className = "mention";
        elem_mention.textContent = "@";
        elem.appendChild(elem_mention);
    }

    if(author.is_banned) {};

    if(author.is_anon) {
        let anon_name = Anon_Name(author, options);
        if(anon_name) elem.appendChild(anon_name);
    }
    else {
		let profile = profile_get(author.id);
        let profile_name = Profile_Name(profile, options);
        if(profile_name) elem.appendChild(profile_name);
    }

    return elem;
}

export const Author_Avatar = (author :any, options: any = {}) => {
    if(!("size" in options)) options.size = "32";
	if(!("specific" in options)) options.specific = true;

    let elem = document.createElement("div");
    elem.className = "author_avatar";
    if(!author) return elem;

    if(author.is_banned) {
        elem.innerHTML = Icon.Author.Banned;
        return elem;
    }

    if(author.is_anon) {
        let anon_avatar = Anon_Avatar(author, options);
        if(anon_avatar) elem.appendChild(anon_avatar);
    }
    else {
		let profile = profile_get(author.id);
        let profile_avatar = Profile_Avatar(profile, options);
        if(profile_avatar) elem.appendChild(profile_avatar);
    }

    return elem;
}

export const Author_Color = (author :any) => {
	/* Take last 3 hex values from ID as a string */
    let seconds =  author.id.slice(0, 8);
    let x = seconds.substring(seconds.length - 3);

	/* Turn that string into a number */
    let color = 0;
    for(let i = 0; i < 3; ++i) {
        let c = parseInt(x[i], 16) * 16;
		if(c != 0) c-= 1;
        let a = c << (i*8)
        color += a;
    }

    return color;
}

export const Author_Status = (author: any, options: any = {}) => {
}

export const Author_Entry = (author: any, options: any = {}) => {
	let elem = document.createElement("div");
	elem.className = "author_entry";
	if(author.is_anon) {
		let anon_entry = Anon_Entry(author, options);
		if(anon_entry) elem.appendChild(anon_entry);
	}
	else {
		let profile = profile_get(author.id);
		let profile_entry = Profile_Entry(profile, options);
		if(profile_entry) elem.appendChild(profile_entry);
	}
	return(elem);
}