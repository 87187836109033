import { state } from "../../state";

const SIZE_1080P = { width: 1920, height: 1080 };
const size_greater = (size :any, res :any) => {
    return (size.width > res.width || size.height > res.height);
}

export const Media = (media :any, options: any = {}) :HTMLImageElement | HTMLVideoElement => {
	if(!("size" in options)) options.size = "64";
	if(!("thumb" in options)) options.thumb = false;
	if(!("onload" in options)) options.onload = null;

	if(!("video" in options)) options.video = {};
	if(!("loop" in options.video)) options.video.loop = true;
	if(!("muted" in options.video)) options.video.muted = true;
	if(!("controls" in options.video)) options.video.controls = false;
	if(!("autoplay" in options.video)) options.video.autoplay = true;
	if(!("preview" in options.video)) options.video.preview = true;

    const img_null = document.createElement("img");
    if(!media) return(img_null);

    let size_id = 0;
    switch(options.size) {
        case "32":       size_id = 0; break;
        case "64":       size_id = 1; break;
        case "128":      size_id = 2; break;
        case "512":      size_id = 3; break;
        case "full":     size_id = 4; break;
        case "original": size_id = 5; break;
    }

    const type = media.type.split("/")[0];
    const ext = media.type.split("/")[1];

    let path = media.path;
    const media_size = { width: media.width, height: media.height };
    if ((type == "image" && ext != "gif") || (type == "video" && options.thumb)) {
        switch (size_id) {
            case 1: {
                if (media_size.width > 64) path = media.path.split('.').slice(0, -1).join('.') + "_64" + ".jpg";
                else path = media.path.split('.').slice(0, -1).join('.') + "_full" + ".jpg";
            } break;
            case 2: {
                if (media_size.width > 128) path = media.path.split('.').slice(0, -1).join('.') + "_128" + ".jpg";
                else path = media.path.split('.').slice(0, -1).join('.') + "_full" + ".jpg";
            } break;
            case 3: {
                if (ext == "gif") break;
                if (media_size.width > 512) path = media.path.split('.').slice(0, -1).join('.') + "_512" + ".jpg";
                else path = media.path.split('.').slice(0, -1).join('.') + "_full" + ".jpg";
            } break;
            case 4: {
                if (ext == "gif") break;
                if (!size_greater(media_size, SIZE_1080P)) path = media.path.split('.').slice(0, -1).join('.') + "_full" + ".jpg";
                else {
                    const window_size = { width: window.screen.width, height: window.screen.height };
                    if (size_greater(window_size, SIZE_1080P)) path = media.path.split('.').slice(0, -1).join('.') + "_full" + ".jpg";
                    else path = media.path.split('.').slice(0, -1).join('.') + "_1080p" + ".jpg";
                }
            } break;
            case 5: {
                path = media.path;
            } break;
        }
    }
    path = "/content/" + encodeURI(path);

    if(!(media.id in state.contents)) state.contents[media.id] = {};
	if(!("render" in state.contents[media.id])) state.contents[media.id].render = {};

    if(media.thumbnail) {
        if(!("0" in state.contents[media.id].render)) {
            let ar = new Uint8Array(media.thumbnail.data);
            let blob = new Blob([ar], { type: "image/jpeg" } );
            state.contents[media.id].render[0] = URL.createObjectURL(blob);
        }
    }

    let sizes = Object.keys(state.contents[media.id].render).map((size_id) => parseInt(size_id)).sort(function(a, b) { return b-a; });;
    let elem_img = document.createElement("img");
    elem_img.id = `media/${media.id}/${size_id}`;
    elem_img.className = "media";

    if (type == "video" && !options.thumb) {
        let elem_video = document.createElement("video");
        elem_video.className = "media";
        elem_video.id = `media/${media.id}/${size_id}`
        elem_video.loop = options.video.loop;
        elem_video.controls = options.video.controls;
        elem_video.autoplay = options.video.autoplay;
		elem_video.muted = options.video.muted;

        let elem_source = document.createElement("source");
        elem_video.appendChild(elem_source);
        elem_source.src =`${path}`;

		if(options.video.preview) {
			elem_video.onloadeddata = (e) => {
				let vid = (e.target as HTMLVideoElement);
				vid.onloadeddata = null;

				let parent = elem_img.parentElement;
				parent!.innerHTML = "";
				parent?.appendChild(vid);

				if(options.onload) options.onload();
			}
		}
		else return(elem_video);
    }
    else if(sizes.length == 0 || sizes[0] < size_id) {
		state.contents[media.id].render[size_id] = path;
		fetch(path)
		.then(file => file.blob())
		.then(blob => {
			let url = URL.createObjectURL(blob);
        	elem_img.src =`${url}`;
			elem_img.onload = () => {
				URL.revokeObjectURL(url);
				if(options.onload) options.onload();
			}
		})
		.catch(err => console.error(err))
    }

    if(sizes.length) elem_img.src = state.contents[media.id].render[sizes[0]];
    return(elem_img);
}