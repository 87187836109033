import { state } from "../../../../state";
import {Page, Page_Type} from "../../page";
import { ACTION, socket_send } from "../../../../socket";
import { Author_Avatar, Author_Name } from "../../../modular/author";

class Page_Admin_Members extends Page {
    constructor() {
        super({type: Page_Type.ADMIN_MEMBERS, id: ""});
		socket_send(ACTION.ADMIN_MEMBERS_GET);
    }

    new () {
        let elem = document.createElement("div");
        elem.className = "admin_members";
        elem.innerHTML = "admin_members";
        if(state.admin.members) this.update_elem(elem);
        return({e: elem, es: elem, h: 0, s: 0});
    }

    add (p :any) {
        let key = Object.keys(p)[0];
        let value = Object.values(p)[0];

        if(!state.admin.members) return;
        for(let i of Object.keys(this.instances)) {
            let elem = this.instances[i].e;
            let elem_header = elem.querySelector(".header");
            elem_header.textContent = `TOTAL: ${parseInt(elem_header.textContent.split(" ")[1])+1}`;

            switch(key) {
                case "profile": {
                    let elem_profiles = elem.querySelector(".container .profiles");
                    let elem_profiles_header = elem_profiles.querySelector(".header");
                    elem_profiles_header.textContent = `PROFILES: ${parseInt(elem_profiles_header.textContent.split(" ")[1])+1}`;

                    let elem_profiles_items = elem_profiles.querySelector(".items");
                    let elem_profile = this.new_profile(value);
                    elem_profiles_items.prepend(elem_profile);
                } break;
                case "guest": {
                    let elem_guests = elem.querySelector(".container .guests");
                    let elem_guests_header = elem_guests.querySelector(".header");
                    elem_guests_header.textContent = `GUESTS: ${parseInt(elem_guests_header.textContent.split(" ")[1])+1}`;

                    let elem_guests_items = elem_guests.querySelector(".items");
                    let elem_guest = this.new_guest(value);
                    elem_guests_items.prepend(elem_guest);
                } break;
            }
        }
    }

    del (p :any) {
        let key = Object.keys(p)[0];
        let value = Object.values(p)[0];

        if(!state.admin.members) return;
        for(let i of Object.keys(this.instances)) {
            let elem = this.instances[i].e;
            let elem_header = elem.querySelector(".header");
            elem_header.textContent = `TOTAL: ${parseInt(elem_header.textContent.split(" ")[1])-1}`;

            switch(key) {
                case "profile": {
                    let elem_profiles = elem.querySelector(".container .profiles");
                    let elem_profiles_header = elem_profiles.querySelector(".header");
                    elem_profiles_header.textContent = `PROFILES: ${parseInt(elem_profiles_header.textContent.split(" ")[1])-1}`;

                    let elem_profiles_items = elem_profiles.querySelector(".items");
                    let elem_profile = document.getElementById(`member_${value}`);
                    elem_profiles_items.removeChild(elem_profile);
                } break;
                case "guest": {
                    let elem_guests = elem.querySelector(".container .guests");
                    let elem_guests_header = elem_guests.querySelector(".header");
                    elem_guests_header.textContent = `GUESTS: ${parseInt(elem_guests_header.textContent.split(" ")[1])-1}`;

                    let elem_guests_items = elem_guests.querySelector(".items");
                    let elem_guest = document.getElementById(`member_${value}`);
                    elem_guests_items.removeChild(elem_guest);
                } break;
            }
        }
    }
    
    new_profile (profile :any) {
        let elem_profile = document.createElement("div");
        elem_profile.className = "item profile";
        elem_profile.id = `member_${profile.id}`;
        elem_profile.setAttribute("lc", `modal:profile/${profile.id}/right`);
        elem_profile.setAttribute("rc", `menu:profile/${profile.id}/right`);

        //elem_profile.setAttribute("lc", `nav:${Page_Type.CHANNEL}/${channel.id}`)
        elem_profile.appendChild(Author_Avatar(profile, {size: "32"}));
        let elem_right = document.createElement("div");
        elem_right.className = "right";
        elem_right.appendChild(Author_Name(profile)!);
        let elem_status = document.createElement("div");
        elem_status.className = "status";
        elem_right.appendChild(elem_status);
        elem_profile.appendChild(elem_right);
        return elem_profile;
    }

    new_guest (guest_ip :any) {
        let elem_guest = document.createElement("div");
        elem_guest.className = "item guest";
        elem_guest.id = `member_${guest_ip}`;
        elem_guest.textContent = `${guest_ip}`;
        return elem_guest;
    }

    update_elem (elem :HTMLElement) {
        elem.innerHTML = "";

        let members = state.admin.members;
        let profile_count = Object.keys(members.profiles).length;
        let guest_count = Object.keys(members.guests).length;
        let total_count = profile_count + guest_count;

        let elem_header = document.createElement("div");
        elem_header.className = "header";
        elem_header.textContent = `TOTAL: ${total_count}`;
        elem.appendChild(elem_header);

        let elem_container = document.createElement("div");
        elem_container.className = "container";

        let elem_profiles = document.createElement("div");
        elem_profiles.className = "profiles";
        let elem_profiles_header = document.createElement("div");
        elem_profiles_header.className = "header";
        elem_profiles_header.textContent = `PROFILES: ${profile_count}`;
        elem_profiles.appendChild(elem_profiles_header);
        let elem_profiles_items = document.createElement("div");
        elem_profiles_items.className = "items";
        for(let profile of state.admin.members.profiles) {
            let elem_profile = this.new_profile(profile);
            elem_profiles_items.appendChild(elem_profile);
        }
        elem_profiles.appendChild(elem_profiles_items);
        elem_container.appendChild(elem_profiles);

        let elem_guests = document.createElement("div");
        elem_guests.className = "guests";
        let elem_guests_header = document.createElement("div");
        elem_guests_header.className = "header";
        elem_guests_header.textContent = `GUESTS: ${guest_count}`;
        elem_guests.appendChild(elem_guests_header);
        let elem_guests_items = document.createElement("div");
        elem_guests_items.className = "items";
        for(let guest_ip of state.admin.members.guests) {
            let elem_guest = this.new_guest(guest_ip);
            elem_guests_items.appendChild(elem_guest);
        }
        elem_guests.appendChild(elem_guests_items);
        elem_container.appendChild(elem_guests);
        elem.appendChild(elem_container);
    }

    update () {
        if(!state.admin.members) return;
        for(let i of Object.keys(this.instances)) this.update_elem(this.instances[i].e);
    }
}

export default Page_Admin_Members;
