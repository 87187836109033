export const ROLE_USER_GENERIC = {
	ENABLED:           (1 << 0), //weather it also has generic mask

	AUTH:              (1 << 1), //weather auth kind matters
	AUTH_USER:         (1 << 2), //vs guest

	STATUS:            (1 << 3), //weather the user status matter
	STATUS_ONLINE:     (1 << 4), //vs Offline
	STATUS_ANON:       (1 << 5), //user vs anon

	OBJECT:            (1 << 6), //weather object status matters
	OBJECT_CREATED:    (1 << 7), //board creator or thread op
	OBJECT_WATCHING:   (1 << 8), //board joined or thread watching
	OBJECT_INTERACTED: (1 << 9), //board interaction or thread participant
};
