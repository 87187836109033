import { Page, Page_Type } from "../../page";

class Page_SettingsSafety extends Page {
	constructor () {
		super({type: Page_Type.SETTINGS_SAFETY, id: ""});
	}

    new = () => {
        let elem = document.createElement("div");
        elem.className = "settings safety";
        this.update_elem(elem);
        return({e: elem, es: elem, h: 0, s: 0});
    }

	update_elem = (elem :any)  => {
	}
}

export default Page_SettingsSafety;
