import {Author_Name, Author_Avatar} from "../modular/author"
import {Icon} from "../modular/icon";

import {CONST} from "../../global";
import {state} from "../../state"
import { auth_is_user, auth_profile} from "../../auth"
import {Page_ID, Page_Type} from "./page"
import { participants_me_you_get } from "../../model/channel";
import { channel_get, board_get, content_get, upload_get, profile_get } from "../../helper";
import { Content } from "../modular/content";
import {ID_Type} from "../../id.ts"
import { Profile_Switch } from "../modal/profile_switch";
import { date_str } from "../modular/time";

const HEADER_SIZE = 35;

const BOARD_FLAG = {
    DELETED:  0x1,
    PRIVATE:  0x1,
	OFFICIAL: 0x4,
    PINNED:   0x8,
}

const THREAD_FLAG = {
    PINNED:   0x01,
    EDITED:   0x02,
    MERGE:    0x04,

    DELETED:  0x10,
    ARCHIVED: 0x20,
}

class BarLeft {
	page_selected :Page_ID;

    loaded = {
        boards: false,
        threads: false,
        messages: false,
    };
    elem :any;
    elem_mid :any;

    /* New */
    elem_left :any;
    elem_right :any;
    elem_bot :any;

	elem_groups :any;
	elem_selection :any;
	elem_selected_tab :any;
	elem_selected_right :any;
	elem_selected_bubble :any;

    left :any;
    right :any;
	bot :any;

    constructor () {
		this.page_selected = {type: Page_Type.NULL, id: ""};
        this.elem = document.createElement("div");
        this.elem.id = "bar_left";
        this.elem.className = "bar_side";

        this.update();
    }

    update () {
        this.elem.innerHTML = "";

        let elem_top = document.createElement("div"); this.elem.appendChild(elem_top);
        elem_top.className = "top";

        this.elem_left = document.createElement("div"); elem_top.appendChild(this.elem_left);
        this.elem_left.className = "left";
        this.left =  {
            admin:    {elem: null, group_elem: null},
            links:    {elem: null, group_elem: null},
            messages: {elem: null, group_elem: null, list: {}},
            boards:   {elem: null, group_elem: null, list: {}},
        }

		this.elem_selection = document.createElement("div"); this.elem_left.prepend(this.elem_selection);
		this.elem_selection.className = "selection";
		this.elem_selected_tab = null;
		this.elem_selected_right = null;
		this.elem_selected_bubble = null;

		this.elem_groups = document.createElement("groups"); this.elem_left.appendChild(this.elem_groups);
		this.elem_groups.className = "groups";

        this.elem_right = document.createElement("div"); elem_top.appendChild(this.elem_right);
        this.elem_right.className = "right";
        this.right = {
            admin:    {elem: null},
            links:    {elem: null},
            messages: {elem: null},
			boards:   {elem: null},
			settings: {elem: null},

            board:       {list: {}},
            thread:      {list: {}},
            channel:     {list: {}},
            channel_tmp: {list: {}},
        }

		this.bot = {
			settings: {elem: null},
		}

        this.update_links();
		this.update_messages();
        this.update_boards();


        this.elem_bot = document.createElement("div"); this.elem.appendChild(this.elem_bot);
        this.elem_bot.className = "bot";
		this.update_bot();

		//this.elem.addEventListener("ready", () => );
		setTimeout(() => this.update_select(), 1000);
    }

	del_board_tmp () {
		delete this.left.boards.list[state.board_tmp];
		delete this.right.board.list[state.board_tmp];

		this.left.boards.group_elem.removeChild(this.left.boards.group_elem.children[1]);
		this.elem_right.removeChild(this.elem_right.children[1]);
		state.board_tmp = null;
	}

    update_select () {
        let window_state = state.windows[state.window_selected];
        let tab = state.tabs[window_state.tabs[window_state.tab_selected]];
        let page_id = tab.history[tab.pos];
		this.page_selected = page_id;

		/* Selection */
		this.elem_selected_tab = null;
		this.elem_selected_right = null;
		this.elem_selected_bubble = null;

        /* Deselect Everything */
        let actives = this.elem.querySelectorAll(".active");
        for(let e of actives) e.classList.remove("active");

        if(state.board_tmp != null) {
            let board_id :any = null;
            if(this.page_selected.type == Page_Type.THREAD) board_id = state.threads[this.page_selected.id].board;
            else if(this.page_selected.type == Page_Type.BOARD) board_id = this.page_selected.id;
            if(board_id != state.board_tmp) this.del_board_tmp();
        }

		/* Find correct elements */
        switch(this.page_selected.type)
        {
            case Page_Type.ADMIN:
            case Page_Type.ADMIN_MEMBERS:
            case Page_Type.ADMIN_CHANNELS:
            {
                this.elem_selected_bubble = this.left.admin.elem;
                this.elem_selected_right = this.right.admin.elem;
                this.left.admin.elem.setAttribute("lc", `nav:${this.page_selected.type}`);

                switch(this.page_selected.type)
                {
                    case Page_Type.ADMIN: this.elem_selected_tab = this.elem_selected_tab = this.right.admin.elem.querySelector(".admin"); break;
                    case Page_Type.ADMIN_MEMBERS: this.elem_selected_tab = this.elem_selected_tab = this.right.admin.elem.querySelector(".members"); break;
                    case Page_Type.ADMIN_CHANNELS: this.elem_selected_tab = this.elem_selected_tab = this.right.admin.elem.querySelector(".channels"); break;
                }
            
            
            } break;

            case Page_Type.HOME:
            case Page_Type.TIPS:
            case Page_Type.RULES:
            case Page_Type.INVITE:
            case Page_Type.INVITES:
            case Page_Type.SUBSCRIPTION:
            case Page_Type.SUBSCRIPTION_UPDATE:
            {
                this.elem_selected_bubble = this.left.links.elem;
                this.elem_selected_right = this.right.links.elem;
                this.left.links.elem.setAttribute("lc", `nav:${this.page_selected.type}`);

                switch(this.page_selected.type)
                {
                    case Page_Type.HOME: this.elem_selected_tab = this.elem_selected_tab = this.right.links.elem.querySelector(".home"); break;
                    case Page_Type.TIPS: this.elem_selected_tab = this.elem_selected_tab = this.right.links.elem.querySelector(".tips"); break;
                    case Page_Type.RULES: this.elem_selected_tab = this.elem_selected_tab = this.right.links.elem.querySelector(".rules"); break;
                    case Page_Type.INVITES: this.elem_selected_tab = this.elem_selected_tab = this.right.links.elem.querySelector(".invites"); break;
                    case Page_Type.SUBSCRIPTION: this.elem_selected_tab = this.elem_selected_tab = this.right.links.elem.querySelector(".subscription"); break;
                }
            } break;
            case Page_Type.MESSAGES:
            case Page_Type.CHANNEL:
            case Page_Type.CHANNEL_TMP:
            {
                this.elem_selected_bubble = this.left.messages.elem;
                this.elem_selected_right = this.right.messages.elem;

                if(this.page_selected.id) {
                    this.left.messages.elem.setAttribute("lc", `nav:${this.page_selected.type}/${this.page_selected.id}`);
                    if(this.page_selected.type == Page_Type.CHANNEL     && this.page_selected.id in this.right.channel.list)     this.elem_selected_tab = this.right.channel.list[this.page_selected.id];
                    if(this.page_selected.type == Page_Type.CHANNEL_TMP && this.page_selected.id in this.right.channel_tmp.list) this.elem_selected_tab = this.right.channel_tmp.list[this.page_selected.id];
                }
                else {
                    this.left.messages.elem.setAttribute("lc", `nav:${this.page_selected.type}`);
                }
            } break;
			case Page_Type.BOARDS_OWNED:
			case Page_Type.BOARDS_JOINED:
			case Page_Type.BOARDS_OFFICIAL:
			case Page_Type.BOARDS_PUBLIC:
			{
				this.elem_selected_bubble = this.left.boards.elem;
				this.elem_selected_right = this.right.boards.elem;
				this.left.boards.elem.setAttribute("lc", `nav:${this.page_selected.type}`);

				switch(this.page_selected.type)
				{
					case Page_Type.BOARDS_OWNED: this.elem_selected_tab = this.right.boards.elem.querySelector(".owned"); break;
					case Page_Type.BOARDS_JOINED: this.elem_selected_tab = this.right.boards.elem.querySelector(".joined"); break;
					case Page_Type.BOARDS_OFFICIAL: this.elem_selected_tab = this.right.boards.elem.querySelector(".official"); break;
					case Page_Type.BOARDS_PUBLIC: this.elem_selected_tab = this.right.boards.elem.querySelector(".public"); break;
				}
			} break;

			case Page_Type.SETTINGS_ACCOUNT:
			case Page_Type.SETTINGS_PROFILE:
			case Page_Type.SETTINGS_SAFETY:
			case Page_Type.SETTINGS_NOTIFS:
			{
				this.elem_selected_right = this.right.settings.elem;
				this.bot.settings.elem.setAttribute("lc", `nav:${this.page_selected.type}`);

				switch(this.page_selected.type)
				{
					case Page_Type.SETTINGS_ACCOUNT: this.elem_selected_tab = this.right.settings.elem.querySelector(".account"); break;
					case Page_Type.SETTINGS_PROFILE: this.elem_selected_tab = this.right.settings.elem.querySelector(".profile"); break;
					case Page_Type.SETTINGS_SAFETY: this.elem_selected_tab = this.right.settings.elem.querySelector(".safety"); break;
					case Page_Type.SETTINGS_NOTIFS: this.elem_selected_tab = this.right.settings.elem.querySelector(".notifs"); break;
				}
			} break;

            case Page_Type.BOARD:
            {
                if(!state.boards || !(this.page_selected.id in state.boards)) return;

                if(this.page_selected.id in this.left.boards.list) {
					this.elem_selected_bubble = this.left.boards.list[this.page_selected.id];
					if(this.page_selected.id) this.left.boards.list[this.page_selected.id].setAttribute("lc", `nav:${Page_Type.BOARD}/${this.page_selected.id}`)
                }
                else {
                    state.board_tmp = this.page_selected.id;
                    let board = state.boards[state.board_tmp];

                    /* Left */
                    this.left.boards.list[board.id] = document.createElement("div");
                    this.left.boards.list[board.id].className = `bubble board ${board.id} active`;
					if(board.flags & BOARD_FLAG.DELETED) this.left.boards.list[board.id];

                    this.left.boards.list[board.id].setAttribute("lc", `nav:${Page_Type.BOARD}/${board.id}`)
                    this.left.boards.list[board.id].setAttribute("rc", `menu:board/${board.id}/right`)
					if(board.picture) {
						let content = content_get(upload_get(board.picture).content);
						this.left.boards.list[board.id].appendChild(Content(content, {size: "64", thumb: true}));
					}
					else {
						if(board.key) this.left.boards.list[board.id].textContent = `/${board.key}/`;
						else this.left.boards.list[board.id].textContent = board.name.split(" ").map((word :string) => word[0]).join("");
					}
                    this.left.boards.group_elem.prepend(this.left.boards.list[board.id]);
					this.left.boards.elem.insertAdjacentElement("afterend", this.left.boards.list[board.id]);

                    /* Right */
                    this.update_board_right(board, true);
					this.elem_selected_bubble = this.left.boards.list[board.id];
                }
                if(this.page_selected.id in this.right.board.list) {
                    this.elem_selected_right = this.right.board.list[this.page_selected.id];
                    this.elem_selected_tab = this.right.board.list[this.page_selected.id].querySelector(".item.listing")!;
                }
            } break;
            case Page_Type.THREAD:
            {
                if(!state.threads || !(this.page_selected.id in state.threads)) return;

                let board_id = state.threads[this.page_selected.id].board;
                if(board_id in this.left.boards.list) {
                    this.elem_selected_bubble = this.left.boards.list[board_id];
                    this.left.boards.list[board_id].setAttribute("lc", `nav:${Page_Type.THREAD}/${this.page_selected.id}`);
                }
                else {
                }
                if(board_id in this.right.board.list) this.elem_selected_right = this.right.board.list[board_id];
                if(this.page_selected.id in this.right.thread.list) this.elem_selected_tab = this.right.thread.list[this.page_selected.id];
            } break;
        }

		/* Select right elements */
		if(this.elem_selected_tab) this.elem_selected_tab.classList.add("active");
		if(this.elem_selected_right) this.elem_selected_right.classList.add("active");
		if(this.elem_selected_bubble) {
			this.elem_selected_bubble.classList.add("active");
			this.elem_selection.style.display = "block";
			this.elem_selection.style.top = `${this.elem_selected_bubble.offsetTop + 4}px`;
		}
		else {
			this.elem_selection.style.display = "none";
		}
    }

    add_user_admin () {
		if(!this.left.admin.group_elem) {
			this.left.admin.group_elem = document.createElement("div");
			this.left.admin.group_elem.className = "group admin";
			this.elem_groups.insertAdjacentElement("afterbegin", this.left.admin.group_elem);
		}

		if(!this.left.admin.elem) {
			this.left.admin.elem = document.createElement("div");
			this.left.admin.elem.className = "bubble admin";
			this.left.admin.elem.innerHTML = Icon.Crown;
			this.left.admin.elem.setAttribute("lc", `nav:${Page_Type.ADMIN}`);
			this.left.admin.group_elem.appendChild(this.left.admin.elem);

			this.right.admin.elem = document.createElement("div");
			this.right.admin.elem.className = "admin";
			this.elem_right.appendChild(this.right.admin.elem);
		}

		this.right.admin.elem.innerHTML = "";

		let elem_admin_item_channels = document.createElement("div");
		elem_admin_item_channels.className = "item channels";
		elem_admin_item_channels.textContent = "CHANNELS";
		elem_admin_item_channels.setAttribute("lc", `nav:${Page_Type.ADMIN_CHANNELS}`)
		this.right.admin.elem.appendChild(elem_admin_item_channels);

		let elem_admin_item_members = document.createElement("div");
		elem_admin_item_members.className = "item members";
		elem_admin_item_members.textContent = "MEMBERS";
		elem_admin_item_members.setAttribute("lc", `nav:${Page_Type.ADMIN_MEMBERS}`)
		this.right.admin.elem.appendChild(elem_admin_item_members);
	}

    del_user_admin () {
		/* Off */
		if(this.left.admin.group_elem) {
			this.left.admin.group_elem.parentElement.removeChild(this.left.admin.group_elem);
			this.left.admin.group_elem = null;
		}

		if(this.right.admin.elem) {
			this.right.admin.elem.parentElement.removeChild(this.right.admin.elem);
			this.right.admin.elem = null;
		}

		if(this.left.admin.elem) {
			this.left.admin.elem = null;
		}
	}

    add_user_mod () {
        return;
        /* Items */
        let elem_mod_items = this.elem_mid.querySelector(".mod.items");
        if(!elem_mod_items) {
            elem_mod_items = document.createElement("div");
            elem_mod_items.className = "mod items";
            this.elem_mid.prepend(elem_mod_items);
        }

        /* Header */
        let elem_mod_header :HTMLElement = this.elem_mid.querySelector(".mod.header")!;
        if(!elem_mod_header) {
            elem_mod_header = document.createElement("div");
            elem_mod_header.className = "mod header";
            let elem_mod_title = document.createElement("div");
            elem_mod_title.className = "title";
            elem_mod_title.textContent = "MOD";
            elem_mod_header.appendChild(elem_mod_title);
            let elem_mod_arrow = document.createElement("div");
            elem_mod_arrow.className = "arrow";
            elem_mod_header.appendChild(elem_mod_arrow);
            elem_mod_header.onclick = (e) => this.scroll_to(elem_mod_items);
            this.elem_mid.prepend(elem_mod_header);
        }

        /* Items */
        elem_mod_items!.innerHTML = "";

        let elem_mod_item_reports = document.createElement("div");
        elem_mod_item_reports.className = "item reports";
        elem_mod_item_reports.textContent = "REPORTS";
        elem_mod_item_reports.setAttribute("lc", `nav:${Page_Type.MOD_REPORTS}`)
        elem_mod_items.appendChild(elem_mod_item_reports);

    }

	del_user_mod () {
	}

    update_links () {
        if(!this.left.links.group_elem) {
            this.left.links.group_elem = document.createElement("div");
            this.left.links.group_elem.className = "group links";
            this.elem_groups.appendChild(this.left.links.group_elem);
        }

        if(!this.left.links.elem) {
            this.left.links.elem = document.createElement("div");
            this.left.links.elem.className = "bubble links";
            this.left.links.elem.innerHTML = Icon.Home;
            this.left.links.elem.setAttribute("lc", `nav:${Page_Type.HOME}`);
            this.left.links.group_elem.appendChild(this.left.links.elem);

            this.right.links.elem = document.createElement("div");
            this.right.links.elem.className = "links";
            this.elem_right.appendChild(this.right.links.elem);
        }

		/* Right Bar */
        this.right.links.elem.innerHTML = "";

        let elem_home = document.createElement("div");
        elem_home.className = "item home";
        elem_home.setAttribute("lc", `nav:${Page_Type.HOME}`);
        elem_home.appendChild(document.createTextNode(CONST.WEBSITE_TITLE));
        this.right.links.elem.appendChild(elem_home);

        /*
        let elem_tips = document.createElement("div");
        elem_tips.className = "item tips";
        elem_tips.setAttribute("lc", `nav:${Page_Type.TIPS}`);
        elem_tips.appendChild(document.createTextNode("TIPS"));
        this.right.links.elem.appendChild(elem_tips);

        let elem_rules = document.createElement("div");
        elem_rules.className = "item rules";
        elem_rules.setAttribute("lc", `nav:${Page_Type.RULES}`);
        elem_rules.appendChild(document.createTextNode("RULES"));
        this.right.links.elem.appendChild(elem_rules);
        */

        let elem_backup = document.createElement("div");
        elem_backup.className = "item backup";
        elem_backup.onclick = () => window.location.href = "https://t.me/nullchan_net";
        elem_backup.appendChild(document.createTextNode("BACKUP"));
        this.right.links.elem.appendChild(elem_backup);

		if(auth_is_user()) {
			let elem_invites = document.createElement("div");
			elem_invites.className = "item invites";
			elem_invites.setAttribute("lc", `nav:${Page_Type.INVITES}`);
			elem_invites.appendChild(document.createTextNode("INVITES"));
			this.right.links.elem.appendChild(elem_invites);

			let elem_subscription = document.createElement("div");
			elem_subscription.className = "item subscription";
			elem_subscription.setAttribute("lc", `nav:${Page_Type.SUBSCRIPTION}`);
			elem_subscription.appendChild(document.createTextNode("SUBSCRIPTION"));
			this.right.links.elem.appendChild(elem_subscription);
		}
    }

    update_messages () {
		if(auth_is_user()) {
			if(!this.left.messages.group_elem) {
				this.left.messages.group_elem = document.createElement("div");
				this.left.messages.group_elem.className = "group messages";
				this.left.links.group_elem.insertAdjacentElement("afterend", this.left.messages.group_elem);
			}

			if(!this.left.messages.elem) {
				this.left.messages.elem = document.createElement("div");
				this.left.messages.elem.className = "bubble messages";
				this.left.messages.elem.innerHTML = Icon.Message;
				this.left.messages.elem.setAttribute("lc", `nav:${Page_Type.MESSAGES}`);
				this.left.messages.group_elem.appendChild(this.left.messages.elem);

				this.right.messages.elem = document.createElement("div");
				this.right.messages.elem.className = "messages";
				this.right.links.elem.insertAdjacentElement("afterend", this.right.messages.elem);
			}
		}
    }

    add_user_channel (channel_id :string) {
        if(!channel_id) return;
        if(!(channel_id in state.channels)) return;
        if(channel_id in this.left.messages.list) return;

        let channel = channel_get(channel_id);
        if(!channel) return;

		/* Left */
		this.left.messages.list[channel.id] = document.createElement("div");
		this.left.messages.list[channel.id].className = `bubble channel ${channel.id}`;
		//if(channel.flags & BOARD_FLAG.DELETED) this.left.messages.list[board.id].classList.add("deleted");
		//if(state.cat == "boards" && state.cat1 == board.id) this.left.boards.list[board.id].classList.add("active");

		this.left.messages.list[channel.id].setAttribute("lc", `nav:${Page_Type.CHANNEL}/${channel.id}`)
		this.left.messages.list[channel.id].setAttribute("rc", `menu:channel/${channel.id}/right`)

		let [user_me, user_you] = Object.values(participants_me_you_get(channel.participants));
        this.left.messages.list[channel.id].appendChild(Author_Avatar(user_you, {size: "64"}));
		this.left.messages.group_elem.appendChild(this.left.messages.list[channel.id]);

        /* Notif */
        let elem_notif = document.createElement("div"); this.left.messages.list[channel.id].appendChild(elem_notif);
        elem_notif.className = "notif";
        elem_notif.textContent = `${channel.message_count-1 - channel.read.last.index}`;
    }

    del_user_channel (channel_id :string) {
		if(!(channel_id in this.left.messages.list)) return;

		/* Left */
		this.left.messages.list[channel_id].remove();
		delete this.left.messages.list[channel_id];
    }

	del_user_messages () {
	}

    elem_notif_add (count :number) {
        let elem_notif = document.createElement("div");
        elem_notif.className = "notif";
        elem_notif.textContent = `${count}`;
        return(elem_notif);
    }

	channel_listing_add (channel_id :string, is_tmp :boolean) {
        let channel = is_tmp ? state.channels_tmp[channel_id] : state.channels[channel_id];

        let elem = null;
        if(is_tmp && channel_id in this.right.channel_tmp.list) elem = this.right.channel_tmp.list[channel_id];
        else if(channel_id in this.right.channel.list) elem = this.right.channel.list[channel_id];

        if(!elem) {
            elem = document.createElement("div");
            elem.className = "channel";
            if(is_tmp) {
                elem.classList.add("temporary");
                if(this.page_selected.type == Page_Type.CHANNEL_TMP && this.page_selected.id == channel_id) elem.classList.add("active");
                elem.setAttribute("lc", `nav:${Page_Type.CHANNEL_TMP}/${channel_id}`)
                elem.setAttribute("rc", `menu:channel_tmp/${channel_id}/right`);
            }
            else {
                if(this.page_selected.type == Page_Type.CHANNEL && this.page_selected.id == channel_id) elem.classList.add("active");
                elem.setAttribute("lc", `nav:${Page_Type.CHANNEL}/${channel_id}`)
                elem.setAttribute("rc", `menu:channel/${channel_id}/right`);
            }

            let elem_user = document.createElement("div"); elem.appendChild(elem_user);
            elem_user.className = "user";

            let [user_me, user_you] = Object.values(participants_me_you_get(channel.participants));
            elem_user.appendChild(Author_Avatar(user_you, {size: "32"})!);

            let elem_name_status = document.createElement("div"); elem_user.appendChild(elem_name_status);
            elem_name_status.className = "name_status";

            elem_name_status.appendChild(Author_Name(user_you, {badges: true})!);

            let elem_status = document.createElement("div"); elem_name_status.appendChild(elem_status);
            elem_status.className = "status";

            /*
            if(!user_you.is_anon) {
                let profile = profile_get(user_you.id)!;
                //console.log(profile);
                elem_status.textContent = `${(new Date(profile.date_last_online).toLocaleString())}`;
            }
            else {
                //console.log(user_you.target);
            }
            */

            if(is_tmp) this.right.channel_tmp.list[channel_id] = elem;
            else this.right.channel.list[channel_id] = elem
        }

        if(!is_tmp) this.channel_listing_update(channel);
		return(elem);
	}

    channel_listing_update (channel :any) {
        if(!channel || !(channel.id in this.right.channel.list)) return;

        let is_unread = "read" in channel && channel.read.last.index < channel.message_count-1;
        if(channel.id in this.right.channel.list) {
            let elem = this.right.channel.list[channel.id];
            let elem_is_unread = elem.classList.contains("unread");
            if(elem_is_unread) {
                let elem_notif = elem.querySelector(".notif")!;
                if(!is_unread) {
                    elem.classList.remove("unread");
                    elem_notif.remove();
                }
                else elem_notif.textContent = `${channel.message_count-1 - channel.read.last.index}`;
            }
            else {
                if(is_unread) {
                    elem.classList.add("unread");
                    elem.appendChild(this.elem_notif_add(channel.message_count-1 - channel.read.last.index));
                }
            }
        }

        if(is_unread) {
            if(channel.id in this.left.messages.list) {
                /* Edit */
                let elem = this.left.messages.list[channel.id];
                let elem_notif = elem.querySelector(".notif")!;
                elem_notif.textContent = `${channel.message_count-1 - channel.read.last.index}`;
            }
            else this.add_user_channel(channel.id);
        }
        else this.del_user_channel(channel.id);
    }

	add_tmp_channel (channel_id: string, is_tmp :boolean) {
		let elem = this.channel_listing_add(channel_id, true);
		this.right.messages.elem.prepend(elem);
	}

	rem_tmp_channel (channel_tmp_id :string, channel_id :string) {
		this.right.channel_tmp.list[channel_tmp_id].remove();
	}

	update_user_channels () {
		/* TODO: If we want to wipe everything we need to make sure we add the temp channels first */
        this.right.messages.elem.innerHTML = "";

		if(state.channels_tmp) {
			for(let channel_id of Object.keys(state.channels_tmp).reverse()) {
				let channel = state.channels_tmp[channel_id];
				if(channel.is_replaced) continue;

                let elem = null;
                if(channel_id in this.right.channel_tmp.list) elem = this.right.channel_tmp.list[channel_id];
                else elem = this.channel_listing_add(channel_id, true);
				this.right.messages.elem.appendChild(elem);
			}
		}

		if(state.channels && state.channels_sorted) {
			for(let channel_id of state.channels_sorted) {
				let channel = state.channels[channel_id];

                let elem = null;
                if(channel_id in this.right.channel.list) elem = this.right.channel.list[channel_id];
                else elem = this.channel_listing_add(channel_id, false);
				this.right.messages.elem.appendChild(elem);
			}
		}
	}

    update_boards() {
        if(!this.left.boards.group_elem) {
            this.left.boards.group_elem = document.createElement("div");
            this.left.boards.group_elem.className = "group boards";
            this.elem_groups.appendChild(this.left.boards.group_elem);
        }

		if(!this.left.boards.elem) {
            this.left.boards.elem = document.createElement("div");
            this.left.boards.elem.className = "bubble boards";
            this.left.boards.elem.innerHTML = Icon.Board;
            this.left.boards.elem.setAttribute("lc", `nav:${Page_Type.BOARDS_OFFICIAL}`);
            this.left.boards.group_elem.appendChild(this.left.boards.elem);

            this.right.boards.elem = document.createElement("div");
            this.right.boards.elem.className = "boards";
            this.elem_right.appendChild(this.right.boards.elem);
        }

		/* Right Bar */
        this.right.boards.elem.innerHTML = "";

		let elem_official = document.createElement("div");
		elem_official.className = "item official";
		elem_official.setAttribute("lc", `nav:${Page_Type.BOARDS_OFFICIAL}`);
		elem_official.appendChild(document.createTextNode("OFFICIAL"));
		this.right.boards.elem.appendChild(elem_official);

		let elem_public = document.createElement("div");
		elem_public.className = "item public";
		elem_public.setAttribute("lc", `nav:${Page_Type.BOARDS_PUBLIC}`);
		elem_public.appendChild(document.createTextNode("PUBLIC"));
		this.right.boards.elem.appendChild(elem_public);

		if(auth_is_user()) {
			let elem_owned = document.createElement("div");
			elem_owned.className = "item owned";
			elem_owned.setAttribute("lc", `nav:${Page_Type.BOARDS_OWNED}`);
			elem_owned.appendChild(document.createTextNode("OWNED"));
			this.right.boards.elem.appendChild(elem_owned);

			let elem_joined = document.createElement("div");
			elem_joined.className = "item joined";
			elem_joined.setAttribute("lc", `nav:${Page_Type.BOARDS_JOINED}`);
			elem_joined.appendChild(document.createTextNode("JOINED"));
			this.right.boards.elem.appendChild(elem_joined);

			for(let board_id of state.boards_me) this.add_user_board(board_id);
		}
    }

    update_board_right (board :any, at_start = false) {
        if(board.id in this.right.board.list) return;

        /* Right */
		let elem_board = document.createElement("div");
		elem_board.className = "board";

		let elem_listing = document.createElement("div");
		elem_listing.className = "listing";
		elem_listing.textContent = "BOARD";
		elem_listing.setAttribute("lc", `nav:${Page_Type.BOARD}/${board.id}`)
		elem_board.appendChild(elem_listing);
		this.right.board.list[board.id] = elem_board;

		this.update_threads_pinned(board.id);
		this.update_threads_watching(board.id);

		if(at_start) this.elem_right.prepend(elem_board);
		else this.elem_right.appendChild(elem_board);
    }

	add_user_board (board_id :string) {
		if(!board_id) return;
		if(!(board_id in state.boards)) return;
		if(board_id in this.left.boards.list) return;

		let board = board_get(board_id);
		if(!board) return;
		
		/* Left */
		this.left.boards.list[board.id] = document.createElement("div");
		this.left.boards.list[board.id].className = `bubble board ${board.id}`;
		if(board.flags & BOARD_FLAG.DELETED) this.left.boards.list[board.id].classList.add("deleted");
		if(state.cat == "boards" && state.cat1 == board.id) this.left.boards.list[board.id].classList.add("active");

		this.left.boards.list[board.id].setAttribute("lc", `nav:${Page_Type.BOARD}/${board.id}`)
		this.left.boards.list[board.id].setAttribute("rc", `menu:board/${board.id}/right`)
		if(board.picture) {
			let content = content_get(upload_get(board.picture).content);
			this.left.boards.list[board.id].appendChild(Content(content));
		}
		else {
			if(board.key) this.left.boards.list[board.id].textContent = `/${board.key}/`;
			else this.left.boards.list[board.id].textContent = board.name.split(" ").map((word :string) => word[0]).join("");
		}
		this.left.boards.group_elem.appendChild(this.left.boards.list[board.id]);

		/* Right */
		this.update_board_right(board);
	}

	del_user_board (board_id :string) {
		if(!(board_id in this.left.boards.list)) return;

		/* Left */
		this.left.boards.list[board_id].remove();
		delete this.left.boards.list[board_id];

		/* Right */
		this.right.board.list[board_id].remove();
		delete this.right.board.list[board_id];

		/* TODO: Remove all threads? */
		/* TODO: Temporary board replacement if you're still in it? */
	}

	thread_listing_add (thread :any) {
        let elem = null;
        if(thread.id in this.right.thread.list) elem = this.right.thread.list[thread.id];

        if(!elem) {
            elem = document.createElement("div");
            elem.className = "item thread";
            elem.setAttribute("lc", `nav:${Page_Type.THREAD}/${thread.id}`);
            elem.setAttribute("rc", `menu:thread/${thread.id}/right`);
            if(thread.flags & THREAD_FLAG.DELETED) elem.classList.add("deleted");

            let elem_text = document.createElement("div");
            elem_text.className = "text";
            elem_text.textContent = `# ${thread.subject}`
            elem.appendChild(elem_text);

            this.right.thread.list[thread.id] = elem;
        }

        this.thread_listing_update(thread);
		return(elem);
	}

	thread_listing_update (thread :any) {
		if(!thread || !(thread.id in this.right.thread.list)) return;

		let is_unread = "read" in thread && thread.read.last.index < thread.message_count-1;
        if(thread.id in this.right.thread.list) {
            let elem = this.right.thread.list[thread.id];
            let elem_is_unread = elem.classList.contains("unread");
            if(elem_is_unread) {
                let elem_notif = elem.querySelector(".notif")!;
                if(!is_unread) {
                    elem.classList.remove("unread");
                    elem_notif.remove();
                }
                else elem_notif.textContent = `${thread.message_count-1 - thread.read.last.index}`;
            }
            else {
                if(is_unread) {
                    elem.classList.add("unread");
                    elem.appendChild(this.elem_notif_add(thread.message_count-1 - thread.read.last.index));
                }
            }
        }
	}

    listing_update (target :any) {
        switch(target.type) {
        case ID_Type.THREAD: {
            let thread = state.threads[target.id]
            this.thread_listing_update(thread);
        } break;
        case ID_Type.CHANNEL: {
            let channel = state.channels[target.id]
            this.channel_listing_update(channel);
        }
        }
    }


	thread_pinned_add (board_id :string, thread_id :string, pin_category :string, index :number) {
        if(!(board_id in state.boards)) return;
        if(!(thread_id in state.threads)) return;

		let board = state.boards[board_id];
		if(!("pins" in board) || Object.keys(board.pins).length == 0) return;

		let thread = state.threads[thread_id];

        let elem_pinned = this.right.board.list[board_id].querySelector(".pinned")!;
        if(!elem_pinned) {
            elem_pinned = document.createElement("div");
            elem_pinned.className = "group pinned";

            let elem_pinned_header = document.createElement("div");
            elem_pinned_header.className = "header";
            elem_pinned_header.textContent = "PINNED";
            elem_pinned.appendChild(elem_pinned_header);

            let elem_pinned_items = document.createElement("div");
            elem_pinned_items.className = "items";
            elem_pinned.appendChild(elem_pinned_items);

            this.right.board.list[board_id].children[0].insertAdjacentElement("afterend", elem_pinned)
        }

        let elem_pinned_items = elem_pinned.querySelector(".items")!;
		let elem_thread = this.thread_listing_add(thread);

		if(pin_category == "") {
			if(elem_pinned_items.children.length == 0 || elem_pinned_items.children.length <= index) elem_pinned_items.appendChild(elem_thread);
            else elem_pinned_items.children[index].insertAdjacentElement("beforebegin", elem_thread)
		}
		else {
			/* TODO:  */
		}
	}

	thread_pinned_del (board_id :string, thread_id :string, pin_category :string) {
        if(!(board_id in state.boards)) return;
        if(!(thread_id in state.threads)) return;

		let board = state.boards[board_id];
		if(!("pins" in board) || Object.keys(board.pins).length == 0) return;

		let thread = state.threads[thread_id];

        let elem_pinned = this.right.board.list[board_id].querySelector(".pinned")!;
		if(!elem_pinned) return;

        let elem_pinned_items = elem_pinned.querySelector(".items")!;
		if(pin_category == "") {
			this.right.thread.list[thread.id].remove();
			delete this.right.thread.list[thread.id];
		}
		else {
			/* TODO:  */
		}

		let thread_count = 0;
		for(let category in board.pins) {
			if(category == "") for(let thread_id of board.pins[category]) thread_count += 1;
			else for(let thread_id of board.pins[category].threads) thread_count += 1;
		}
		if(thread_count == 0) elem_pinned.remove();
	}

    update_threads_pinned (board_id: string) {
        if(!(board_id in state.boards)) return;

		let board = state.boards[board_id];
		if(!("pins" in board) || Object.keys(board.pins).length == 0) return;

        let elem_pinned = this.right.board.list[board_id].querySelector(".pinned")!;
        if(!elem_pinned) {
            elem_pinned = document.createElement("div");
            elem_pinned.className = "group pinned";

            let elem_pinned_header = document.createElement("div");
            elem_pinned_header.className = "header";
            elem_pinned_header.textContent = "PINNED";
            elem_pinned.appendChild(elem_pinned_header);

            let elem_pinned_items = document.createElement("div");
            elem_pinned_items.className = "items";
            elem_pinned.appendChild(elem_pinned_items);

            this.right.board.list[board_id].children[0].insertAdjacentElement("afterend", elem_pinned)
        }

        let elem_pinned_items = elem_pinned.querySelector(".items")!;
        elem_pinned_items.innerHTML = "";

		for(let category in board.pins) {
			if(category == "") {
				for(let thread_id of board.pins[category]) {
					let thread = state.threads[thread_id];
					let elem_thread = this.thread_listing_add(thread);
					elem_pinned_items.appendChild(elem_thread);
				}
			}
			else {
				/* TODO: */
				//let elem_category = document.createElement("div");
			}
		}
    }

    update_threads_watching (board_id: string) {
        if(!state.threads_watching || !(board_id in state.threads_watching)) return;

        let elem_watching = this.right.board.list[board_id].querySelector(".watching")!;
        if(!elem_watching) {
            elem_watching = document.createElement("div");
            elem_watching.className = "group watching";

            let elem_watching_header = document.createElement("div");
            elem_watching_header.className = "header";
            elem_watching_header.textContent = "WATCHING";
            elem_watching.appendChild(elem_watching_header);

            let elem_watching_items = document.createElement("div");
            elem_watching_items.className = "items";
            elem_watching.appendChild(elem_watching_items);

            this.right.board.list[board_id].appendChild(elem_watching);
        }

        let elem_watching_items = elem_watching.querySelector(".items")!;
        elem_watching_items.innerHTML = "";

        for(let thread_id of state.threads_watching[board_id]) {
            let thread = state.threads[thread_id];
			if(thread.flags & THREAD_FLAG.PINNED) continue;

			let elem_thread = this.thread_listing_add(thread);
            elem_watching_items.appendChild(elem_thread);
        }
		this.update_select();
    }

    update_bot () {
        let elem_bot = this.elem.querySelector(".bot");
        if(elem_bot) {
            elem_bot.innerHTML = "";
            if(auth_is_user()) {
				let profile = auth_profile();

                let elem_user = document.createElement("div");
                elem_user.className = "user";
				elem_user.setAttribute("lc", `nav:${Page_Type.PROFILE_CONTENT}/${profile.id}`);
                elem_user.appendChild(Author_Avatar(profile, {size: "64", specific: false})!);
                elem_user.appendChild(Author_Name(profile, {badges: true, specific: false})!);
                elem_bot.appendChild(elem_user);

				let elem_buttons = document.createElement("div");
				elem_buttons.className = "buttons";
				elem_bot.appendChild(elem_buttons);

                let elem_button_switch = document.createElement("div");
                elem_button_switch.className = "button switch";
                elem_button_switch.innerHTML = Icon.Switch;
				elem_button_switch.onclick = () => {
                    let profile_switch = new Profile_Switch();
                    profile_switch.new();
				}
                elem_buttons.appendChild(elem_button_switch);

                this.bot.settings.elem = document.createElement("div");
                this.bot.settings.elem.className = "button settings bubble";
                this.bot.settings.elem.innerHTML = Icon.Settings;
                this.bot.settings.elem.setAttribute("lc", `nav:${Page_Type.SETTINGS_ACCOUNT}`)
                elem_buttons.appendChild(this.bot.settings.elem);

                /* Settings Nav */
                this.right.settings.elem = document.createElement("div");
                this.right.settings.elem.className = "settings";
                this.elem_right.appendChild(this.right.settings.elem);

                let elem_account = document.createElement("div"); this.right.settings.elem.appendChild(elem_account);
                elem_account.className = "item account";
                elem_account.setAttribute("lc", `nav:${Page_Type.SETTINGS_ACCOUNT}`);
                elem_account.appendChild(document.createTextNode("ACCOUNT"));

				if(!profile.is_anon) {
					let elem_profile = document.createElement("div");
					elem_profile.className = "item profile";
					elem_profile.setAttribute("lc", `nav:${Page_Type.SETTINGS_PROFILE}`);
					elem_profile.appendChild(document.createTextNode("PROFILE"));
					this.right.settings.elem.appendChild(elem_profile);
				}

                /*
                let elem_safety = document.createElement("div");
                elem_safety.className = "item safety";
                elem_safety.setAttribute("lc", `nav:${Page_Type.SETTINGS_SAFETY}`);
                elem_safety.appendChild(document.createTextNode("SAFETY"));
                this.right.settings.elem.appendChild(elem_safety);

                let elem_notifs = document.createElement("div");
                elem_notifs.className = "item notifs";
                elem_notifs.setAttribute("lc", `nav:${Page_Type.SETTINGS_NOTIFS}`);
                elem_notifs.appendChild(document.createTextNode("NOTIFS"));
                this.right.settings.elem.appendChild(elem_notifs);
                */
            }
            else {
                let elem_auth = document.createElement("div");
                elem_auth.className = "auth";
                elem_auth.setAttribute("lc", "modal:auth");

                elem_auth.appendChild(document.createTextNode("Login / Register"));
                elem_bot.append(elem_auth);
            }
        }
    }
}

export default BarLeft;
