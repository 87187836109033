import { nav_to } from "../../../router";
import { socket_send, ACTION } from "../../../socket";
import { date_str } from "../../modular/time";
import { Page, Page_Type } from "../page";
import { PAGE_HOME } from "../../../router";
import { views } from "../../../view";
import { state } from "../../../state";

class Page_subscriptionUpdate extends Page {
    constructor () {
        super(({type: Page_Type.SUBSCRIPTION_UPDATE, id: ""}));
    }

    new () {
        let elem = document.createElement("div");
        elem.className = "subscription_update";

		if(state.sub_update) this.update_elem(elem, state.sub_update);
		else socket_send(ACTION.SUBSCRIPTION_UPDATE);

        return({e: elem, es: elem, h: 0, s: 0});
    }

    update_elem = (elem :HTMLElement, payload :any) => {
		if(!payload || !("is_subscriber" in payload) || !("is_subscribed" in payload) || !("date_sub_update" in payload)) {
			nav_to({type: PAGE_HOME, id: ""});
			return;
		}

		/*
		let date_now = new Date()
		payload = {is_subscriber: true, is_subscribed: true, date_sub_update: date_now.toISOString()};
		*/

        elem.innerHTML = "";
        let elem_container = document.createElement("div");
        elem_container.className = "container";
		elem.appendChild(elem_container);

		let elem_title = document.createElement("div");
		elem_title.className = "title";
		elem_title.textContent = payload.is_subscribed ? "Thank you for subscribing!" : "You have canceled your subscription";
		elem_container.appendChild(elem_title);

		let elem_text = document.createElement("div");
		elem_text.className = "text";
		if(payload.is_subscribed) {
			elem_text.textContent = `Subscription renews ${date_str(new Date(payload.date_sub_update))}`;
		}
		else {
			if(payload.is_subscriber) elem_text.textContent = `Subscription finishes ${date_str(new Date(payload.date_sub_update))}, without renewing.`;
			else elem_text.textContent = `Subscriptionh has ended.`;
		}
		elem_container.appendChild(elem_text);

		let elem_continue = document.createElement("div");
		elem_continue.className = "continue";
		elem_continue.textContent = "continue";
		elem_container.onclick = () => nav_to({type: PAGE_HOME, id: ""});
		elem_container.appendChild(elem_continue);

		if(views.page.subscription) views.page.subscription.update_all();
    }

	update_all (payload :any) {
        for(let i of Object.keys(this.instances)) this.update_elem(this.instances[i].e, payload);
	}
}

export default Page_subscriptionUpdate;
