import { socket_send, ACTION } from "../../../socket";
import { state } from "../../../state";
import { auth, auth_is_subscriber, auth_is_subscribed } from "../../../auth";
import { Page, Page_Type } from "../page";
import { date_str } from "../../modular/time";
import { nav_to } from "../../../router";

const SUB_PLATFORM = {
	"NULL":   0,
	"GIFT":   1,
	"POINTS": 2,
	"PAYPAL": 3,
};

class Page_subscription extends Page {
	elem_buttons: HTMLElement | null = null;

    constructor () {
        super(({type: Page_Type.SUBSCRIPTION, id: ""}));
    }

    new () {
        let elem = document.createElement("div");
        elem.className = "subscription";
        this.update_elem(elem);
        return({e: elem, es: elem, h: 0, s: 0});
    }

    update_elem = (elem :HTMLElement) => {
        elem.innerHTML = "";

        let elem_container = document.createElement("div");
        elem_container.className = "container";
        
        let elem_status = document.createElement("div"); elem_container.appendChild(elem_status);
        elem_status.className = "status";
        if(auth_is_subscriber()) {
            let elem_member = document.createElement("div");
            elem_member.className = "member";
            elem_member.textContent = auth_is_subscribed() ? "You are subscribed" : "Sub canceled (benefits still active)";
            elem_status.appendChild(elem_member);

            let elem_expiration = document.createElement("div");
            elem_expiration.className = "expiration";
            elem_expiration.textContent = auth_is_subscribed() ? `Subscription renews ${date_str(new Date(auth.user.date_sub_update))}` : `Subscription benefits are valid until ${date_str(new Date(auth.user.date_sub_update))}`;
            elem_container.appendChild(elem_expiration);
        }
        else {
            let elem_top = document.createElement("span"); elem_status.appendChild(elem_top);
            elem_top.className = "top";
            elem_top.textContent = "Subscribe now,";

            let elem_bot = document.createElement("span"); elem_status.appendChild(elem_bot);
            elem_bot.className = "bot";

            let elem_price = document.createElement("span"); elem_bot.appendChild(elem_price);
            elem_price.className = "price";
            elem_price.textContent = "5€/month";

			elem_bot.appendChild(document.createTextNode(" or 20 invites"));

            let elem_other_options = document.createElement("div");
            elem_other_options.className = "other_options";
            elem_other_options.textContent = `Optionally you can obtain it by being active in the community, we gift the regulars.`;
            elem_container.appendChild(elem_other_options);
        }

        let elem_features = document.createElement("div");
        elem_features.className = "features";

        let elem_features_header = document.createElement("div");
        elem_features_header.className = "header";
        elem_features_header.textContent = "What you can do with it: ";
        elem_features.appendChild(elem_features_header);

        let elem_features_list = document.createElement("div");
        elem_features_list.className = "list";

        let items = [
			"Download content",
			"View archived posts",
			"Shows your support with a badge",
		];
        for(let item of items) {
            let elem_item = document.createElement("div");
            elem_item.className = "item";
            elem_item.textContent = `- ${item}`;
            elem_features_list.appendChild(elem_item);
        }
        elem_features.appendChild(elem_features_list);
        elem_container.appendChild(elem_features);

		this.elem_buttons = document.createElement("div");
		this.elem_buttons.className = "buttons";
		elem_container.appendChild(this.elem_buttons);
		this.buttons_update();

        elem.appendChild(elem_container);
    }

    update_all() {
        for(let i of Object.keys(this.instances)) this.update_elem(this.instances[i].e);
    }

	buttons_update () {
		this.elem_buttons!.innerHTML = "";
        if(auth_is_subscribed()) {
			let elem_button_cancel = document.createElement("div"); this.elem_buttons!.appendChild(elem_button_cancel);
			elem_button_cancel.className = "button cancel";
			elem_button_cancel.textContent = "Cancel Subscription";
			elem_button_cancel.onclick = () => {
				this.elem_buttons!.style.display = "none";
				socket_send(ACTION.SUBSCRIPTION_STOP);
			}
		}
		else {
			let elem_button_points = document.createElement("div"); this.elem_buttons!.appendChild(elem_button_points);
			elem_button_points.className = "button subscribe points";
			elem_button_points.textContent = "Subscribe w/ points";
			elem_button_points.onclick = () => {
				this.elem_buttons!.style.display = "none";
				socket_send(ACTION.SUBSCRIPTION_START, {platform: SUB_PLATFORM.POINTS});
				if(auth.user.invite_points < 20) nav_to({id: "", type: Page_Type.INVITES});
			}

			let elem_button_paypal = document.createElement("div"); this.elem_buttons!.appendChild(elem_button_paypal);
			elem_button_paypal.className = "button subscribe paypal";
			elem_button_paypal.textContent = "Subscribe w/ PayPal";
			elem_button_paypal.onclick = () => {
				this.elem_buttons!.style.display = "none";
				socket_send(ACTION.SUBSCRIPTION_START, {platform: SUB_PLATFORM.PAYPAL});
			}
		}
	}
}

export default Page_subscription;
