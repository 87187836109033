import { emoji_set_get } from "../../../helper";
import { auth_assert } from "../../modal/auth_quick";
import { Page, Page_Type } from "../page";

class Page_EmojiSet extends Page {

    constructor (id :string) {
        super({type: Page_Type.EMOJI_SET, id: id});
    }

    new () {
        let elem = document.createElement("div");
        elem.className = "emoji_set";
        this.update(elem);
        return({e: elem, es: elem, h: 0, s: 0});
    }

    update (elem :HTMLElement) {
        let emoji_set = emoji_set_get(this.id.id);

        let elem_list = document.createElement("div"); elem.appendChild(elem_list);
        elem_list.className = "list";

        let elem_buttons = document.createElement("div"); elem.appendChild(elem_buttons);
        elem_buttons.className = "buttons";

        let elem_button_upload = document.createElement("div"); elem_buttons.appendChild(elem_button_upload);
        elem_button_upload.className = "upload";

        let elem_button_upload_label = document.createElement("label"); elem_button_upload.appendChild(elem_button_upload_label);
        elem_button_upload_label.className = "label";
        elem_button_upload_label.htmlFor = "upload_input";
        elem_button_upload_label.textContent = "Upload emoji";

        auth_assert(() => {
            let elem_button_upload_input = document.createElement("input"); elem_button_upload.appendChild(elem_button_upload_input);
            elem_button_upload_input.className = "input";
            elem_button_upload_input.id = "upload_input";
            elem_button_upload_input.type = "file";
            elem_button_upload_input.multiple = false;
            elem_button_upload_input.style.display = "none";
            elem_button_upload_input.onchange = (e :any) => {
                if(!e.target.files || e.target.files.length == 0) return;
                for(let file of e.target.files) this.file_add(file);
            }
        })

    }

    file_add (file :any) {

    }
}

export default Page_EmojiSet;