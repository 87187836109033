import {Content} from "../../modular/content";
import { state } from "../../../state";
import { Create_Thread } from "../../modal/create";
import { dropzone_new } from "../../modular/dropzone";
import {Icon} from "../../modular/icon";
import {ID_Type} from "../../../id";
import { socket_send, ACTION } from "../../../socket";
import { Invite } from "../../modal/invite";
import { auth_assert } from "../../modal/auth_quick";
import { auth, auth_is_guest, auth_is_user } from "../../../auth";
import { Perms_Manage, auth_perms_get } from "../../../permission";
import { author_is_me, author_self_other } from "../../../author";
import { Author_Avatar, Author_Entry, Author_Name } from "../../modular/author";
import { content_get, upload_get } from "../../../helper";

class Panel_Board {
    id :string;
    elem :HTMLElement;

    constructor (id :string) {
        this.id = id;
        this.elem = document.createElement("div");
        this.elem.className = "board";
        this.update();
    }

    update () {
        if(!state.boards || !(this.id in state.boards)) return;
        let board = state.boards[this.id];

        this.elem.innerHTML = "";

        let elem_top = document.createElement("div");
        elem_top.className = "top";
        this.elem.appendChild(elem_top);

        let elem_header = document.createElement("div");
        elem_header.className = "header";
        elem_top.appendChild(elem_header);

        let elem_name = document.createElement("div");
        elem_name.className = "name";
        elem_name.textContent = board.key ? `/${board.key}/ - ${board.name}` : board.name;
        elem_header.appendChild(elem_name);

        let elem_description = document.createElement("div");
        elem_description.className = "description";
        elem_description.textContent = `${board.description}`;
        elem_header.appendChild(elem_description);

        let elem_sort = document.createElement("div");
        elem_sort.className = "sort";
        elem_top.appendChild(elem_sort);
        let elem_refresh = document.createElement("div");
        elem_refresh.className = "refresh";
        elem_sort.appendChild(elem_refresh);
        let elem_order = document.createElement("div");
        elem_order.className = "order";
        elem_sort.appendChild(elem_order);
        let elem_by = document.createElement("div");
        elem_by.className = "by";
        elem_sort.appendChild(elem_by);

        let elem_buttons = document.createElement("div");
        elem_buttons.className = "buttons";
        elem_top.appendChild(elem_buttons);

        let elem_buttons_regular = document.createElement("div");
        elem_buttons_regular.className = "regular";

        let elem_button_share = document.createElement("div");
        elem_button_share.className = "share";
        elem_button_share.innerHTML = Icon.Invite;
        elem_button_share.onclick = () => {
			auth_assert(Invite({type: ID_Type.BOARD, id: board.id}))
        }
        elem_buttons.appendChild(elem_button_share);
        elem_top.appendChild(elem_buttons);

        elem_buttons.appendChild(elem_buttons_regular);

		if(0) {
		//if(auth_is_user()) {
			let options :any = {board: board.id};
			let perms = auth_perms_get(options);

			if(perms[author_self_other(board.author)].obj.board & Perms_Manage.EDIT) {
				let elem_buttons_power = document.createElement("div");
				elem_buttons_power.className = "power";
				elem_buttons.appendChild(elem_buttons_power);

				let elem_button_edit = document.createElement("div");
				elem_button_edit.className = "edit";
				elem_button_edit.textContent = "edit";
				elem_buttons_power.appendChild(elem_button_edit);
			}
		}

		let content = content_get(upload_get(board.banner)?.content);
        let elem_top_bg = Content(content, {size: "128", thumb: true});
		if(elem_top_bg) {
			elem_top_bg.classList.add("background");
			elem_top.appendChild(elem_top_bg);
		}

        let elem_mid = document.createElement("div");
        elem_mid.className = "mid group";
        this.elem.appendChild(elem_mid);

        let elem_create = document.createElement("div");
        elem_create.className = "item create";
        elem_create.textContent = "New Thread";
        elem_mid.appendChild(elem_create);

        elem_create.onclick = (e) => {
			auth_assert(() => {
                let board = state.boards[this.id];

                let create = new Create_Thread(board);
                create.new();

                dropzone_new(create.elem, create);
			})
        }

        let elem_bot = document.createElement("div");
        elem_bot.className = "bot";
        this.elem.appendChild(elem_bot);

		let elem_members = document.createElement("div");
		elem_members.className = "members";
		elem_bot.appendChild(elem_members);

		let elem_members_title = document.createElement("div");
		elem_members_title.className = "title";
		elem_members.textContent = `Members (${board.member_count})`;
		elem_members.appendChild(elem_members_title);

		let elem_members_list = document.createElement("div");
		elem_members_list.className = "list";
		elem_members.appendChild(elem_members_list);

		this.update_members();
    }

	member (author :any) {
	}

	update_members () {
		let elem_members = this.elem.querySelector(".bot .members")!;
		let elem_list = elem_members?.querySelector(".list")!;
		elem_list.innerHTML = "";

        if(!state.boards || !(this.id in state.boards)) return;
        let board = state.boards[this.id];

		/* TODO: Other roles */
		let role_owner = document.createElement("div");
		role_owner.className = "role";
		elem_list.appendChild(role_owner);

		let role_owner_title = document.createElement("div");
		role_owner_title.className = "title";
		role_owner_title.textContent = `OWNER - ${1}`;
		role_owner.appendChild(role_owner_title);

		let author = !board.author.is_anon ? state.profiles[board.author.id] : board.author;
		role_owner.appendChild(Author_Entry(author, {profile: "left"}));
	}
}

export default Panel_Board;
