import { socket_send, ACTION } from "../../socket";
import { state } from "../../state"

const modal_close = () => {
    if(state.modal != "") {
        let elem_modal = document.querySelector("#modal");
        if(elem_modal) elem_modal!.outerHTML = "";
        state.modal = "";
    }
}

export const Invite = (target: any) => {
    modal_close();
    state.modal = "invite";

    let options = {
        "time": 0,
        "user": 0,
    }

    let elem_app = document.querySelector("#app");

    let elem_invite = document.createElement("div");
    elem_invite.id = "modal";
    elem_invite.className = "invite";
    elem_invite.setAttribute("lc", "stop");
    elem_app!.appendChild(elem_invite);

    let elem_invite_top = document.createElement("div");
    elem_invite_top.className = "top";
    elem_invite.appendChild(elem_invite_top);

    let elem_invite_bot = document.createElement("div");
    elem_invite_bot.className = "bot";
    elem_invite.appendChild(elem_invite_bot);

    let elem_title = document.createElement("div");
    elem_title.className = "title";
    elem_title.textContent = "Invite link settings";
    elem_invite_top.appendChild(elem_title);

    /* ------------------------------------------------------------------------- */

    let elem_time_expire = document.createElement("div");
    elem_time_expire.className = "option time";
    elem_invite_top.appendChild(elem_time_expire);

    let elem_time_expire_title = document.createElement("div");
    elem_time_expire_title.className = "title";
    elem_time_expire_title.textContent = "Expire after";
    elem_time_expire.appendChild(elem_time_expire_title);

    let elem_time_expire_dropdown = document.createElement("div");
    elem_time_expire_dropdown.className = "dropdown";
    elem_time_expire.appendChild(elem_time_expire_dropdown);

    let minute = 60;
    let hour = 60*minute;
    let day = 24*hour;
    let time_expire_options = {
        "Never": 0,
        "30 minutes": 30 * minute,
        "1 hour": 1*hour,
        "6 hours": 6*hour,
        "12 hours": 12*hour,
        "1 day": 1*day,
        "7 days": 7*day,
    }
    let time_expire_selected = 0;

    let elem_time_expire_dropdown_selected = document.createElement("div");
    elem_time_expire_dropdown_selected.className = "selected";
    elem_time_expire_dropdown_selected.textContent = Object.entries(time_expire_options)[time_expire_selected][0];
    elem_time_expire_dropdown.appendChild(elem_time_expire_dropdown_selected);

    let elem_time_expire_dropdown_items = document.createElement("div");
    elem_time_expire_dropdown_items.className = "items";
    elem_time_expire_dropdown_items.style.display = "none";
    elem_time_expire_dropdown.appendChild(elem_time_expire_dropdown_items);

    elem_time_expire_dropdown_selected.onclick = (e: any) => elem_time_expire_dropdown_items.style.display = "block";

    const time_expire_select = (index :number) => {
        elem_time_expire_dropdown_selected.textContent = Object.entries(time_expire_options)[index][0];
    }

    for(let i = 0; i < Object.keys(time_expire_options).length; ++i) {
        let time = Object.entries(time_expire_options)[i];

        let elem_time = document.createElement("div");
        elem_time.className = "item";
        elem_time.textContent = time[0];
        elem_time_expire_dropdown_items.appendChild(elem_time);

        elem_time.onclick = (e: any) => {
            elem_time_expire_dropdown_items.style.display = "none";
            time_expire_select(i);
        }
    }

    /* ------------------------------------------------------------------------- */

    let elem_user_limit = document.createElement("div");
    elem_user_limit.className = "option user";
    elem_invite_top.appendChild(elem_user_limit);

    let elem_user_limit_title = document.createElement("div");
    elem_user_limit_title.className = "title";
    elem_user_limit_title.textContent = "Max number of users";
    elem_user_limit.appendChild(elem_user_limit_title);

    let elem_user_limit_dropdown = document.createElement("div");
    elem_user_limit_dropdown.className = "dropdown";
    elem_user_limit.appendChild(elem_user_limit_dropdown);

    let user_limit_options = {
        "No limit": 0,
        "1 use": 1,
        "5 uses": 5,
        "10 uses": 10,
        "25 uses": 25,
        "50 uses": 50,
        "100 uses": 100,
    }
    let user_limit_selected = 0;

    let elem_user_limit_dropdown_selected = document.createElement("div");
    elem_user_limit_dropdown_selected.className = "selected";
    elem_user_limit_dropdown_selected.textContent = Object.entries(user_limit_options)[user_limit_selected][0];
    elem_user_limit_dropdown.appendChild(elem_user_limit_dropdown_selected);

    let elem_user_limit_dropdown_items = document.createElement("div");
    elem_user_limit_dropdown_items.className = "items";
    elem_user_limit_dropdown_items.style.display = "none";
    elem_user_limit_dropdown.appendChild(elem_user_limit_dropdown_items);

    elem_user_limit_dropdown_selected.onclick = (e: any) => elem_user_limit_dropdown_items.style.display = "block";

    const user_limit_select = (index :number) => {
        elem_user_limit_dropdown_selected.textContent = Object.entries(user_limit_options)[index][0];
    }

    for(let i = 0; i < Object.keys(user_limit_options).length; ++i) {
        let user = Object.entries(user_limit_options)[i];

        let elem_user = document.createElement("div");
        elem_user.className = "item";
        elem_user.textContent = user[0];
        elem_user_limit_dropdown_items.appendChild(elem_user);

        elem_user.onclick = (e: any) => {
            elem_user_limit_dropdown_items.style.display = "none";
            user_limit_select(i);
        }
    }

    /* ------------------------------------------------------------------------- */

    let elem_cancel = document.createElement("div");
    elem_cancel.className = "cancel";
    elem_cancel.textContent = "Cancel";
    elem_cancel.onclick = (e: any) => {
        modal_close();
    }
    elem_invite_bot.appendChild(elem_cancel);

    let elem_generate = document.createElement("div");
    elem_generate.className = "generate";
    elem_generate.textContent = "Generate new link";
    elem_generate.onclick = (e: any) => {
        socket_send(ACTION.INVITE_ADD, {target: target, user_limit: options.user, time_expire: options.time});
        modal_close();
    }
    elem_invite_bot.appendChild(elem_generate);
} 