import {ADDRESS_SOCKET, ADDRESS_WEBSITE, ENV} from "./config";
import {views} from "./view";
import {state, Status} from "./state";
import {auth, auth_is_user, auth_on_join, auth_on_leave, auth_on_login, auth_callback_set, auth_callback_exec, auth_on_subscription, auth_roles_update} from "./auth";

import {Page_Type} from "./view/app/page";
import { ID_Type } from "./id";
import { nav_to } from "./router";
import {Notif, Notif_Type} from "./view/modular/notification";
import { participants_me_you_get, participants_me_you_match, channels_sort, channel_tmp_replace } from "./model/channel";
import { channel_get, message_get, thread_get } from "./helper";

const THREAD_FLAG = {
    PINNED:   0x01,
    EDITED:   0x02,
    MERGE:    0x04,

    DELETED:  0x10,
    ARCHIVED: 0x20,
}

export enum ACTION {
    AUTH_JOIN = "0",
    AUTH_LEAVE = "1",
    AUTH_LOGIN = "2",
    AUTH_REGISTER_FULL  = "3",
    AUTH_REGISTER_QUICK = "4",

	BOARDS_ME_GET = "5",
    BOARDS_OWNED_GET = "6",
    BOARDS_JOINED_GET = "7",
    BOARDS_OFFICIAL_GET = "8",
    BOARDS_PUBLIC_GET = "9",

    BOARD_ADD = "10",
	BOARD_DEL = "11",
    BOARD_LIST_ADD = "12",
    BOARD_LIST_DEL = "13",
	BOARD_PIN_ADD = "14",
	BOARD_PIN_DEL = "15",
	BOARD_PIN_MOVE = "16",
	BOARD_PIN_CAT_ADD = "17",
	BOARD_PIN_CAT_DEL = "18",
	BOARD_PIN_CAT_EDIT = "19",
	BOARD_PIN_CAT_MOVE = "20",
    BOARD_THREADS_GET = "21",

    THREADS_PINNED_GET = "22",
    THREADS_WATCHING_GET = "23",

    THREAD_GET = "24",
    THREAD_ADD = "25",
    THREAD_DEL = "26",
    THREAD_EDIT = "27",
    THREAD_MERGE = "28",

    THREAD_WATCHING_ADD = "29",
    THREAD_WATCHING_DEL = "30",
    THREAD_PARTICIPANTS_GET = "31",

    CHANNELS_ME_GET = "32",
    CHANNEL_ME_GET = "33",
    CHANNEL_ME_MESSAGE_ADD = "34",

    CHANNEL_GET = "35",

	MESSAGES_GET = "36",
	MESSAGE_ADD = "37",
	MESSAGE_DEL = "38",
	MESSAGE_EDIT = "39",
	MESSAGE_READ_SET = "40",

    EMOJI_SET_ADD = "41",
    EMOJI_SET_DEL = "42",
    EMOJI_SET_EDIT = "43",

    EMOJI_ADD = "44",
    EMOJI_DEL = "45",
    EMOJI_EDIT = "46",

    ADMIN_SUBSCRIPTION = "47",
    ADMIN_CHANNELS_GET = "48",
    ADMIN_MEMBERS_GET = "49",
    ADMIN_MEMBER_ADD = "50",
    ADMIN_MEMBER_DEL = "51",

    MOD_REPORTS_GET = "52",

    RULESET_GET = "53",
    RULESET_UPDATE = "54",

    USER_BAN = "55",
    USER_REPORT = "56",

	PROFILE_ADD = "57",
	PROFILE_DEL = "58",
	PROFILE_CONTENT_GET = "59",
	PROFILE_ITEM_SAVE = "60",

    ROLES_GET = "61",
    ROLE_ADD = "62",
    ROLE_DEL = "63",

    INVITES_GET = "64",
    INVITE_GET = "65",
    INVITE_ADD = "66",
    INVITE_DEL = "67",

	SETTINGS_ACCOUNT = "68",
	SETTINGS_PROFILE = "69",
	SETTINGS_SAFETY  = "70",
	SETTINGS_NOTIFS  = "71",

	SETTINGS_BOARD_PROFILE = "72",
	SETTINGS_BOARD_SAFETY  = "73",
	SETTINGS_BOARD_NOTIFS  = "74",

	SUBSCRIPTION_START     = "75",
	SUBSCRIPTION_STOP     = "76",
	SUBSCRIPTION_UPDATE   = "77",
}

const ACTION_STR = {
    "0": "AUTH_JOIN",
    "1": "AUTH_LEAVE",
    "2": "AUTH_LOGIN",
    "3": "AUTH_REGISTER_FULL",
    "4": "AUTH_REGISTER_QUICK",

	"5": "BOARDS_ME_GET",
    "6": "BOARDS_OWNED_GET",
    "7": "BOARDS_JOINED_GET",
    "8": "BOARDS_OFFICIAL_GET",
    "9": "BOARDS_PUBLIC_GET",

    "10": "BOARD_ADD",
	"11": "BOARD_DEL",
    "12": "BOARD_LIST_ADD",
    "13": "BOARD_LIST_DEL",
	"14": "BOARD_PIN_ADD",
	"15": "BOARD_PIN_DEL",
	"16": "BOARD_PIN_MOVE",
	"17": "BOARD_PIN_CAT_ADD",
	"18": "BOARD_PIN_CAT_DEL",
	"19": "BOARD_PIN_CAT_EDIT",
	"20": "BOARD_PIN_CAT_MOVE",
    "21": "BOARD_THREADS_GET",

    "22": "THREADS_PINNED_GET",
    "23": "THREADS_WATCHING_GET",

    "24": "THREAD_GET",
    "25": "THREAD_ADD",
    "26": "THREAD_DEL",
    "27": "THREAD_EDIT",
    "28": "THREAD_MERGE",

    "29": "THREAD_WATCHING_ADD",
    "30": "THREAD_WATCHING_DEL",
    "31": "THREAD_PARTICIPANTS_GET",

    "32": "CHANNELS_ME_GET",
    "33": "CHANNEL_ME_GET",
    "34": "CHANNEL_ME_MESSAGE_ADD",
	
    "35": "CHANNEL_GET",

    "36": "MESSAGES_GET",
    "37": "MESSAGE_ADD",
    "38": "MESSAGE_DEL",
    "39": "MESSAGE_EDIT",
    "40": "MESSAGE_READ_SET",

    "41": "EMOJI_SET_ADD",
    "42": "EMOJI_SET_DEL",
    "43": "EMOJI_SET_EDIT",

    "44": "EMOJI_ADD",
    "45": "EMOJI_DEL",
    "46": "EMOJI_EDIT",

    "47": "ADMIN_SUBSCRIPTION",
    "48": "ADMIN_CHANNELS_GET",
    "49": "ADMIN_MEMBERS_GET",
    "50": "ADMIN_MEMBER_ADD",
    "51": "ADMIN_MEMBER_DEL",

    "52": "MOD_REPORTS_GET",

    "53": "RULESET_GET",
    "54": "RULESET_UPDATE",

    "55": "USER_BAN",
    "56": "USER_REPORT",

	"57": "PROFILE_ADD",
	"58": "PROFILE_DEL",

	"59": "PROFILE_CONTENT_GET",
	"60": "PROFILE_ITEM_SAVE",

    "61": "ROLES_GET",
    "62": "ROLE_ADD",
    "63": "ROLE_DEL",

    "64": "INVITES_GET",
    "65": "INVITE_GET",
    "66": "INVITE_ADD",
    "67": "INVITE_DEL",

	"68": "SETTINGS_ACCOUNT",
	"69": "SETTINGS_PROFILE",
	"70": "SETTINGS_SAFETY",
	"71": "SETTINGS_NOTIFS",

	"72": "SETTINGS_BOARD_PROFILE",
	"73": "SETTINGS_BOARD_SAFETY",
	"74": "SETTINGS_BOARD_NOTIFS",

	"75": "SUBSCRIPTION_START",
	"76": "SUBSCRIPTION_STOP",
	"77": "SUBSCRIPTION_UPDATE",
}

export let socket: null | WebSocket = null;
export let socket_queue :string[] = [];

export const socket_send = (action :ACTION, payload :any = {}) => {
    const message = JSON.stringify({a:action, p:payload});

    if(socket && socket.readyState == 1)
	{
		if(ENV == "DEV") console.log("SEND: ", "a: ", ACTION_STR[action], "\tp: ", payload);
		socket.send(message);
	}
    else
	{
		if(ENV == "DEV") console.log("QUEUE: ", "a: ", ACTION_STR[action], "\tp:", payload);
		socket_queue.push(message);
	}
}

const socket_disconnect = () => {
    socket!.close();
    socket = null;
}

export const socket_connect = () => {
    if(socket != null) socket_disconnect();

    socket = new WebSocket(ADDRESS_SOCKET);
    socket.addEventListener("open", () => {
        if(ENV == "DEV") console.log("Socket opened!");

		let payload = {
			token: localStorage.getItem("token"),
			profile_index: localStorage.getItem("profile_index"),
		};
        socket_send(ACTION.AUTH_JOIN, payload);
    })

    socket.addEventListener("close", (e :any) => {
        socket = null;
        if(ENV == "DEV") console.log(`Socket closed! ${e.reason}`);
        setTimeout(() => socket_connect(), 1000);
    })

    socket.addEventListener("error", (e :any) => {
        if(ENV == "DEV") console.error(`Socket error. ${e.message}`);
        socket_disconnect();
    })

    socket.addEventListener("message", (e :any) => {
        const data = JSON.parse(e.data);
        const action = data.a;
        const payload = data.p;
        const status = data.s;

        if(ENV == "DEV") console.log("RECV: ", "a: ", ACTION_STR[action], "\ts: ", status, "\tp: ", payload);

        if(status.c > 0) {
			switch(action) {
			case ACTION.AUTH_JOIN: {
				//localStorage.removeItem("token");
				window.location.reload();
			} break;
			}

            let message = "";
            switch(status.c)
            {
            case 2: message = "Not Connected";               break;
            case 3: message = "Not Permited";                break;
            case 4: message = "Not Authorized";              break;
            case 5: message = "Not Authenticated";           break;
            case 6: message = "Field Missing " + (status.m); break;
            case 7: message = "Field Invalid " + (status.m); break;
            case 8: message = "Couldn't find " + (status.m); break;
            case 9: message = "Failed to save" + (status.m); break;
			default: message = status.m; break;
            }
            Notif(Notif_Type.ERROR, 5000, {msg: message});
            console.error(status);
            return;
        }

        switch(action) {
            case ACTION.AUTH_JOIN: {
				if(!payload || !payload.auth) {
					//localStorage.removeItem("token");
					window.location.reload();
				}

				if(!payload.auth.is_guest) profiles_store(payload.auth.user.profiles);
				auth_on_join(payload);
			} break;
            case ACTION.AUTH_LEAVE: {
				auth_on_leave(payload);
            } break;
            case ACTION.AUTH_LOGIN:
            case ACTION.AUTH_REGISTER_FULL:
            case ACTION.AUTH_REGISTER_QUICK: {
				if(!payload || !payload.auth) {
					//localStorage.removeItem("token");
					window.location.reload();
				}

				if(!payload.auth.is_guest) profiles_store(payload.auth.user.profiles);
				auth_on_login(payload);
				if(action == ACTION.AUTH_REGISTER_QUICK) auth_callback_exec();
            } break;

			case ACTION.BOARDS_ME_GET: {
				boards_store(payload.boards);
				state.boards_me = Object.keys(payload.boards);
				views.bar_left.update_boards();

				/* Add join board if it's not on the list */
			} break;
            case ACTION.BOARDS_OWNED_GET:
			case ACTION.BOARDS_JOINED_GET:
			case ACTION.BOARDS_OFFICIAL_GET:
			case ACTION.BOARDS_PUBLIC_GET: {
                boards_store(payload.boards);

				/* TODO: Are these two necessary? */
				/*
                let window_state = state.windows[state.window_selected];
                let tab = state.tabs[window_state.tabs[window_state.tab_selected]];
                let page_id = tab.history[tab.pos];
                if(page_id.type == Page_Type.BOARD) {
                    views.bar_right.update();
                }

                for(let tab_i = 0; tab_i < state.tabs.length; ++tab_i) {
                    let tab = state.tabs[tab_i];
                    let page_id = tab.history[tab.pos];
                    if(page_id.type == Page_Type.BOARD) {
                        // let board = state.boards[page_id.id];
                        let tab_view = views.tab[tab_i];
                        tab_view.update();
                    }
                }
				*/

                /* Update Boards Page */ 
				switch(action) {
					case ACTION.BOARDS_OWNED_GET: {
						state.boards_owned = Object.keys(payload.boards);
						if(views.page.boards_owned) views.page.boards_owned.update_all();
					} break;
					case ACTION.BOARDS_JOINED_GET: {
						state.boards_joined = Object.keys(payload.boards);
						if(views.page.boards_joined) views.page.boards_joined.update_all();
					} break;
					case ACTION.BOARDS_OFFICIAL_GET: {
						state.boards_official = Object.keys(payload.boards);
						if(views.page.boards_official) views.page.boards_official.update_all();
					} break;
					case ACTION.BOARDS_PUBLIC_GET: {
						state.boards_public = Object.keys(payload.boards);
						if(views.page.boards_public) views.page.boards_public.update_all();
					} break;
				}
            } break;
            case ACTION.BOARD_ADD: {
                if(payload.is_you) {
                    if(!state.boards_me) state.boards_me = [];
                    boards_store([payload.board])
                    state.boards_me.push(payload.board.id);
                    views.bar_left.update_boards();

                    close_modal();
                    nav_to({type: Page_Type.BOARD, id: payload.board.id});
                }
            } break;
            case ACTION.BOARD_THREADS_GET: {
                if(!state.board_threads) state.board_threads = {};
                state.board_threads[payload.board_id] = Object.keys(payload.threads);

                threads_store(payload.threads);
                views.page.board[payload.board_id].update_all();

                views.bar_left.update_threads_watching(payload.board_id);
            } break;
            case ACTION.BOARD_LIST_ADD: {
				if(state.board_tmp == payload.board_id) views.bar_left.del_board_tmp();
                state.boards_me.push(payload.board_id);
                views.bar_left.add_user_board(payload.board_id);
				views.bar_left.update_select();

				/* Delete page join bar */
				views.page.board[payload.board_id]?.del_join_all();
				for(let [thread_id, page] of Object.entries(views.page.thread)) {
					let thread = state.threads[thread_id];
					if(thread.board == payload.board_id) {
						views.page.thread[thread_id].del_join_all();
					}
				}
            } break;
            case ACTION.BOARD_LIST_DEL: {
                state.boards_me = state.boards_me.filter((b :string) => b != payload.board_id);
                views.bar_left.del_user_board(payload.board_id);
            } break;
			case ACTION.BOARD_PIN_ADD: {
				if(!(payload.board_id in state.boards)) return;
				if(!("pins" in state.boards[payload.board_id])) return;

				threads_store([payload.thread])
				if(payload.pin_category == "") {
					if(!("" in state.boards[payload.board_id].pins)) state.boards[payload.board_id].pins[""] = [];
					state.boards[payload.board_id].pins[""].splice(payload.index, 0, payload.thread.id);
				}
				else {
					/* TODO: */;
				}
				state.threads[payload.thread.id].flags |= THREAD_FLAG.PINNED;
				views.bar_left.thread_pinned_add(payload.board_id, payload.thread.id, payload.pin_category, payload.index);
			} break;
			case ACTION.BOARD_PIN_DEL: {
				if(!(payload.board_id in state.boards)) return;
				if(!("pins" in state.boards[payload.board_id])) return;
				if(payload.pin_category == "") state.boards[payload.board_id].pins[""] = state.boards[payload.board_id].pins[""].filter((thread_id :string) => thread_id != payload.thread_id);
				state.threads[payload.thread_id].flags &= ~THREAD_FLAG.PINNED;
				views.bar_left.thread_pinned_del(payload.board_id, payload.thread_id, payload.pin_category);
			} break;
            case ACTION.THREADS_PINNED_GET: {
				if(!(payload.board_id in state.boards)) return;
				for(let category in payload.pins) {
					if(category == "") {
						threads_store(payload.pins[category]);
						payload.pins[category] = payload.pins[category].map((thread :any) => thread.id);
					}
					else  {
						threads_store(payload.pins[category].threads);
						payload.pins[category].threads = payload.pins[category].threads.map((thread :any) => thread.id);
					}
				}
				state.boards[payload.board_id].pins = payload.pins;
				views.bar_left.update_threads_pinned(payload.board_id);
            } break;
            case ACTION.THREADS_WATCHING_GET: {
                for(let thread_id in payload.threads) payload.threads[thread_id].board = payload.board_id;
                threads_store(payload.threads, 0);

                if(!state.threads_watching) state.threads_watching = {};
                state.threads_watching[payload.board_id] = Object.keys(payload.threads);
                views.bar_left.update_threads_watching(payload.board_id);
            } break;
            case ACTION.THREAD_GET: {
                threads_store({[payload.thread.id]: payload.thread});
                if(payload.thread.id in views.panel.thread) views.panel.thread[payload.thread.id].update();

                /* TODO: Check if the page has this thread id then update bar_right */
                let window_state = state.windows[state.window_selected];
                let tab = state.tabs[window_state.tabs[window_state.tab_selected]];
                let page_id = tab.history[tab.pos];
                if(page_id.type == Page_Type.THREAD && page_id.id == payload.thread.id) {
                    views.bar_right.update();
                }
                
                for(let tab_i = 0; tab_i < state.tabs.length; ++tab_i) {
                    let tab = state.tabs[tab_i];
                    let page_id = tab.history[tab.pos];
                    if(page_id.type == Page_Type.THREAD && page_id.id == payload.thread.id) {
                        let tab_view = views.tab[tab_i];
                        tab_view.update();
                    }
                }
            } break;
            case ACTION.THREAD_ADD: {
                if(payload.is_you) {
                    threads_store({[payload.thread.id]: payload.thread});
                    close_modal();
                    nav_to({type: Page_Type.THREAD, id: payload.thread.id});
                }
            } break;
			case ACTION.THREAD_DEL: {
				if(payload.thread.board in views.page.board) {
					views.page.board[payload.thread.board].thread_del(payload.thread);
				}
			} break;
            case ACTION.THREAD_WATCHING_ADD: {
                if(!state.threads_watching) state.threads_watching = {};
                if(!(payload.board_id in state.threads_watching)) state.threads_watching[payload.board_id] = [];
                state.threads_watching[payload.board_id].unshift(payload.thread_id);
                views.bar_left.update_threads_watching(payload.board_id);
            } break;
            case ACTION.THREAD_WATCHING_DEL: {
                if(!state.threads_watching) state.threads_watching = {};
                if(!(payload.board_id in state.threads_watching)) state.threads_watching[payload.board_id] = [];
                state.threads_watching[payload.board_id] = state.threads_watching[payload.board_id].filter((thread_id :string) => thread_id != payload.thread_id);
                views.bar_left.update_threads_watching(payload.board_id);
            } break;

            case ACTION.THREAD_PARTICIPANTS_GET: {
                if(status.c > 0) {
                    console.error("ERROR: ", payload.err);
                    return;
                }

                state.profiles_online = [...state.profiles_online, ...payload.online];
                state.threads[payload.thread_id].online = payload.online;
                state.threads[payload.thread_id].participants = payload.participants;
                views.panel.thread[payload.thread_id].update_participants();

                /* Users */
                for(let user of Object.values(payload.participants.user) as any[]) if(!(user.id in state.profiles)) state.profiles[user.id] = user;
            } break;

            case ACTION.CHANNELS_ME_GET: {
				/* TODO: */
				state.channels_loaded = true;
                channels_store(payload.channels);
                views.bar_left.update_user_channels();
            } break;
            case ACTION.CHANNEL_ME_GET: {
				if(Object.keys(payload.channels).length > 0) {
					channels_store(payload.channels);
					views.bar_left.update_user_channels();
				}
				else {
					/* Channel does not exist */
					for(let channel_id of Object.keys(state.channels_tmp)) {
						let channel = state.channels_tmp[channel_id];
						if(participants_me_you_match(channel, payload.me, payload.you)) {
						}
					}
				}
            } break;
			case ACTION.CHANNEL_GET: {
				channels_store(payload.channels);
				views.bar_left.update_user_channels();
			} break;
            case ACTION.CHANNEL_ME_MESSAGE_ADD: {
                console.error("We're not supposed to get this back!");
            } break;
            case ACTION.MESSAGES_GET: {
                messages_store(payload.target, payload.messages);

				var object = null;
				switch(payload.target.type) {
				case ID_Type.THREAD: object = thread_get(payload.target.id); break;
				case ID_Type.CHANNEL: object = channel_get(payload.target.id); break;
				}

				var view = null;
				switch(payload.target.type) {
				case ID_Type.THREAD: view = views.page.thread[payload.target.id]; break;
				case ID_Type.CHANNEL: view = views.page.channel[payload.target.id]; break;
				}
                view.instances.map((e :any, i: number) => e.msgs.chunk_add(i, payload.messages, payload.key, payload.all, payload.more));
            } break;
            case ACTION.MESSAGE_ADD: {
                messages_store(payload.target, [payload.message]);

				/* TODO: Update object date_bumpted */
				var object = null;
				switch(payload.target.type) {
				case ID_Type.THREAD: object = thread_get(payload.target.id); break;
				case ID_Type.CHANNEL: object = channel_get(payload.target.id); break;
				}
				if(object) {
					object.message_count += 1;
					object.media_count += payload.message.contents.length;
					object.date_bumped = payload.message.date_created;
                    if(payload.target.type == ID_Type.CHANNEL && auth_is_user()) {
                        channels_sort();
                        views.bar_left.update_user_channels();
                    }
				}

				var view = null;
				switch(payload.target.type) {
				case ID_Type.THREAD: view = views.page.thread[payload.target.id]; break;
				case ID_Type.CHANNEL: view = views.page.channel[payload.target.id]; break;
				}
				if(view) {
					view.instances.map((e :any, i: number) => e.msgs.message_add(i, payload.message));
				}
				else {
                    if(object) {
                        /* TODO: Play sound */
                        views.bar_left.listing_update(payload.target);
                    }

					switch(payload.target.type) {
					case ID_Type.THREAD: /* Do nothing */; break;
					case ID_Type.CHANNEL: socket_send(ACTION.CHANNEL_GET, {channel_id: payload.target.id}); break;
					}
				}
            } break;
            case ACTION.MESSAGE_DEL: {
				let message = message_get(payload.message_id);
				if(!message) return;

				var object = null;
				switch(message.target.type) {
				case ID_Type.THREAD: object = thread_get(message.target.id); break;
				case ID_Type.CHANNEL: object = channel_get(message.target.id); break;
				}
				if(object) {
					object.message_count -= 1;
					object.media_count -= message.contents.length;
				}

				var view = null;
				switch(message.target.type) {
				case ID_Type.THREAD: view = views.page.thread[message.target.id]; break;
				case ID_Type.CHANNEL: view = views.page.channel[message.target.id]; break;
				}
                if(view) {
                    view.instances.map((e :any, i: number) => e.msgs.message_del(message));
                }
                else {
                    if(object) {
                        views.bar_left.listing_update(payload.target);
                    }
                }
            } break;
            case ACTION.MESSAGE_EDIT: {
				var message = message_get(payload.message.id);
				if(!message) return;

				messages_store(message.target, [payload.message]);

				var view = null;
				switch(message.target.type) {
				case ID_Type.THREAD: view = views.page.thread[message.target.id]; break;
				case ID_Type.CHANNEL: view = views.page.channel[message.target.id]; break;
				}
                view.instances.map((e :any, i: number) => e.msgs.message_edit(i, payload.message));
            } break;
			case ACTION.MESSAGE_READ_SET: {
                switch(payload.target.type) {
                case ID_Type.THREAD: {
                    let thread = thread_get(payload.target.id);
                    thread.read = payload.read;
                } break;
                case ID_Type.CHANNEL: {
                    let channel = channel_get(payload.target.id);
                    channel.read = payload.read;
                }
                }
                views.bar_left.listing_update(payload.target);
			} break;

			case ACTION.EMOJI_SET_ADD: {
				if(!state.boards_me) state.boards_me = [];
				boards_store([payload.board])
				state.boards_me.push(payload.board.id);
				views.bar_left.update_boards();

				close_modal();
				nav_to({type: Page_Type.BOARD, id: payload.board.id});
			} break;

			/*
			case ACTION.EMOJI_SET_DEL: {
			} break;
			case ACTION.EMOJI_SET_EDIT: {
			} break;
			case ACTION.EMOJI_ADD: {
			} break;
			case ACTION.EMOJI_DEL: {
			} break;
			case ACTION.EMOJI_EDIT: {
			} break;
			*/

            case ACTION.ADMIN_CHANNELS_GET: {
                if(!state.admin.channels) state.admin.channels = Object.keys(payload.channels);

                channels_store(payload.channels);
                if(views.page.admin.channels) views.page.admin.channels.update();
            } break;

            case ACTION.ADMIN_MEMBERS_GET: {
                if(!state.admin.members) state.admin.members = payload;
                if(views.page.admin.members) views.page.admin.members.update();
            } break;
            case ACTION.ADMIN_MEMBER_ADD: {
                if(!state.admin.members) return;
                if(views.page.admin.members) views.page.admin.members.add(payload);
            } break;
            case ACTION.ADMIN_MEMBER_DEL: {
                if(!state.admin.members) return;
                if(views.page.admin.members) views.page.admin.members.del(payload);
            } break;

            case ACTION.MOD_REPORTS_GET: {
				/* TODO: */
				/*
                state.mod.reports = payload.reports;
                let users :any = {};
                for(let report_id in payload.reports) {
                    let report = payload.reports[report_id];
                    let user :any = report.user;
                    if(!(user.id in users)) users[user.id] = user;
                }
                authors_store(users);
                if((views.page.mod.reports)) views.page.mod.reports.update();
				*/
            } break;

            case ACTION.RULESET_GET: {
                state.rules = payload.rules;
                if(views.page.rules) views.page.rules.update();
            } break;

			case ACTION.USER_BAN:
			case ACTION.USER_REPORT: {
				console.error("Not implemented\n");
			} break;

			case ACTION.PROFILE_ADD: {
				window.location.reload();
			} break;
			case ACTION.PROFILE_DEL: {
			} break;
			case ACTION.PROFILE_CONTENT_GET: {
				var profile = state.profiles[payload.profile_id];
				switch(payload.kind) {
				case "saves": {
					items_store(payload.items);
					profile.saves = payload.items; /* TODO: Keep only ID's */

					if(views.modal.repost) views.modal.repost.update_page(payload.kind);
					if(payload.profile_id in views.page.profile_content) views.page.profile_content[payload.profile_id].update_all_pages(payload.kind);
				} break;
				case "uploads": {
					uploads_store(payload.uploads);
					profile.uploads = payload.uploads.map((upload :any) => upload.id);

					if(views.modal.repost) views.modal.repost.update_collection(payload.kind);
					if(payload.profile_id in views.page.profile_content) views.page.profile_content[payload.profile_id].update_all_collections(payload.kind);
				} break;
				case "emoji_sets": {
					emoji_sets_store(payload.emoji_sets);
					profile.emoji_sets = payload.emoji_sets.map((emoji_set :any) => emoji_set.id);

					if(views.modal.repost) views.modal.repost.update_collection(payload.kind);
					if(payload.profile_id in views.page.profile_content) views.page.profile_content[payload.profile_id].update_all_collections(payload.kind);
				} break;
				default: throw Error();
				}
			} break;
			case ACTION.PROFILE_ITEM_SAVE: {
				items_store([payload.item]);
				Notif(Notif_Type.ITEM_SAVED, 5000, {item: payload.item, });
				//if(payload.profile_id in views.page.profile_content) views.page.profile_content[payload.profile_id].update_all_pages(payload.kind);
			} break;

            case ACTION.ROLES_GET: {
				roles_store(payload.roles);

				if(payload.target == null) {
					state.roles_target = {};
					state.roles_target[""] = [];
					state.roles_target[ID_Type.THREAD] = {"": []};
					state.roles_target[ID_Type.BOARD] = {"": []};
					state.roles_target[ID_Type.WEBSITE] = [];
				}
				else {
					if(!state.roles_target) throw Error("Default roles not fetched first");
					state.roles_target[payload.target.type][payload.target.id] = [];
				}

				for(let role_id in payload.roles) {
					let role = state.roles[role_id];

					var roles_list = null;
					switch(role.target.type) {
					case null: roles_list = state.roles_target[""]; break;
					case ID_Type.WEBSITE: roles_list = state.roles_target[ID_Type.WEBSITE]; break;
					default: {
						if(role.target.id == null) role.target.id = "";
						roles_list = state.roles_target[role.target.type][role.target.id];
					} break;
					}

					if(roles_list) {
						if(!roles_list.includes(role_id)) roles_list.push(role.id);
						roles_list.sort((a :any, b :any) => state.roles[b].pos - state.roles[a].pos);
					}
				}

				auth_roles_update(payload.target);
            } break;

            case ACTION.INVITES_GET: {
				/* TODO: Invites should go in profile, and invite points should go in auth.user */
                state.invites = payload.invites;
                state.invite_points = payload.invite_points;
                if(views.page.invites) views.page.invites.update();
            } break;
            case ACTION.INVITE_ADD: {
				if(!state.invites) state.invites = [];
				state.invites.push(payload.invite);

				if(views.page.invites) views.page.invites.update();

                navigator.clipboard.writeText(`${ADDRESS_WEBSITE}/invite/${payload.invite.link}`);
                Notif(Notif_Type.INFO, 5000, {msg: "Invite link copied to clipboard"});
            } break;
            case ACTION.INVITE_DEL: {
				/* TODO: */
                if(state.invites) {
                    state.invites = state.invites.filter((invite :any) => invite.id != payload.invite.id);
                }
                if(views.page.invites) views.page.invites.update();
            } break;
            case ACTION.INVITE_GET: {
				/* Maybe in case we want to embed invites in chat */
                switch(payload.invite.target.type)
                {
                case ID_Type.WEBSITE: break;
                case ID_Type.BOARD: boards_store({[payload.invite.target.id]: payload.invite.target_ret}); break;
                case ID_Type.THREAD: threads_store({[payload.invite.target.id]: payload.invite.target_ret}); break;
                }

                /* If invite page is open with this link, redirect to the link page */
                if(views.page.invite && views.page.invite.id.id == payload.invite.id) {
                    views.page.invite.set_invite(payload.invite)
                }
            } break;
			case ACTION.SETTINGS_ACCOUNT: {
				if(payload.account_is_claimed) {
					let elem_claim_account = document.querySelector("#claim_account");
					if(elem_claim_account) {
						elem_claim_account.remove();

						let elem_important = document.querySelector("#important") as HTMLElement;
						let elem_app = document.querySelector("#app") as HTMLElement;
						elem_app.style.height = `calc(100% - ${elem_important.offsetHeight}px)`;
						elem_app.style.minHeight = elem_app.style.height;
						elem_app.style.maxHeight = elem_app.style.height;
					}
				}

				auth.user.email = payload.email;
				auth.user.account_is_claimed = payload.account_is_claimed;

				if(views.page.settings.account) {
					views.page.settings.account.reset();
					views.page.settings.account.update_all();
				}

				Notif(Notif_Type.SUCESS, 5000, {msg: "Account credentials updated"})
			} break;
			case ACTION.SETTINGS_PROFILE: {
				profiles_store([payload]);
				views.bar_left.update_bot();
			} break;

			case ACTION.SUBSCRIPTION_START: {
				if("redirect" in payload && payload.redirect) {
					window.location.replace(payload.redirect);
				}
			} break;
			case ACTION.SUBSCRIPTION_STOP:
			case ACTION.SUBSCRIPTION_UPDATE: {
				auth.user = {...auth.user, ...payload};

				/* Update Pages */
				if(views.page.subscription_update) {
					views.page.subscription_update.update_all(payload);
				}
				else state.sub_update = payload;
				nav_to({id: "", type: Page_Type.SUBSCRIPTION_UPDATE});

				if(views.page.subscription) {
					views.page.subscription.update_all();
				}

				/* Update Auth and pages that depend on subscription */
				auth_on_subscription(payload);
			} break;
        }
    })
}

/* Ohter functions */
export const items_store = (items :any) => {
    Object.values(items).map((item: any) => {
		switch(item.id.type) {
		case ID_Type.CONTENT: contents_store([item.ret]); break;
		case ID_Type.UPLOAD: uploads_store([item.ret]); break;
		//default: throw Error("Not implemented");
		default: return;
		}
    })
}

export const ids_store = (type: ID_Type, ids :any) => {
	if(!type || !ids) return;
	switch(type) {
	case ID_Type.ANON: break;
	case ID_Type.PROFILE: profiles_store(ids); break;
	case ID_Type.CONTENT: contents_store(ids); break;
	case ID_Type.UPLOAD:  uploads_store(ids); break;
	case ID_Type.MESSAGE: messages_store(null, ids, {uploads: false}); break;
	default: {
		console.error(type, ids);
		throw Error("Not implemented");
	}
	}
}

export const mentions_store = (obj :any) => {
	if(!obj) return;
    Object.entries(obj).map((entry) => {
		let type :ID_Type = Number(entry[0]);
		let ids :any = entry[1];
		ids_store(type, ids);
    })
}

export const roles_store = (roles :any) => {
    if(!state.roles) state.roles = {};
    Object.values(roles).map((role: any) => {
		if(!role) return;
		state.roles[role.id] = {...state.roles[role.id], ...role};
    })
}

export const profiles_store = (profiles :any) => {
    if(!state.profiles) state.profiles = {};
    Object.values(profiles).map((profile: any) => {
		if(!profile) return;
		if("avatar" in profile) {
			uploads_store([profile.avatar]);
			profile.avatar = profile.avatar?.id || null;
		}
		if("banner" in profile) {
			uploads_store([profile.banner]);
			profile.banner = profile.banner?.id || null;
		}
		state.profiles[profile.id] = {...state.profiles[profile.id], ...profile};
    })
}

export const authors_store = (authors :any) => {
    Object.values(authors).map((author: any) => {
		if(!author || author.is_anon) return;
		profiles_store([author]);
    })
}

export const boards_store = (boards :any) => {
    if(!state.boards) state.boards = {};
    Object.values(boards).map((board :any) => {
		if(!board) return;
        if(!(board.id in state.boards)) {
            board.status = Status.LOADED_PARTLY;
            state.boards[board.id] = board;
        }
		if("author" in board) {
			authors_store([board.author]);
			board.author = {id: board.author.id, is_anon: board.author.is_anon};
		}
		if("banner" in board) {
			uploads_store([board.banner])
			board.banner = board.banner?.id || null;
		}
		if("picture" in board) {
			uploads_store([board.picture])
			board.picture = board.picture?.id || null;
		}
		state.boards[board.id] = {...state.boards[board.id], ...board};

        if(board.id in views.panel.board) views.panel.board[board.id].update();
    })
}

export const threads_store = (threads :any, level = 1) => {
    if(!state.threads) state.threads = {};
	if(!state.threads_read) state.threads_read = {};

    Object.values(threads).map((thread :any) => {
		if(!thread) return;
		if(!(thread.id in state.threads)) {
            thread.status = Status.LOADED_PARTLY;
            thread.messages = null;
            thread.messages_status = {b: Status.NULL, a: Status.NULL};
            state.threads[thread.id] = thread;
		}

		/* Store */
		if(("author" in thread)) {
			authors_store([thread.author]);
			thread.author = {id: thread.author.id, is_anon: thread.author.is_anon};
		}
		if("picture" in thread) {
			uploads_store([thread.picture])
			thread.picture = thread.picture?.id || null;
		}
		thread.level = Math.max(state.threads[thread.id].level, thread.level);
		state.threads[thread.id] = {...state.threads[thread.id], ...thread};

		//if(!(thread.id in state.threads_read)) state.threads_read[thread.id] = {curr: {index: thread.message_count-1, date: thread.date_bumped}, read: {index: thread.message_count-1, date: thread.date_bumped}};
        if(thread.level == 1 && thread.id in views.panel.thread) views.panel.thread[thread.id].update();
    })
}

export const contents_store = (contents :any) => {
    if(!state.contents) state.contents = {};
    Object.values(contents).map((content: any) => {
		if(!content) return;
		state.contents[content.id] = {...state.contents[content.id], ...content};
    })
}

export const uploads_store = (uploads :any) => {
	if(!state.uploads) state.uploads = {};
	Object.values(uploads).map((upload: any) => {
		if(!upload) return;
		contents_store([upload.content]);
		upload.content = upload.content?.id || null;
		state.uploads[upload.id] = {...state.uploads[upload.id], ...upload};
	})
}

export const channels_store = (channels: any) => {
	/* This function is only use for real channels with messages */
    if(!state.channels) state.channels = {};
    if(!state.channels_sorted) state.channels_sorted = [];

    Object.values(channels).map((channel: any) => {
		if(!channel) return;
		let channel_list_changed = false;
        if(!(channel.id in state.channels)) {
			let users = participants_me_you_get(channel.participants);
			channel.me = users.me;
			channel.you = users.you;

			/* NOTE: This needs to go after participants_me_you_get() */
			authors_store(channel.participants);
			channel.participants = channel.participants.map((participant: any) => ({is_anon: participant.is_anon, id: participant.id, target: participant.target || null}));

            channel.status = Status.LOADED_PARTLY;
            channel.messages = null;
            channel.messages_status = {b: Status.NULL, a: Status.NULL};
            state.channels[channel.id] = channel;

			/* Check temporary and replace if matches */
			if(state.channels_tmp) {
				for(let chan_id of Object.keys(state.channels_tmp)) {
					let chan = state.channels_tmp[chan_id];
					if(participants_me_you_match(chan.participants, channel.me, channel.you)) {
						/* Replace */
						channel_tmp_replace(chan_id, channel.id);
					}
				}
			}
			channel_list_changed = true;
        }
		if(channel_list_changed) channels_sort();
    })
}

export const messages_store = (target :any, messages :any, options :any = {}) => {
    if(!state.messages) state.messages = {};
	if(!("uploads" in options)) options.uploads = true;

    Object.values(messages).map((message: any) => {
		if(!message) return;
		message.target = target;

		authors_store([message.author]);
		if(message.author.is_anon) message.author = {id: message.author.id, is_anon: message.author.is_anon, is_you: message.author.is_you};
		else message.author = {id: message.author.id, is_anon: message.author.is_anon};

		if(options.uploads) {
			uploads_store(message.contents);
			message.contents = message.contents.filter((content :any) => !!content).map((content :any) => content?.id || null);
		}
		mentions_store(message.mentions);

		state.messages[message.id] = {...state.messages[message.id], ...message};
    })
}

export const emoji_sets_store = (emoji_sets :any) => {
    if(!state.emoji_sets) state.emoji_sets = {};

    Object.values(emoji_sets).map((emoji_set: any) => {
		if(!emoji_set) return;

		/* TODO: Emojis */

		state.emoji_sets[emoji_set.id] = {...state.emoji_sets[emoji_set.id], ...emoji_set};
    })
}

const close_modal = () => {
    if(state.modal != "") {
        let elem_modal = document.querySelector("#modal");
        if(elem_modal) elem_modal.outerHTML = "";
    }
}
