import {views} from "../../view";
import {state, Status,} from "../../state";
import {Page_ID, Page_Type} from "../app/page";
import { ID_Type } from "../../id";

import {Author_Name, Author_Avatar} from "../modular/author";
import {Size, Layouter, RectSide} from "../util/layout";
import {Gallery} from "../modal/gallery";
import {Content} from "../modular/content";
import {Icon} from "../modular/icon";
import {date_str, TimeStamp} from "../modular/time";
import { content_get, upload_get } from "../../helper";

const MSG_FLAG = {
    EDIT:   0x1,
    MERGE:  0x2,
    DELETE: 0x4,
};

const text_parse_link = (text :string) => {
    let url_regex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(url_regex, (url) => `<a target="_blank" href="${url}">${url}</a>`);
}

const is_mention = (text :string, index :number) => {
    if(index >= text.length) return false;

    const ID_LEN = 26;
    const c = text[index];
    if(c == "<" && index+ID_LEN < text.length && text[index+ID_LEN] == ">") return true;
}

const type_from_sym = (sym :string) => {
    switch(sym) {
        case "!": return ID_Type.ANON;
        case "@": return ID_Type.PROFILE;

        case "#": return ID_Type.THREAD;
        case "$": return ID_Type.BOARD;

        case "%": return ID_Type.MESSAGE;
        case "&": return ID_Type.UPLOAD;

        //case ":": return ID_Type.EMOJI;
        //case "*": return ID_Type.STICKER;
        //case "^": return ID_Type.SOUND;
		default: return null;
    }
}

const message_parts = (text :string) => {
    text = text.trim();
    let parts = [];
    let part :any = {};
    for(let i = 0; i < text.length; ++i) {
        const c = text[i];
        if(is_mention(text, i)) {
            if(Object.keys(part).length === 0) part = {type: "mention", value: {}};
            else if(part.type == "text") {
                if(parts.length) {
                    const part_last = parts[parts.length-1];
                    if(part_last.type == "mention" && part_last.value.type == ID_Type.MESSAGE) part.value = part.value.trimStart();
                }
                parts.push(part);
                part = {type: "mention", value: {}}
            }

            if(part.type == "mention") {
                const ID_LEN = 26;
                const type = type_from_sym(text[i+1]);
                const id = text.substring(i+2, i+ID_LEN);
                part.value = {id, type};
                parts.push(part);
                part = {};
                i += ID_LEN;
            }
        }
        else {
            if(Object.keys(part).length === 0) part = {type: "text", value: ""};
            if(part.type == "text") {
                part.value += c;
            }
        }
    }

    if(Object.keys(part).length != 0) {
        if(part.type == "text") {
            if(parts.length) {
                const part_last = parts[parts.length-1];
                if(part_last.type == "mention" && part_last.value.type == ID_Type.MESSAGE) part.value = part.value.trimStart();
            }
            parts.push(part);
        }
    }
    return parts;
}

const message_line = (line :string, mentions: any) => {
    let parts = message_parts(line);

    let elem_line = document.createElement("div");
    elem_line.className = "line";

    parts.map((part, i) => {
        let elem_part = document.createElement("div");
        elem_part.className = "part";

        switch(part.type) {
            case "text": {
                elem_part.classList.add("text");
                elem_part.innerHTML = text_parse_link(part.value);
            } break;
            case "mention": {
                elem_part.classList.add("mention");

				let object = null;
				if(part.value.type in mentions && part.value.id in mentions[part.value.type]) object = mentions[part.value.type][part.value.id];
				if(!object) elem_part.classList.add("not_found");

                switch(part.value.type) {
                    case ID_Type.ANON: {
                        elem_part.classList.add("anon");
                        if(object) elem_part.appendChild(Author_Name(object, {profile: "right", mention: true})!);
						else elem_part.textContent = "<anon not found>";
                    } break;
                    case ID_Type.PROFILE: {
                        elem_part.classList.add("profile");
						if(object) elem_part.appendChild(Author_Name(object, {profile: "right", mention: true})!);
						else elem_part.textContent = "<profile not found>";
                    } break;
                    case ID_Type.MESSAGE: {
                        elem_part.classList.add("msg");
						if(object) {
							elem_part.appendChild(Author_Name(object.author, {profile: "right", mention: true})!);
							let msg_text_elem = document.createElement("div");
							msg_text_elem.className = "text";
							msg_text_elem.textContent = object.text;
							elem_part.appendChild(msg_text_elem);
						}
						else {
							elem_part.textContent = "<message not found>";
						}
                    } break;
					case ID_Type.UPLOAD: {
						elem_part.classList.add("upload");
						if(object) {
							let content = content_get(object.content);

							let elem_thumb = document.createElement("div"); elem_part.appendChild(elem_thumb);
							elem_thumb.className = "thumb";
							elem_thumb.appendChild(Content(content, {size: "32", thumb: true})!);

							let elem_name = document.createElement("div"); elem_part.appendChild(elem_name);
							elem_name.className = "name";
							elem_name.textContent = object.name;

							let elem_date = document.createElement("div"); elem_part.appendChild(elem_date);
							elem_date.className = "date";
							elem_date.textContent = date_str(new Date(object.date_uploaded));
						}
						else {
							elem_part.textContent = "<upload not found>";
						}
					} break;
					default: {
						throw Error("Invalid");
					}
                }
            } break;
        }
        elem_line.appendChild(elem_part);
    })
    return(elem_line);
}

export const message_new = (message :any) => {
    let elem = document.createElement("div");
    elem.className = "message";
    if(message.mentions_you) elem.classList.add("mention");
    elem.setAttribute("rc", `menu:message`);
    elem.setAttribute("id", `msg/${message.id}`);
    elem.setAttribute("date", `${new Date(message.date_created).getTime()}`);

    const content_on_click = (index :number) => {
        if(!views.modal.gallery) views.modal.gallery = new Gallery();
        views.modal.gallery.contents_set(message.contents, "upload", index, message);
        views.modal.gallery.open();
    }

    if(message.text) {
        const message_lines = message.text.split("\n");
        let elem_text = document.createElement("div");
        elem_text.className = "text selectable";

        message_lines.map((line: any) => {
            let elem_line = message_line(line, message.mentions);
            if(line[0] == ">") elem_line.classList.add("greentext");
            elem_text.appendChild(elem_line);
        })
        elem.appendChild(elem_text);
    }
    if(message.contents && message.contents.length) {
        /* Filter existing */
        message.contents = message.contents.filter((media :any) => !!media);
        if(!message.contents.length) return(elem);

        let elem_contents = document.createElement("div");
        elem_contents.className = "contents";
        elem.appendChild(elem_contents);

        /* Filter files and media */
        let content_files :string[] = [];
        let content_media :string[] = [];
        message.contents.map((content_id :any) => {
			let content = content_get(upload_get(content_id).content);
			let is_media = ["image","video"].includes(content.type.split("/")[0]);
			if(is_media) content_media.push(content_id)
			else content_files.push(content_id)
		});

        if(content_files.length) {
            /* Files */
        }

        if(content_media.length > 0) {
            const MAX_WIDTH = 400;
            const MAX_HEIGHT = 440;
        
            // Max Width changes with container width, max height doesn't
            let padding_right = 20;
            let width_around = 12*2 + 36 + padding_right;
            let max_width = state.view.main.width > MAX_WIDTH ? MAX_WIDTH : state.view.main.width - width_around;

            let topbar_height = 36;
            let message_input_height = 50;
            let some_other_shit = 100;
            //let max_height = 400;
            //let max_height = window.innerHeight - topbar_height - message_input_height - some_other_shit; 
            let max_height = max_width + (max_width * (2/4));

			let media_chunk_count = Math.ceil(content_media.length/10);
			for(let media_chunk_i = 0; media_chunk_i < media_chunk_count; ++media_chunk_i) {
				let media_chunk :any[] = [];
				if(media_chunk_i == media_chunk_count-1) media_chunk = content_media.slice(10*media_chunk_i);
				else media_chunk = content_media.slice(10*media_chunk_i, 10*(media_chunk_i+1));

				if(media_chunk.length == 1) {
					const upload = upload_get(media_chunk[0]);
					const media = content_get(upload.content);

					let ar = media.height / media.width;
					let width = media.width;
					let height = media.height;
				
					if(height > max_height) {
						height = max_height;
						width = max_height / ar;
					}
					if (width >= max_width) {
						width = max_width;
						height = max_width * ar;
					}

					let elem_media = document.createElement("div"); elem_contents.appendChild(elem_media);
					elem_media.className = "media single";
					elem_media.style.width = `${width}px`;
					elem_media.style.height = `${height}px`;
					elem_media.setAttribute("rc", `menu:upload/${upload.id}/right`);

					if(media.type.split("/")[0] == "video") {
						let elem_video_info = document.createElement("div");
						elem_media.appendChild(elem_video_info);
						elem_video_info.className = "video_info";
						elem_video_info.innerHTML = Icon.Video.Play;
					}
					elem_media.appendChild(Content(media, {size: "512", thumb: true})!);
					elem_media.onclick = () => content_on_click(0);
				}
				else {
					let elem_album = document.createElement("div"); elem_contents.appendChild(elem_album);
					elem_album.className = "album";

					const spacing = 2;
					const sizes :Size[] = media_chunk.map((media_id :any) :Size => {
						let content = content_get(upload_get(media_id).content);
						return {w: content.width, h: content.height}
					});

					const layouter = new Layouter(sizes, 0, max_width, max_height, spacing);
					const layout = layouter.layout();

					const w_item = layout.find((item) => item.s & RectSide.R);
					const w = w_item!.geo.w + w_item!.geo.x;

					const h_item = layout.find((item) => item.s & RectSide.B);
					const h = h_item!.geo.h + h_item!.geo.y;

					elem_album.style.width = w + "px";
					elem_album.style.height = h + "px";

					layout.forEach(({geo, s}, idx) => {
						const upload = upload_get(media_chunk[idx]);
						const media = content_get(upload.content);

						let elem = document.createElement("div"); elem_album.appendChild(elem);
						elem.className = "media";
						elem.setAttribute("rc", `menu:upload/${upload.id}/right`);

						if(media.type.split("/")[0] == "video") {
							let elem_video_info = document.createElement("div");
							elem.appendChild(elem_video_info);
							elem_video_info.className = "video_info";
							elem_video_info.innerHTML = Icon.Video.Play;
						}
						elem.appendChild(Content(media, {size: "512", thumb: true})!);

						let index = (10*media_chunk_i) + idx;
						elem.onclick = () => content_on_click(index);

						elem.style.width = (geo.w / w*100) + "%";
						elem.style.height = (geo.h / h*100) + "%";
						elem.style.top = (geo.y / h*100) + "%";
						elem.style.left = (geo.x / w*100) + "%";

						if(s & RectSide.L && s & RectSide.T) elem.style.borderTopLeftRadius = "inherit";
						if(s & RectSide.L && s & RectSide.B) elem.style.borderBottomLeftRadius = "inherit";
						if(s & RectSide.R && s & RectSide.T) elem.style.borderTopRightRadius = "inherit";
						if(s & RectSide.R && s & RectSide.B) elem.style.borderBottomRightRadius = "inherit";
					})
				}
			}
        }
    }
    
    if(message.flags & MSG_FLAG.DELETE) elem.classList.add("deleted");
    return(elem);

}

export const message_block_new = (author: any, date :string) => {
    let elem = document.createElement("div");
    elem.className = "block";
    elem.setAttribute("author_is_anon", String(author.is_anon));
    elem.setAttribute("author_id", author.id);

    elem.appendChild(Author_Avatar(author, {size: "64", profile: "right"})!);

    let elem_right = document.createElement("div");
    elem_right.className = "right";
    let elem_author_name_and_date = document.createElement("div");
    elem_author_name_and_date.className = "author_name_and_date";
    let elem_author_name = Author_Name(author, {profile: "right", badges: true});
    elem_author_name_and_date.appendChild(elem_author_name);
    let elem_date = document.createElement("div");
    elem_date.className = "date";
    //elem_date.textContent = TimeStamp(date);
    //elem_date.textContent = `${TimeStamp(date)}, ${date_str(new Date(date))}`;
    elem_date.textContent = `${date_str(new Date(date))}, ${TimeStamp(date)}`;
    elem_author_name_and_date.appendChild(elem_date);

    //elem_author_name_and_date.style.width = `${elem_author_name!.offsetWidth + elem_date.offsetWidth}px`;

    elem_right.appendChild(elem_author_name_and_date);
    elem.appendChild(elem_right);
    return(elem);
}

const elem_date_new = (date :Date) => {
	let elem_date = document.createElement("div");
	elem_date.className = "date";
	elem_date.textContent = date_str(date);
	return(elem_date);
}

export const message_chunk_new = (chunk :any, date_start :number, date_end :number) => {
    let elem :HTMLDivElement = document.createElement("div");
    elem.className = "chunk";
    elem.setAttribute("start", date_start.toString());
    elem.setAttribute("end",   date_end.toString());
	//elem.append(elem_date_new(new Date(date_start)));

    chunk.map((block :any, i :number) => {
		const block_curr = chunk[i+0];

        let elem_block       = message_block_new(block_curr[0].author, block_curr[0].date_created);
        let elem_block_right = elem_block.children[1];

        let all_deleted = true;
        block.map((message :any, i :any) => {
            if(!(message.flags & MSG_FLAG.DELETE)) {
                all_deleted = false;
            }
            let elem_msg = message_new(message);
            elem_block_right.appendChild(elem_msg);
        })

        if(all_deleted) elem_block.classList.add("deleted");
        elem!.appendChild(elem_block);

		/* Possible new date */
		/*
		if(i != chunk.length-1) {
			const block_next = chunk[i+1];
			if(new Date(block_curr[0].date_created).getDay() != new Date(block_next[0].date_created).getDay()) {
				elem.append(elem_date_new(new Date(block_next[0].date_created)));
			}
		}
		*/
    })
    return(elem);
}