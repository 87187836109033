import { socket_send, ACTION } from "../../../../socket";
import { state } from "../../../../state";
import { Profile_Avatar, Profile_Name } from "../../../modular/profile";
import {Page, Page_Type} from "../../page";
import {DateAgo} from "../../../modular/time"

const REPORT_STATE = {
    ACTIVE:     0,
    FULLFILLED: 1,
    DECLINED:   2,
}

const state_str = (state :number) => {
    switch(state) {
        case REPORT_STATE.ACTIVE: return "active";
        case REPORT_STATE.FULLFILLED: return "fullfilled";
        case REPORT_STATE.DECLINED: return "declined";
        default: return "";
    }
}

class Page_Mod_Reports extends Page {
    constructor () {
        super({type: Page_Type.MOD_REPORTS, id: ""});
        if(!state.mod.reports) socket_send(ACTION.MOD_REPORTS_GET);
    }

    new () {
        let elem = document.createElement("div");
        elem.className = "mod_reports";
        if(state.mod.reports) this.update_elem(elem);
        return({e: elem, es: elem, h: 0, s: 0});
    }

    update_elem = (elem :HTMLElement) => {
        elem.innerHTML = "";
        let elem_container = document.createElement("div");
        elem_container.className = "container";

        for(let report_id in state.mod.reports) {
            let report = state.mod.reports[report_id];
            let elem_report = document.createElement("div");
            elem_report.className = "report";
            elem_report.classList.add(state_str(report.state));

            let elem_top = document.createElement("div");
            elem_top.className = "top";
            elem_report.appendChild(elem_top);

            let elem_user = document.createElement("div");
            elem_user.className = "user";
            elem_user.appendChild(Profile_Avatar(report.user, {profile: "right"})!);
            let elem_user_name_date = document.createElement("div");
            elem_user_name_date.className = "name_date";
            elem_user_name_date.appendChild(Profile_Name(report.user, {profile: "right", badges: true})!);
            let elem_date = document.createElement("div");
            elem_date.className = "date";
            elem_date.textContent = DateAgo(report.date_created);
            elem_user_name_date.appendChild(elem_date);
            elem_user.appendChild(elem_user_name_date);
            elem_top.appendChild(elem_user);

            // let elem_buttons = document.createElement("div");
            // elem_buttons.className = "buttons";

            // let elem_button_decline = document.createElement("div");
            // elem_button_decline.className = "button decline";
            // elem_button_decline.textContent = "X";
            // elem_buttons.appendChild(elem_button_decline);

            // let elem_button_accept = document.createElement("div");
            // elem_button_accept.className = "button accept";
            // elem_button_accept.textContent = "V";
            // elem_buttons.appendChild(elem_button_accept);

            // elem_top.appendChild(elem_buttons);

            let elem_bot = document.createElement("div");
            elem_bot.className = "bot";

            let elem_violations = document.createElement("div");
            elem_violations.className = "section violations";

            let elem_violations_header = document.createElement("div")
            elem_violations_header.className = "header";
            elem_violations_header.textContent = "VIOLATIONS";
            elem_violations.appendChild(elem_violations_header);

            let elem_violations_items = document.createElement("div");
            elem_violations_items.className = "items";
            for(let vio_id of report.violations) {
                let vio = state.rules.filter((rule :any) => rule.id == vio_id)[0];
                let elem_vio = document.createElement("div");
                elem_vio.className = "vio";
                elem_vio.textContent = vio.description;
                elem_violations_items.appendChild(elem_vio);
            }
            elem_violations.appendChild(elem_violations_items);
            elem_bot.appendChild(elem_violations);

            if(report.messages && report.messages.length > 0) {
                let elem_messages = document.createElement("div");
                elem_messages.className = "section messages";

                let elem_messages_header = document.createElement("div")
                elem_messages_header.className = "header";
                elem_messages_header.textContent = "messages";
                elem_messages.appendChild(elem_messages_header);

                let elem_messages_items = document.createElement("div");
                elem_messages_items.className = "items";
                for(let vio_id of report.messages) {
                    let vio = state.rules.filter((rule :any) => rule.id == vio_id)[0];
                    let elem_vio = document.createElement("div");
                    elem_vio.className = "vio";
                    elem_vio.textContent = vio.description;
                    elem_messages_items.appendChild(elem_vio);
                }
                elem_messages.appendChild(elem_messages_items);
                elem_bot.appendChild(elem_messages);
            }

            elem_report.appendChild(elem_bot);
            elem_container.appendChild(elem_report);
        }

        elem.appendChild(elem_container);
    }
    
    update () {
        if(!state.mod.reports) return;
        for(let i of Object.keys(this.instances)) this.update_elem(this.instances[i].e);
    }
}

export default Page_Mod_Reports;
