import { state } from "./state";

export const role_get = (role_id :string) => {
	if(!role_id) return(null);
	if(!state.roles || !(role_id in state.roles)) return(null);
	return(state.roles[role_id]);
}

export const profile_get = (profile_id :string) => {
	if(!profile_id) return(null);
	if(!state.profiles || !(profile_id in state.profiles)) return(null);
	return(state.profiles[profile_id]);
}

export const board_get = (board_id :string) => {
	if(!board_id) return(null);
	if(!state.boards || !(board_id in state.boards)) return(null);
	return(state.boards[board_id]);
}

export const thread_get = (thread_id :string) => {
	if(!thread_id) return(null);
	if(!state.threads || !(thread_id in state.threads)) return(null);
	return(state.threads[thread_id]);
}

export const channel_get = (channel_id :string) => {
	if(!channel_id) return(null);
	if(!state.channels || !(channel_id in state.channels)) return(null);
	return(state.channels[channel_id]);
}

export const content_get = (content_id :string) => {
	if(!content_id) return(null);
	if(!state.contents || !(content_id in state.contents)) return(null);
	return(state.contents[content_id]);
}

export const upload_get = (upload_id :string) => {
	if(!upload_id) return(null);
	if(!state.uploads || !(upload_id in state.uploads)) return(null);
	return(state.uploads[upload_id]);
}

export const message_get = (message_id :string) => {
	if(!message_id) return(null);
	if(!state.messages || !(message_id in state.messages)) return(null);
	return(state.messages[message_id]);
}

export const emoji_set_get = (emoji_set_id :string) => {
	if(!emoji_set_id) return(null);
	if(!state.emoji_sets || !(emoji_set_id in state.emoji_sets)) return(null);
	return(state.emoji_sets[emoji_set_id]);
}