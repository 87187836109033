import { auth, auth_is_subscriber, auth_is_user, auth_profile } from "../../auth";
import { author_is_me, author_self_other } from "../../author";
import { content_get } from "../../helper";
import { ID_Type } from "../../id";
import { Perms_Manage, auth_perms_get } from "../../permission";
import { nav_to } from "../../router";
import { ACTION, socket_send } from "../../socket";
import { state } from "../../state";
import { views } from "../../view";
import { Page_Type } from "../app/page";
import { auth_assert } from "../modal/auth_quick";
import {menu_close} from "../modal/menu";

const upload_download = async (upload :any) => {
	let content = content_get(upload.content)
	const file_path = "/content/" + encodeURI(content.path);
	const file = await fetch(file_path);

	const file_blob = await file.blob();
	const file_url = URL.createObjectURL(file_blob);

	const link = document.createElement('a')
	link.href = file_url
	link.download = upload.name;
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}

const upload_mention_get = (upload :any) => {
	return `<&${upload.id}>`;
}

export const Menu_Upload = (upload :any) => {
    let elem = document.createElement("div");
    elem.className = "content";

	if(auth_is_user()) {
		let perms = auth_perms_get();

		let elem_block_top = document.createElement("div");
		elem_block_top.className = "block top";
		elem.appendChild(elem_block_top);

		let window = state.windows[state.window_selected];
		let tab = state.tabs[window.tabs[window.tab_selected]];
		let page_id = tab.history[tab.pos];

		if(page_id.type != Page_Type.PROFILE_CONTENT) {
			let elem_save = document.createElement("div");
			elem_save.className = "tab save disabled";
			elem_save.textContent = "Save";
			if(perms[author_self_other(upload.author)].obj.content & Perms_Manage.SAVE) {
				elem_save.classList.remove("disabled");
				elem_save.onclick = () => {
					socket_send(ACTION.PROFILE_ITEM_SAVE, {profile_id: auth_profile().id, id: {type: ID_Type.UPLOAD, id: upload.id}});
					menu_close();
				}
			}
			else {

			}
			elem_block_top.appendChild(elem_save);
		}

		if(perms[author_self_other(upload.author)].obj.content & Perms_Manage.SAVE) {
			let elem_download = document.createElement("div");
			elem_download.className = "tab download";
			elem_download.textContent = "Download";
			elem_download.onclick = () => auth_assert(() => {
				//if(perms[author_self_other(upload.author)].obj.content & Perms_Manage.DOWNLOAD)  {
				if(auth_is_subscriber()) {
					upload_download(upload);
				}
				else {
					if(state.modal == "gallery") {
						let gallery = views.modal.gallery;
						gallery.close();
					}
					nav_to({type: Page_Type.SUBSCRIPTION, id: ""});
				}
				menu_close();
			});
			elem_block_top.appendChild(elem_download);
		}
	}

    /* Block Bot */
    let elem_block_bot = document.createElement("div");
    elem_block_bot.className = "block bot";

    let elem_copy_id = document.createElement("div");
    elem_copy_id.className = "tab copy_id";
    elem_copy_id.textContent = "Copy ID";
    elem_copy_id.onclick = () => {
        navigator.clipboard.writeText(upload_mention_get(upload));
        menu_close();
    }
    elem_block_bot.appendChild(elem_copy_id);

    elem.appendChild(elem_block_bot);
    return(elem);
}