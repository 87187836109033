import { Media } from "./media";

export const Content = (content :any, options :any = {}) => {
	if(!content) return(null);

	let result = null;
	switch(content.type.split("/")[0]) {
	case "image":
	case "video": {
		result = Media(content, options);
	} break;
	}

	return(result);
}