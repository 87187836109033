import { auth_is_guest } from "../../auth";
import {state} from "../../state"
import { Auth_Quick, auth_assert } from "../modal/auth_quick";

export const dropzone_new = (elem_parent :any, input :any) => {
    let elem_dropzone = document.createElement("div");
    elem_parent.appendChild(elem_dropzone);
    elem_dropzone.className = "dropzone";

    let elem_upload = document.createElement("div");
    elem_dropzone.appendChild(elem_upload);
    elem_upload.className = "upload";
    elem_upload.textContent = "UPLOAD FILES";

    /* Dropzone */
    let counter = 0;
    elem_parent.ondragenter = (e :any) => {
        e.preventDefault();
        counter += 1;
        if(counter == 1) elem_dropzone.style.display = "block";
    }

    elem_parent.ondragleave = () => {
        counter -= 1;
        if(counter == 0) elem_dropzone.style.display = "none";
    }

    elem_parent.ondragover = (e :any) => {
        e.preventDefault();
    }

    elem_parent.ondrop = (e :DragEvent) => {
        e.preventDefault();

        counter = 0;
        elem_dropzone.style.display = "none";
        if(!e.dataTransfer || !e.dataTransfer.files || !e.dataTransfer.files.length) return;

		auth_assert(() => {
            for(let file of e.dataTransfer.files) {
                input.file_add(file);
            }
		});
    }
}