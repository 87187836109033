import { auth, auth_is_user } from "./auth"

export const anon_is_me = (anon :any) => {
	return(auth_is_user() && anon && anon.is_you);
}

export const profile_is_me = (profile_id :string) => {
	return(auth_is_user() && profile_id && Object.keys(auth.user.profiles).includes(profile_id));
}

export const author_is_me = (author :any) => {
	return(auth_is_user() && author && (author.is_anon ? anon_is_me(author) : profile_is_me(author.id)));
}

export const author_self_other = (author :any) => {
	return(author_is_me(author) ? "self" : "other");
}

export const author_same = (a :any, b: any) => {
	if(a.is_anon == b.is_anon) {
		if(a.id == b.id) return(true);
		if(a.is_anon && (a.is_you && b.is_you)) return(true);
	}
	else {
		let anon = a.is_anon ? a : b;
		let user = a.is_anon ? b : a;
		if(anon.is_you && Object.keys(auth.user.profiles).includes(user.id)) return(true);
	}
	return(false);
}