import { Status, state } from "../state";
import { auth, auth_profile } from "../auth";
import { Page_Type, page_id_eql } from "../view/app/page";
import { views } from "../view";
import Page_Channel from "../view/app/page/channel";
import { nav_to } from "../router";
import { ACTION, socket_send } from "../socket";
import { anon_is_me, profile_is_me } from "../author";

export const participants_me_you_get = (participants :any) => {
	let result = {
		me: null,
		you: null,
	}

	participants.map((participant: any) => {
		if(participant.is_anon) {
			if(anon_is_me(participant)) result.me = participant;
			else result.you = participant;
		}
		else {
			if(profile_is_me(participant.id)) result.me = participant;
			else result.you = participant;
		}
	})

	return(result);
}

export const participants_me_you_match = (participants :any, me :any, you :any) => {
	let me_found = false;
	let you_found = false;

	participants.map((participant: any) => {
		if(participant.is_anon) {
			if(me.is_anon  && (me.id  == participant.id || (me.target.type  == participant.target.type && me.target.id  == participant.target.id))) me_found = true;
			if(you.is_anon &&  you.id == participant.id) you_found = true;
		}
		else {
			if(!me.is_anon  && me.id  == participant.id) me_found = true;
			if(!you.is_anon && you.id == participant.id) you_found = true;
		}
	})

	if(me_found && you_found) return(true);
	else return(false);
}

export const channel_tmp_yield = (me :any, you: any, look_for = true) => {
	/* This function is only use for temporary channels without messages */
    if(!state.channels_tmp) state.channels_tmp = {};

	let channel :any = {};
	channel.id = state.channel_tmp_count;
	channel.me = me;
	channel.you = you;
	channel.status = Status.LOADED_PARTLY;
	channel.messages = null;
	channel.messages_status = {b: Status.NULL, a: Status.NULL};
	channel.participants = [channel.me, channel.you];
	channel.is_replaced = false;
	state.channels_tmp[channel.id] = channel;
	state.channel_tmp_count += 1;

	/* Bar left */
	views.page.channel_tmp[channel.id] = new Page_Channel(channel.id, true, look_for);
	//views.bar_left.add_tmp_channel(channel.id);
	views.bar_left.update_user_channels();

	return(channel);
}

export const channel_tmp_replace = (channel_tmp_id :string, channel_id :string) => {
	state.channels_tmp[channel_tmp_id].is_replaced = true;
	views.bar_left.rem_tmp_channel(channel_tmp_id);

	let window_state = state.windows[state.window_selected];
	let tab = state.tabs[window_state.tabs[window_state.tab_selected]];

	let page_id_chan     = {type: Page_Type.CHANNEL,     id: channel_id};
	let page_id_chan_old = {type: Page_Type.CHANNEL_TMP, id: channel_tmp_id};

	/* Update Windows containing this page */
	for(let win_i = 0; win_i < state.windows.length; ++win_i) {
	    let window = state.windows[win_i];
	    let tab = state.tabs[window.tabs[window.tab_selected]];
	    let page_id = tab.history[tab.pos];

		if(page_id_eql(page_id, page_id_chan_old)) {
			page_id.id = page_id_chan.id;
			page_id.type = page_id_chan.type;
	        views.window[win_i].update_page();
			if(win_i == state.window_selected) views.bar_left.update_select();
		}
	}

	/* Update all tabs history */ 
	for(let tab_i = 0; tab_i < state.tabs.length; ++tab_i) {
	    let tab = state.tabs[tab_i];
	    for(let page_id of tab.history) {
			if(page_id_eql(page_id, page_id_chan_old)) {
				page_id.id = page_id_chan.id;
				page_id.type = page_id_chan.type;
			}
	    }
	}
}

export const channels_sort = () => {
	if(!state.channels_sorted) state.channels_sorted = [];
	state.channels_sorted = Object.keys(state.channels).sort((a_id :string, b_id :string) => {
		let a = state.channels[a_id];
		let b = state.channels[b_id];
		return(new Date(b.date_bumped).getTime() - new Date(a.date_bumped).getTime());
	})
}


export const channel_page_find = (me :any, you :any) => {
    let result = null;

    let channel_arrays :any[] = [
		{
			list: state.channels_tmp,
			page_type: Page_Type.CHANNEL_TMP,
		},
		{
			list: state.channels,
			page_type: Page_Type.CHANNEL,
		},
	];

	for(let channel_array of channel_arrays) {
		if(!channel_array.list) continue;
		for(let channel_id of Object.keys(channel_array.list)) {
			let channel = channel_array.list[channel_id];
			if(participants_me_you_match(channel.participants, me, you)) {
				result = {id: channel.id, type: channel_array.page_type};
				break;
			}
		}
		if(result) break;
	}

    return(result);
}

export const channel_page_find_or_yield = (me :any, you :any, look_for = true) => {
    let result = channel_page_find(me, you);
    if(!result) {
		/* New Temporary */
		let channel = channel_tmp_yield(me, you, look_for);
		result = {id: channel.id, type: Page_Type.CHANNEL_TMP};
	}
    return(result);
}