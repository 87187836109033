import { content_get } from "../../helper";
import { Content } from "./content";

export enum Notif_Type {
    THREAD_MSG,
    CHANNEL_MSG,
    ITEM_SAVED,

    INFO,
    ERROR,
    SUCESS,
}

export const Notif = (type :any, time :number, body: any = {}) => {
    let elem_notification = document.querySelector("#app > #notifications");
    if(!elem_notification) {
        elem_notification = document.createElement("div");
        elem_notification.id = "notifications";

        let elem_app = document.querySelector("#app")!;
        elem_app.appendChild(elem_notification);
    }

    let elem = document.createElement("div");
    elem.className = "notif";
	elem.onclick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if("on_click" in body) body.on_click();
		elem.outerHTML = "";
	}

	let elem_details = document.createElement("div"); elem.appendChild(elem_details);
	elem_details.className = "details";
	
	let elem_img = document.createElement("div"); elem_details.appendChild(elem_img);
	elem_img.className = "img";
	elem_img.style.display = "none";

    let elem_text = document.createElement("div"); elem_details.appendChild(elem_text);
    elem_text.className = "text";

    let elem_text_top = document.createElement("div"); elem_text.appendChild(elem_text_top);
    elem_text_top.className = "top";

    let elem_text_bot = document.createElement("div"); elem_text.appendChild(elem_text_bot);
	elem_text_bot.className = "bot";

    switch(type)
    {
	case Notif_Type.INFO: {
		elem.classList.add("info"); 
		elem_text_top.textContent = body.msg.top;
		elem_text_bot.textContent = body.msg.bot;
	} break;
	case Notif_Type.ERROR: {
		elem.classList.add("error"); 
		elem_text_top.textContent = "ERROR";
		elem_text_bot.textContent = body.msg;
	} break;
	case Notif_Type.SUCESS: {
		elem.classList.add("sucess"); 
		elem_text_top.textContent = "SUCCESS";
		elem_text_bot.textContent = body.msg;
	} break;
	case Notif_Type.THREAD_MSG: {
		elem.classList.add("thread_msg"); 
		elem_text_top.textContent = "New message in thread:";
		elem_text_bot.textContent = body.msg;
	} break;
	case Notif_Type.CHANNEL_MSG: {
		elem.classList.add("channel_msg"); 
		elem_text_top.textContent = "New message from:";
		elem_text_bot.textContent = body.msg;
	} break;
	case Notif_Type.ITEM_SAVED: {
		elem.classList.add("item_saved");

		let upload = body.item.ret;
		let content = content_get(upload.content);

		elem_img.style.display = "block";
		elem_img.appendChild(Content(content, {size: "32", thumb: true})!);
		elem_text_top.textContent = `Saved ${upload.name}`;
	} break;
    }

    let elem_bar = document.createElement("div");
    elem_bar.className = "bar";
	elem_bar.style.animation = `countdown ${time}ms linear`;
    elem.appendChild(elem_bar);

    elem_notification.insertBefore(elem, elem_notification.children[0]);
    setTimeout(() => { elem.outerHTML = ""; }, time);
}