import Window from "./window"

import {views, view_new} from "../../view";
import {state} from "../../state";

class Main {
    elem :HTMLElement;

    constructor () {
        this.elem = document.createElement("div");
        this.elem.id = "main";
        if("windows" in state) {
            this.init_windows();
            this.update();
        }
    }

    init_windows () {
        for(let window_i = 0; window_i < state.windows.length; ++window_i) {
            if(!(window_i in views.window)) views.window[window_i] = new Window(window_i);
        }
    }

    elem_from_layout (layout :any) {
        let elem :HTMLElement;
        if(Number.isInteger(layout)) elem = views.window[layout].elem;
        else {
            elem = document.createElement("div");
            elem.className = "layout";
            elem.style.display = "flex";
            switch(layout.s) {
                case "v": elem.style.flexDirection = "row"; break;
                case "h": elem.style.flexDirection = "column"; break;
            }

            let elems = layout.w.map((l :any) => this.elem_from_layout(l));
            switch(layout.s) {
                case "v": {
                    elems[0].style.width = (layout.d * 100) + "%";
                    elems[0].style.height = "100%";
                    elems[1].style.width = (100 - (layout.d * 100)) + "%";
                    elems[1].style.height = "100%";
                } break;
                case "h":  {
                    elems[0].style.width = "100%";
                    elems[0].style.height = (layout.d * 100) + "%";
                    elems[1].style.width = "100%";
                    elems[1].style.height = (100 - (layout.d * 100)) + "%";
                } break;
            }

            let border_size = 0;
            elems.map((e :HTMLElement) => {
                if(e.className.includes("window")) {
                    e.style.borderWidth = border_size + "px";
                    e.style.borderStyle = "solid";
                    e.style.width = `calc(${e.style.width} - ${border_size*2}px)`;
                    e.style.height = `calc(${e.style.height} - ${border_size*2}px)`;
                }
                elem.appendChild(e);
            })
        }
        return(elem);
    }

    update () {
        this.elem.innerHTML = "";
        this.elem.append(this.elem_from_layout(state.layout));
    }
}

export default Main;