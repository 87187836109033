import App from "./app";

import Window from "./view/app/window";
import BarLeft from "./view/app/bar_left";
import BarRight from "./view/app/bar_right";

import Home from "./view/app/page/home";
import Tips from "./view/app/page/tips";
import Rules from "./view/app/page/rules";

export let views :any = {
    app: null,
    main: null,
    bar_left: null,
    bar_right: null,

	modal: {
		repost: null,
		gallery: null,
	},

    tab: {},
    window: {},
    page: {
        admin: {
            members: null,
            channels: null,
        },
        mod: {
            reports: null,
        },

        home: null,
        tips: null,
        rules: null,
		invites: null,
        subscription: null,
        subscription_update: null,

        messages: null,
        emoji_set: {},

		boards: {
			owned: null,
			joined: null,
			official: null,
			public: null,
		},
        settings: {
			account: null,
			profile: null,
			safety: null,
			notifs: null,
		},

        board: {},
        thread: {},
        channel: {},
        channel_tmp: {},
		profile_content: {},
    },
    panel: {
        boards: null,
        board: {},
        thread: {},
        channel: {},
    }
};