let time_s = 1000;
let time_m = 1000 * 60;
let time_h = 1000 * 60 * 60;
let time_D = 1000 * 60 * 60 * 24;
let time_W = 1000 * 60 * 60 * 24 * 7;
let time_M = 1000 * 60 * 60 * 24 * 30;

export const Time = (iso_date :string) :string => {
    let date = new Date(iso_date);
    let hour = date.getHours().toString(); if(hour.length < 2) hour = `0${hour}`;
    let minute = date.getMinutes().toString(); if(minute.length < 2) minute = `0${minute}`;
    let time = `${hour}:${minute}`;
    return time;
}

export const TimeStamp = (iso_date :string) :string => {
	return Time(iso_date);
}

export const TimeAgo = (iso_date :string) :string => {
    return DateAgo(iso_date);

    /*
    let date = new Date(iso_date);
    let time_pass = new Date().getTime() - date.getTime();

    let time = "";
    if(time_pass > time_D) {
        let hour = date.getHours().toString(); if(hour.length < 2) hour = `0${hour}`;
        let minute = date.getMinutes().toString(); if(minute.length < 2) minute = `0${minute}`;
        time = `${hour}:${minute}`;
    }
    else {
        let date_ago = new Date(time_pass);
        let hour = date_ago.getHours();
        let minute = date_ago.getMinutes();
        if(hour > 0) time = `${hour}h ago`;
        else time = `${minute}m ago`;
    }

    return time;
    */
}

export const date_str = (date :Date) :string => {
	let result = "";

	/* Jun 14, 2017 */

	var month = new Intl.DateTimeFormat("en-US", { month: "short" }).format;
	result += month(date);
	result += " ";

	var day = new Intl.DateTimeFormat("en-US", { day: "numeric" }).format;
	result += day(date);
	result += ", ";

	var year = new Intl.DateTimeFormat("en-US", { year: "numeric"}).format;
	result += year(date);

	return(result);
}

export const DateAgo = (iso_date :string) :string => {
    let date = new Date(iso_date);

    let time = "";
    let time_pass = new Date().getTime() - date.getTime();

    if(time_pass > time_M) {
        let month = date.getMonth().toString(); if(month.length < 2) month = `0${month}`;
        let day = date.getDay().toString(); if(day.length < 2) day = `0${day}`;
        let year = date.getFullYear().toString().substring(2); 
        time = `${month}/${day}/${year}`;
    }
    else {
        let date_ago = new Date(time_pass);
        if(time_pass > time_W) {
            let week_pass = Math.floor(time_pass / time_W);
            time = `${week_pass}w ago`;
        }
        else if(time_pass > time_D) {
            let day_pass = Math.floor(time_pass / time_D);
            time = `${day_pass}d ago`;
        }
        else if(time_pass > time_h) {
            let hour_pass = Math.floor(time_pass / time_h);
            time = `${hour_pass}h ago`;
        }
        else {
            let minute_pass = Math.floor(time_pass / time_m);
            time = `${minute_pass}m ago`;
        }
    }

    return time;
}