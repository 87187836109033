import { ACTION, socket_send } from "../../socket";
import { views } from "../../view";
import { Page_Type } from "../app/page";
import {menu_close} from "../modal/menu";
import {auth_perms_get, Perms_Manage} from "../../permission";
import {state} from "../../state";
import { message_block_new } from "../modular/message";
import { auth, auth_is_user } from "../../auth";
import { author_is_me, author_self_other } from "../../author";
import { ID_Type } from "../../id";

const msg_mention_get = (msg :any) => {
    return `<%${msg.id}>`;
}

export const Menu_Message = (message :any) => {
    let elem = document.createElement("div");
    elem.className = "message";

    /* Block Power */
    if(auth_is_user()) {
		let view = null;
		if(message.target.type == ID_Type.THREAD) view = views.page.thread[message.target.id];
		else if(message.target.type == ID_Type.CHANNEL) view = views.page.channel[message.target.id];

		let options :any = {};
		if(message.target.type == ID_Type.THREAD) {
			options.thread = message.target.id;
			options.board = state.threads[message.target.id].board;
		}
		if(message.target.type == ID_Type.BOARD) options.board = message.target.id;
		let perms = auth_perms_get(options);

        if(message.target.type == ID_Type.THREAD || message.target.type == ID_Type.CHANNEL) {
            let elem_interact = document.createElement("div");
            elem_interact.className = "block interact";
            let elem_reply = document.createElement("div");
            elem_reply.className = "tab reply";
            elem_reply.textContent = "Reply";
            elem_reply.onclick = () => {
                let msg_input = view!.instances[state.window_selected].msg_input;
                let v = msg_input.elem_text.value;
                if(v != "" && v[v.length-1] != "\n") msg_input.text_insert("\n");
                msg_input.text_insert(`${msg_mention_get(message)}\n`);

                menu_close();
            }
            elem_interact.appendChild(elem_reply);
            elem.appendChild(elem_interact);
        }

        /* Destructive */
        let elem_block_power = null;
        if(perms[author_self_other(message.author)].obj.message & Perms_Manage.EDIT) {
            if(!elem_block_power) {
                elem_block_power = document.createElement("div");
                elem_block_power.className = "block power";
                elem.appendChild(elem_block_power);
            }

            let elem_edit = document.createElement("div");
            elem_edit.className = "tab caution edit";
            elem_edit.textContent = "Edit";
            elem_edit.onclick = () => {
                let msg_input = view!.instances[state.window_selected].msg_input;
                msg_input.message_edit(message);

                menu_close();
            }
            elem_block_power.appendChild(elem_edit);
            elem.appendChild(elem_block_power);
        }

        if(perms[author_self_other(message.author)].obj.message & Perms_Manage.DELETE) {
            if(!elem_block_power) {
                elem_block_power = document.createElement("div");
                elem_block_power.className = "block power";
                elem.appendChild(elem_block_power);
            }

            let elem_delete = document.createElement("div");
            elem_delete.className = "tab caution delete";
            elem_delete.textContent = "Delete";
            elem_delete.onclick = () => {
				socket_send(ACTION.MESSAGE_DEL, {message_id: message.id});
                menu_close();
            }
            elem_block_power.appendChild(elem_delete);
        }
    }

    /* Block Bot */
    let elem_block_bot = document.createElement("div");
    elem_block_bot.className = "block bot";

    let elem_copy_id = document.createElement("div");
    elem_copy_id.className = "tab copy_id";
    elem_copy_id.textContent = "Copy ID";
    elem_copy_id.onclick = () => {
        navigator.clipboard.writeText(msg_mention_get(message));
        menu_close();
    }
    elem_block_bot.appendChild(elem_copy_id);

    elem.appendChild(elem_block_bot);

    return(elem);
}
