import { ID_Type } from "../../../id";
import { socket_send, ACTION } from "../../../socket";
import { state } from "../../../state";
import { Page, Page_Type } from "../page";
import {Icon} from "../../modular/icon";
import { Notif, Notif_Type } from "../../modular/notification";
import { ADDRESS_WEBSITE } from "../../../config";
import { CONST } from "../../../global";
import { auth_assert } from "../../modal/auth_quick";
import { Invite } from "../../modal/invite";

class Page_Invites extends Page {
    constructor () {
        super(({type: Page_Type.INVITES, id: ""}));
    }

    new () {
        let elem = document.createElement("div");
        elem.className = "invites";

        socket_send(ACTION.INVITES_GET);
        this.update_elem(elem);

        return({e: elem, es: elem, h: 0, s: 0});
    }

    update_elem = (elem :HTMLElement) => {
        elem.innerHTML = "";

        let elem_container = document.createElement("div");
        elem_container.className = "container";
        elem.appendChild(elem_container);

        if(!state.invites) {
            let elem_nothing = document.createElement("h2");
            elem_nothing.className = "nothing";
            elem_nothing.textContent = "There are no invites";
            elem_container.appendChild(elem_nothing);
        }
        else {
            let elem_invite_create = document.createElement("div");
            elem_invite_create.className = "invite_create";
            elem_invite_create.textContent = "New Invite";
			elem_invite_create.onclick = () => {
				auth_assert( Invite({type: ID_Type.WEBSITE, id: ""}))
			}
            elem_container.appendChild(elem_invite_create);

            let elem_invite_list = document.createElement("div");
            elem_invite_list.className = "invite_list";

            let elem_invite_list_info = document.createElement("div");
            elem_invite_list_info.className = "info invite";

            let elem_invite_list_info_text = document.createElement("div");
            elem_invite_list_info_text.className = "text";
            let elem_invite_list_info_id = document.createElement("div");
            elem_invite_list_info_id.className = "id";
            elem_invite_list_info_id.textContent = "ID";
            elem_invite_list_info_text.appendChild(elem_invite_list_info_id);

            let elem_invite_list_info_to = document.createElement("div");
            elem_invite_list_info_to.className = "to";
            elem_invite_list_info_to.textContent = "TO";
            elem_invite_list_info_text.appendChild(elem_invite_list_info_to);
            elem_invite_list_info.appendChild(elem_invite_list_info_text);

            let elem_invite_list_info_numbers = document.createElement("div");
            elem_invite_list_info_numbers.className = "numbers";
            let elem_invite_list_info_clicks = document.createElement("div");
            elem_invite_list_info_clicks.className = "clicks";
            elem_invite_list_info_clicks.textContent = "CLICKS";
            elem_invite_list_info_numbers.appendChild(elem_invite_list_info_clicks);

            let elem_invite_list_info_registers = document.createElement("div");
            elem_invite_list_info_registers.className = "registers";
            elem_invite_list_info_registers.textContent = "REGISTERS"
            elem_invite_list_info_numbers.appendChild(elem_invite_list_info_registers);

            let elem_invite_list_info_del = document.createElement("div");
            elem_invite_list_info_del.className = "del";
            elem_invite_list_info_del.textContent = "DEL";
            elem_invite_list_info_numbers.appendChild(elem_invite_list_info_del);
            elem_invite_list_info.appendChild(elem_invite_list_info_numbers);
            elem_invite_list.appendChild(elem_invite_list_info);

            for(let invite of state.invites) {
                let elem_invite = document.createElement("div");
                elem_invite.className = "item invite";

                let elem_invite_text = document.createElement("div");
                elem_invite_text.className = "text";

                let elem_invite_id = document.createElement("div");
                elem_invite_id.className = "id";
                //elem_invite_id.innerHTML = `${Icon.Copy}<span>${invite.link}<span/>`;
                elem_invite_id.innerHTML = `${invite.link}`;
                elem_invite_id.onclick = () => {
					let link = `${ADDRESS_WEBSITE}/invite/${invite.link}`;
                    navigator.clipboard.writeText(link);
                    Notif(Notif_Type.INFO, 5000, {msg: {top: "Link copied to clipboard", bot: link}});
                }
                elem_invite_text.appendChild(elem_invite_id);

                let elem_invite_to = document.createElement("div");
                elem_invite_to.className = "to";
                let to_text = "";
                switch(invite.target.type) {
                    case ID_Type.WEBSITE: to_text = `${CONST.WEBSITE_TITLE}`; break;
                    case ID_Type.BOARD:   to_text = `$ ${invite.target_ret.name}`; break;
                    case ID_Type.THREAD:  to_text = `# ${invite.target_ret.subject}`; break;
                }
                elem_invite_to.textContent = to_text;
                elem_invite_text.appendChild(elem_invite_to);
                elem_invite.appendChild(elem_invite_text);

                let elem_invite_numbers = document.createElement("div");
                elem_invite_numbers.className = "numbers";

                let elem_invite_clicks = document.createElement("div");
                elem_invite_clicks.className = "clicks";
                elem_invite_clicks.textContent = invite.clicks;
                elem_invite_numbers.appendChild(elem_invite_clicks);

                let elem_invite_registers = document.createElement("div");
                elem_invite_registers.className = "registers";
                elem_invite_registers.textContent = invite.registers;
                elem_invite_numbers.appendChild(elem_invite_registers);

                let elem_invite_del = document.createElement("div");
                elem_invite_del.className = "del";
                elem_invite_del.textContent = "X";
                elem_invite_numbers.appendChild(elem_invite_del);
                elem_invite.appendChild(elem_invite_numbers);

                elem_invite_list.appendChild(elem_invite);
            }
            elem_container.appendChild(elem_invite_list);

            let elem_points = document.createElement("div");
            elem_points.className = "points";
            elem_points.textContent = `Current invite points: ${state.invite_points}`;
            elem_container.appendChild(elem_points);

            let elem_goal = document.createElement("div");
            elem_goal.className = "goal";
            elem_goal.innerHTML = `Invite at least 20 new people (registers) to earn a free subscription.<br>TIP: Post the link in group chats.`;
            elem_container.appendChild(elem_goal);
        }
    }

    update () {
        for(let i of Object.keys(this.instances)) this.update_elem(this.instances[i].e);
    }
}

export default Page_Invites;
