import {state} from "../../state";
import {auth, auth_is_user, auth_profile} from "../../auth";
import {author_is_me} from "../../author";
import { anon_name_from_id } from "../modular/anon";

import {Page_ID, Page_Type} from "../app/page";
import {Author_Color, Author_Name, Author_Avatar, author_name_str} from "../modular/author";
import {nav_to} from "../../router"
import {socket_send, ACTION} from "../../socket";
import {views} from "../../view";
import Page_Channel from "../app/page/channel";
import { color_rgb_parse, color_hex_to_rgb, color_int_to_hex, color_rgb_brightness } from "../modular/color";
import { Content } from "../modular/content";
import { date_str } from "../modular/time";
import { ID_Type } from "../../id";
import { channel_find, channel_page_find_or_yield } from "../../model/channel";
import { auth_assert } from "./auth_quick";
import { profile_roles_groups_get } from "../../permission";
import { content_get, upload_get } from "../../helper";

const close_modal = () => {
    if(state.modal != "") {
        let elem_modal = document.querySelector("#modal");
        if(elem_modal) elem_modal.outerHTML = "";
    }
}

export const Profile = (author :any, target :any, elem_in: HTMLElement, v: string = "down", h :string = "right") => {
    close_modal();
    state.modal = "profile";

	let elem_profile = document.createElement("div");
    document.querySelector("#app")!.appendChild(elem_profile);

	let profile_width = 340;
    elem_profile.setAttribute("lc", "stop");
    elem_profile.id = "modal";
    elem_profile.className = "profile";
    elem_profile.style.position = "fixed";
    elem_profile.style.zIndex = "100";

	/* COLORS */
	let color_primary = color_int_to_hex(author.color_primary || Author_Color(author));
	let color_secondary = color_int_to_hex(author.color_secondary) || color_primary;

	let c = color_rgb_parse(color_hex_to_rgb(color_primary!));
	let theme = (!author.is_anon && c.r + c.g + c.b) / 3.0 > 0x80 ? "light" : "dark";
	let color_name = color_int_to_hex(author.color_name) || (theme == "dark" ? "#ffffff" : "#000000");

	let CARD_ANON   = 0;
	let CARD_COLOR  = 1;
	let has_banner = !author.is_anon && !!author.banner;

	let card_kind = -1;
	if(author.is_anon) card_kind = CARD_ANON;
	else card_kind = CARD_COLOR;

	let elem_card = document.createElement("div"); elem_profile.appendChild(elem_card);
	elem_card.className = "profile_card";
	elem_card.classList.add(theme);

	if(card_kind == CARD_ANON) {
		elem_card.style.backgroundColor = `rgb(35 36 40)`;
	}
	else if(card_kind == CARD_COLOR) {
		elem_card.classList.add("colored");
		elem_card.style.backgroundImage = `linear-gradient(${color_primary}, ${color_primary} ${has_banner ? "120px" : "60px"}, ${color_secondary})`;

		let elem_outer = document.createElement("div"); elem_card.appendChild(elem_outer);
		elem_outer.className = "outer";
		elem_outer.style.background = `linear-gradient(${color_primary}, ${color_secondary})`
	}

	let elem_header = document.createElement("div"); elem_card.appendChild(elem_header);
	elem_header.className = "header";

	let elem_banner = document.createElement("div"); elem_header.appendChild(elem_banner);
	if(has_banner) {
		elem_banner.className = "banner-img";
			let content = content_get(upload_get(author.banner).content);
		elem_banner.appendChild(Content(content, {thumb: false, size: "512"})!);
	}
	else {
		elem_banner.className = "banner";
		elem_banner.style.backgroundColor = color_primary;
	}

	let elem_body = document.createElement("div"); elem_card.appendChild(elem_body)
	elem_body.className = "body";

	let elem_body_header = document.createElement("div"); elem_body.appendChild(elem_body_header);
	elem_body_header.className = "header";

	let elem_body_header_logo = document.createElement("div"); elem_body_header.appendChild(elem_body_header_logo);
	elem_body_header_logo.className = "logo";
	elem_body_header_logo.appendChild(Author_Avatar(author, {size: "128"}));

	let elem_body_header_logo_border = document.createElement("div"); elem_body_header_logo.appendChild(elem_body_header_logo_border);
	elem_body_header_logo_border.className = "logo_border";
	if(card_kind == CARD_ANON) {
		elem_body_header_logo_border.style.backgroundColor = `rgb(35 36 40)`;
	}
	else if(card_kind == CARD_COLOR) {
		//elem_body_header_logo_border.style.backgroundColor = `#0000006c`;
		//elem_body_header_logo_border.style.backgroundImage = `linear-gradient(${color_primary}, ${color_primary} ${has_banner ? "120px" : "60px"}, ${color_secondary})`;
		elem_body_header_logo_border.style.backgroundImage = !!has_banner ? color_primary : ``;
	}

	//let elem_body_header_logo_border = document.createElement("div"); elem_body_header_logo.appendChild(elem_body_header_logo_border);
	//elem_body_header_logo_border.className = "logo_border";

	if(!author.is_anon) {
		/*
		let elem_body_header_badges = document.createElement("div"); elem_body_header.appendChild(elem_body_header_badges);
		elem_body_header_badges.className = "badges";

		let elem_body_header_badge = document.createElement("div"); elem_body_header_badges.appendChild(elem_body_header_badge);
		elem_body_header_badge.className = "badge";

		let elem_body_header_badge_img = document.createElement("img"); elem_body_header_badge.appendChild(elem_body_header_badge_img);
		elem_body_header_badge_img.src = "./assets/img/badges/bravery.webp";
		*/
	}

	let elem_body_body = document.createElement("div"); elem_body.appendChild(elem_body_body);
	elem_body_body.className = "body";

	let elem_name = Author_Name(author);
	elem_name.removeAttribute("lc");
	elem_name.removeAttribute("rc");

	let elem_body_body_username = document.createElement("div"); elem_body_body.appendChild(elem_body_body_username);
	elem_body_body_username.className = "username";
	elem_body_body_username.style.color = color_name;
	elem_body_body_username.appendChild(elem_name);

	if(!author.is_anon) {
		elem_body_body.appendChild(document.createElement("hr"));

		/* About Me */
		if(author.about && author.about != "") {
			let elem_body_body_about = document.createElement("div"); elem_body_body.appendChild(elem_body_body_about);
			elem_body_body_about.className = "info";

			let elem_body_body_about_title = document.createElement("div"); elem_body_body_about.appendChild(elem_body_body_about_title);
			elem_body_body_about_title.className = "title";
			elem_body_body_about_title.textContent = "About Me";

			let elem_body_body_about_content = document.createElement("p"); elem_body_body_about.appendChild(elem_body_body_about_content);
			elem_body_body_about_content.textContent = author.about;
		}

		/* Member Since */
		let elem_body_body_member_since = document.createElement("div"); elem_body_body.appendChild(elem_body_body_member_since);
		elem_body_body_member_since.className = "info";

		let elem_body_body_member_since_title = document.createElement("div"); elem_body_body_member_since.appendChild(elem_body_body_member_since_title);
		elem_body_body_member_since_title.className = "title";
		elem_body_body_member_since_title.textContent = "Member Since";

		let elem_body_body_member_since_content = document.createElement("p"); elem_body_body_member_since.appendChild(elem_body_body_member_since_content);
		elem_body_body_member_since_content.textContent = date_str(new Date(author.date_register));

		/* Roles */
		let elem_body_body_roles = document.createElement("div"); elem_body_body.appendChild(elem_body_body_roles);
		elem_body_body_roles.className = "roles";

		let elem_body_body_roles_title = document.createElement("div"); elem_body_body_roles.appendChild(elem_body_body_roles_title);
		elem_body_body_roles_title.className = "title";

		let options :any = {};
		if(target.type == ID_Type.THREAD) {
			options.thread = target.id;
			options.board = state.threads[target.id].board;
		}
		if(target.type == ID_Type.BOARD) options.board = target.id;

		let roles = profile_roles_groups_get(author, options);
		let has_roles = Object.keys(roles).length > 0;
		elem_body_body_roles_title.textContent = has_roles ? "Roles" : "No Roles";

		if(has_roles) {
			for(let role_group in roles) {
				let elem_body_body_roles_group = document.createElement("div"); elem_body_body_roles.appendChild(elem_body_body_roles_group);
				elem_body_body_roles_group.className = "group";

				let elem_body_body_roles_group_title = document.createElement("p"); elem_body_body_roles_group.appendChild(elem_body_body_roles_group_title);
				elem_body_body_roles_group_title.className = "title";

				let elem_body_body_roles_group_title_line1 = document.createElement("p"); elem_body_body_roles_group_title.appendChild(elem_body_body_roles_group_title_line1);
				elem_body_body_roles_group_title_line1.className = "line";

				let elem_body_body_roles_group_title_text = document.createElement("p"); elem_body_body_roles_group_title.appendChild(elem_body_body_roles_group_title_text);
				elem_body_body_roles_group_title_text.className = "text";
				switch(Number(role_group)) {
				case ID_Type.WEBSITE: elem_body_body_roles_group_title_text.textContent = "Website"; break;
				case ID_Type.BOARD: elem_body_body_roles_group_title_text.textContent = "Board"; break;
				case ID_Type.THREAD: elem_body_body_roles_group_title_text.textContent = "Thread"; break;
				default: { console.error("Invalid"); }
				}

				let elem_body_body_roles_group_title_line2 = document.createElement("p"); elem_body_body_roles_group_title.appendChild(elem_body_body_roles_group_title_line2);
				elem_body_body_roles_group_title_line2.className = "line";

				let elem_body_body_roles_list = document.createElement("div"); elem_body_body_roles_group.appendChild(elem_body_body_roles_list);
				elem_body_body_roles_list.className = "list";
				for(let role_id of roles[role_group]) {
					let role = state.roles[role_id];
					let elem_body_body_roles_list_role = document.createElement("div"); elem_body_body_roles_list.appendChild(elem_body_body_roles_list_role);
					elem_body_body_roles_list_role.className = "role";

					if(role.icon) {
						let elem_body_body_roles_list_role_icon = document.createElement("div"); elem_body_body_roles_list_role.appendChild(elem_body_body_roles_list_role_icon);
						elem_body_body_roles_list_role_icon.className = "icon";
						elem_body_body_roles_list_role_icon.appendChild(Content(role.icon));
					}
					else {
						let elem_body_body_roles_list_role_color = document.createElement("div"); elem_body_body_roles_list_role.appendChild(elem_body_body_roles_list_role_color);
						elem_body_body_roles_list_role_color.className = "color";
						elem_body_body_roles_list_role_color.style.background = color_int_to_hex(role.color)!;
					}

					let elem_body_body_roles_list_role_content = document.createElement("p"); elem_body_body_roles_list_role.appendChild(elem_body_body_roles_list_role_content);
					elem_body_body_roles_list_role_content.textContent = role.name;
				}

				/* NOTE: This should only ever appear if you have permissions */
				/*
				let elem_body_body_roles_list_add = document.createElement("div"); elem_body_body_roles_list.appendChild(elem_body_body_roles_list_add);
				elem_body_body_roles_list_add.className = "role add";

				let elem_body_body_roles_list_add_text = document.createElement("p"); elem_body_body_roles_list_add.appendChild(elem_body_body_roles_list_add_text);
				elem_body_body_roles_list_add_text.className = "text";
				elem_body_body_roles_list_add_text.textContent = "+";
				*/
			}
		}
	}

	/* Message */
	if(!author_is_me(author)) {
		let elem_body_body_message = document.createElement("div"); elem_body_body.appendChild(elem_body_body_message);
		elem_body_body_message.className = "message";

		let elem_body_body_message_input = document.createElement("input"); elem_body_body_message.appendChild(elem_body_body_message_input);
		elem_body_body_message_input.type = "text";
		elem_body_body_message_input.placeholder = `Message @${author_name_str(author)}`;
		elem_body_body_message_input.onkeydown = (e :KeyboardEvent) => {
			if(e.key == "Enter") {
				if(e.shiftKey) {
					/* New Line */
				}
				else {
					e.preventDefault();

					auth_assert(() => {
						/* Send Message */
						let me  = { ...auth_profile(), target: target};
						let you = { ...author,         target: target};

						/* Send message */
						let message = { text: elem_body_body_message_input.value.trim(), contents: [], };
						elem_body_body_message_input.value = "";

						let msg_test_text = !message.text || /^\s*$/.test(message.text);
						if(msg_test_text) return;
						socket_send(ACTION.CHANNEL_ME_MESSAGE_ADD, {target: target, you: {id: you.id, is_anon: you.is_anon}, message});

						/* Move to page */
						let page_id = channel_page_find_or_yield(me, you, false);
						nav_to(page_id);
						close_modal();
					})
				}
			}
		}


		if(!author.is_anon) elem_body_body_message_input.style.border = `1px solid ${color_secondary}`;
		else                elem_body_body_message_input.style.border = `1px solid #4b4b4b`;
	}

	/* Set Position */
	let rect = elem_in.getBoundingClientRect();

	if(v == "down") elem_profile.style.top = rect.top + "px";
	else if(v == "up") elem_profile.style.top = (rect.bottom - elem_profile.offsetHeight) + "px"

	if(h == "right") elem_profile.style.left = (rect.right + 10) + "px";
	else if (h == "left") elem_profile.style.left = (rect.left - profile_width - 10) + "px"

	/* Fix Position */
	let profile_rect = elem_profile.getBoundingClientRect();

	if(v == "down" && profile_rect.bottom > window.innerHeight - 10) {
		elem_profile.style.top = "unset";
		elem_profile.style.bottom = 10 + "px";
	}
	else if(v == "up" && profile_rect.top < 10) {
		elem_profile.style.left = "unset";
		elem_profile.style.top = 10 + "px";
	}

	if(h == "right" && profile_rect.right > window.innerWidth - 10) {
		elem_profile.style.left = "unset";
		elem_profile.style.right = 10 + "px";
	}
	else if(h == "left" && profile_rect.left < 10) {
		elem_profile.style.right = "unset";
		elem_profile.style.left = 10 + "px";
	}
}