import {Page, Page_Type} from "../page";
import {state} from "../../../state";
import {socket_send, ACTION} from "../../../socket"
import {Content} from "../../modular/content"
import { Author_Name, Author_Avatar } from "../../modular/author";
import { auth, auth_is_user } from "../../../auth";
import { auth_assert } from "../../modal/auth_quick";
import { ID_Type } from "../../../id";
import { Perms_Manage, auth_perms_get } from "../../../permission";
import { author_self_other } from "../../../author";
import { content_get, upload_get } from "../../../helper";

const THREAD_FLAG = {
    PINNED:   0x01,
    EDITED:   0x02,
    MERGE:    0x04,

    DELETED:  0x10,
    ARCHIVED: 0x20,
}

class Page_Board extends Page {
	elem_join :HTMLElement | null = null;

    constructor (id :string) {
        super({type: Page_Type.BOARD, id: id});
        socket_send(ACTION.BOARD_THREADS_GET, {board_id: id});
		socket_send(ACTION.ROLES_GET, {target: {type: ID_Type.BOARD, id: id}});

		/* TODO: */
		let nav_bar_is_open = true;
		if(nav_bar_is_open) {
			socket_send(ACTION.THREADS_PINNED_GET, {board_id: id});
			if(auth_is_user() && state.boards && id in state.boards) {
				socket_send(ACTION.THREADS_WATCHING_GET, {board_id: id});
			}
		}
    }

    new () {
        let elem = document.createElement("div");
        elem.className = "board";

        let elem_entries = document.createElement("div"); elem.appendChild(elem_entries);
        elem_entries.className = "entries";
        if(state.board_threads && this.id.id in state.board_threads) this.update(elem);

        return({e:elem, es: elem_entries, h: 0, s: 0});
    }

	thread_del (thread :any) {
		let options :any = {board: thread.board};
		let perms = auth_perms_get(options);

        for(let i of Object.keys(this.instances)) {
			let elem = this.instances[i].e;
			let elem_thread = elem.querySelector(`#thread\\/${thread.id}`);
			if(!elem_thread) return;

			let elem_entries = elem.querySelector(".entries");
			if(perms[author_self_other(thread.author)].obj.thread & Perms_Manage.VIEW_DELETED) {
				elem_thread.classList.add("deleted");
			}
			else {
				if(elem_entries.children.length == 1) {
					elem_entries.classList.add("empty");
					elem_entries.innerHTML = "There are no threads";
				}
				else elem_entries.removeChild(elem_thread);
			}
		}
	}

    update (elem :HTMLElement) {
        const entries = elem.querySelector(".entries")!;

        if(!state.board_threads || !(this.id.id in state.board_threads) || !state.board_threads[this.id.id].length) {
            /* Empty */
            entries.classList.add("empty")
            entries.textContent = "There are no threads";
        }
        else {
            entries.classList.remove("empty");
        }

        Object.values(state.board_threads[this.id.id]).map((thread_id: any) => {
            const thread = state.threads[thread_id];
			let thread_picture = upload_get(thread?.picture);
			let thread_content = content_get(thread_picture?.content);

            /* Thread content */
            const content_max_width = 120;
            const content_max_height = 120;

            let content_width = thread_picture ? thread_content.width : content_max_width;
            let content_height = thread_picture ? thread_content.height : content_max_height;
            let content_ar = thread_picture ? thread_content.height / thread_content.width : 1;

            if(content_height > content_max_height) {
                content_height = content_max_height;
                content_width = content_max_height / content_ar;
            }
            if (content_width >= content_max_width) {
                content_width = content_max_width;
                content_height = content_max_width * content_ar;
            }

            let thread_picture_path = "#";
            if(thread_picture)
            {
                let path_ext = "";
                const type = thread_content.type.split("/");
                if(type[0] == "image" && type[1] == "png") path_ext = "png";
                else path_ext = "jpg";

                let content_path = thread_content.path.split('.');
                content_path[content_path.length-1] = path_ext;
                content_path[content_path.length-2] += "_128";
                thread_picture_path = content_path.join(".");
            }

            let elem_thread = document.createElement("div");
            elem_thread.id = `thread/${thread.id}`;
            elem_thread.className = "entry";
            elem_thread.setAttribute("lc", `nav:${Page_Type.THREAD}/${thread.id}`);
            elem_thread.setAttribute("rc", `menu:thread/${thread.id}/right`);
			if(thread.flags & THREAD_FLAG.DELETED) elem_thread.classList.add("deleted");

            let thread_status = document.createElement("div");
            thread_status.className = "status";
            elem_thread.appendChild(thread_status);

            let thread_media = document.createElement("div");
            thread_media.className = "media_container";
            thread_media.style.width = `${content_width}px`;
            thread_media.style.minWidth = `${content_width}px`;
            thread_media.style.maxWidth = `${content_width}px`;
            thread_media.style.height = `${content_height}px`;
            thread_media.style.minHeight = `${content_height}px`;
            thread_media.style.maxHeight = `${content_height}px`;

            let media = Content(thread_content, {size: "128", thumb: true});
            if(media) thread_media.appendChild(media);
            elem_thread.appendChild(thread_media);

            let thread_meta = document.createElement("div");
            thread_meta.className = "meta";
            let thread_meta_messages = document.createElement("span");
            thread_meta_messages.appendChild(document.createTextNode(`R: ${thread.message_count}`));
            thread_meta.appendChild(thread_meta_messages);
            let thread_meta_media = document.createElement("span");
            thread_meta_media.appendChild(document.createTextNode(`M: ${thread.media_count}`));
            thread_meta.appendChild(thread_meta_media);
            elem_thread.appendChild(thread_meta);

            let thread_info = document.createElement("div");
            thread_info.className = "info";

            let thread_profile_date = document.createElement("div");
            thread_profile_date.className = "profile_date";
            thread_profile_date.appendChild(Author_Avatar(thread.author, {size: "64", profile: "right"})!);

            let thread_info_right = document.createElement("div");
            thread_info_right.className = "right";
            let thread_author_name_date = document.createElement("div");
            thread_author_name_date.className = "author_name_date";
            thread_author_name_date.appendChild(Author_Name(thread.author, {profile: "right"})!);
            let thread_date = document.createElement("div");
            thread_date.className = "date";
            thread_date.appendChild(document.createTextNode("12:34"));
            thread_author_name_date.appendChild(thread_date);
            thread_info_right.appendChild(thread_author_name_date);

            let thread_info_subject = document.createElement("div");
            thread_info_subject.className = "subject";
            thread_info_subject.appendChild(document.createTextNode(`${thread.subject}`));
            thread_info_right.appendChild(thread_info_subject);
            thread_profile_date.appendChild(thread_info_right);
            thread_info.appendChild(thread_profile_date);

            let thread_info_comment = document.createElement("div");
            thread_info_comment.className = "comment";
            thread_info_comment.appendChild(document.createTextNode(`${thread.comment}`));
            thread_info.appendChild(thread_info_comment);
            elem_thread.appendChild(thread_info);

			let thread_bg = document.createElement("img");
			thread_bg.className = "background";
			if(media) thread_bg.src = media!.src;
			elem_thread.appendChild(thread_bg);

            entries!.appendChild(elem_thread);
        });

        /* Thread entry */
        const columns = 2;
        let width = 0;
        let height = 0;

        const window_width = window.innerWidth;
        if(window.innerWidth < 540) {
            /* TODO: Padding, border, margin */
            const border_size = 1;
            const margin_size = 8;
            const padding_size = 0;
            const scrollbar_size = 4;

            width = (window_width / columns) - (border_size*2) - (margin_size*2) - padding_size - scrollbar_size;
            height = 300;
        }
        else {
            width = 240;
            height = 300;
        }

        for(const entry of entries!.children) {
            const a = entry as HTMLElement;
            a.style.width = width + "px";
            a.style.height = height + "px";
        }

		/* Joiner */
		if(!state.boards_me || !state.boards_me.includes(this.id.id)) this.add_join(elem);
    }

    update_all() {
        for(let i of Object.keys(this.instances)) this.update(this.instances[i].e);
    }

	add_join (elem :HTMLElement) {
		let elem_join = document.createElement("div");
		elem_join.className = "join";
		elem_join.onclick = () => auth_assert(() => socket_send(ACTION.BOARD_LIST_ADD, {board_id: this.id.id}));
		elem.appendChild(elem_join);

		let elem_join_text = document.createElement("div");
		elem_join_text.textContent = "Join Board";
		elem_join.append(elem_join_text);
	}

	del_join (elem :HTMLElement) {
		let elem_join = elem.querySelector(".join");
		elem_join?.remove();
	}

	add_join_all () {
        for(let i of Object.keys(this.instances)) this.add_join(this.instances[i].e);
	}

	del_join_all () {
		for(let i of Object.keys(this.instances)) this.del_join(this.instances[i].e);
	}

}

export default Page_Board;
