import { auth, auth_is_user, auth_profile } from "../../../auth";
import { author_is_me, profile_is_me } from "../../../author";
import { content_get, emoji_set_get, profile_get, upload_get } from "../../../helper";
import { ID_Type } from "../../../id";
import { ACTION, socket_send } from "../../../socket";
import { state } from "../../../state";
import { views } from "../../../view";
import { auth_assert } from "../../modal/auth_quick";
import { Create_EmojiSet } from "../../modal/create";
import { Gallery } from "../../modal/gallery";
import { Author_Avatar, Author_Name, author_name_str } from "../../modular/author";
import { Content } from "../../modular/content";
import { dropzone_new } from "../../modular/dropzone";
import { date_str } from "../../modular/time";
import { Page, Page_Type } from "../page";

class Page_ProfileContent extends Page {
	constructor (id :string) {
		super({type: Page_Type.PROFILE_CONTENT, id: id});
	}

	new () {
		let elem = document.createElement("div");
		elem.className = "profile_content";
		this.update(elem);
		return({e: elem, es: elem, h: 0, s: 0});
	}

	update (elem :HTMLElement) {
		let profile = profile_get(this.id.id);

		/*
		let elem_profile = document.createElement("div");
		elem_profile.className = "profile";
		elem.appendChild(elem_profile);

		let elem_profile_top = document.createElement("div");
		elem_profile_top.className = "top";
		elem_profile_top.appendChild(Author_Avatar(profile, {size: "128"})!);
		elem_profile_top.appendChild(Author_Name(profile)!);
		elem_profile.appendChild(elem_profile_top);

		let elem_profile_bot = document.createElement("div");
		elem_profile_bot.className = "bot";
		elem_profile_bot.textContent = profile.about;
		elem_profile.appendChild(elem_profile_bot);
		*/

		let elem_content = document.createElement("div"); elem.appendChild(elem_content);
		elem_content.className = "content";

		let elem_page_select = document.createElement("div"); elem_content.appendChild(elem_page_select);
		elem_page_select.className = "page_select";

		let elem_pages = document.createElement("div"); elem_content.appendChild(elem_pages);
		elem_pages.className = "pages";

		let elem_page_select_saves = document.createElement("div"); elem_page_select.appendChild(elem_page_select_saves);
		elem_page_select_saves.className = "item saves";
		elem_page_select_saves.textContent = "Saves";
		elem_page_select_saves.onclick = (e :any) => {this.select_page(elem, "saves")};

		let elem_page_saves = document.createElement("div"); elem_pages.appendChild(elem_page_saves);
		elem_page_saves.className = "page saves";

		if(profile_is_me(profile.id)) {
			let elem_page_select_collection = document.createElement("div"); elem_page_select.appendChild(elem_page_select_collection);
			elem_page_select_collection.className = "item collection";
			elem_page_select_collection.textContent = "Collection";
			elem_page_select_collection.onclick = (e :any) => {this.select_page(elem, "collection")};

			let elem_page_collection = document.createElement("div"); elem_pages.appendChild(elem_page_collection);
			elem_page_collection.className = "page collection";

			let elem_collection_select = document.createElement("div"); elem_page_collection.appendChild(elem_collection_select);
			elem_collection_select.className = "collection_select";

			let elem_collections = document.createElement("div"); elem_page_collection.appendChild(elem_collections);
			elem_collections.className = "collections";

			/* uploads */
			let elem_collection_select_uploads = document.createElement("div"); elem_collection_select.appendChild(elem_collection_select_uploads);
			elem_collection_select_uploads.className = "item uploads";
			elem_collection_select_uploads.textContent = "Uploads";
			elem_collection_select_uploads.onclick = (e :any) => {this.select_collection(elem, "uploads")};

			let elem_collection_uploads = document.createElement("div"); elem_collections.appendChild(elem_collection_uploads);
			elem_collection_uploads.className = "collection uploads";

			/* emojis */
			/*
			let elem_collection_select_emojis = document.createElement("div"); elem_collection_select.appendChild(elem_collection_select_emojis);
			elem_collection_select_emojis.className = "item emoji_sets";
			elem_collection_select_emojis.textContent = "Emoji Sets";
			elem_collection_select_emojis.onclick = (e :any) => {this.select_collection(elem, "emoji_sets")};

			let elem_collection_emojis = document.createElement("div"); elem_collections.appendChild(elem_collection_emojis);
			elem_collection_emojis.className = "collection emoji_sets";

			let elem_collection_emojis_list = document.createElement("div"); elem_collection_emojis.appendChild(elem_collection_emojis_list);
			elem_collection_emojis_list.className = "list";

			let elem_collection_emojis_buttons = document.createElement("div"); elem_collection_emojis.appendChild(elem_collection_emojis_buttons);
			elem_collection_emojis_buttons.className = "buttons";

			let elem_collection_emojis_button_create = document.createElement("div"); elem_collection_emojis_buttons.appendChild(elem_collection_emojis_button_create);
			elem_collection_emojis_button_create.className = "create";
			elem_collection_emojis_button_create.textContent = "New emoji set"
			elem_collection_emojis_button_create.onclick = (e :any) => {
				auth_assert(() => {
					let create = new Create_EmojiSet();
					create.new();
					dropzone_new(create.elem, create);
				})
			}
			*/
		}
		this.select_page(elem, "saves");
	}

	select_page (elem :HTMLElement, option :string) {
		let options :string[] =  ["saves", "collection"];
		if(!(options.includes(option))) return;

		let items = elem.querySelectorAll(".content .page_select .item");
		for(let item of items) {
			if(item.classList.contains(option) && !item.classList.contains("selected")) item.classList.add("selected");
			else if(item.classList.contains("selected")) item.classList.remove("selected");
		}

		let pages = elem.querySelectorAll(".content .pages .page");
		for(let page of pages) {
			let elem_page = page as HTMLElement;
			if(page.classList.contains(option)) elem_page.style.display = "block";
			else elem_page.style.display = "none";
		}

		switch(option)
		{
		case "saves": {
			let profile = state.profiles[this.id.id];
			if("saves" in profile) this.update_page(elem, option);
			else socket_send(ACTION.PROFILE_CONTENT_GET, {kind: option, profile_id: profile.id});
		} break;
		case "collection": {
			this.select_collection(elem, "uploads");
		} break;
		}
	}

	select_collection (elem :HTMLElement, option :string) {
		let options :string[] =  ["uploads", "emoji_sets"];
		if(!(options.includes(option))) return;

		let elem_page = elem.querySelector(`.content .pages .page.collection`)!;

		let items = elem_page.querySelectorAll(".collection_select .item");
		for(let item of items) {
			if(item.classList.contains(option) && !item.classList.contains("selected")) item.classList.add("selected");
			else if(item.classList.contains("selected")) item.classList.remove("selected");
		}

		let pages = elem_page.querySelectorAll(".collections .collection");
		for(let page of pages) {
			let elem_page = page as HTMLElement;
			if(page.classList.contains(option)) elem_page.style.display = "block";
			else elem_page.style.display = "none";
		}

		let profile = state.profiles[this.id.id];
		if(option in profile) this.update_page(elem, option);
		else socket_send(ACTION.PROFILE_CONTENT_GET, {kind: option, profile_id: profile.id});
	}

	content_on_click (contents :any, index :number) {
        if(!views.modal.gallery) views.modal.gallery = new Gallery();
        views.modal.gallery.contents_set(contents, "upload", index, null);
        views.modal.gallery.open();
	}

	update_page (elem :HTMLElement, page_kind: string) {
		let elem_page = elem.querySelector(`.content .pages .page.${page_kind}`)!;
		elem_page.innerHTML = "";

		var profile = profile_get(this.id.id);
		if(profile[page_kind].length == 0) {
			switch(page_kind) {
			case "saves": {
				if(profile_is_me(profile.id)) elem_page.textContent = "You haven't saved anything";
				else elem_page.textContent = `${author_name_str(profile)} hasn't saved anything`;
			} break;
			default: throw Error();
			}
		}

		for(let [index, item] of profile[page_kind].entries()) {
			let date = null;
			let item_ret = null;
			let contents = null;
			switch(page_kind) {
			case "saves": {
				switch(item.id.type) {
				case ID_Type.UPLOAD: {
					date = item.date_saved;
					item_ret = item.ret;
				} break;
				default: throw Error("Not implemented");
				}
				contents = profile[page_kind].filter((item :any) => item.id.type == ID_Type.UPLOAD).map((item :any) => item.id.id);
			} break;
			default: throw Error();
			}

			let elem_item = document.createElement("div");
			elem_item.className = "item";
			elem_item.onclick = () => this.content_on_click(contents!, index);

			let elem_item_thumb = document.createElement("div");
			elem_item_thumb.className = "thumb";
			elem_item.appendChild(elem_item_thumb);

			let elem_item_name = document.createElement("div");
			elem_item_name.className = "name";
			elem_item.appendChild(elem_item_name);

			let elem_item_date = document.createElement("div");
			elem_item_date.className = "date";
			elem_item.appendChild(elem_item_date);

			let content = content_get(item_ret.content);
			elem_item.setAttribute("rc", `menu:upload/${item_ret.id}/right`);
			elem_item_thumb.appendChild(Content(content, {size: "64", thumb: true})!);
			elem_item_name.appendChild(document.createTextNode(item_ret.name))
			elem_item_date.appendChild(document.createTextNode(date_str(new Date(date))))
			elem_page.appendChild(elem_item);
		}
	}

	update_collection (elem :HTMLElement, collection_kind: string) {
		let elem_page = elem.querySelector(`.content .pages .page.collection`)!;
		let elem_collection = elem_page.querySelector(`.collections .collection.${collection_kind}`)!;
		elem_collection.innerHTML = "";

		var profile = profile_get(this.id.id);
		if(profile[collection_kind].length == 0) {
			switch(collection_kind) {
			case "uploads": elem_collection.textContent = "You haven't uploaded anything"; break;
			case "emoji_sets": elem_collection.textContent = "You haven't created any emoji sets"; break;
			}
		}

		for(let [index, item] of profile[collection_kind].entries()) {
			let elem_item = document.createElement("div");
			elem_item.className = "item";

			switch(collection_kind) {
			case "uploads": {
				let item_ret = upload_get(item);
				elem_item.setAttribute("rc", `menu:upload/${item_ret.id}/right`);

				let elem_item_thumb = document.createElement("div"); elem_item.appendChild(elem_item_thumb);
				elem_item_thumb.className = "thumb";

				let contents = profile[collection_kind];
				elem_item.onclick = () => this.content_on_click(contents!, index);

				let content = content_get(item_ret.content);
				elem_item_thumb.appendChild(Content(content, {size: "64", thumb: true})!);

				let elem_item_name = document.createElement("div"); elem_item.appendChild(elem_item_name);
				elem_item_name.className = "name";
				elem_item_name.appendChild(document.createTextNode(item_ret.name))

				let date = item_ret.date_uploaded;
				let elem_item_date = document.createElement("div"); elem_item.appendChild(elem_item_date);
				elem_item_date.className = "date";
				elem_item_date.appendChild(document.createTextNode(date_str(new Date(date))))
			} break;
			case "emoji_sets": {
				let item_ret = emoji_set_get(item);
				elem_item.setAttribute("lc", `nav:${Page_Type.EMOJI_SET}/${item_ret.id}`);
				elem_item.setAttribute("rc", `menu:emoji_set/${item_ret.id}/right`);

				let elem_item_name = document.createElement("div"); elem_item.appendChild(elem_item_name);
				elem_item_name.className = "name";
				elem_item_name.appendChild(document.createTextNode(item_ret.name))

				let date = item_ret.date_created;
				let elem_item_date = document.createElement("div"); elem_item.appendChild(elem_item_date);
				elem_item_date.className = "date";
				elem_item_date.appendChild(document.createTextNode(date_str(new Date(date))))
			} break;
			default: {
				throw Error("Wrong collection kind");
			}
			}

			elem_collection.appendChild(elem_item);
		}

	}

	update_all_pages (page_kind :string) {
        for(let i of Object.keys(this.instances)) this.update_page(this.instances[i].e, page_kind);
	}

	update_all_collections (collection_kind :string) {
        for(let i of Object.keys(this.instances)) this.update_collection(this.instances[i].e, collection_kind);
	}
}

export default Page_ProfileContent;
