import { Author_Color, author_name_str_from_id } from "./author";
import {color_int_to_hex} from "./color";
import {Icon} from "./icon";

export const anon_name_str = (id: string) => {
	return(`Anon ${author_name_str_from_id(id)}`);
}

export const Anon_Name = (anon :any, options :any = {}) => {
    if(!("specific" in options)) options.specific = true;
    if(!("profile" in options)) options.profile = null;

    let elem = document.createElement("div");
    elem.className = "anon_name";

    let elem_name = document.createElement("div");
    elem_name.className = "name";
    elem_name.textContent = options.specific ? anon_name_str(anon.id) : "Anonymous";
    elem.appendChild(elem_name);

    if(anon.is_you) {
        let elem_extra = document.createElement("div");
        elem_extra.className = "extra";
        elem_extra.textContent = "(You)";
        elem_extra.style.color = "red";
        elem.appendChild(elem_extra);
    }

    if(options.profile) {
        if(anon.is_you) {
            elem.setAttribute("lc", `modal:anon/${anon.id}/${options.profile}/you`);
            elem.setAttribute("rc", `menu:anon/${anon.id}/${options.profile}/you`);
        }
        else {
            elem.setAttribute("lc", `modal:anon/${anon.id}/${options.profile}`);
            elem.setAttribute("rc", `menu:anon/${anon.id}/${options.profile}`);
        }
    }

    return(elem);
}

export const Anon_Avatar = (anon :any, options :any = {}) => {
    if(!("specific" in options)) options.specific = true;
    if(!("profile" in options)) options.profile = null;

    let elem = document.createElement("div");
    elem.className = "anon_avatar";
    elem.innerHTML = Icon.Author.Anon;

    /* Color */
    let color = Author_Color(anon);
    let elem_svg :HTMLElement = (elem.children[0] as HTMLElement);
	if(options.specific) elem_svg.style.backgroundColor = color_int_to_hex(color);

    if(options.profile) {
        if(anon.is_you) {
            elem.setAttribute("lc", `modal:anon/${anon.id}/${options.profile}/you`);
            elem.setAttribute("rc", `menu:anon/${anon.id}/${options.profile}/you`);
        }
        else {
            elem.setAttribute("lc", `modal:anon/${anon.id}/${options.profile}`);
            elem.setAttribute("rc", `menu:anon/${anon.id}/${options.profile}`);
        }
    }

    return(elem);
}

export const Anon_Entry = (anon :any, options :any = {}) => {
    if(!anon) return null;
	if(!("size" in options)) options.size = "32";

    let elem = document.createElement("div");
    elem.className = "anon_entry";
	elem.appendChild(Anon_Avatar(anon, {...options, profile: false})!);

	let elem_right = document.createElement("div");
	elem_right.className = "right";
	elem_right.appendChild(Anon_Name(anon, {...options, profile: false})!);

	let elem_status = document.createElement("div");
	elem_status.className = "status";
	elem_right.appendChild(elem_status);
	elem.appendChild(elem_right);

    if(options.profile) {
        if(anon.is_you) {
            elem.setAttribute("lc", `modal:anon/${anon.id}/${options.profile}/you`);
            elem.setAttribute("rc", `menu:anon/${anon.id}/${options.profile}/you`);
        }
        else {
            elem.setAttribute("lc", `modal:anon/${anon.id}/${options.profile}`);
            elem.setAttribute("rc", `menu:anon/${anon.id}/${options.profile}`);
        }
    }

    return(elem);
}