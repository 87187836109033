import {Page, Page_Type} from "../page";

class Page_Tips extends Page {
    constructor () {
        super({type: Page_Type.TIPS, id: ""});
    }

    new () {
        let elem = document.createElement("div");
        elem.className = "tips";
        elem.innerHTML = ` <div class="container">
            <ul>
                <h2>User Interface</h2> <br />
                <ul>
                    <h3>Bars</h3>
                    <li>- You can reach these bars by swiping left/right (on mobile) your clicking the hambuer (top-left) and panel buttons (top-right)</li>
                    <li>- Nav bar (left) - Contains all the navigation links</li>
                    <li>- Info bar (right) - Contains extra options and information about the current page (only available on specific pages)</li>
                    <br />

                    <h3>Menus</h3>
                    <li>- You can open menus to perform various types of actions by either right clicking (desktop) or tapping and holding the object (mobile)</li>
                    <li>- These objects are threads, users, messages and video/pictures</li>
                </ul>
                <br /> <br />

                <h2>Website features</h2><br />
                <ul>
                    <h3>Users</h3>
                    <li>- You can start a private conversation with any user (option in menu)</li>
                    <br />

                    <h3>Boards</h3>
                    <li>- Boards are small communities</li>
                    <li>- The nav bar only shows the ones that are currently active, you can check them all <Link to="/boards">here</Link></li>
                    <br />

                    <h3>Threads</h3>
                    <li>- Anyone can create their own thread in their desired board by clicking the create > thread option in the nav bar</li>
                    <li>- You can also watch a thread, which will pin it in the nav bar and give you notification on new messages (option in menu and info bar)</li>
                    <br />

                    <h3>Messages</h3>
                    <li>- You can copy any message (option in menu)</li>
                    <li>- You can delete your messages (option in menu)</li>
                    <br />

                    <h3>Text Formating</h3>
                    <li>- Starting a paragraph with the character > will make the text green (originally used for quoting in other influntial websites)</li>
                    <br />

                    <h3>Uploads</h3>
                    <li>- You can either upload something by clicking the + button next to the message container or by dragging and dropping the file in your desired channel</li>
                    <li>- The upload limit is 200MB per file</li>
                </ul>
                <br /> <br />

                <h2>Personal features</h2> <br />
                <ul>
                    <h3>Profile</h3>
                    <li>- You can change your profile (picture/name) in the settings by clicking the cog icon in the bottom left of the nav bar</li>
                    <br />

                    <h3>Status</h3>
                    <li>- You can change your status by clicking your profile in the bottom left corner of the nav bar</li>
                    <li>- This includes (online, busy, away, invisible) and the option to turn on anonymous mode</li>
                    <br />

                    <h3 style="color: crimson;">Anonymous Mode</h3>
                    <li>- Your post are made under a anonymous identity, unique/different for every thread</li>
                    <li>- Whenever you start a new conversation with a user, that conversation will use the anonymous identity from that specific thread</li>
                    <li>- Anon identeties always appear offline</li>
                    <li>- This feature can be enabled in your profile status</li>
                </ul>
            </ul>
        </div>`
        return({e: elem, es: elem, h: 0, s: 0});
    }
}

export default Page_Tips;
