import {Create_Board} from "../../modal/create";
import { dropzone_new } from "../../modular/dropzone";
import { state } from "../../../state";
import { auth_is_guest } from "../../../auth";
import { Auth_Quick, auth_assert } from "../../modal/auth_quick";

class Panel_Boards {
	elem: HTMLElement;

	constructor () {
		this.elem = document.createElement("div");
		this.elem.className = "boards";
		this.update();
	}

	update = () => {
		this.elem.innerHTML = "";

		//let elem_top = document.createElement("div");
		//elem_top.className = "top";
		//this.elem.appendChild(elem_top);

		//let elem_sort = document.createElement("div");
		//elem_sort.className = "sort";
		//elem_top.appendChild(elem_sort);

		let elem_mid = document.createElement("div");
		elem_mid.className = "mid group";
		this.elem.appendChild(elem_mid);

		let elem_create = document.createElement("div");
		elem_create.className = "item create";
		elem_create.textContent = "New Board";
		elem_mid.appendChild(elem_create);

		elem_create.onclick = () => {
			auth_assert(() => {
				let create = new Create_Board();
				create.new();

				dropzone_new(create.elem, create);
			})
		}
	}
}

export default Panel_Boards;