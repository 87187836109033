import { auth } from "../../../../auth";
import { ACTION, socket_send } from "../../../../socket";
import { Notif, Notif_Type } from "../../../modular/notification";
import { Page, Page_Type } from "../../page";

class Page_SettingsAccount extends Page {
    account :any = {
        email: "",
        password: "",
    }
    packet :any = {};

    extra :any = {
        password_confirm: "",
    }
    elem_buttons :null | HTMLElement = null;

	constructor () {
		super({type: Page_Type.SETTINGS_ACCOUNT, id: ""});
        this.reset();
	}

    new = () => {
        let elem = document.createElement("div");
        elem.className = "settings account";
        this.update_elem(elem);
        return({e: elem, es: elem, h: 0, s: 0});
    }

    reset = () => {
        this.account.email = auth.user.email || "";
        this.account.password = "";
    }

    changed = () => {
        this.elem_buttons?.style.display = "none";
        if(Object.entries(this.packet).length > 0) {
            this.elem_buttons?.style.display = "block";
        }
    }

	update_elem = (elem :any)  => {
        elem.innerHTML = "";

		let elem_container = document.createElement("div"); elem.appendChild(elem_container);
		elem_container.className = "container";

		/* OPTIONS */
		let elem_options = document.createElement("div"); elem_container.appendChild(elem_options);
		elem_options.className = "options";

        /* Email */
		{
			let elem_option_email = document.createElement("div"); elem_options.appendChild(elem_option_email);
			elem_option_email.className = "section email";

			let elem_option_email_title = document.createElement("div"); elem_option_email.appendChild(elem_option_email_title);
			elem_option_email_title.className = "title";
			elem_option_email_title.textContent = "Email";

			let elem_option_email_content = document.createElement("input"); elem_option_email.appendChild(elem_option_email_content);
			elem_option_email_content.className = "content txt";
			elem_option_email_content.placeholder = "Change email";
            elem_option_email_content.type = "email";
			elem_option_email_content.value = this.account.email;
			elem_option_email_content.oninput = (e: any) => {
				this.account.email = e.target.value;
                if(!this.account.email || this.account.email == "" || this.account.email == auth.user.email) {
                    if("email" in this.packet) delete this.packet.email;
                }
                else this.packet.email = e.target.value;

				this.changed();
			}
		}

        /* Password */ 
        let elem_option_password = document.createElement("div"); elem_options.appendChild(elem_option_password);
        elem_option_password.className = "section password";

        let elem_option_password_confirm = document.createElement("div"); elem_options.appendChild(elem_option_password_confirm);
        elem_option_password_confirm.className = "section password_confirm";
        elem_option_password_confirm.style.display = "none";

        /* Input */
        let elem_option_password_title = document.createElement("div"); elem_option_password.appendChild(elem_option_password_title);
        elem_option_password_title.className = "title";
        elem_option_password_title.textContent = "Password";

        let elem_option_password_content = document.createElement("input"); elem_option_password.appendChild(elem_option_password_content);
        elem_option_password_content.className = "content txt";
        elem_option_password_content.placeholder = "Change password"
        elem_option_password_content.type = "password";
        elem_option_password_content.value = "";
        elem_option_password_content.oninput = (e :any) => {
            this.account.password = e.target.value;
            if(!this.account.password || this.account.password == "") {
                if("password" in this.packet) delete this.packet.password;
                elem_option_password_confirm.style.display =  "none";
            }
            else {
                this.packet.password = e.target.value;
                elem_option_password_confirm.style.display =  "block";
            }
            this.changed();
        }

        /* Confirm */ 
        let elem_option_password_confirm_title = document.createElement("div"); elem_option_password_confirm.appendChild(elem_option_password_confirm_title);
        elem_option_password_confirm_title.className = "title";
        elem_option_password_confirm_title.textContent = "Confirm Password";

        let elem_option_password_confirm_content = document.createElement("input"); elem_option_password_confirm.appendChild(elem_option_password_confirm_content);
        elem_option_password_confirm_content.className = "content txt";
        elem_option_password_confirm_content.placeholder = "Confirm Password"
        elem_option_password_confirm_content.type = "password";
        elem_option_password_confirm_content.value = "";
        elem_option_password_confirm_content.oninput = (e :any) => {
            this.extra.password_confirm = e.target.value;
        }

		/* Buttons */
		this.elem_buttons = document.createElement("div"); elem.appendChild(this.elem_buttons);
		this.elem_buttons.className = "buttons";
		this.elem_buttons.style.display = "none";

		let elem_buttons_top = document.createElement("div"); this.elem_buttons.appendChild(elem_buttons_top);
		elem_buttons_top.className = "top";

		let elem_buttons_bot = document.createElement("div"); this.elem_buttons.appendChild(elem_buttons_bot);
		elem_buttons_bot.className = "bot";

		let elem_buttons_bot_reset = document.createElement("div"); elem_buttons_bot.appendChild(elem_buttons_bot_reset);
		elem_buttons_bot_reset.className = "reset";
		elem_buttons_bot_reset.textContent = "Reset";
		elem_buttons_bot_reset.onclick = () => {
			this.reset();
			this.update_elem(elem);
		}

		let elem_buttons_bot_save = document.createElement("div"); elem_buttons_bot.appendChild(elem_buttons_bot_save);
		elem_buttons_bot_save.className = "save";
		elem_buttons_bot_save.textContent = "Save changes";
		elem_buttons_bot_save.onclick = () => {
            if(this.packet.password && this.packet.password != this.extra.password_confirm) {
                this.extra.password_confirm = "";
                elem_option_password_confirm_content.value = "";
                Notif(Notif_Type.ERROR, 5000, {msg: "Passwords don't match"});
            }
            else {
                socket_send(ACTION.SETTINGS_ACCOUNT, this.packet);
                this.elem_buttons?.style.display = "none";
            }
		}
	}

    update_all() {
        for(let i of Object.keys(this.instances)) this.update_elem(this.instances[i].e);
    }

}

export default Page_SettingsAccount;
