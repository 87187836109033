export const UPLOAD_TARGET = {
	NULL:           0,
	MESSAGE:        1,
	CHANNEL:        2,
	THREAD:         3,
	THREAD_IMAGE:   4,
	BOARD:          5,
	BOARD_BANNER:   6,
	BOARD_PICTURE:  7,
	PROFILE_AVATAR: 8,
	PROFILE_BANNER: 9,
	ROLE:           10,
	FOLDER:         11,
}