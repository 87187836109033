import {Content} from "../../modular/content";
import { state } from "../../../state";
import { Author_Name, Author_Avatar } from "../../modular/author";
import {Icon} from "../../modular/icon";
import { ACTION, socket_send } from "../../../socket";
import { ID_Type } from "../../../id";
import { ADDRESS_WEBSITE } from "../../../config";
import { auth_assert } from "../../modal/auth_quick";
import { Invite } from "../../modal/invite";
import { content_get, upload_get } from "../../../helper";

class Panel_Thread {
    id :string;
    elem :HTMLElement;

    constructor (id :string) {
        this.id = id;
        this.elem = document.createElement("div");
        this.elem.className = "thread";
        this.update();
    }

    update_participants() {
        let participants = state.threads[this.id].participants;

        let online = state.threads[this.id].online;
        let offline = Object.keys(participants.profile).filter((profile_id) => !online.includes(profile_id));
        let anon_length = Object.keys(participants.anon).length;

        let elem_participants = this.elem.querySelector(".mid .participants");
        elem_participants!.querySelector(".header")!.textContent = `Participants - ${online.length+offline.length}`;
        elem_participants!.querySelector(".online .title")!.textContent = `Online - ${online.length}`;
        elem_participants!.querySelector(".offline .title")!.textContent = `Offline - ${offline.length}`;
        elem_participants!.querySelector(".anon .title")!.textContent = `Anon - ${anon_length}`;

        let elem_online_list = elem_participants?.querySelector(".online .list");
        for(let profile_id of online) {
            let profile = participants.profile[profile_id];
            if(!profile) continue;

            let elem = document.createElement("div");
            elem.className = "profile entry";

            elem.appendChild(Author_Avatar(profile, {size: "64"})!);

            let elem_right = document.createElement("div");
            elem_right.className = "right";
            elem_right.appendChild(Author_Name(profile, {badges: true})!);
            let elem_status = document.createElement("div");
            elem_status.className = "status";
            elem_right.appendChild(elem_status);
            elem.appendChild(elem_right);

            if(!profile.is_banned) {
                elem.setAttribute("lc", `modal:profile/${profile_id}/left`);
                elem.setAttribute("rc", `menu:profile/${profile_id}/left`);
            }

            elem_online_list?.appendChild(elem);
        }

        let elem_offline_list = elem_participants?.querySelector(".offline .list");
        for(let profile_id of offline.reverse()) {
            let profile = participants.profile[profile_id];
            let elem = document.createElement("div");
            elem.className = "profile entry";

            elem.appendChild(Author_Avatar(profile, {size: "64"})!);

            let elem_right = document.createElement("div");
            elem_right.className = "right";
            elem_right.appendChild(Author_Name(profile, {badges: true})!);
            let elem_status = document.createElement("div");
            elem_status.className = "status";
            elem_right.appendChild(elem_status);
            elem.appendChild(elem_right);

            if(!profile.is_banned) {
                elem.setAttribute("lc", `modal:profile/${profile_id}/left`);
                elem.setAttribute("rc", `menu:profile/${profile_id}/left`);
            }

            elem_offline_list?.appendChild(elem);
        }

        let elem_anon_list = elem_participants?.querySelector(".anon .list");
        for(let anon of Object.values(participants.anon).reverse() as any[]) {
            let elem = document.createElement("div");
            elem.className = "anon entry";

            elem.appendChild(Author_Avatar(anon, {size: "64"})!);

            let elem_right = document.createElement("div");
            elem_right.className = "right";
            elem_right.appendChild(Author_Name(anon)!);
            let elem_status = document.createElement("div");
            elem_status.className = "status";
            elem_right.appendChild(elem_status);
            elem.appendChild(elem_right);

            elem.setAttribute("lc", `modal:anon/${anon.id}/left`);
            elem.setAttribute("rc", `menu:anon/${anon.id}/left`);

            elem_anon_list?.appendChild(elem);
        }

        //let elem_online = elem_participants!.querySelector(".online");
        //elem_online!.textContent = `Online (${online.length})`;
    }

    update () {
        if(!state.threads || !(this.id in state.threads)) return;
        let thread = state.threads[this.id];

        this.elem.innerHTML = "";
        let elem_top = document.createElement("div");
        elem_top.className = "top";

        let elem_info = document.createElement("div");
        elem_info.className = "info";
        let elem_profile_date = document.createElement("div");
        elem_profile_date.className = "profile_date";
        elem_profile_date.appendChild(Author_Avatar(thread.author, {size: "64"})!);
        let elem_right = document.createElement("div");
        elem_right.className = "right";
        let elem_name_date = document.createElement("div");
        elem_name_date.className = "name_date";
        elem_name_date.appendChild(Author_Name(thread.author)!);
        let elem_date = document.createElement("div");
        elem_date.className = "date";
        elem_date.appendChild(document.createTextNode("12:34"));
        elem_name_date.appendChild(elem_date);
        elem_right.appendChild(elem_name_date);
        let elem_subject = document.createElement("div");
        elem_subject.className = "subject";
        elem_subject.appendChild(document.createTextNode(thread.subject));
        elem_right.appendChild(elem_subject);
        elem_profile_date.appendChild(elem_right);
        let elem_comment = document.createElement("div");
        elem_comment.className = "comment";
        elem_comment.appendChild(document.createTextNode(thread.comment));
        elem_info.appendChild(elem_profile_date);
        elem_info.appendChild(elem_comment);
        elem_top.appendChild(elem_info);

        let elem_buttons = document.createElement("div");
        elem_buttons.className = "buttons";
        let elem_button_watch = document.createElement("div");
        elem_button_watch.className = "watch";
        elem_button_watch.innerHTML = Icon.Eye;

        let is_watching = state.threads_watching && thread.board in state.threads_watching && state.threads_watching[thread.board].includes(thread.id);
        elem_button_watch.style.color = is_watching ? "crimson" : "white";
        elem_button_watch.onclick = () => {
			auth_assert(() => {
				let is_watching = state.threads_watching && thread.board in state.threads_watching && state.threads_watching[thread.board].includes(thread.id);
				if(is_watching) {
					elem_button_watch.style.color = "white";
					socket_send(ACTION.THREAD_WATCHING_DEL, {thread_id: thread.id});
				}
				else {
					elem_button_watch.style.color = "crimson";
					socket_send(ACTION.THREAD_WATCHING_ADD, {thread_id: thread.id});
				}
			})
        }
        elem_buttons.appendChild(elem_button_watch);

        let elem_button_share = document.createElement("div");
        elem_button_share.className = "share";
        elem_button_share.innerHTML = Icon.Invite;
        elem_button_share.onclick = () => {
			auth_assert(Invite({type: ID_Type.THREAD, id: thread.id}))
        }
        elem_buttons.appendChild(elem_button_share);
        elem_top.appendChild(elem_buttons);

		let content = content_get(upload_get(thread.picture).content);
        let elem_top_bg = Content(content, {size: "128", thumb: true});
		if(elem_top_bg) {
			elem_top_bg.classList.add("background");
			elem_top.appendChild(elem_top_bg);
		}

        this.elem.appendChild(elem_top);

		/*
        let elem_mid = document.createElement("div");
        elem_mid.className = "mid";
        let elem_group_content = document.createElement("div");
        elem_group_content.className = "group content";
        let elem_media_item = document.createElement("div");
        elem_media_item.className = "item";
        elem_media_item.textContent = `Content (${thread.media_count})`;
        elem_group_content.appendChild(elem_media_item);
        elem_mid.appendChild(elem_group_content);

        let elem_participants = document.createElement("div");
        elem_participants.className = "participants";
        let elem_participants_header = document.createElement("div");
        elem_participants_header.className = "header";
        elem_participants_header.textContent = `Participants (${"participants" in thread ? thread.participants.length : 0})`;
        elem_participants.appendChild(elem_participants_header);

        let elem_online = document.createElement("div");
        elem_online.className = "online";
        let elem_online_title = document.createElement("div");
        elem_online_title.className = "title";
        elem_online_title.textContent = `Online ()`;
        elem_online.appendChild(elem_online_title);
        let elem_online_list = document.createElement("div");
        elem_online_list.className = "list";
        elem_online.appendChild(elem_online_list);
        elem_participants.appendChild(elem_online);

        let elem_offline = document.createElement("div");
        elem_offline.className = "offline";
        let elem_offline_title = document.createElement("div");
        elem_offline_title.textContent = `Offline ()`;
        elem_offline_title.className = "title";
        elem_offline.appendChild(elem_offline_title);
        let elem_offline_list = document.createElement("div");
        elem_offline_list.className = "list";
        elem_offline.appendChild(elem_offline_list);
        elem_participants.appendChild(elem_offline);

        let elem_anon = document.createElement("div");
        elem_anon.className = "anon";
        let elem_anon_title = document.createElement("div");
        elem_anon_title.textContent = `Anon ()`;
        elem_anon_title.className = "title";
        elem_anon.appendChild(elem_anon_title);
        let elem_anon_list = document.createElement("div");
        elem_anon_list.className = "list";
        elem_anon.appendChild(elem_anon_list);
        elem_participants.appendChild(elem_anon);

        elem_mid.appendChild(elem_participants);

        this.elem.appendChild(elem_mid);
		*/
    }
}

export default Panel_Thread;