import { socket_send, ACTION } from "../../socket";
import {state} from "../../state";

export const Auth = () => {
    if(state.modal != "") {
        let elem_modal = document.querySelector("#modal");
        if(elem_modal) elem_modal!.outerHTML = "";
    }
    state.modal = "auth";

    let elem_app = document.querySelector("#app");

    let elem_modal = document.createElement("div");
    elem_modal.id = "modal";

    let elem_auth = document.createElement("div");
    elem_auth.className = "auth";
    elem_auth.setAttribute("lc", "stop");
    elem_modal.appendChild(elem_auth);

    let elem_background = document.createElement("div");
    elem_background.className = "background";
    elem_modal.appendChild(elem_background);

    let elem_tabs = document.createElement("div");
    elem_tabs.className = "tabs";

    let elem_tab_login = document.createElement("div");
    elem_tab_login.className = "tab";
    elem_tab_login.classList.add("login");
    elem_tab_login.textContent = "Login";
    elem_tabs.appendChild(elem_tab_login);

    let elem_tab_register = document.createElement("div");
    elem_tab_register.className = "tab selected";
    elem_tab_register.classList.add("register");
    elem_tab_register.classList.add("selected");
    elem_tab_register.textContent = "Register";
    elem_tabs.appendChild(elem_tab_register);

    elem_auth.append(elem_tabs);

    let elem_container = document.createElement("div");
    elem_container.className = "container";

    let elem_login = document.createElement("form");
    //elem_login.onsubmit = () => {}

    elem_login.autocomplete = "off";
    elem_login.innerHTML = `
        <input type="email" id="email" name="email" placeholder="Email" autocomplete="off">
        <input type="password" id="password" name="password" placeholder="Password" autocomplete="off">
    `;

    let elem_button_login = document.createElement("button");
    elem_button_login.textContent = "Submit";
    elem_button_login.onclick = (e) => {
        e.preventDefault();
        let elem_login_email :HTMLInputElement|null = elem_login.querySelector("#email");
        let elem_login_password :HTMLInputElement|null = elem_login.querySelector("#password");

        let email = elem_login_email!.value;
        let password = elem_login_password!.value;
        socket_send(ACTION.AUTH_LOGIN, {email, password, agent: navigator.userAgent});
    }
    elem_login.appendChild(elem_button_login);

    let elem_register = document.createElement("form");
    elem_register.autocomplete = "off";
    elem_register.innerHTML = `
        <input type="email"    id="email"            name="email"            placeholder="Email" autocomplete="off">
        <input type="password" id="password"         name="password"         placeholder="Password"         autocomplete="off">
        <input type="password" id="password_confirm" name="password_confirm" placeholder="Confirm password" autocomplete="off">
    `;
    let elem_button_register = document.createElement("button");
    elem_button_register.textContent = "Submit";
    elem_button_register.onclick = (e) => {
        e.preventDefault();
        let elem_register_email :HTMLInputElement|null = elem_register.querySelector("#email");
        let elem_register_password :HTMLInputElement|null = elem_register.querySelector("#password");
        let elem_register_password_confirm :HTMLInputElement|null = elem_register.querySelector("#password_confirm");

        let email = elem_register_email!.value;
        let password = elem_register_password!.value;
        let password_confirm = elem_register_password_confirm!.value;
        let invite = localStorage.getItem("invite");

        if(password == password_confirm) {
            socket_send(ACTION.AUTH_REGISTER_FULL, {email, password, invite: invite, agent: navigator.userAgent});
        }
    }
    elem_register.appendChild(elem_button_register);

    elem_container.appendChild(elem_register);
    elem_auth.appendChild(elem_container);

    elem_tab_login.onclick = () => {
        if(elem_tab_login.classList.contains("selected")) return;
        elem_tab_login.classList.add("selected");
        elem_tab_register.classList.remove("selected");

        elem_container.innerHTML = "";
        elem_container.appendChild(elem_login);
    }

    elem_tab_register.onclick = () => {
        if(elem_tab_register.classList.contains("selected")) return;
        elem_tab_register.classList.add("selected");
        elem_tab_login.classList.remove("selected");

        elem_container.innerHTML = "";
        elem_container.appendChild(elem_register);
    }

    elem_app?.appendChild(elem_modal);
}