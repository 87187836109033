import "../css/style.scss";

import BarLeft from "./view/app/bar_left";
import BarRight from "./view/app/bar_right";
import Main from "./view/app/main";

import {views} from "./view";
import { auth, auth_exists, auth_is_user } from "./auth";
import {state} from "./state";
import {socket_connect} from "./socket"

import {router_init} from "./router";
import "./socket";

const MARGIN = 8;
const BAR_LEFT_LEFT_SIZE = 72;
const BAR_LEFT_RIGHT_SIZE = 240;

const BAR_LEFT_SIZE = BAR_LEFT_LEFT_SIZE + BAR_LEFT_RIGHT_SIZE + MARGIN;
const MAIN_VISIBILITY_WIDTH = 60;

const BAR_RIGHT_SIZE = 280;

export default class App {
    elem :HTMLElement;

    limit :number;
    bar :string;
    dir :string;
    moving :boolean;
    x_start :number;
    y_start :number;
    trigger :number;

    constructor() {
        this.limit = 60;
        this.bar = "";
        this.dir = "";
        this.moving = false;
        this.x_start = 0;
        this.y_start = 0;
        //this.trigger = 52;
        this.trigger = 0;

        let elem_important = document.createElement("div");
        document.body.appendChild(elem_important);
        elem_important.id = "important";

        this.elem = document.createElement("div");
        document.body.appendChild(this.elem);
        this.elem.id = "app";
        this.elem.style.height = `100%`;

        views.main = new Main();
        views.bar_left = new BarLeft();
        views.bar_right = new BarRight();
        resize();

        if(window.innerWidth < 540) {
            views.main.elem.onclick = () => {
                this.set_main();
            }

            this.elem.ontouchstart = (e) => {
                if(state.modal != "") return;

                this.moving = true;
                this.x_start = e.touches[0].clientX;
                this.y_start = e.touches[0].clientY;
            }

            this.elem.ontouchmove = (e) => {
                if(!this.moving) return;

                let dx = e.touches[0].clientX - this.x_start;
                let dy = e.touches[0].clientY - this.y_start;
                //if(this.bar == "" && dx <= 0 && bar_right) return;

                let elem_main = views.main.elem;
                let elem_left = views.bar_left.elem;
                let elem_right = views.bar_right.elem;

                
                if(this.dir == "") {
                    if(Math.abs(dx) > Math.abs(dy)) this.dir = "x";
                    else this.dir = "y";
                }
                if(this.dir == "y") return;

                if(this.bar == "") {
                    if(dx >= state.view.bar_left.width-this.trigger+(2*MARGIN)) { this.bar = "left"; return; }
                    else if(dx <= -state.view.bar_right.width-(2*MARGIN)) { this.bar = "right"; return; }

                    if(Math.abs(dx) >= this.trigger) {
                        //elem_main.style.left = (dx - (Math.sign(dx)*this.trigger)) + "px";
                        elem_main.style.left = dx + "px";
                        if(dx > 0) {
                            elem_left.style.zIndex = "10";
                            elem_right.style.zIndex = "0";
                        }
                        else {
                            elem_left.style.zIndex = "0";
                            elem_right.style.zIndex = "10";
                        }
                    }
                }
                else if(this.bar == "left") {
                    if(dx <= -state.view.bar_left.width-this.trigger) { this.bar = ""; return; }
                    else if(dx >= 0) return;
                    elem_main.style.left = (state.view.bar_left.width-this.trigger+dx) + "px";
                }
                else if(this.bar == "right") {
                    if(dx >= state.view.bar_right.width+(2*MARGIN)) { this.bar = ""; return; }
                    else if(dx <= 0) return;
                    elem_main.style.left = (-state.view.bar_right.width-(2*MARGIN)+dx) + "px";
                }
            }

            this.elem.ontouchend = (e) => {
                if(!this.moving) return;
                if(this.dir == "y") {this.dir = ""; return;}
                let dx = e.changedTouches[0].clientX - this.x_start;

                if(this.bar == "") {
                    if(dx > this.limit) this.set_left();
                    else if(dx < -this.limit) this.set_right();
                    else this.set_main();
                }
                else if(this.bar == "left") {
                    if(dx < -this.limit) this.set_main();
                    else this.set_left();
                }
                else if(this.bar == "right") {
                    if(dx > this.limit) this.set_main();
                    else this.set_right();
                }

                this.dir = "";
                this.moving = false;
            }
        }

        this.update();
    }

    set_main () {
        this.bar = "";
        views.main.elem.style.left = "0";
        views.main.elem.style.right = "0";
        // views.bar_left.elem.style.display = "none";
        // views.bar_right.elem.style.display = "none";
    }

    set_left () {
        this.bar = "left";
        views.main.elem.style.left = (state.view.bar_left.width+(2*MARGIN)) + "px";
        views.main.elem.style.right = "unset";
        // views.bar_left.elem.style.display = "block";
        // views.bar_right.elem.style.display = "none";
    }

    set_right () {
        this.bar = "right";
        views.main.elem.style.left = "unset";
        views.main.elem.style.right = (state.view.bar_right.width+(2*MARGIN)) + "px";
        // views.bar_left.elem.style.display = "none";
        // views.bar_right.elem.style.display = "block";
    }

    update () {
        this.elem.innerHTML = "";

        let bans :any[] = [];
        let is_banned = false;
        if(auth_exists()) {
            if("bans" in auth) bans = [...bans, ...auth.bans];
            if(auth_is_user() && "bans" in auth.user) bans = [...bans, ...auth.user.bans];
            for(let ban of bans) for(let vio of ban.violations) if(vio.target.type == 0) is_banned = true;
        }

        if(!is_banned) {
            this.update_bar_left();
            this.elem.appendChild(views.main.elem);
            this.update_bar_right();
        }
        else {
            this.elem.classList.add("banned");

            let elem_banned = document.createElement("div");
            elem_banned.textContent = "You are banned";

            let elem_banned_title = document.createElement("div");

            for(let ban of bans) {
                let elem_ban = document.createElement("div");
                elem_ban.className = "ban";
                for(let vio of ban.violations) {
                    let elem_vio = document.createElement("div");
                    elem_vio.className = "violation";
                    elem_vio.textContent = `${vio.description}`;
                    elem_ban.appendChild(elem_vio);
                }

                let elem_extra = document.createElement("div");
                elem_extra.className = "extra";
                elem_extra.textContent = `${ban.extra}`;
                elem_ban.appendChild(elem_extra);
                
                elem_banned.appendChild(elem_ban);
            }

            this.elem.appendChild(elem_banned);
        }
    }

    update_bar_left () {
        if(state.view.bar_left.is_open) {
            this.elem.appendChild(views.bar_left.elem);
        }
    }

    update_bar_right () {
        if(state.view.bar_right.is_open) {
            this.elem.appendChild(views.bar_right.elem);
        }
    }

    update_page () {
        let window_state = state.windows[state.window_selected];
        let window_view = views.window[state.window_selected];
        window_view.update_tab_and_page();
        views.bar_right.update();
        views.bar_left.update_select();
    }
}

export const resize = () => {
    const main :any = document.querySelector("#main")!;

    state.view.width = window.innerWidth;
    state.view.height = window.innerHeight;

    if(window.innerWidth < 540) {
        /* Mobile */
        state.view.main.width = state.view.width;
        views.main.elem.style.position = "absolute";
        views.main.elem.style.width = state.view.main.width + "px";
        views.main.elem.style.minWidth = state.view.main.width + "px";
        views.main.elem.style.maxWidth = state.view.main.width + "px";

        state.view.bar_left.width = (window.innerWidth * .8) - 2*MARGIN;
        views.bar_left.elem.style.position = "absolute";
        views.bar_left.elem.style.left = 0;
        views.bar_left.elem.style.width = state.view.bar_left.width + "px";
        views.bar_left.elem.style.minWidth = state.view.bar_left.width + "px";
        views.bar_left.elem.style.maxWidth = state.view.bar_left.width + "px";

        state.view.bar_right.width = (window.innerWidth * .8) - 2*MARGIN;
        views.bar_right.elem.style.position = "absolute";
        views.bar_right.elem.style.right = 0;
        views.bar_right.elem.style.width = state.view.bar_right.width + "px";
        views.bar_right.elem.style.minWidth = state.view.bar_right.width + "px";
        views.bar_right.elem.style.maxWidth = state.view.bar_right.width + "px";
    }
    else {
        /* Desktop */
        let bars_size = 0;
		let margin_width = 2 * MARGIN;
        if(state.view.bar_left.is_open) {
            bars_size += BAR_LEFT_SIZE;
            views.bar_left.elem.style.width = BAR_LEFT_SIZE + "px";
            views.bar_left.elem.style.minWidth = BAR_LEFT_SIZE + "px";
            views.bar_left.elem.style.maxWidth = BAR_LEFT_SIZE + "px";
			margin_width += MARGIN;
        }
        if(state.view.bar_right.is_open) {
            bars_size += BAR_RIGHT_SIZE;
            views.bar_right.elem.style.width = BAR_RIGHT_SIZE + "px";
            views.bar_right.elem.style.minWidth = BAR_RIGHT_SIZE + "px";
            views.bar_right.elem.style.maxWidth = BAR_RIGHT_SIZE + "px";
			margin_width += MARGIN;
        }

        state.view.main.width = state.view.width - bars_size - margin_width;
        views.main.elem.style.width = state.view.main.width + "px";
        views.main.elem.style.minWidth = state.view.main.width + "px";
        views.main.elem.style.maxWidth = state.view.main.width + "px";
    }
}

const service_worker_init = () => {
    if("serviceWorker" in navigator) {
        navigator.serviceWorker.register("/worker.js")
        .then(() => {
            console.log("Service worker registered");
        })
        .catch((err) => {
            console.error("Service worker was not able to register", err);
        });
    }
}

const service_worker_term = () => {
	if("serviceWorker" in navigator)
	navigator.serviceWorker.getRegistrations().then(registrations => {
		for(const registration of registrations) {
			registration.unregister();
		} 
	});
}

window.addEventListener('DOMContentLoaded', (event) => {
    //service_worker_init();
    service_worker_term();
    router_init();
	socket_connect();

    views.app = new App();
});

window.addEventListener("resize", () => {
    resize();
})

window.addEventListener("keydown", (e :any) => {
	if(state.modal && state.modal != "") {
		switch(state.modal) {
		case "gallery": {
			let view = views.modal.gallery;
			switch(e.keyCode) {
			case 27: view.close(); break;
			case 74: view.content_index_set(view.content_index+1); break;
			case 75: view.content_index_set(view.content_index-1); break;
			case 76: view.content_save(); break;
			}
		} break;
		}
		return;
	}
})

window.addEventListener("mousedown", (e :any) => {
	if(state.modal && state.modal != "") {
		switch(state.modal) {
		case "gallery": {
			let view = views.modal.gallery;
			switch(e.button) {
			case 1: view.close(); break;
			}
		} break;
		}
		return;
	}
})

window.addEventListener("wheel", (e :any) => {
	if(state.modal && state.modal != "") {
		switch(state.modal) {
		case "gallery": {
			let view = views.modal.gallery;
			if(e.deltaY < 0) view.content_index_set(view.content_index-1);
			else if(e.deltaY > 0) view.content_index_set(view.content_index+1);
		} break;
		}
		return;
	}
})