import {ACTION, socket_send, uploads_store} from "../../socket";
import {state} from "../../state";
import {auth, auth_exists, auth_profile} from "../../auth";
import {Author_Name, Author_Avatar} from "../modular/author";
import {Icon} from "../modular/icon";
import { UPLOAD_TARGET } from "../../upload";
import { file_upload } from "../modular/file";

const close_modal = () => {
    if(state.modal != "") {
        let elem_modal = document.querySelector("#modal");
        if(elem_modal) elem_modal.outerHTML = "";
    }
}

export class Create_Thread {
    board :any = {};
    thread :any = {
        board_id: "",
        subject: "",
        comment: "",
        picture: null,
    };

    elem :HTMLDivElement | null = null;
    elem_preview :HTMLDivElement | null = null;
    elem_button :HTMLButtonElement | null = null;

    constructor (board :any) {
        this.board = board;
        this.thread.board_id = board.id;
    }

    new () {
        close_modal();
        state.modal = "create_thread";

        let elem_app = document.querySelector("#app")!;

        let elem_modal = document.createElement("div");
        elem_modal.id = "modal";
        elem_app.appendChild(elem_modal);

        let elem_background = document.createElement("div");
        elem_background.className = "background";
        elem_modal.appendChild(elem_background);

        this.elem = document.createElement("div"); elem_modal.appendChild(this.elem);
        this.elem.className = "create_thread";
        this.elem.setAttribute("lc", "stop");

        let elem_options = document.createElement("div"); this.elem.appendChild(elem_options);
        elem_options.className = "options";

        let elem_top = document.createElement("div"); elem_options.appendChild(elem_top);
        elem_top.className = "top";

		let profile = auth_profile();
        let elem_top_profile = document.createElement("div"); elem_top.appendChild(elem_top_profile);
        elem_top_profile.className = "profile";
        elem_top_profile.appendChild(Author_Avatar(profile, {size: "64", specific: false})!);
        elem_top_profile.appendChild(Author_Name(profile, {specific: false})!);

        let elem_top_close = document.createElement("div"); elem_top.appendChild(elem_top_close);
        elem_top_close.className = "close";
        elem_top_close.innerHTML = Icon.Close;
        elem_top_close.onclick = () => close_modal();

        let elem_mid = document.createElement("div"); elem_options.appendChild(elem_mid);
        elem_mid.className = "mid";

        let elem_mid_board = document.createElement("div"); elem_mid.appendChild(elem_mid_board);
        elem_mid_board.className = "board";

		let elem_mid_board_title = document.createElement("div"); elem_mid_board.appendChild(elem_mid_board_title);
		elem_mid_board_title.className = "title";
        elem_mid_board_title.textContent = `BOARD`;

		let elem_mid_board_input = document.createElement("div"); elem_mid_board.appendChild(elem_mid_board_input);
		elem_mid_board_input.className = "input";
        elem_mid_board_input.textContent = `${this.board.name}`;

        let elem_mid_subject = document.createElement("div"); elem_mid.appendChild(elem_mid_subject);
        elem_mid_subject.className = "subject";

        let elem_mid_subject_title = document.createElement("div"); elem_mid_subject.appendChild(elem_mid_subject_title);
        elem_mid_subject_title.className = "title";
        elem_mid_subject_title.textContent = "Subject";

		let elem_mid_subject_input = document.createElement("input"); elem_mid_subject.appendChild(elem_mid_subject_input);
		elem_mid_subject_input.className = "input";
        elem_mid_subject_input.type = "text";
        elem_mid_subject_input.name = "subject";
        elem_mid_subject_input.placeholder = "Subject";
        elem_mid_subject_input.oninput = () => {this.thread.subject = elem_mid_subject_input.value; this.button_check();};

        let elem_mid_comment = document.createElement("div"); elem_mid.appendChild(elem_mid_comment);
        elem_mid_comment.className = "comment";

        let elem_mid_comment_title = document.createElement("div"); elem_mid_comment.appendChild(elem_mid_comment_title);
        elem_mid_comment_title.className = "title";
        elem_mid_comment_title.textContent = "Comment";

		let elem_mid_comment_input = document.createElement("input"); elem_mid_comment.appendChild(elem_mid_comment_input);
		elem_mid_comment_input.className = "input";
        elem_mid_comment_input.type = "text";
        elem_mid_comment_input.name = "comment";
        elem_mid_comment_input.placeholder = "Comment";
        elem_mid_comment_input.oninput = () => {this.thread.comment = elem_mid_comment_input.value};

		let elem_mid_picture = document.createElement("div"); elem_mid.appendChild(elem_mid_picture);
		elem_mid_picture.className = "picture content";

        let elem_mid_picture_title = document.createElement("div"); elem_mid_picture.appendChild(elem_mid_picture_title);
        elem_mid_picture_title.className = "title";
        elem_mid_picture_title.textContent = "Picture";

		let elem_mid_picture_input = document.createElement("div"); elem_mid_picture.appendChild(elem_mid_picture_input);
		elem_mid_picture_input.className = "input";

		/* Picture */
		let elem_mid_picture_input_upload = document.createElement("div"); elem_mid_picture_input.appendChild(elem_mid_picture_input_upload);
		let elem_mid_picture_input_remove = document.createElement("div"); elem_mid_picture_input.appendChild(elem_mid_picture_input_remove);

		/* upload */
		elem_mid_picture_input_upload.className = "upload";

		let elem_mid_picture_input_upload_label = document.createElement("label"); elem_mid_picture_input_upload.appendChild(elem_mid_picture_input_upload_label);
		elem_mid_picture_input_upload_label.className = "label";
		elem_mid_picture_input_upload_label.htmlFor = "picture_input";
		elem_mid_picture_input_upload_label.textContent = "Upload picture";

		let elem_mid_picture_input_upload_input = document.createElement("input"); elem_mid_picture_input_upload.appendChild(elem_mid_picture_input_upload_input);
		elem_mid_picture_input_upload_input.id = "picture_input";
		elem_mid_picture_input_upload_input.className = "input";
		elem_mid_picture_input_upload_input.type = "file";
		elem_mid_picture_input_upload_input.multiple = false;
		elem_mid_picture_input_upload_input.style.display = "none";
		elem_mid_picture_input_upload_input.onchange = (e :any) => {
			if (!e.target.files || !e.target.files.length) return;
			elem_mid_picture_input_upload.className = "change";
			elem_mid_picture_input_upload_label.textContent = "Change picture";
			elem_mid_picture_input_remove.style.display = "flex";
			this.file_add(e.target.files[0], this.elem_preview);
		}

		/* remove */
		elem_mid_picture_input_remove.style.display = "none";
		elem_mid_picture_input_remove.className = "remove";
		elem_mid_picture_input_remove.textContent = "Remove picture";
		elem_mid_picture_input_remove.onclick = (e :any) => {
			elem_mid_picture_input_remove.style.display = "none";
			elem_mid_picture_input_upload.className = "upload";
			elem_mid_picture_input_upload_label.textContent = "Upload picture";
			this.thread.picture = null;
			this.button_check();
		}

        let elem_bot = document.createElement("div"); elem_options.appendChild(elem_bot);
        elem_bot.className = "bot";

        let elem_bot_button = document.createElement("button"); elem_bot.appendChild(elem_bot_button);
        this.elem_button = elem_bot_button;

        elem_bot_button.className = "button";
        elem_bot_button.textContent = "No picture";
        elem_bot_button.disabled = true;
        elem_bot_button.onclick = (e) => {
            e.preventDefault();
            socket_send(ACTION.THREAD_ADD, this.thread);
        }
		this.button_check();
    }

    button_check = () => {
        let check_subject = this.thread.subject != "";
        let check_picture = this.thread.picture != null;

        if(check_subject && check_picture) {
            this.elem_button!.disabled = false;
            this.elem_button!.textContent = "Submit";
        }
        else {
            this.elem_button!.disabled = true;
            if(!check_subject && !check_picture) this.elem_button!.textContent = "Subject & Picture required";
            else if(!check_subject) this.elem_button!.textContent = "Subject requried";
            else if(!check_picture) this.elem_button!.textContent = "Picture requried";
        }
    }

    file_add = (file :File, elem_status: HTMLElement) => {
		//elem_status.textContent = ``;

		let xhr = new XMLHttpRequest();
		xhr.upload.onprogress = (e :any) => {
			const percentage = (e.loaded / e.total)*100.0;
			//elem_status.textContent = `${Math.round(percentage)}%`;
		}
		xhr.onload = (e :any) => {
			if(xhr.status == 200) {
				const res = JSON.parse(xhr.response);
				uploads_store([res]);

				//elem_status.innerHTML = `<img src="${URL.createObjectURL(file)}" />`;
				this.thread.picture = res.id;
				this.button_check();
			}
		}
        file_upload(xhr, file, {});
    }
}

export class Create_Board {
    board :any = {
        name: "",
        description: "",
        banner: null,
        picture: null,

        safety: 0,
		privacy: 0,
    }
    elem :HTMLElement | null = null;
    elem_preview :HTMLElement | null = null;
    elem_button :HTMLButtonElement | null = null;

    constructor () {
    }

    new () {
        close_modal();
        state.modal = "create_board";

        let elem_app = document.querySelector("#app")!;
        let elem_modal = document.createElement("div");
        elem_modal.id = "modal";
        elem_app.appendChild(elem_modal);

        let elem_background = document.createElement("div");
        elem_background.className = "background";
        elem_modal.appendChild(elem_background);

        this.elem = document.createElement("div");
        this.elem.className = "create_board";
        this.elem.setAttribute("lc", "stop");
        elem_modal.appendChild(this.elem);

        let elem_options = document.createElement("div"); this.elem.appendChild(elem_options);
        elem_options.className = "options";

        let elem_top = document.createElement("div"); elem_options.appendChild(elem_top);
        elem_top.className = "top";

		let profile = auth_profile();
        let elem_top_profile = document.createElement("div"); elem_top.appendChild(elem_top_profile);
        elem_top_profile.className = "profile";
        elem_top_profile.appendChild(Author_Avatar(profile, {size: "64", specific: false})!);
        elem_top_profile.appendChild(Author_Name(profile, {specific: false})!);

        let elem_top_close = document.createElement("div"); elem_top.appendChild(elem_top_close);
        elem_top_close.className = "close";
        elem_top_close.innerHTML = Icon.Close;
        elem_top_close.onclick = () => close_modal();

        let elem_mid = document.createElement("div"); elem_options.appendChild(elem_mid);
        elem_mid.className = "mid";

        let elem_mid_name = document.createElement("div"); elem_mid.appendChild(elem_mid_name);
        elem_mid_name.className = "name";

		let elem_mid_name_title = document.createElement("div"); elem_mid_name.appendChild(elem_mid_name_title);
		elem_mid_name_title.className = "title";
		elem_mid_name_title.textContent = "Name";

        let elem_mid_name_input = document.createElement("input"); elem_mid_name.appendChild(elem_mid_name_input);
        elem_mid_name_input.type = "text";
        elem_mid_name_input.name = "name";
        elem_mid_name_input.placeholder = "Name";
        elem_mid_name_input.oninput = () => {this.board.name = elem_mid_name_input.value; this.button_check();};

        let elem_mid_description = document.createElement("div"); elem_mid.appendChild(elem_mid_description);
        elem_mid_description.className = "description";

		let elem_mid_description_title = document.createElement("div"); 
		elem_mid_description_title.className = "title";
		elem_mid_description_title.textContent = "Description";
        elem_mid_description.appendChild(elem_mid_description_title);

        let elem_mid_description_input = document.createElement("textarea"); elem_mid_description.appendChild(elem_mid_description_input);
        elem_mid_description_input.name = "description";
        elem_mid_description_input.placeholder = "Description";
        elem_mid_description_input.oninput = () => {this.board.description = elem_mid_description_input.value;};

		/* Content */
		let elem_mid_content = document.createElement("div");
		elem_mid_content.className = "content";
		elem_mid.appendChild(elem_mid_content);

		let elem_mid_content_title = document.createElement("div"); elem_mid_content.appendChild(elem_mid_content_title);
		elem_mid_content_title.className = "title";
		elem_mid_content_title.textContent = "Content";

		let elem_mid_content_input = document.createElement("div"); elem_mid_content.appendChild(elem_mid_content_input);
		elem_mid_content_input.className = "input";

		/* Picture */
		let elem_mid_content_picture = document.createElement("div"); elem_mid_content_input.appendChild(elem_mid_content_picture);
		elem_mid_content_picture.className = "picture";

		let elem_mid_content_picture_upload = document.createElement("div"); elem_mid_content_picture.appendChild(elem_mid_content_picture_upload);
		let elem_mid_content_picture_remove = document.createElement("div"); elem_mid_content_picture.appendChild(elem_mid_content_picture_remove);

		/* upload */
		elem_mid_content_picture_upload.className = "upload";

		let elem_mid_content_picture_upload_label = document.createElement("label"); elem_mid_content_picture_upload.appendChild(elem_mid_content_picture_upload_label);
		elem_mid_content_picture_upload_label.className = "label";
		elem_mid_content_picture_upload_label.htmlFor = "picture_input";
		elem_mid_content_picture_upload_label.textContent = "Upload picture";

		let elem_mid_content_picture_upload_input = document.createElement("input"); elem_mid_content_picture_upload.appendChild(elem_mid_content_picture_upload_input);
		elem_mid_content_picture_upload_input.id = "picture_input";
		elem_mid_content_picture_upload_input.className = "input";
		elem_mid_content_picture_upload_input.type = "file";
		elem_mid_content_picture_upload_input.multiple = false;
		elem_mid_content_picture_upload_input.style.display = "none";
		elem_mid_content_picture_upload_input.onchange = (e :any) => {
			if (!e.target.files || !e.target.files.length) return;
			elem_mid_content_picture_upload.style.display = "none";
			elem_mid_content_picture_remove.style.display = "block";
			this.file_add(e.target.files[0], "picture", this.elem_preview);
		}

		/* remove */
		elem_mid_content_picture_remove.style.display = "none";
		elem_mid_content_picture_remove.className = "remove";
		elem_mid_content_picture_remove.textContent = "Remove picture";
		elem_mid_content_picture_remove.onclick = (e :any) => {
			elem_mid_content_picture_remove.style.display = "none";
			elem_mid_content_picture_upload.style.display = "block";
			//this.profile.picture = "";
			//this.packet.picture = "";

			//elem_picture.innerHTML = "";
			//elem_picture.className = "picture"
			//elem_{icture.style.backgroundColor = this.profile.color_primary;
		}

		/* Banner */
		let elem_mid_content_banner = document.createElement("div"); elem_mid_content_input.appendChild(elem_mid_content_banner);
		elem_mid_content_banner.className = "banner";

		let elem_mid_content_banner_upload = document.createElement("div"); elem_mid_content_banner.appendChild(elem_mid_content_banner_upload);
		let elem_mid_content_banner_remove = document.createElement("div"); elem_mid_content_banner.appendChild(elem_mid_content_banner_remove);

		/* upload */
		elem_mid_content_banner_upload.className = "upload";

		let elem_mid_content_banner_upload_label = document.createElement("label"); elem_mid_content_banner_upload.appendChild(elem_mid_content_banner_upload_label);
		elem_mid_content_banner_upload_label.className = "label";
		elem_mid_content_banner_upload_label.htmlFor = "banner_input";
		elem_mid_content_banner_upload_label.textContent = "Upload banner";

		let elem_mid_content_banner_upload_input = document.createElement("input"); elem_mid_content_banner_upload.appendChild(elem_mid_content_banner_upload_input);
		elem_mid_content_banner_upload_input.id = "banner_input";
		elem_mid_content_banner_upload_input.className = "input";
		elem_mid_content_banner_upload_input.type = "file";
		elem_mid_content_banner_upload_input.multiple = false;
		elem_mid_content_banner_upload_input.style.display = "none";
		elem_mid_content_banner_upload_input.onchange = (e :any) => {
			if (!e.target.files || !e.target.files.length) return;
			elem_mid_content_banner_upload.style.display = "none";
			elem_mid_content_banner_remove.style.display = "block";
			this.file_add(e.target.files[0], "banner", this.elem_preview);
		}

		/* remove */
		elem_mid_content_banner_remove.style.display = "none";
		elem_mid_content_banner_remove.className = "remove";
		elem_mid_content_banner_remove.textContent = "Remove banner";
		elem_mid_content_banner_remove.onclick = (e :any) => {
			elem_mid_content_banner_remove.style.display = "none";
			elem_mid_content_banner_upload.style.display = "block";
			//this.profile.banner = "";
			//this.packet.banner = "";

			//elem_banner.innerHTML = "";
			//elem_banner.className = "banner"
			//elem_banner.style.backgroundColor = this.profile.color_primary;
		}

		/* Privacy */
		let elem_mid_privacy_safety = document.createElement("div"); elem_mid.appendChild(elem_mid_privacy_safety);
		elem_mid_privacy_safety.className = "privacy_safety";

		let elem_mid_privacy_safety_title = document.createElement("div"); elem_mid_privacy_safety.appendChild(elem_mid_privacy_safety_title);
		elem_mid_privacy_safety_title.className = "title";
		elem_mid_privacy_safety_title.textContent = "Privacy & Safety";

		let elem_mid_privacy_safety_input = document.createElement("div"); elem_mid_privacy_safety.appendChild(elem_mid_privacy_safety_input);
		elem_mid_privacy_safety_input.className = "input";

		let elem_mid_privacy = document.createElement("div"); elem_mid_privacy_safety_input.appendChild(elem_mid_privacy);
		elem_mid_privacy.className = "privacy";

		/* Privacy */
		let privacy = {"Private": 0, "Public": 1};

        let elem_mid_privacy_selected = document.createElement("div"); elem_mid_privacy.appendChild(elem_mid_privacy_selected);
        elem_mid_privacy_selected.className = "selected item";

        let elem_mid_privacy_selected_name = document.createElement("div"); elem_mid_privacy_selected.appendChild(elem_mid_privacy_selected_name);
        elem_mid_privacy_selected_name.className = "name";

        const privacy_select = (privacy_level :number) => {
            this.board.privacy = privacy_level;
            elem_mid_privacy_selected_name.textContent = Object.keys(privacy)[privacy_level];
        }
        privacy_select(0);

        let elem_mid_privacy_dropdown = document.createElement("div"); elem_mid_privacy.appendChild(elem_mid_privacy_dropdown);
        elem_mid_privacy_dropdown.className = "dropdown";
        elem_mid_privacy_dropdown.style.display = "none";

        for(let i = 0; i < 2; ++i) {
            let priv =  Object.entries(privacy)[i];

            let elem_mid_privacy_item = document.createElement("div"); elem_mid_privacy_dropdown.appendChild(elem_mid_privacy_item);
            elem_mid_privacy_item.className = "item";

            let elem_mid_privacy_item_name = document.createElement("div"); elem_mid_privacy_item.appendChild(elem_mid_privacy_item_name);
            elem_mid_privacy_item_name.className = "name";
            elem_mid_privacy_item_name.textContent = priv[0];

            elem_mid_privacy_item.onclick = (e :any) => {
                elem_mid_privacy_dropdown.style.display = "none";
                privacy_select(i);
            }
        }
        elem_mid_privacy_selected.onclick = (e: any) => {
            elem_mid_privacy_dropdown.style.display = "block";
        }


		/* Safety */
        let safety = {
            "SFW":  "green",
            "NSFW": "yellow",
            "NSFL": "red",
        };
        let safety_default = Object.entries(safety)[0];

        let elem_mid_safety = document.createElement("div"); elem_mid_privacy_safety_input.appendChild(elem_mid_safety);
        elem_mid_safety.className = "safety";

        let elem_mid_safety_selected = document.createElement("div"); elem_mid_safety.appendChild(elem_mid_safety_selected);
        elem_mid_safety_selected.className = "selected item";
        let elem_mid_safety_selected_color = document.createElement("div"); elem_mid_safety_selected.appendChild(elem_mid_safety_selected_color);
        elem_mid_safety_selected_color.className = "color";

        let elem_mid_safety_selected_name = document.createElement("div"); elem_mid_safety_selected.appendChild(elem_mid_safety_selected_name);
        elem_mid_safety_selected_name.className = "name";

        const safety_select = (safety_level :number) => {
            this.board.safety = safety_level;
            elem_mid_safety_selected_color.style.backgroundColor = Object.entries(safety)[safety_level][1];
            elem_mid_safety_selected_name.textContent = Object.entries(safety)[safety_level][0];
        }
        safety_select(0);

        let elem_mid_safety_dropdown = document.createElement("div"); elem_mid_safety.appendChild(elem_mid_safety_dropdown);
        elem_mid_safety_dropdown.className = "dropdown";
        elem_mid_safety_dropdown.style.display = "none";

        for(let i = 0; i < 3; ++i) {
            let safe =  Object.entries(safety)[i];

            let elem_mid_safety_item = document.createElement("div"); elem_mid_safety_dropdown.appendChild(elem_mid_safety_item);
            elem_mid_safety_item.className = "item";

            let elem_mid_safety_item_color = document.createElement("div"); elem_mid_safety_item.appendChild(elem_mid_safety_item_color);
            elem_mid_safety_item_color.className = "color";
            elem_mid_safety_item_color.style.backgroundColor = safe[1];

            let elem_mid_safety_item_name = document.createElement("div"); elem_mid_safety_item.appendChild(elem_mid_safety_item_name);
            elem_mid_safety_item_name.className = "name";
            elem_mid_safety_item_name.textContent = safe[0];

            elem_mid_safety_item.onclick = (e :any) => {
                elem_mid_safety_dropdown.style.display = "none";
                safety_select(i);
            }
        }

        elem_mid_safety_selected.onclick = (e: any) => {
            elem_mid_safety_dropdown.style.display = "block";
        }

        let elem_bot = document.createElement("div"); elem_options.appendChild(elem_bot);
        elem_bot.className = "bot";

        let elem_bot_button = document.createElement("button");
        elem_bot_button.className = "button";
        elem_bot_button.textContent = "Submit";
        elem_bot_button.disabled = true;
        elem_bot_button.onclick = (e) => {
            e.preventDefault();

            const name = this.board.name;
            const description = this.board.description;
            const banner = this.board.banner;
            const picture = this.board.picture;
            const safety = this.board.safety;
			const is_public = this.board.privacy == 1;
            socket_send(ACTION.BOARD_ADD, {name, description, banner, picture, safety, is_public});
        }
        elem_bot.appendChild(elem_bot_button);
        this.elem_button = elem_bot_button;
    }

    button_check = () => {
        let check_name = this.board.name != "";
		this.elem_button!.disabled = !check_name;
    }

    file_add = (file :File, path :string, elem_status: HTMLElement) => {
		//elem_status.textContent = ``;

		let xhr = new XMLHttpRequest();
		xhr.upload.onprogress = (e :any) => {
			const percentage = (e.loaded / e.total)*100.0;
			//elem_status.textContent = `${Math.round(percentage)}%`;
		}
		xhr.onload = (e :any) => {
			if(xhr.status == 200) {
				const res = JSON.parse(xhr.response);
				uploads_store([res]);

				//elem_status.innerHTML = `<img src="${URL.createObjectURL(file)}" />`;
				this.board[path] = res.id;
			}
		}
        file_upload(xhr, file, {});
    }
}

export class Create_EmojiSet {
    emoji_set = {
        name: "",
    }

    elem :HTMLElement | null = null;
    elem_button :HTMLButtonElement | null = null;

    constructor () {
    }

    new () {
        close_modal();
        state.modal = "create_emoji_set";

        let elem_app = document.querySelector("#app")!;
        let elem_modal = document.createElement("div");
        elem_modal.id = "modal";
        elem_app.appendChild(elem_modal);

        let elem_background = document.createElement("div");
        elem_background.className = "background";
        elem_modal.appendChild(elem_background);

        this.elem = document.createElement("div");
        this.elem.className = "create_emoji_set";
        this.elem.setAttribute("lc", "stop");
        elem_modal.appendChild(this.elem);

        let elem_options = document.createElement("div"); this.elem.appendChild(elem_options);
        elem_options.className = "options";

        let elem_top = document.createElement("div"); elem_options.appendChild(elem_top);
        elem_top.className = "top";

        let elem_top_close = document.createElement("div"); elem_top.appendChild(elem_top_close);
        elem_top_close.className = "close";
        elem_top_close.innerHTML = Icon.Close;
        elem_top_close.onclick = () => close_modal();

        let elem_mid = document.createElement("div"); elem_options.appendChild(elem_mid);
        elem_mid.className = "mid";

        let elem_mid_name = document.createElement("div"); elem_mid.appendChild(elem_mid_name);
        elem_mid_name.className = "name";

		let elem_mid_name_title = document.createElement("div"); elem_mid_name.appendChild(elem_mid_name_title);
		elem_mid_name_title.className = "title";
		elem_mid_name_title.textContent = "Name";

        let elem_mid_name_input = document.createElement("input"); elem_mid_name.appendChild(elem_mid_name_input);
        elem_mid_name_input.type = "text";
        elem_mid_name_input.name = "name";
        elem_mid_name_input.placeholder = "Name";
        elem_mid_name_input.oninput = () => {this.emoji_set.name = elem_mid_name_input.value; this.button_check();};

        let elem_bot = document.createElement("div"); elem_options.appendChild(elem_bot);
        elem_bot.className = "bot";

        let elem_bot_button = document.createElement("button");
        elem_bot_button.className = "button";
        elem_bot_button.textContent = "Submit";
        elem_bot_button.disabled = true;
        elem_bot_button.onclick = (e) => {
            e.preventDefault();

            const name = this.emoji_set.name;
            socket_send(ACTION.EMOJI_SET_ADD, {name});
        }
        elem_bot.appendChild(elem_bot_button);
        this.elem_button = elem_bot_button;
    }

    button_check () {
        let check_name = this.emoji_set.name != "";
		this.elem_button!.disabled = !check_name;
    }
}